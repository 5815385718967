import helpers from "../config";

export const GET_INTERVIEW = "GET_INTERVIEW";
export const GET_INTERVIEW_DATA = "GET_INTERVIEW_DATA";
export const GET_INTERVIEW_FAIL = "GET_INTERVIEW_FAIL";

export const GET_VIDEOTIPS = "GET_VIDEOTIPS";
export const GET_VIDEOTIPS_DATA = "GET_VIDEOTIPS_DATA";
export const GET_VIDEOTIPS_FAIL = "GET_VIDEOTIPS_FAIL";

export const getInterviewTips = () => {
  return (dispatch) => {
    dispatch({ type: GET_INTERVIEW })
    helpers.getServerUrl().then(url => {
      var url = url.serverUrl
      fetch(url + "/jobseeker/getAllIntTips", {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        },
      })
        .then(response => response.json())
        .then(response => {
          dispatch({
            type: GET_INTERVIEW_DATA,
            payload: response
          })
        })
        .catch(err => {
          dispatch({
            type: GET_INTERVIEW_FAIL,
            payload: err
          })
        })
    })
  }
}

export const getVideoTips = () => {
  return (dispatch) => {
    dispatch({ type: GET_VIDEOTIPS })
    helpers.getServerUrl().then(url => {
      var url = url.serverUrl
      fetch(url + "/admin/getVideoTips", {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        },
      })
        .then(response => response.json())
        .then(responseData => {
          dispatch({
            type: GET_VIDEOTIPS_DATA,
            payload: responseData.videos
          })
        })
        .catch(err => {
          dispatch({
            type: GET_VIDEOTIPS_FAIL,
            payload: err
          })
        })
    })
  }
}
