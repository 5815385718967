import React from "react";
import { Link } from "react-router-dom";
import helpers from "../config";
import '../_style/profile.css';
import Strings from "../utilities/stringlanguage";
import Webheader from "../components/webheader";
import { Route } from 'react-router-dom';
import Images from "../Images";
import Footer from "../components/footer";
import LoadingOverlay from "react-loading-overlay";
import ModalDiv from "../components/modal";
import { getJobseekerProfile } from "../actions/profileAction";
import { connect } from "react-redux";
var jobseekerId;
var url;
var imageUrl;
var selectedLang;
var valid = "#success";
var myData;
class AboutMe extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dataFound: false,
			loading: true,
			aboutMeNew: myData,
			aboutMe: myData,
			valid: "#success"
		};
	}
	async componentDidMount() {
		let apiUrl = await helpers.getServerUrl()
		url = apiUrl.serverUrl
		imageUrl = apiUrl.imageUrl
		selectedLang = localStorage.getItem("selectedLang")
		if (selectedLang != null && selectedLang != undefined && selectedLang != "") {
			Strings.setLanguage(selectedLang);
		}
		this.state.isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
		jobseekerId = localStorage.getItem("jobseekerId");
		this.props.getJobseekerProfile(jobseekerId);
		this.setState({ aboutMe: myData, aboutMeNew: myData })
		window.scroll(0, 0);
	}
	componentWillReceiveProps(nextProps) {
		this.setState({ aboutMe: nextProps.jobseekerData.aboutMe, aboutMeNew: nextProps.jobseekerData.aboutMe })
	}

	handleaboutme = (e) => {
		this.setState({ aboutMe: e.target.value });
		if (this.state.aboutMe != null) {
			if (this.state.aboutMe != "" && this.state.aboutMe.length > 20) {
				this.setState({ valid: "#success" });
			}
			else {
				this.setState({ valid: "#notvalid" });
			}
		}

	};

	discard = e => {
		this.setState({ aboutMe: this.state.aboutMeNew });
		this.props.history.push('/aboutme');
	};

	updateAboutme = async (e) => {
		if (this.state.aboutMe !== null && this.state.aboutMe !== "" && this.state.aboutMe !== undefined) {
			try {
				let response = await fetch(url + "/jobseeker/updateProfile", {
					method: "POST",
					headers: {
						"Content-Type": "application/json"
					},
					body: JSON.stringify({
						aboutMe: this.state.aboutMe.trim(),
						jobseekerId: jobseekerId,
					})
				})
				response = await response.json();
				if (response[0].success) {
					this.props.history.push("/profile");
				}
			}
			catch (err) {
				console.log(err);
			}
		}
	}

	render() {
		let { jobseekerData, jsDataError, jsDataLoading } = this.props;
		if(jobseekerData !=null && jobseekerData !=undefined && jobseekerData !=""){
			jsDataLoading = false
		}
		myData = jobseekerData.aboutMe
		return (
			<LoadingOverlay
				active={jsDataLoading}
				spinner={<div className="loading-aboutme">
					<center className=" bg-dark3 rounded mt-5 col-md-10 col-sm-6 p-2 col-12">
						<h4 className="loadingText pt-1 mb-1 d-sm-block d-md-block">{Strings.loadingProfileSummary}</h4>
						<h6 className="text-white pt-1 pb-1 d-block d-sm-none d-md-none"></h6>
						<img src={Images.loaderWhite} alt="White Image" className="pb-2" width="30" />
					</center>
				</div>}
			>
				<div>
					<div className="container col-md-6 No-Pading col-lg-6 col-sm-6 col-12">
						{this.state.aboutMeNew === this.state.aboutMe ?
							<Webheader pageName={Strings.aboutMe} aboutMeState={false} backLink="/profile" />
							:
							<Webheader pageName={Strings.aboutMe} aboutMeState={true} />
						}
						<div className="container-fluid pt-1">
							<div className="form-group">
								<label className="inputHeadingText ml-1 mt-3 font-weight-bold">{Strings.profileSummary}</label>
								<textarea value={this.state.aboutMe} onChange={e => this.handleaboutme(e)} className="form-control col-12 col-md-12 aboutMeText" rows="7" placeholder={Strings.profileBriefDesc}></textarea>
							</div>
							<div className="d-flex justify-content-center">
								<div type="submit" data-toggle="modal" data-target={this.state.valid} className="btn btn-success col-md-12 col-12 mt-3 rounded">{Strings.submit}</div>
							</div>
						</div>
					</div>
					{/* success modal start*/}
					<ModalDiv singleBtnModal={true} modalId="success" success={true} crossBtn={true} modalText={Strings.ProfileUpdatedSuccessfully} btnText1={Strings.oK} buttonClick={() => this.updateAboutme()} />
					{/* success modal end*/}
					{/* valid true modal*/}
					<ModalDiv singleBtnModal={true} modalId="valid" twoBtn={true} crossBtn={true} modalText={Strings.aboutTextSave} discardText={Strings.discardAboutText} discardFun={() => this.discard()} discardSaveText={Strings.discardAboutSave} buttonClick={() => this.updateAboutme()} />
					{/* valid false modal*/}
					<ModalDiv singleBtnModal={true} modalId="notvalid" crossBtn={true} modalText={Strings.profileSummaryShort} btnText1={Strings.oK} />
					<div className="container-fluid bg-light col-md-6 col-lg-6 col-sm-6 col-12 No-Pading">
						<Route component={Footer} className="d-none d-md-block d-lg-block d-sm-block d-xl-block" />
					</div>
				</div>
			</LoadingOverlay>
		);
	}
}

const mapStateToProps = state => {

	return {
		jobseekerData: state.profileReducer.jobseekerData,
		jsDataLoading: state.profileReducer.jsDataLoading,
		jsDataError: state.profileReducer.jsDataError,
	};
};
const mapDispatchToProps = dispatch => {
	return {
		getJobseekerProfile: jobseekerId => dispatch(getJobseekerProfile(jobseekerId)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(AboutMe);