 import firebase from 'firebase';
 export const initializeFirebase = () => {
   firebase.initializeApp({
    apiKey: "AIzaSyC9vPXVTk-zWIrHyW7YF1ycFHcTJZGuRLE",
    authDomain: "jhatpatjobs-f06b2.firebaseapp.com",
    databaseURL: "https://jhatpatjobs-f06b2.firebaseio.com",
    projectId: "jhatpatjobs-f06b2",
    storageBucket: "jhatpatjobs-f06b2.appspot.com",
    messagingSenderId: "395204668867",
    appId: "1:395204668867:web:997a84a2be661371fbe176",
    measurementId: "G-BCD370PP49"
   });
 }
 
 export const askForPermissioToReceiveNotifications = async () => {
  try {
    const messaging = firebase.messaging();
    await messaging.requestPermission();
    const token = await messaging.getToken();
    // console.log('Web Token id---------', token);
    localStorage.setItem("tokenId",token);
    return token;
  } catch (error) {
    // console.error(error);
  }
}
