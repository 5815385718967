import React, { Component } from "react";
import Images from "../Images";
import '../_style/profile.css';
import Webheader from "../components/webheader";
import { Link, Route } from 'react-router-dom';
import Strings from "../utilities/stringlanguage";
import GoogleDocsViewer from 'react-google-docs-viewer';
import Footer from "../components/footer";
const FileSaver = require('file-saver');
var jobseekerId, jobseekerName;
var resumeLink;
var selectedLang;
export default class ViewResume extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    resumeLink = this.props.location.state.resume;
    this.state.isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
    jobseekerId = localStorage.getItem("jobseekerId");
    jobseekerName = localStorage.getItem("givenName");
    this.setState({ loading: false })
    selectedLang = localStorage.getItem("selectedLang")
		if(selectedLang != null && selectedLang != undefined && selectedLang != "")
		{
		  Strings.setLanguage(selectedLang);
		}
  }

  downloadResume = () => {
    let type = this.props.location.state.resume.substring(
      this.props.location.state.resume.lastIndexOf(".") + 1,
      this.props.location.state.resume.length
    );
    FileSaver.saveAs(this.props.location.state.resume, (jobseekerName + "_resume"));
  }
  render() {
    if (this.state.loading) {
      return (
        <center>
          <img src={Images.loader} alt="Resume Loader" />
        </center>
      )
    }
    return (
      <div className="container col-md-6 No-Pading col-lg-6 col-sm-6 col-12">
        <Webheader pageName={Strings.previewResume} backLink="/profile"/>
        <GoogleDocsViewer
          className="col-md-12 col-12 col-sm-12"
          height='600px' width='100%'
          fileUrl={resumeLink}
        />
        <div onClick={() => this.downloadResume()} className='bg-success col-md-12 col-lg-12 col-sm-12 col-12 
        pointer resumeCls'>
          <p className="text-white text-uppercase text-center viewResume">{Strings.download} </p>
        </div>
        <div className="container-fluid bg-light col-md-12 col-lg-12 col-sm-12 col-12 No-Pading">
          <Route component={Footer} className="d-none d-md-block d-lg-block d-sm-block d-xl-block" />
        </div>
      </div>
    )
  }
}
