import React, { Component } from "react";
import helpers from "../config";
import Images from "../Images";
import $ from 'jquery';
import '../_style/interest.css';
import Strings from "../utilities/stringlanguage";
import Webheader from "../components/webheader";
import { Link, Route } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import Footer from "../components/footer";
import ModalDiv from "../components/modal";
var interests = [];
var jobseekerId;
var selectedValues = [];
var url, imageUrl;
var selectedLang;
var title;
var keywords;
var description;
var mydata;
var maxShow;
export default class Interest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      selectedJobs: [],
      maxShow:false
    };
    this.goBack = this.goBack.bind(this);
  }
  async componentDidMount() {
    let apiUrl = await helpers.getServerUrl()
    url = apiUrl.serverUrl
    imageUrl = apiUrl.imageUrl
    selectedLang = localStorage.getItem("selectedLang")
    if (selectedLang != null && selectedLang != undefined && selectedLang != "") {
      Strings.setLanguage(selectedLang);
    }
    jobseekerId = localStorage.getItem("jobseekerId");
    this.getInterest();
  }
  goBack() {
    this.props.history.go(-2);
  }
  getInterest = async (e) => {
    interests = [];
    try {
      let response = await fetch(url + "/jobs/getAllJobsTypes", {
        method: "GET"
      })
      response = await response.json();
      if (response !== null && response !== undefined && response.length > 0) {
        response.forEach((data, index) => {
          interests.push({
            "label": data.jobName,
            "id": index,
            "isSelected": false,
          })
        })
      }
      this.isInterestSelected(interests);
      this.setState({
        interests: interests,
        loading: false
      });
    }
    catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }
  }
  isInterestSelected = async (allJobProfile) => {
    selectedValues = []
    try {
      let response = await fetch(url + "/jobseeker/interestDetail", {
        method: "POST",
        headers: {
          "content-type": "application/json"
        },
        body: JSON.stringify({
          jobseekerId: jobseekerId
        })
      })
      response = await response.json();
      if (response.success == true) {
        title = response.title
        keywords = response.keywords
        description = response.description
        allJobProfile.forEach((data, index) => {
          response.interests.forEach(resdata => {
            if (resdata.interest === data.label) {
              selectedValues.push({
                "label": data.label,
                "id": index,
              })
              this.checkBoxClicked(data.label, data.index);
            }
          })
        })
      }
      this.setState({ selectedJobs: selectedValues ,isAnyItemSelected: true,loading: false })
    }
    catch (err) {
      console.log(err);
    }
  }
  afterSelect = (e) => {
    const selected = [];
    this.state.interests.forEach(job => {
      if (job.isSelected) {
        selected.push(job.label);
      }
    });
    if (selected.length < 3) {
      return (
        this.setState({ show: true, msg: Strings.chooseProfileDialog })
      )
    } else if (selected.length > 6 ) {
      return (
        this.setState({ show: true, msg: Strings.maxProfile })
      )
    }
    this.postInterest(selected);
  }

  postInterest = async (selected) => {
    try {
      let response = await fetch(url + "/jobseeker/addInterestsOfJobseeker", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          jobseekerId: jobseekerId,
          interests: selected,
        })
      })
      response = await response.json();
      if (response.success) {
        if (mydata) {
          this.props.history.go(-1);
        } else {
          // this.props.history.push('/joblist')
          window.location.href = 'https://jobam.co/jobs?/bluCursor-Infotech-Pvt.-Ltd.-Demo-Electronic-Complex-Indore-158206';
        }
      }
    }
    catch (error) {
      console.log(error);
    }
  }
  checkBoxClicked = (item, index) => {
    var maxshows;
    var that = this;
    $("input:checkbox").click(function(){
      var maxshow=$("input:checkbox:checked").length
      if (maxshow > 6){
        that.setState({show: true, msg: Strings.maxProfile});
        return false;
     }
  });
    const maxSelected = [];
    var selected = this.state.interests.map((job, i) => {
      if (i === index) {
        job.isSelected = !job.isSelected;
      }
      if (item === job.label) {
        job.isSelected = true;
      }
      return job;
    });
    this.setState({
      selectedJobs: selected
    })
    this.state.selectedJobs.forEach(job => {
      if (job.isSelected) {
        maxSelected.push(job.label);
      }
    });
    if (maxSelected.length > 6 ) {
      return (
        this.setState({ show: true, msg: Strings.maxProfile })
      )
    }
  }

  hideModal = e => {
    this.setState({ show: false });
  };

  render() {
    const { data } = this.props.location
     mydata =data;
    if (title != undefined) {
      document.title = title;
      document.getElementsByTagName("META")[2].content = description
      document.getElementsByTagName("META")[3].content = keywords
    }
    if (this.state.loading) {
      return (
        <LoadingOverlay
          active={this.state.loading}
          spinner={
            <div className="loading-interest">
              <center className=" bg-dark1 rounded mt-5 col-md-6 col-sm-6 p-2 col-12">
                <h4 className="loadingText pt-1 mb-1 d-sm-block d-md-block">{Strings.loadingJobProfiles}</h4>
                <h6 className="text-white pt-1 pb-1 d-block d-sm-none d-md-none">{""}</h6>
                <img src={Images.loaderWhite} alt="img" className="pb-2" width="30" />
              </center>
            </div>
          }
        ></LoadingOverlay>
      );
    }
    return (
      <div className="texture">
        <div className="container col-md-6 No-Pading col-lg-6 col-sm-6 col-12">
          <Webheader pageName={Strings.JobPROFILE} handleBack={this.goBack} />
          <div className="interestBack">
            <h4 className="ml-3 mt-2 mb-2">{Strings.chooseProfiles} </h4>
            <div className="col-md-12">
              {this.state.interests.map((item, index) => (
                <div key={index} className="btn-group btn-group-toggle" data-toggle="buttons">
                  <label className={item.isSelected ? "shadow btn text-dark mr-2 mb-2 active rounded  btn-outline-secondary" : "border-0  shadow bg-white text-dark btn rounded mr-2 mb-2"}>
                    <input type="checkbox"
                      checked={
                        item.isSelected
                      }
                      onClick={i => this.checkBoxClicked(item, index)}
                      name="options" autoComplete="off" value="{item.label}" /> {item.label}
                  </label>
                </div>
              ))}
            </div>
            <center>
              <button
                onClick={() => this.afterSelect()}
                type="button"
                className="btn btn-success rounded-pill text-uppercase mt-4 mb-4 buttonCon col-md-3"
              >
                {Strings.Confirm}
              </button>
            </center>
          </div>         
          <ModalDiv modalReact={true} showModal={this.state.show} crossBtnModal={true} hideModal={()=>this.hideModal()} msg={this.state.msg} btn1Text={Strings.oK} btn1Click={()=>this.hideModal()}/>
        </div>
        <div className="container-fluid bg-light col-md-6 col-lg-6 col-sm-6 col-12 No-Pading">
          <Route component={Footer} className="d-none d-md-block d-lg-block d-sm-block d-xl-block" />
        </div>
      </div>
    );
  }
}
