import React from 'react';
import { Link, Route } from "react-router-dom";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Strings from "../utilities/stringlanguage";
var isUserLoggedIn, jobseekerName;

class webBottomNav extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pathMap: [
        "/joblist",
        "/myjobs",
        "/interviewtips",
        "/profile",
        "/register"
      ],
    };
  }
  componentDidMount() {
    isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
    jobseekerName = localStorage.getItem("givenName")
  }

  render() {
    isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
    jobseekerName = localStorage.getItem("givenName")
    return (
      <div className="row">
        <div className="d-block d-sm-none d-md-none d-lg-none d-xl-none fixed-bottom col-12 p-0">
          <BottomNavigation
            showLabels
          >
            {this.props.homeActive ?
              <BottomNavigationAction label={this.props.homeText} className="Mui-selected"
                to={this.state.pathMap[0]}
                component={Link}
                icon={<i className="fas fa-home"></i>} />

              :
              <BottomNavigationAction label={this.props.homeText} to={this.state.pathMap[0]}
                component={Link}
                icon={<i className="fas fa-home"></i>} />
            }
            {this.props.myjobsActive ?
              <BottomNavigationAction label={this.props.myJobText} className="Mui-selected"
                to={this.state.pathMap[1]}
                component={Link}
                icon={<i className="fas fa-user-check"></i>} />
              :
              <BottomNavigationAction label={this.props.myJobText}
                to={this.state.pathMap[1]}
                component={Link}
                icon={<i className="fas fa-user-check"></i>} />
            }
            {this.props.interviewActive ?
              <BottomNavigationAction label={this.props.interviewText} className="Mui-selected"
                to={this.state.pathMap[2]}
                component={Link}
                icon={<svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 486 486" width="46" height="23"><g><g>
                  <path id="XMLID_49_" d="M298.4,424.7v14.2c0,11.3-8.3,20.7-19.1,22.3l-3.5,12.9c-1.9,7-8.2,11.9-15.5,11.9h-34.7   c-7.3,0-13.6-4.9-15.5-11.9l-3.4-12.9c-10.9-1.7-19.2-11-19.2-22.4v-14.2c0-7.6,6.1-13.7,13.7-13.7h83.5   C292.3,411,298.4,417.1,298.4,424.7z M362.7,233.3c0,32.3-12.8,61.6-33.6,83.1c-15.8,16.4-26,37.3-29.4,59.6   c-1.5,9.6-9.8,16.7-19.6,16.7h-74.3c-9.7,0-18.1-7-19.5-16.6c-3.5-22.3-13.8-43.5-29.6-59.8c-20.4-21.2-33.1-50-33.4-81.7   c-0.7-66.6,52.3-120.5,118.9-121C308.7,113.1,362.7,166.9,362.7,233.3z M256.5,160.8c0-7.4-6-13.5-13.5-13.5   c-47.6,0-86.4,38.7-86.4,86.4c0,7.4,6,13.5,13.5,13.5c7.4,0,13.5-6,13.5-13.5c0-32.8,26.7-59.4,59.4-59.4   C250.5,174.3,256.5,168.3,256.5,160.8z M243,74.3c7.4,0,13.5-6,13.5-13.5V13.5c0-7.4-6-13.5-13.5-13.5s-13.5,6-13.5,13.5v47.3   C229.5,68.3,235.6,74.3,243,74.3z M84.1,233.2c0-7.4-6-13.5-13.5-13.5H23.3c-7.4,0-13.5,6-13.5,13.5c0,7.4,6,13.5,13.5,13.5h47.3   C78.1,246.7,84.1,240.7,84.1,233.2z M462.7,219.7h-47.3c-7.4,0-13.5,6-13.5,13.5c0,7.4,6,13.5,13.5,13.5h47.3   c7.4,0,13.5-6,13.5-13.5C476.2,225.8,470.2,219.7,462.7,219.7z M111.6,345.6l-33.5,33.5c-5.3,5.3-5.3,13.8,0,19.1   c2.6,2.6,6.1,3.9,9.5,3.9s6.9-1.3,9.5-3.9l33.5-33.5c5.3-5.3,5.3-13.8,0-19.1C125.4,340.3,116.8,340.3,111.6,345.6z M364.9,124.8   c3.4,0,6.9-1.3,9.5-3.9l33.5-33.5c5.3-5.3,5.3-13.8,0-19.1c-5.3-5.3-13.8-5.3-19.1,0l-33.5,33.5c-5.3,5.3-5.3,13.8,0,19.1   C358,123.5,361.4,124.8,364.9,124.8z M111.6,120.8c2.6,2.6,6.1,3.9,9.5,3.9s6.9-1.3,9.5-3.9c5.3-5.3,5.3-13.8,0-19.1L97.1,68.2   c-5.3-5.3-13.8-5.3-19.1,0c-5.3,5.3-5.3,13.8,0,19.1L111.6,120.8z M374.4,345.6c-5.3-5.3-13.8-5.3-19.1,0c-5.3,5.3-5.3,13.8,0,19.1   l33.5,33.5c2.6,2.6,6.1,3.9,9.5,3.9s6.9-1.3,9.5-3.9c5.3-5.3,5.3-13.8,0-19.1L374.4,345.6z" data-original="#000000" className="active-path" data-old_color="#000000" fill="00c7b6" />
                </g></g> </svg>} />
              :
              <BottomNavigationAction label={this.props.interviewText}
                to={this.state.pathMap[2]}
                component={Link}
                icon={<svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 486 486" width="46" height="23"><g><g>
                  <path id="XMLID_49_" d="M298.4,424.7v14.2c0,11.3-8.3,20.7-19.1,22.3l-3.5,12.9c-1.9,7-8.2,11.9-15.5,11.9h-34.7   c-7.3,0-13.6-4.9-15.5-11.9l-3.4-12.9c-10.9-1.7-19.2-11-19.2-22.4v-14.2c0-7.6,6.1-13.7,13.7-13.7h83.5   C292.3,411,298.4,417.1,298.4,424.7z M362.7,233.3c0,32.3-12.8,61.6-33.6,83.1c-15.8,16.4-26,37.3-29.4,59.6   c-1.5,9.6-9.8,16.7-19.6,16.7h-74.3c-9.7,0-18.1-7-19.5-16.6c-3.5-22.3-13.8-43.5-29.6-59.8c-20.4-21.2-33.1-50-33.4-81.7   c-0.7-66.6,52.3-120.5,118.9-121C308.7,113.1,362.7,166.9,362.7,233.3z M256.5,160.8c0-7.4-6-13.5-13.5-13.5   c-47.6,0-86.4,38.7-86.4,86.4c0,7.4,6,13.5,13.5,13.5c7.4,0,13.5-6,13.5-13.5c0-32.8,26.7-59.4,59.4-59.4   C250.5,174.3,256.5,168.3,256.5,160.8z M243,74.3c7.4,0,13.5-6,13.5-13.5V13.5c0-7.4-6-13.5-13.5-13.5s-13.5,6-13.5,13.5v47.3   C229.5,68.3,235.6,74.3,243,74.3z M84.1,233.2c0-7.4-6-13.5-13.5-13.5H23.3c-7.4,0-13.5,6-13.5,13.5c0,7.4,6,13.5,13.5,13.5h47.3   C78.1,246.7,84.1,240.7,84.1,233.2z M462.7,219.7h-47.3c-7.4,0-13.5,6-13.5,13.5c0,7.4,6,13.5,13.5,13.5h47.3   c7.4,0,13.5-6,13.5-13.5C476.2,225.8,470.2,219.7,462.7,219.7z M111.6,345.6l-33.5,33.5c-5.3,5.3-5.3,13.8,0,19.1   c2.6,2.6,6.1,3.9,9.5,3.9s6.9-1.3,9.5-3.9l33.5-33.5c5.3-5.3,5.3-13.8,0-19.1C125.4,340.3,116.8,340.3,111.6,345.6z M364.9,124.8   c3.4,0,6.9-1.3,9.5-3.9l33.5-33.5c5.3-5.3,5.3-13.8,0-19.1c-5.3-5.3-13.8-5.3-19.1,0l-33.5,33.5c-5.3,5.3-5.3,13.8,0,19.1   C358,123.5,361.4,124.8,364.9,124.8z M111.6,120.8c2.6,2.6,6.1,3.9,9.5,3.9s6.9-1.3,9.5-3.9c5.3-5.3,5.3-13.8,0-19.1L97.1,68.2   c-5.3-5.3-13.8-5.3-19.1,0c-5.3,5.3-5.3,13.8,0,19.1L111.6,120.8z M374.4,345.6c-5.3-5.3-13.8-5.3-19.1,0c-5.3,5.3-5.3,13.8,0,19.1   l33.5,33.5c2.6,2.6,6.1,3.9,9.5,3.9s6.9-1.3,9.5-3.9c5.3-5.3,5.3-13.8,0-19.1L374.4,345.6z" data-original="#000000" className="active-path" data-old_color="#000000" fill="#757575" />
                </g></g> </svg>} />
            }


            {
              this.props.profileActive ?
                <BottomNavigationAction label={isUserLoggedIn == "true" ? jobseekerName : Strings.loginTabs} className="Mui-selected"
                  to={isUserLoggedIn == "true" ? this.state.pathMap[3] : this.state.pathMap[4]}
                  component={Link}
                  icon={<i className="fas fa-user"></i>} />
                :
                <BottomNavigationAction label={isUserLoggedIn == "true" ? jobseekerName : Strings.loginTabs}
                  to={isUserLoggedIn == "true" ? this.state.pathMap[3] : this.state.pathMap[4]}
                  component={Link}
                  icon={<i className="fas fa-user"></i>} />

            }
          </BottomNavigation>

        </div>
      </div>
    )
  }
}
export default webBottomNav;