import React from 'react';
import './App.css';
import MainPage from './screens/mainPage';

function App() {
  return (
    <div >
        <MainPage/>
    </div>
  );
}

export default App;

 

