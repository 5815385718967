import * as actionTypes from '../actions/changeLanguageAction';
const initialState= {
    languageData: [],
    languageDataLoading: false,
    languageDataError: null,
};
const language = (state = initialState, action) => {
    switch ( action.type ) {
        case actionTypes.SET_LANGUAGE: 
            return{...state, languageDataLoading: true, languageDataError: null};
        case actionTypes.SET_LANGUAGE_DATA: 
            return{...state, languageDataLoading: false, languageData: action.payload,  languageDataError: null};
        case actionTypes.SET_LANGUAGE_FAIL: 
            return{...state, languageDataLoading: false, languageData: [], languageDataError: action.payload};
        default: 
            return state;
    }  
};
 
export default language;




