import * as actionTypes from '../actions/experienceAction';
const initialState= {
    getIndustryData: [],
    getIndustryLoading: false,
    getIndustryError: null,
    setExpData:[],
    setExpLoading:false,
    setExpError:null,
    updateExpData:[],
    updateExpLoading:false,
    updateExpError:null,
    getExperienceData:[],
    getExperienceLoading:false,
    getExperienceError:null,
    delExperienceData:[],
    delExperienceLoading:false,
    delExperienceError:null,

  
};
const workExperience = (state = initialState, action) => {
    // console.log(" fresher REDUCER CALLED =======" + JSON.stringify(action.payload))
    switch ( action.type ) {
        case actionTypes.GET_INDUSTRYDATA_LOADING: 
            return{...state, getIndustryLoading: true, getIndustryError: null};
        case actionTypes.GET_INDUSTRY_DATA: 
            return{...state, getIndustryLoading: false, getIndustryData: action.payload, getIndustryError: null};
        case actionTypes.GET_INDUSTRYDATA_FAIL: 
            return{...state, getIndustryLoading: false, getIndustryData: [], getIndustryError: action.payload};
        case actionTypes.SET_EXPERIENCE: 
            return{...state, setExpLoading: true, setExpError: null};
        case actionTypes.SET_EXPERIENCE_DATA: 
            return{...state, setExpLoading: false, setExpData: action.payload, setExpError: null};
        case actionTypes.SET_EXPERIENCE_FAIL: 
            return{...state, setExpLoading: false, setExpData: [], setExpError: action.payload};
        case actionTypes.UPDATE_EXPERIENCE: 
            return{...state, updateExpLoading: true, updateExpError: null};
        case actionTypes.UPDATE_EXPERIENCEDATA_DATA: 
            return{...state, updateExpLoading: false, updateExpData: action.payload, updateExpError: null};
        case actionTypes.UPDATE_EXPERIENCEDATA_FAIL: 
            return{...state, updateExpLoading: false, updateExpData: [], updateExpError: action.payload};
        case actionTypes.GET_EXPERIENCE_LOADING: 
            return{...state, getExperienceLoading: true, getExperienceError: null};
        case actionTypes.GET_EXPERIENCE_DATA: 
            return{...state, getExperienceLoading: false, getExperienceData: action.payload, getExperienceError: null};
        case actionTypes.GET_EXPERIENCE_FAIL: 
            return{...state, getExperienceLoading: false, getExperienceData: [], getExperienceError: action.payload};
        case actionTypes.DELETE_EXPERIENCE_LOADING: 
            return{...state, delExperienceLoading: true, delExperienceError: null};
        case actionTypes.DELETE_EXPERIENCE_DATA: 
            return{...state, delExperienceLoading: false, delExperienceData: action.payload, delExperienceError: null};
        case actionTypes.DELETE_EXPERIENCE_FAIL: 
            return{...state, delExperienceLoading: false, delExperienceData: [], delExperienceError: action.payload};
           
        default: 
            return state;
    }  
};
 
export default workExperience;




