import { combineReducers } from 'redux';
import allJobs from './jobListReducer';
import profile from './profileReducer';
import candidateFaq from './candidateFaqReducer';
import companyFeedback from './companyFeedbackReducer';
import companyProfile from './companyProfileReducer';
import contactSupport from './contactSupportReducer';
import changeLanguage from './changeLanguageReducer';
import chatCompany from './chatCompanyReducer';
import myJobs from './myJobsReducer';
import mapJobs from './mapJobsReducer';
import interviewTips from './interviewTipsReducer';
import experience from './experienceReducer';
import fresher from './fresherReducer';
import notiResult from './norificationReducer';
import blogsR from './blogReducer';
import searchJob from './searchJobReducer';
import educationDetail from './educationDetailReducer';

export default combineReducers({
  allJobsReducer: allJobs,
  profileReducer: profile,
  candidateFaqR: candidateFaq,
  companyFeedbackReducer: companyFeedback,
  companyProfileReducer: companyProfile,
  contactSupportReducer: contactSupport,
  changeLanguageReducer:changeLanguage,
  chatCompanyReducer: chatCompany,
  myJobsR: myJobs,
  mapJobsData: mapJobs,
  interviewTipsR: interviewTips,
  experienceReducer:experience,
  fresherR:fresher,
  notificationData : notiResult,
  blogsReducer : blogsR,
  searchJobReducer : searchJob,
  educationDetailReducer : educationDetail,
}) 