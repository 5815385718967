import React from 'react';
import '../_style/navbar.css';
import { NavLink } from 'react-router-dom';
import Strings from '../utilities/stringlanguage';
import Images from '../Images';
import stringlanguage from "../utilities/stringlanguage";
var isUserLoggedIn, jobseekerName;
var selectedLang;
export default class navBar extends React.PureComponent {
	componentDidMount() {
		isUserLoggedIn = localStorage.getItem('isUserLoggedIn');
		jobseekerName = localStorage.getItem('givenName');
	}
	render() {
		isUserLoggedIn = localStorage.getItem('isUserLoggedIn');
		jobseekerName = localStorage.getItem('givenName');
		selectedLang = localStorage.getItem("selectedLang")
		if (selectedLang != null && selectedLang != undefined && selectedLang != "") {
		  stringlanguage.setLanguage(selectedLang);
		}
		return (
			<div>
				<div className="container col-md-6 col-lg-6 col-sm-6 col-12 ">
					<center className="fixed-top">
						<nav className="navbar navbar-expand-lg navbar-light fixed-top col-md-6 bg-light">
							<NavLink
								className="navbar-brand  d-none d-sm-block d-md-block d-lg-block col-md-4 col-sm-4 col-lg-4 col-xl-4 pl-0 mr-4"
								to="/mainPage"
							>
								<img src={Images.headerLogo} width="190" height="55" alt="Header logo" />
							</NavLink>
							<div
								className="d-none d-sm-block d-md-block d-lg-block col-md-8 col-sm-8 col-lg-8 col-xl-8"
								id="navbarNav "
							>
								<ul className="navbar-nav  myNavbarRight">
									<li className="nav-item">
										<NavLink to="/joblist" className="nav-link pointer">
											<i className="fas fa-home mr-2" aria-hidden="true"></i>
											{Strings.homeTabs}
											<span className="sr-only"></span>
										</NavLink>  
									</li>
									<li className="nav-item ">
										<NavLink className="nav-link pointer" to="/myjobs">
											<i className="fas fa-user-check mr-2"></i>
											{Strings.myJobTabs}
										</NavLink>
									</li>
									<li className="nav-item">
										<NavLink to="/interviewtips" className="nav-link pointer">
											<i className="fas fa-lightbulb mr-2" aria-hidden="true"></i>
											{Strings.interviewTabs}
										</NavLink>
									</li>
									{/* for green tab use active class */}
									{isUserLoggedIn == 'true' ? (
										<li className="nav-item">
											<NavLink to="/profile" className="nav-link pointer">
												<i className="fas fa-user mr-2" aria-hidden="true"></i>
												{jobseekerName}
											</NavLink>
										</li>
									) : (
										<li className="nav-item">
											<NavLink to="/register" className="nav-link pointer">
												<i className="fa fa-user mr-2" aria-hidden="true"></i>
												{Strings.loginTabs}
											</NavLink>
										</li>
									)}
								</ul>
							</div>
						</nav>
					</center>
				</div>
			</div>
		);
	}
}
