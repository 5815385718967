import * as actionTypes from '../actions/contactSupportAction';
const initialState= {
    setContactSupportData: [],
    setContactSupportLoading: false,
    setContactSupportError: null,
};
const support = (state = initialState, action) => {
    switch ( action.type ) {
        case actionTypes.SET_CONTACTSUPPORT: 
            return{...state, setContactSupportLoading: true, setContactSupportError: null};
        case actionTypes.SET_CONTACTSUPPORT_DATA: 
            return{...state, setContactSupportLoading: false, setContactSupportData: action.payload, setContactSupportError: null};
        case actionTypes.SET_CONTACTSUPPORT_FAIL: 
            return{...state, setContactSupportLoading: false, setContactSupportData: [], setContactSupportError: action.payload};
        default: 
            return state;
    }  
};
 
export default support;




