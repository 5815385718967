/* eslint-disable no-script-url */
import React from 'react';
import Footer from '../components/faqFooter';
import Images from "../Images";
import { Link } from 'react-router-dom';
import helpers from "../config";
import MetaTags from 'react-meta-tags';
import { getBlogDetail } from '../actions/blogAction';
import { connect } from "react-redux";
var moment = require("moment");
var dataApplied = [];
var url;
var imageUrl;
var blogId;
 class BlogDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            dataFound: false,
            loading: true,
            jobseekerId: "",
            myIndex: 0,
            myAppliedData: dataApplied
        };
    }
       async componentDidMount() {
        let apiUrl = await helpers.getServerUrl();
        url = apiUrl.serverUrl
        imageUrl = apiUrl.imageUrl
       localStorage.setItem("blogId" , this.props.location.state.blogId );
       blogId = localStorage.getItem("blogId")
        this.state.isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
        // this.getBlogById();
        this.props.getBlogDetail(blogId);
    }

    async  getBlogById() {
        dataApplied = [];
        this.setState({ loading: true });
       let response = await fetch(url + "/jobseeker/getBlogPostById", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                blogId: this.state.blogId
            })
        })
            response = await response.json()
            let responseData = response.blogDetails
                if (
                    responseData != null &&
                    responseData != "" &&
                    responseData != undefined
                ) {
                  this.setState({
                        blogImage: responseData.blogImage,
                        display: responseData.display,
                        createdDate: responseData.createdDate,
                        blogId: responseData.blogId,
                        blogTitle: responseData.blogTitle,
                        blogDescription: responseData.blogDescription,
                        dataFound: true,
                        loading: false
                    });
                }
    }
    render() {
        let {blogDetail , blogDetailLoading, blogDetailError} = this.props;
        return (
            <div>
                <MetaTags>
                    {/* for browser */}
                    <title>Jobam Share You Diffrent Skills, Programming Langugages And Many More!</title>
                    {/* <meta name="description" content="Some description." /> */}
                    {/* For Link sharing in social from url */}
                    <meta property="og:title" content="Jobam Share You Diffrent Skills, Programming Langugages And Many More!" />
                    {/* <meta property="og:description" content=""/> */}
                    <meta property="og:url" content="https://jobam.co/blogdetail"/>
                    <meta property="og:image" content="../assets/logo192.png" />
                    <meta property="og:site_name" content="Jobam" />
                    {/* For twitter sharing from url  */}
                    <meta name="twitter:title" content="Jobam Share You Diffrent Skills, Programming Langugages And Many More!"/>
                    {/* <meta name="twitter:description" content="content"/> */}
                    <meta name="twitter:image" content="../assets/logo192.png"/>
                    <meta name="twitter:site" content="@JhatpatJ"/>
                    <meta name="twitter:creator" content="@JhatpatJ"/>
                </MetaTags>
                <nav id="header" className="navbar  navbar-expand-lg bg-dark">
                    <div className="container">
                        <a className="navbar-brand" href="/">
                            <div id="logo" className="pull-left">
                                <Link to="#intro"><img src={Images.logo} width="225" alt="Header Logo" title="" /></Link>
                            </div>
                        </a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                            <ul className="navbar-nav ml-2">
                                <li className="nav-item active ">
                                    <a className="nav-link text-white" href="/blog"><i className="fas fa-arrow-circle-left"></i> Back to Blog List</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                <div className="container-fuild bg-white pt-5 bg-light">
                    <div className="row pt-5">
                        <div className="container pt-2 col-md-10 col-10">
                            <img src={imageUrl + blogDetail.blogImage} alt="" className="col-md-12" />
                            <div className="col-md-12 mt-2">
                                <h3 className="font-weight-bold text-left pt-2">{blogDetail.blogTitle}</h3>
                                <span className="text-muted"><i className="text-muted fas fa-calendar mr-1"></i>
                                Posted on: {moment(blogDetail.createdDate).format("DD MMM YYYY")}</span>
                                <p className="text-secondary">
                                    <div dangerouslySetInnerHTML={{ __html: blogDetail.blogDescription }} />
                                </p>
                                <hr className="mt-2" />
                                <div className="d-inline-flex">
                                    <h5 className="pt-3 text-dark">Share</h5>
                                    <div className="ml-3 mt-1">
                                        <a href="https://twitter.com/share?url=https://jobam.co&text=Visit Jobam, An artificial intelligence-based Job Portal" target="_blank" title="Share on Twitter">
                                       <img src={Images.twitter} width="45" alt="Twitter"></img>
                                        </a>
                                        <a class="mr-1" href="https://www.facebook.com/sharer/sharer.php?u=https://jobam.co&t=Visit Jobam, An artificial intelligence-based Job Portal" target="_blank" title="Share on Facebook">
                                       <img src={Images.facebook} width="35" alt="Facebook" />
                                        </a>
                                        <a class="mr-1" href="https://web.whatsapp.com/?text=Visit Jobam, An artificial intelligence-based Job Portal https://jobam.co"  data-action="share/whatsapp/share" target="_blank" title="Share on whatsapp">
                                       <img src={Images.whatsapp} width="35" alt="Whatsapp"></img>
                                        </a>
                                        <a href="https://www.linkedin.com/shareArticle?mini=true&url=https://jobam.co&t=Visit Jobam, An artificial intelligence-based Job Portal" target="_blank" title="Share on Linkedin" className="cursor-pointer"><img src={Images.linkedIn} width="36" alt="Linkedin"></img></a>
                                       {/* <a href="http://pinterest.com/pin/create/button/?url=https://jobam.co&media=https://images.wallpaperscraft.com/image/keyboard_key_backlight_128828_3840x2400.jpg" target="_blank" title="Share on Pinterest" className="cursor-pointer ml-1"><img src={Images.pinterest} width="36" alt="Pinterest"></img></a>*/}
                                    </div>
                                </div>
                                <hr className="mt-2" />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
      blogDetail: state.blogsReducer.blogDetail,
      blogDetailLoading: state.blogsReducer.blogDetailLoading,
      blogDetailError: state.blogsReducer.blogDetailError,
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
      getBlogDetail: (blogId) => dispatch(getBlogDetail(blogId)),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(BlogDetail);
