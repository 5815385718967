import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Images from "../Images";
import helpers from "../config";
import Footer from '../components/faqFooter';
import CheeseburgerMenu from "cheeseburger-menu";
import HamburgerMenu from 'react-hamburger-menu';
import MetaTags from 'react-meta-tags';
import LoadingOverlay from 'react-loading-overlay';
import { getBlogs } from '../actions/blogAction';
import { connect } from "react-redux";
var dataApplied = [];
var url;
var imageUrl;
var title;
var keywords;
var description;
var jobseekerName = "";

class Blog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataFound: false,
      loading: true,
      jobseekerId: "",
      myIndex: 0,
      menuOpen: false,
      myAppliedData: dataApplied
    };
  }
  openMenu() {
    this.setState({ menuOpen: true })
  }

  closeMenu() {
    this.setState({ menuOpen: false })
  }

  async componentDidMount() {
    let apiUrl = await helpers.getServerUrl()
    url = apiUrl.serverUrl;
    imageUrl = apiUrl.imageUrl;
    this.state.isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
    jobseekerName = localStorage.getItem("jobseekerName")
    this.props.getBlogs();
  }



  render() {
    if(title != undefined){
    document.title = title;
    document.getElementsByTagName("META")[2].content = description;
    document.getElementsByTagName("META")[3].content = keywords
    }
    let {blogs, blogsLoading, blogsError} = this.props;
    title = blogs.title
    description = blogs.description
    keywords = blogs.keywords
    let blog = blogs.blogPosts;
    if(blog !== null && blog !== undefined){
      dataApplied = []
    blog.forEach(blogData => {
      dataApplied.push({
        blogImage: blogData.blogImage,
        display: blogData.display,
        createdDate: blogData.createdDate,
        blogId: blogData.blogId,
        blogTitle: blogData.blogTitle,
        blogDescription: blogData.blogDescription,
        dataFound: true
      });
    })
  }
    return (
      <div>
        <MetaTags>
          {/* for browser */}
          {/* <title>Jobam Share You Diffrent Skills, Programming Langugages And Many More!</title> */}
          {/* For Link sharing in social from url */}
          <meta property="og:title" content="Jobam Share You Diffrent Skills, Programming Langugages And Many More!" />
          {/* <meta property="og:description" content=""/> */}
          <meta property="og:url" content="https://jobam.co/blog"/>
          <meta property="og:image" content="../assets/logo192.png" />
          <meta property="og:site_name" content="Jobam" />
          {/* For twitter sharing from url  */}
          <meta name="twitter:title" content="Jobam Share You Diffrent Skills, Programming Langugages And Many More!"/>
          {/* <meta name="twitter:description" content="content"/> */}
          <meta name="twitter:image" content="../assets/logo192.png"/>
          <meta name="twitter:site" content="@JhatpatJ"/>
          <meta name="twitter:creator" content="@JhatpatJ"/>
        </MetaTags>
        <nav id="header" className="navbar  navbar-expand-lg bg-dark">
          <div className="container">
            <a className="navbar-brand" href="/">
              <div id="logo" className="pull-left">
                <Link to="#intro"><img src={Images.logo} width="225" alt="Header Logo" title="" /></Link>
              </div>
            </a>
            <div className="d-block d-md-none">
            <CheeseburgerMenu
              isOpen={this.state.menuOpen}
              closeCallback={this.closeMenu.bind(this)} backgroundColor={'black'}>
              <ul className="navbar-nav ml-2 pb-2" closeCallback={this.closeMenu.bind(this)}>
                <li className="nav-item emp-text  p-2 mr-md-2 border-bottom">
                  <a className="nav-link text-white myText" href="/"><i className="fas fa-home"></i> HOME</a>
                </li>
                <li className="nav-item emp-text active p-2 mr-md-2 border-bottom">
                  <a className="nav-link text-white myText" href="/blog"><i className="fas fa-rss"></i> BLOG</a>
                </li>
                <li className="nav-item emp-text p-2 mr-md-2 border-bottom">
                  <a className="nav-link text-white myText" href="https://jobam.co/joblist"><i className="fas fa-search"></i> SEARCH A JOB</a>
                </li>
                {this.state.isUserLoggedIn == "true" && jobseekerName != null ?
                  <li className="nav-item emp-text p-2 mr-md-2 border-bottom">
                    <a className="nav-link text-white myText" href="https://jobam.co/profile"><i className="fas fa-user"></i> {" "}{jobseekerName}</a>
                  </li> : <li className="nav-item emp-text p-2 mr-md-2 border-bottom">
                    <a className="nav-link text-white myText" href="https://jobam.co/register"><i className="fas fa-user"></i> SIGN IN</a>
                  </li>}
                <li className="nav-item empButton ml-md-2 mt-3 mt-md-0">
                  <a href="https://jobam.co/employerzone/login.php#signin" target="_blank"
                    className="nav-link text-white text-decoration-none pl-3">Employers | Post a Job</a>
                </li>
                <li className="nav-item empButton ml-md-2 mt-3 mt-md-0">
                  <a style={{marginLeft: '10px'}} href="https://play.google.com/store/apps/details?id=dataflakes.jobs.jhatpatjobs.jobsearch" target="_blank"
                    className="nav-link text-white text-decoration-none pl-3"><i style={{marginRight: '11px'}} className="fas fa-download"></i>Download App</a>
                </li>
              </ul>
            </CheeseburgerMenu>
            
            <HamburgerMenu
              isOpen={this.state.menuOpen}
              menuClicked={this.openMenu.bind(this)}
              width={28}
              height={22}
              strokeWidth={3}
              rotate={0}
              color='white'
              borderRadius={0}
              animationDuration={0.5}
              className="new-menu-icon"
            />
            </div>
            <div className="justify-content-end d-none d-md-block">
              <ul className="navbar-nav ml-2">
                <li className="nav-item">
                  <a className="nav-link text-white" href="/"><i className="fas fa-home"></i> HOME</a>
                </li>
                <li className="nav-item emp-text active mr-0  mr-md-2">
                  <a className="nav-link text-white myText" href="https://jobam.co/blog"><i className="fas fa-rss"></i> Blog</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" href="https://jobam.co/joblist"><i className="fas fa-search"></i> SEARCH A JOB</a>
                </li>
                   {this.state.isUserLoggedIn == "true" && jobseekerName != null ?
                  <li className="nav-item">
                    <a className="nav-link text-white " href="https://jobam.co/profile"><i className="fas fa-user"></i> {" "}{jobseekerName}</a>
                  </li> : <li className="nav-item">
                    <a className="nav-link text-white " href="https://jobam.co/register"><i className="fas fa-user"></i> SIGN IN</a>
                  </li>}
                <li className="nav-item empButton ml-md-2 mt-2 mt-md-0 ">
                  <a href="https://jobam.co/employerzone/login.php#signin" target="_blank"
                    className="nav-link text-white text-decoration-none pl-3">Employers | Post a Job</a>
                </li>
                <li className="nav-item empButton ml-md-2 mt-3 mt-md-0">
                  <a style={{marginLeft: '10px'}} href="https://play.google.com/store/apps/details?id=dataflakes.jobs.jhatpatjobs.jobsearch" target="_blank"
                    className="nav-link text-white text-decoration-none pl-3"><i style={{marginRight: '11px'}} className="fas fa-download"></i>Download App</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <LoadingOverlay
          active={blogsLoading}
          spinner={<div className="loading-blog ">
            <center className=" bg-dark2 rounded mt-5 col-md-6 col-sm-6 p-2 col-12">
              <h4 className="text-white pt-1 d-none d-sm-block d-md-block">Loading Blogs</h4>
              <h6 className="text-white pt-1 pb-1 d-block d-sm-none d-md-none">Loading Blogs</h6>
              <img src={Images.loaderWhite} alt="img" className="pb-2" width="30" />
            </center>
          </div>}
        >   
         <div className="pt-5">
          <div className="row pt-5 mt-3 bg-light">
            {dataApplied !== null ||
              dataApplied !== "" ||
              dataApplied !== undefined
              ?
              dataApplied.map(item => (
                <div className="container col-md-8 col-sm-8 col-9">
                  <Link to={{
                    pathname: "/blogdetail",
                    state: { blogId: item.blogId }
                  }}
                    className="text-decoration-none">
                    <div className="card mb-3 shadow-sm">
                      <div className="row">
                        <div className="col-md-5">
                          <img src={imageUrl + item.blogImage} alt="Blog Image" className="w-100" />
                        </div>
                        <div className="col-md-7 p-4">
                          <h4 className="card-title text-secondary font-weight-bold">{item.blogTitle}</h4>
                          <p className="text-secondary"><span dangerouslySetInnerHTML={{ __html: item.blogDescription }} ></span></p>
                          <div className="btn btn-success rounded-0">
                            <Link to={{ pathname: "/blogdetail", state: { blogId: item.blogId } }} className="text-decoration-none text-white">Read More</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>  
                </div>
              ))
              :
              null}
          </div>
          </div>
        </LoadingOverlay>
        <Footer /> 
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    blogs: state.blogsReducer.blogs,
    blogsLoading: state.blogsReducer.blogsLoading,
    blogsError: state.blogsReducer.blogsError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getBlogs: () => dispatch(getBlogs()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
