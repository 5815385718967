import helpers from "../config";

export const GET_CHAT = "GET_CHAT";
export const GET_CHAT_DATA = "GET_CHAT_DATA";
export const GET_CHAT_FAIL = "GET_CHAT_FAIL";

var jobseekerId;
var cmpnyId;
  export const getChat = (cmpnyId) => {
    jobseekerId = localStorage.getItem("jobseekerId");
      return (dispatch) => {
       dispatch({type: GET_CHAT})
       helpers.getServerUrl().then(url =>   {
        var url = url.serverUrl
       fetch( url + "/chat/getChatforcompany", {
        method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            jobseekerId: jobseekerId,
            companyId: cmpnyId
          })
        })
      .then(response => response.json())
      .then(responseData => {
        dispatch ({
            type: GET_CHAT_DATA,
            payload: responseData
        })
      })
      .catch(err => {
        dispatch({
            type: GET_CHAT_FAIL,
            payload: err
        })
        console.log('error', err)
    })
  })
 }
}

