import React from "react";
class ResumeLink extends React.Component {
    render() {
        return (
            <div>
                
            </div>
        );
    }
}
export default ResumeLink;
