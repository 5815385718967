import React, { Component } from "react";
import { Link, Route } from "react-router-dom";
import Webheader from "../components/webheader";
import Footer from "../components/footer";
import { Modal, Button, ThemeProvider } from "react-bootstrap";
import { connect } from "react-redux";
import { setContactSupport } from "../actions/contactSupportAction";
import Strings from "../utilities/stringlanguage";
import helpers from '../config';
import Modaldiv from "../components/modal";
var jobseekerId;
var url;
var imageUrl;
var selectedLang;

class ContactSupport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      msg: "",
      noDataModal: false,
      onSubmitModal: false,
    }
  }
  async componentDidMount() {
    let apiUrl = await helpers.getServerUrl()
    url = apiUrl.serverUrl
    imageUrl = apiUrl.imageUrl
    selectedLang = localStorage.getItem("selectedLang")
    if (selectedLang != null && selectedLang != undefined && selectedLang != "") {
      Strings.setLanguage(selectedLang);
    }
 
    jobseekerId = localStorage.getItem("jobseekerId");
  }

  handleMsg = (e) => {
    this.setState({ msg: e.target.value });
  };

  async submitForm() {
    if (this.state.msg != undefined && this.state.msg != null && this.state.msg != "") {
      this.props.setContactSupport(this.state.msg);
      this.setState({ onSubmitModal: true, msg: "" });
    } else {
      this.setState({ noDataModal: true });
    }
  }

  hideModal = () => {
    this.setState({ noDataModal: false, onSubmitModal: false });
  }

  hideSubmitModal = () => {
    this.props.history.push('/setting')
  }
  render() {
    let { setContactSupportData, setContactSupportLoading, setContactSupportError } = this.props;
    if (setContactSupport.success) {
      document.getElementById('msgText').value = ""
    }
    document.title = "Connect With Us & Get Every Solution In A Minute";
   var feedbackStrings = Strings.feedbackSubmitSuccessfuly.substring(0,Strings.feedbackSubmitSuccessfuly.indexOf(' ')) + "  " + Strings.feedbackSubmitSuccessfuly.substr(Strings.feedbackSubmitSuccessfuly.indexOf(' ')+1);
    // document.getElementsByTagName("META")[2].content = description;
    // document.getElementsByTagName("META")[3].content = keywords
    return (
      <div>
        <div className="container col-md-6 No-Pading col-lg-6 col-sm-6 col-12">
        <Webheader settingState={true} backLink="/setting" pageName={Strings.contactSupport}/>

          <div className="container-fluid pt-2">
            <form>
              <div className="form-group mt-2">
                <label htmlFor="inputEmail4">{Strings.emailCaps}</label>
                <input type="email" className="form-control" value="admin@jobam.co" readOnly />
              </div>
              <div className="form-group">
                <label htmlFor="inputAddress">{Strings.address}</label>
                <input type="text" className="form-control " value="315, MPSEDC STP IT Park, 142 A, Electronic Complex, Indore, MP, India. Pin: 452010." readOnly />
              </div>
              <div className="form-group">
                <label htmlFor="inputMobile">{Strings.contactNumber}</label>
                <input type="text" className="form-control" value="9165304278" readOnly />
              </div>
              <div className="form-group">
                <label htmlFor="inputMobile">{Strings.Message}</label>
                <textarea type="text" id="msgText" placeholder={Strings.Enterthemessage} onChange={e => this.handleMsg(e)} className="form-control"></textarea>
              </div>
              <div type="button" onClick={() => this.submitForm()} className="btn btn-success col-md-12 rounded-pill border-0 mt-3">{Strings.submit}</div>
            </form>
            <Modaldiv modalReact={true} hideModal={()=>this.hideModal()} showModal={this.state.noDataModal} crossBtnModal={true} msg={Strings.NoFeedbackFound} btn1Text={Strings.oK} btn1Click={() =>this.hideModal()}/>
            <Modaldiv modalReact={true} settingData={true} successModal={true} crossBtnModal={true} msg={feedbackStrings} showModal={this.state.onSubmitModal} hideModal={()=>this.hideModal()} btn1Text={Strings.oK} btn1Click={() =>this.hideModal()}/>
          </div>
        </div>
        <div className="container-fluid bg-light col-md-6 col-lg-6 col-sm-6 col-12 No-Pading">
          <Route component={Footer} className="d-none d-md-block d-lg-block d-sm-block d-xl-block" />
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
    setContactSupportData: state.contactSupportReducer.setContactSupportData,
    setContactSupportLoading: state.contactSupportReducer.setContactSupportLoading,
    setContactSupportError: state.contactSupportReducer.setContactSupportError,

  };
};

const mapDispatchToProps = dispatch => {
  return {
    setContactSupport: msg => dispatch(setContactSupport(msg)),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactSupport);

