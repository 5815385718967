import React from 'react';
import { Route } from "react-router-dom";
import Webheader from "../components/webheader";
import helpers from '../config';
import Images from '../Images';
import Strings from "../utilities/stringlanguage";
import Footer from "../components/footer";
import StarRatings from 'react-star-ratings';
import { Modal } from "react-bootstrap";
import { Link } from 'react-router-dom';
import "../_style/companyProfile.css";
import LoadingOverlay from 'react-loading-overlay';
import { connect } from "react-redux";
import { getCompanyReviewData } from "../actions/companyProfileAction";

var url;
var imageUrl;
var jobseekerId;
var companyId;
var allComments = [];
var reviewModal = true;
var defaultImg = "https://i.stack.imgur.com/l60Hf.png";
var responseReveiw = [];
class CompanyReview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      reviewDoneModal: false,
      notiState: [],
      myIndex: 0,
      ratingValue: 5,
      comments: "",
      isModalOpen: "false",
      reviewValue: "AMAZING",
      rateCompanyModal: false,
      cancelModal: false,
      ratingNumber: [
        {
          index: 0,
          name: "All",
          icon: Images.fulwhiteImg
        },
        {
          index: 1,
          name: "1",
          icon: Images.ratestarImg
        },
        {
          index: 2,
          name: "2",
          icon: Images.ratestarImg
        },
        {
          index: 3,
          name: "3",
          icon: Images.ratestarImg
        },
        {
          index: 4,
          name: "4",
          icon: Images.ratestarImg
        },
        {
          index: 5,
          name: "5",
          icon: Images.ratestarImg
        },
      ]
    }
    this.onRatingClick = this.onRatingClick.bind(this);
  }

  async componentDidMount() {
    let apiUrl = await helpers.getServerUrl()
    url = apiUrl.serverUrl
    imageUrl = apiUrl.imageUrl
    let selectedLang = localStorage.getItem("selectedLang")
    if (selectedLang != null && selectedLang != undefined && selectedLang != "") {
      Strings.setLanguage(selectedLang);
    }
    this.state.isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
    jobseekerId = localStorage.getItem("jobseekerId");
    this.props.getCompanyReviewData();
    localStorage.setItem("companyId", this.props.location.state.companyId);
    companyId = localStorage.getItem("companyId");
    window.scroll(0, 0);
  }

  onRatingClick = (item, index) => {
    this.props.getCompanyReviewData(index)
    this.setState({ myIndex: index });
  }


  changeRating = (newRating, name) => {

    switch (newRating) {
      case 5:
        this.setState({ reviewValue: "AMAZING" });
        break;
      case 4:
        this.setState({ reviewValue: "WOW" });
        break;
      case 3:
        this.setState({ reviewValue: "GOOD" });
        break;
      case 2:
        this.setState({ reviewValue: "OK" });
        break;
      case 1:
        this.setState({ reviewValue: "BAD" });
        break;
      default:
      // code block
    }
    this.setState({
      ratingValue: newRating
    });

  }

  onRateCompany = async () => {
    let jobseekerName = localStorage.getItem("jobseekerName");
    let email = localStorage.getItem("email");
    let jobseekerImage = localStorage.getItem("jobseekerImage");
    let response = await fetch(url + "/jobseeker/reviewTocompany", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        jobseekerId: jobseekerId,
        companyId: companyId,
        comments: this.state.comments,
        ratings: this.state.ratingValue,
        jobseekerName: jobseekerName,
        jobseekerImage: jobseekerImage,
        email: email
      })
    })
    response = await response.json();
    if (response[0].success) {
      this.setState({ cancelModal: true, reviewDoneModal: true, });
    } else {
      alert("Something went wrong please try again later.");
    }
  }

  setCommentValue = (e) => {
    this.setState({ comments: e.target.value })
  }

    handleBack = () => {
       this.props.history.go(-2);
      }
      
  hideModal = () => {
    this.setState({ reviewDoneModal: false })
  }

  cancelRating = (e) => {
    this.setState({ cancelModal: true })

  }

  okClick = (e) => {
    this.setState({ reviewDoneModal: false })
    this.props.history.goBack(-1)
  }

  render() {
    let { companyReviewData, companyReviewLoading } = this.props;
    responseReveiw = [];
    if (companyReviewData != undefined && companyReviewData != "") {
      this.state.loading = false;
      if (companyReviewData[0].review != undefined && companyReviewData[0].review != "") {
        responseReveiw = companyReviewData[0].review;
      }
      if (!companyReviewData[0].isReviewed) {
        this.state.rateCompanyModal = true;
      }
    }
    if (this.state.cancelModal) {
      this.state.rateCompanyModal = false;
    }

    return (
      <div >
        <LoadingOverlay
          active={companyReviewLoading}
          spinner={
            <div className="loading-search">
              <center className=" bg-dark1 rounded mt-5 col-md-6 col-sm-6 p-2 col-12">
                <h4 className="loadingText pt-1  mb-1 d-sm-block d-md-block">
                  Loading Company Reviews
            </h4>
                <h6 className="text-white pt-1 pb-1 d-block d-sm-none d-md-none"></h6>
                <img
                  src={Images.loaderWhite}
                  alt="img"
                  className="pb-2"
                  width="30"
                />
              </center>
            </div>
          }
        >
          <div className="container col-md-6 No-Pading col-lg-6 col-sm-6 col-12 bg-light">

          <Webheader pageName={Strings.reviewCaps} handleBack={this.handleBack}/>
            <div className="pt-0">
              <div className="pt-3 row ml-2">

                {this.state.ratingNumber.map((item, index) =>
                  <div className={this.state.myIndex == index ? "d-flex align-items-center border rounded ml-md-3 p-2 pointer onRatingCls" : "d-flex align-items-center border rounded ml-md-3 p-2 pointer ratingCls" } onClick={() => this.onRatingClick(item, index)} key={item.index}>
                    {this.state.myIndex == item.index ? (
                      <img src={Images.whiteStar} alt="Whitestar" className="ml-1" height='15px' width='15px' />
                    ) : (
                        <img src={Images.greenStar} alt="Whitestar" className="ml-1" height='15px' width='15px' />
                      )}
                    <span className='ml-2'>{item.name}</span>
                  </div>
                )}
                <p className="border-bottom pt-6"></p>
              </div>
              <div className="mt-2">
                {responseReveiw != undefined && responseReveiw != "" ?
                  responseReveiw.map((item, index) =>
                    <div className="card shadow-sm rounded1 mb-2 p-2 ml-2" key={item.index}>
                      <div className="row ml-2 d-flex align-items-center">
                        {item.jobseekerImage != null && item.jobseekerImage != "" && item.jobseekerImage != undefined ?
                          (item.jobseekerImage.startsWith("https://lh3.googleusercontent.com") || item.jobseekerImage.startsWith("https://platform-lookaside.fbsbx.com") || item.jobseekerImage.startsWith("https://lh5.googleusercontent.com") || item.jobseekerImage.startsWith("https://lh6.googleusercontent.com") ) ? <img src={item.jobseekerImage} alt="DefaultImg" className="rounded" height='35px' width='35px' /> : <img src={imageUrl + "jobseeker/getImg/" + item.jobseekerImage} alt="" className="rounded" height='35px' width='35px' />
                          : <img src={defaultImg} alt="DefaultImg" className="rounded" height='35px' width='35px' />}
                        <span className="ml-2">{item.jobseekerName}</span>
                      </div>
                      <StarRatings
                        rating={item.rating}
                        starRatedColor="#0DD3AF"
                        starDimension="20px"
                        starSpacing="0px"
                        numberOfStars={5}
                      />
                      <span>
                        {item.comments}</span>
                    </div>
                  )
                  : <div className="col-md-5 col-9 col-center">
                    <div className="card rounded noRecordCls">
                      <center className="card-header no-data-header p-3 topRadius" >
                        <div className="icon-circle shadow-sm">
                          <img src={Images.noRecord} height={50} width={34} alt="No record" className="mt-2 mb-2 ml-2 mr-2" />
                        </div>
                      </center>
                      <span className="rounded-pills text-dark text-center mt-5 mb-5 pl-2 pr-2">{Strings.notReviewText}</span>
                    </div>
                  </div>}
                <Modal show={this.state.rateCompanyModal} onHide={this.cancelRating} centered>
                  <div className="modal-header bg-success borderRadiusModal SaveUnsaveHeader">
                    <div className="col-md-12">
                    </div>
                  </div>
                  <div className="modal-body">
                    <center>
                      <h5 className="text-warning">
                        {this.state.reviewValue}</h5>
                      <StarRatings
                        rating={this.state.ratingValue}
                        starRatedColor="#FFC107"
                        starDimension="20px"
                        starSpacing="1px"
                        changeRating={this.changeRating}
                        starHoverColor="#FFC107"
                        numberOfStars={5}
                      />
                    </center>
                    <center>
                      <div className="col-md-8 col-8 col-sm-8">
                        <input onChange={this.setCommentValue} type="text" value={this.state.comments} className="form-control shadow-sm mt-3 commentHereCls" placeholder={Strings.commentHere} />
                      </div>
                    </center>
                    <div className="tab-content" id="pills-tabContent">
                      <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                        <div className="col-md-12">
                          <div className="text-center mb-3 mt-4">
                            <div onClick={() => this.cancelRating()} className="btn bg-success text-white col-md-5 col-5 buttonRounded border-0" data-dismiss="modal">
                              <div type="submit"  >{Strings.cancelCaps}</div>
                            </div> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button type="submit" className="btn bg-success text-white col-md-5 col-5 buttonRounded border-0" onClick={() => this.onRateCompany()} data-dismiss="modal">{Strings.submit}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
                <Modal show={this.state.reviewDoneModal} onHide={this.hideModal} centered>
                  <div className="modal-header bg-success borderRadiusModal SaveUnsaveHeader">
                    <div className="col-md-12">
                      <div className="row  justify-content-center">
                        <div className="shadow-sm p-3 mb-5 bg-white rounded-circle">
                          <i className="fas fa-check text-success fa-2x ml-2 mr-2"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-body">
                    <center>
                      <div className="mt-4 mb-2">{Strings.thankyou}</div>
                      <div className="mt-2 mb-2">{Strings.yourFeedbackMeans}</div>
                    </center>

                    <div className="tab-content" id="pills-tabContent">
                      <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-4 col-4 col-sm-4"></div>
                            <div onClick={this.okClick} type="submit" className="btn btn-success col-md-4 mt-2 col-sm-4 col-4 roundedModal" data-dismiss="modal-header">{Strings.oK}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
            <div className="container-fluid bg-light col-md-12 col-lg-12 col-sm-12 col-12 No-Pading">
              <Route component={Footer} className="d-none d-md-block d-lg-block d-sm-block d-xl-block" />
            </div>
          </div>
        </LoadingOverlay>
      </div>
    )
  }
}
const mapStateToProps = state => {
return {
   companyReviewError: state.companyProfileReducer.companyReviewError,
    companyReviewLoading: state.companyProfileReducer.companyReviewLoading,
    companyReviewData: state.companyProfileReducer.companyReviewData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCompanyReviewData: (ratingValue) => dispatch(getCompanyReviewData(ratingValue)),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyReview);