import React, { Component } from "react";
import helpers from "../config";
import Images from "../Images";
import Slider from 'rc-slider';
import DatePicker from 'react-date-picker';
import Strings from "../utilities/stringlanguage";
import { Link } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import '../_style/educationdetail.css';
import { getJobseekerProfile } from "../actions/profileAction";
import ModernDatepicker from 'react-modern-datepicker';
import ModalDiv from "../components/modal";
import { getSectorData, addExperienceByJobseeker, updatedExp,getExpData,deleteExp } from "../actions/experienceAction";
var jobseekerId;
var expAllData = [];
var industrData = [];
var url;
var imageUrl;
var title;
var keywords;
var description;
var getExpArr;
var skillsData;
var currentData;
var Data;
var isCurrentlyWorking=false;
var endDateData;
var moment = require("moment");
class Experience extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      updateBtn: false,
      addIndust: false,
      expAllData: "",
      showForm: false,
      jobPosition: "",
      jobStartDate: "",
      jobEndDate: "",
      jobIndustryOther: "",
      startDate: "",
      skills: "",
      skillsNew:skillsData,
      professionalSummary: "",
      experienceLevel: "0",
      organizationName: "",
      isChecked: false,
    };
  }
  async componentDidMount() {
    let apiUrl = await helpers.getServerUrl()
    url = apiUrl.serverUrl
    imageUrl = apiUrl.imageUrl
    this.state.isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
    jobseekerId = localStorage.getItem("jobseekerId");
    this.props.getJobseekerProfile(jobseekerId);
    this.props.getExpData(jobseekerId);
    this.props.getSectorData();
    window.scrollTo(0, 0);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({  skills: nextProps.jobseekerData.skills,  })
  }
 
  addmoreFun = () => {
    this.setState({
      updateBtn: false,
      showForm: true,
      organizationName: "",
      checkedstart: false,
      jobPosition: "",
      jobStartDate: "",
      jobEndDate: "",
      startDate: "",
      endDate: "",
      jobIndustry: "",
      professionalSummary: "",
      skills: "",
      experienceLevel: 0,
      chosenDate: "",
      addIndust: false,
    })
  }
  showModal = e => {
    this.setState({ show: true });
  };
  hideModal = e => {
    this.setState({ show: false });
  };

  showModalComplete = e => {
    this.setState({ complete: true });
  }
  hideModalComplete = e => {
    this.setState({ complete: false });
  }
  showUpdateModal = e => {
    this.setState({ update: true });
  }
  hideUpdateModal = e => {
    this.setState({ update: false, showForm: false });
  }
  deleteModal = e => {
    this.setState({ delete: true });
  }
  hideDeleteModal = e => {
    this.setState({ delete: false });
  }
  addExperienceData = async (e) => {
    if (this.state.jobIndustry == "Other") {
      if (this.state.jobIndustryOther == null || this.state.jobIndustryOther == undefined || this.state.jobIndustryOther == "") {
        this.showModal();
        this.setState({ msg: Strings.provideAllDetail });
      }
      else {
        this.addExpData();
      }
    }
    else {
      this.addExpData();
    }
  }
  addExpData = async (e) => {
    if (this.state.professionalSummary.trim().length > 20) {
      this.showModalComplete();
      this.setState({ msgcomplete: Strings.workExperienceAddedSuccessfully })
      let jobIndustry = this.state.jobIndustry == "Other" ? this.state.jobIndustryOther : this.state.jobIndustry
      var body = {
        jobseekerId: jobseekerId,
        skills: this.state.skills,
        profileSummary: null,
        organizationName: this.state.organizationName,
        jobPosition: this.state.jobPosition,
        experience: "Experience",
        experienceLevel: this.state.experienceLevel,
        userCurrentLocation: this.state.profileSummary,
        professionalSummary: this.state.professionalSummary,
        jobIndustry: jobIndustry,
        jobStartDate: this.state.jobStartDate,
        jobEndDate: this.state.jobEndDate,
        currentlyWorking: this.state.isChecked
      }
      this.props.addExperienceByJobseeker(body);
       this.setState({ showForm: true })
    }
    else {
      this.showModal();
      this.setState({ msg: Strings.professionalSummaryShort });
    }
  }


  setExperienceData = () => {
    if (this.state.organizationName !== null && this.state.organizationName !== undefined && this.state.organizationName !== "" && this.state.professionalSummary !== null && this.state.professionalSummary !== undefined && this.state.professionalSummary !== "" && this.state.jobPosition !== null && this.state.jobPosition !== undefined && this.state.jobPosition !== "" && this.state.jobStartDate !== "" && this.state.jobStartDate !== null && (this.state.jobEndDate !== "" && this.state.jobEndDate !== null && this.state.jobEndDate !== undefined && this.state.isChecked === false || this.state.isChecked === true)) {
      {
        this.state.updateBtn ?
          this.updateExperienceData() :
          this.addExperienceData()
      }

    }
    else {
      this.showModal();
      this.setState({ msg: Strings.provideAllDetail });
    }
  }
  updateExperienceData = async (e) => {
    if (this.state.professionalSummary.trim().length > 20) {
      this.showUpdateModal();
      this.setState({ updateMsg: Strings.experienceUpdated, })
      var bodyExp = {
        jobseekerId: jobseekerId,
        expId: this.state.expId,
        skills: this.state.skills,
        profileSummary: null,
        organizationName: this.state.organizationName,
        jobPosition: this.state.jobPosition,
        experience: "Experience",
        experienceLevel: this.state.experienceLevel,
        userCurrentLocation: this.state.profileSummary,
        professionalSummary: this.state.professionalSummary,
        jobIndustry: this.state.jobIndustry,
        jobStartDate: this.state.jobStartDate,
        jobEndDate: this.state.jobEndDate,
        currentlyWorking: this.state.isChecked
      }
      this.props.updatedExp(bodyExp);
    }
    else {
      this.showModal();
      this.setState({ msg: Strings.professionalSummaryShort });
    }
  }


  editExp = async (item) => {
    this.setState({ showForm: true, updateBtn: true })
    try {
      let response = await fetch(url + "/jobseeker/getExpOfJs", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          jobseekerId: jobseekerId,
          expId: item.expId
        })
      });
      response = await response.json();
      if (response !== null) {
        let responseData = response;
        const editExpArray = responseData.data.expArray[0]
        this.setState({
          expId: editExpArray.expId,
          skills: editExpArray.skills,
          profileSummary: null,
          organizationName: editExpArray.organizationName,
          jobPosition: editExpArray.jobPosition,
          experience: editExpArray.experience,
          jobEndDate: editExpArray.jobEndDate !== "" && editExpArray.jobEndDate !== null ? new Date(editExpArray.jobEndDate) : "",
          jobStartDate: editExpArray.jobStartDate !== "" && editExpArray.jobStartDate !== null ? new Date(editExpArray.jobStartDate) : "",
          experienceLevel: editExpArray.experienceLevel,
          professionalSummary: editExpArray.professionalSummary,
          jobIndustry: editExpArray.jobIndustry,
          isChecked: editExpArray.currentlyWorking,
        })
      }
    } catch (err) {
    }
  }

  deleteExperience = async (item) => {
     this.props.deleteExp(item);
     this.deleteModal();
       this.setState({ deleteMsg: Strings.workExperienceDeletedSuccessfully })
       
    }
  
  // setJobPosition = (e) => {
  //   this.setState({ jobPosition: e.target.value })
  // }
  setJobPosition(e) {  
    if (e.target.value.match("^[a-zA-Z ]*$") != null) {
      this.setState({ jobPosition: e.target.value });
      // body.jobPosition = e.target.value
    }
  }
  // setOrganizationName = (e) => {
  //   this.setState({ organizationName: e.target.value })
  // }

  setOrganizationName(e) {  
    if (e.target.value.match("^[a-zA-Z ]*$") != null) {
      this.setState({ organizationName: e.target.value });
      // body.organizationName = e.target.value
    }
  }

  setStartDate = (e) => {
    this.setState({ jobStartDate: e })
  }
  setEndDate = (ed) => {
    this.setState({ jobEndDate: ed })
  }
  setProfessionalSummary = (e) => {
    this.setState({ professionalSummary: e.target.value })
  }
  // setSkills = (e) => {
  //   this.setState({ skills: e.target.value})
  // }

  setSkills(e) {  
    if (e.target.value.match("^[a-zA-Z ]*$") != null) {
      this.setState({ skills: e.target.value });
      // body.skills = e.target.value
  }
  }
  setIndustryType(selectedValue) {
    this.setState({ jobIndustry: selectedValue })
    if (selectedValue == "Other") {
      this.setState({ addIndust: true });
    }
    else {
      this.setState({ addIndust: false });
    }
  }

  setUpdatedIndust = (e) => {
    this.setState({ jobIndustryOther: e.target.value })
  }

  experienceLevel = (e) => {
    this.setState({ experienceLevel: e.target.value })
  }
  setToggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  }
  addEndDateData =() => {
    return (
      <form>
          <div className="form-group">
            <label className="inputHeadingText">{Strings.endDateCaps}</label>
            <div className="datepickerStyle">
                <DatePicker className="shadow-sm rounded form-control datepickerStyle"
                    minDate={this.state.jobStartDate}
                    maxDate={new Date()}
                     value={this.state.jobEndDate != null ? this.state.jobEndDate: null }
                     format={'dd MMM y'}
                     onChange={(ed) => this.setEndDate(ed)}
                     placeholder={Strings.endDate}
                    />
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div type="button" onClick={() => this.setExperienceData()} type="submit" className="btn btn-success col-md-10 mt-2  mb-2 rounded" >{Strings.update}</div></div> 
      </form>

    )
  }
  experienceForm = () => {
    return (
      <form>
        <div className="form-group">
          <label className="inputHeadingText">{Strings.organizationname}</label>
          <input onChange={(e) => this.setOrganizationName(e)} type="text" id="orgNameId" value={this.state.organizationName} className="form-control shadow-sm inputHeadClass" aria-describedby="emailHelp" placeholder={Strings.enterorganization} />
        </div>
        <div className="form-group">
          <label className="inputHeadingText">{Strings.jobPosition}</label>
          <input onChange={(e) => this.setJobPosition(e)} type="text" value={this.state.jobPosition} id="jobPositionId" className="form-control shadow-sm inputHeadClass" placeholder={Strings.enterjobpos} />
        </div>
        <div className="form-group">
          <label className="inputHeadingText">{Strings.startDateCaps}</label>
          <div className="datepickerStyle">
              <DatePicker className="shadow-sm rounded form-control datepickerStyle"
                    maxDate={this.state.jobEndDate !== "" && this.state.jobEndDate !== null && this.state.jobEndDate !== undefined ? this.state.jobEndDate : new Date()}
                     value={this.state.jobStartDate != null ? this.state.jobStartDate: null }
                     format={'dd MMM y'}
              onChange={(e) => this.setStartDate(e)}
                     placeholder={Strings.startDate}
                    />
          </div>
        </div>
        <div className="form-label">
          <label className="inputHeadingText"><input type="checkbox" id="currentlyWorkingId" checked={this.state.isChecked}
            onChange={this.setToggleChange} value={this.state.isChecked} placeholder="Start date" /> {Strings.rucurrentlyworkhere}</label>
        </div>
        {this.state.isChecked == false ?
          <div className="form-group">
            <label className="inputHeadingText">{Strings.endDateCaps}</label>
            <div className="datepickerStyle">
                <DatePicker className="shadow-sm rounded form-control datepickerStyle"
                    minDate={this.state.jobStartDate}
                    maxDate={new Date()}
                     value={this.state.jobEndDate != null ? this.state.jobEndDate: null }
                     format={'dd MMM y'}
                     onChange={(ed) => this.setEndDate(ed)}
                     placeholder={Strings.endDate}
                    />
            </div>
          </div> : null}
        <div className="form-group">
          <label className="inputHeadingText">{Strings.industryType}</label>
          <select value={this.state.jobIndustry}
            onChange={(e) => this.setIndustryType(e.target.value)} className="form-control shadow-sm styled-select text-secondary inputHeadClass">
            <option disabled={"Choose one", this.state.jobIndustry}>{"Choose one"}</option>
            {this.state.industrDataResponse !== null && this.state.industrDataResponse !== "" && this.state.industrDataResponse !== undefined ?
              this.state.industrDataResponse.map((item, index) => (
                this.state.jobIndustry == item.value ?
                  <option key={index} className="jobIndustryCls">{item.value}</option>
                  :
                  <option key={index} >{item.value}</option>
              )) : null
            }
          </select>
        </div>
        {this.state.addIndust ?
          <input placeholder="Please enter industry type"
            value={this.state.jobIndustry == "Other" ? null : this.state.jobIndustry}
            onChange={(e) => this.setUpdatedIndust(e)}
            type="text" className="form-control shadow-sm mb-2 inputHeadClass" /> : null
        }
        <div className="form-group">
          <label className="inputHeadingText">{Strings.professionalSummary}</label>
          <input placeholder={Strings.updateProfessionalSummary} onChange={(e) => this.setProfessionalSummary(e)} type="text" value={this.state.professionalSummary} className="form-control shadow-sm inputHeadClass" />
        </div>
        <div className="form-group">
          <label className="inputHeadingText">{Strings.workExperienceCaps}</label>
          <div>
            <div className="ml-2 mr-2">
              <Slider
                min={0}
                max={10}
                handleStyle={[{ backgroundColor: '#1b888b', borderColor: "#1b888b" }]}
                railStyle={{ backgroundColor: "#D3D3D3" }}
                trackStyle={{ backgroundColor: '#0DD3AF', }}
                value={this.state.experienceLevel}
                onChange={(experienceLevel) => this.setState({ experienceLevel: experienceLevel })}
              />
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="text-success">{Strings.experience}</div>
                {""}
                {this.state.experienceLevel !== undefined ?
                  <div className="ml-2 text-success">{this.state.experienceLevel}
                    {this.state.experienceLevel == "0" || this.state.experienceLevel == "1" ?
                      " Year" :
                      " Years"
                    }
                  </div> : null}
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label className="inputHeadingText">{Strings.skills}</label>
          <input placeholder={Strings.enterYourSkills} onChange={(e) => this.setSkills(e)} type="text" value={this.state.skills} className="form-control shadow-sm inputHeadClass" />
        </div>

        {this.state.updateBtn ?
          <div className="d-flex justify-content-center">
            <div type="button" onClick={() => this.setExperienceData()} type="submit" className="btn btn-success col-md-10 mt-2  mb-2 rounded" >{Strings.update}</div></div> :
          <div className="d-flex justify-content-center">
            <div type="button" onClick={() => this.setExperienceData()} type="submit" className="btn btn-success col-md-12 mt-2 mb-2 rounded" >{Strings.submit}</div></div>}
      </form>

    )
  }

  render() {
    let { jobseekerData, jsDataError, jsDataLoading ,getIndustryData, getIndustryLoading, getIndustryError, setExpData, setExpLoading, setExpError, updateExpData, updateExpLoading, updateExpError ,getExperienceData,getExperienceLoading,getExperienceError,delExperienceData,delExperienceLoading,delExperienceError} = this.props;

    if (getIndustryData !== "" && getIndustryData !== null && getIndustryData !== undefined) {
      industrData = [];
      getIndustryData.forEach(getIndustryData => {
        industrData.push({
          value: getIndustryData.sectorName
        });

      });
      this.state.industrDataResponse = industrData;
    }

    if(jobseekerData!==null && jobseekerData!=="" && jobseekerData!==undefined){
    this.state.experience= jobseekerData.experience;
    this.state.organizationName1= jobseekerData.organizationName;
    currentData=jobseekerData.currentlyWorking;
    Data = jobseekerData.professionalSummary;
    endDateData=jobseekerData.jobEndDate;
    skillsData = jobseekerData.experience === "Experience" ? jobseekerData.skills : "";
    this.state.profileSummary= jobseekerData.profileSummary;
    }
    
    if(getExperienceData.experience !== null && getExperienceData.experience  !== null &&  getExperienceData.experience !== undefined ){
       getExpArr = getExperienceData.experience;
       if(getExpArr.length == 0 ){
        this.state.addForm =true;
       }
       else{
        this.state.addForm =false;

       }
    }
    if(getExperienceData !=undefined && getExperienceData !=""){
       isCurrentlyWorking=getExperienceData.isCurrentlyWorking
    }

    if (getExperienceData.title != undefined) {
      document.title = getExperienceData.title;
      document.getElementsByTagName("META")[2].content =getExperienceData.description
      document.getElementsByTagName("META")[3].content =getExperienceData.keywords
    }
    return (
      <LoadingOverlay
      active={getExperienceLoading||getIndustryLoading||updateExpLoading||jsDataLoading}
      spinner={<div className="loading-fresher">
        <center className=" bg-dark3 rounded mt-5 col-md-10 col-sm-6 p-2 col-12">
          <h4 className="loadingText pt-1 mb-1 d-sm-block d-md-block">{Strings.loadingWorkExperience}</h4>
          <h6 className="text-white pt-1 pb-1 d-block d-sm-none d-md-none"></h6>
          <img src={Images.loaderWhite} alt="img" className="pb-2" width="30" />
        </center>
      </div>}
    >
      <div >
        <div className="container-fluid col-md-12 col-lg-12 col-sm-12 col-12 mt-1 pl-0 pr-0">
          <div className="tab-pane fade show active" id="pills-pg" role="tabpanel" aria-labelledby="pills-pg-tab">
           { this.state.showForm ||this.state.addForm? 
           this.experienceForm()
              :
              <div className="conatiner-fluid pt-1 mt-0">
                <div className="form-group">
                  <div className="pt-1 pl-1 pr-1 pb-1 mb-1">
                    {(getExpArr !== null &&
                      getExpArr !== "" &&
                      getExpArr !== undefined &&  getExpArr.length > 0)
                      ? getExpArr.map(item => (
                        <div key={item.expId}>
                          <ul className="nav nav-pills mb-0 d-flex justify-content-end" id="pills-tab" role="tablist">
                            <li className="nav-item mr-3 pointer">
                              <div className="text-success" onClick={() => this.deleteExperience(item)} ><i className="far fa-trash-alt text-success-gradient mr-2"></i>{Strings.delete}</div>
                            </li>
                            <li className="nav-item pointer">
                              <div className="text-success" onClick={() => this.editExp(item)} ><i className="fa fa-edit d-inline-flex"></i> {Strings.Edit}</div>
                            </li>
                          </ul>
                          <div className="card pt-2 pl-3 pr-3 pb-2 shadow-sm mb-3 rounded">
                            <div><i className="fa fa-city text-darksuccess mr-2" aria-hidden="true"></i>
                              <span>{item.organizationName}</span> <span className="badge badge-success"></span>
                            </div>
                            <div><i className="fa fa-user text-darksuccess mr-2" aria-hidden="true"></i> <span>{item.jobPosition}</span></div>
                            <div className="row pl-3"><div><i className="fa fa-calendar text-darksuccess mr-2"></i>
                              <span> {item.jobStartDate !== null && item.jobStartDate !== "" && item.jobStartDate !== undefined ?
                                moment(item.jobStartDate).format(
                                  "DD MMM YYYY") : "NA"}</span>
                              {item.currentlyWorking ? <span className="bg-success text-white pl-2 pr-2  pt-1 pb-1 ml-2 currentWorkingCls">{Strings.CurrentWorking}</span> :
                                <span> {item.jobEndDate !== null && item.jobEndDate !== "" && item.jobEndDate !== undefined ? "To " +
                                  moment(item.jobEndDate).format(
                                    "DD MMM YYYY") : null}</span>}
                            </div></div>
                            <div><i className="fa fa-industry text-darksuccess mr-2"></i> <span> {item.jobIndustry !== null && item.jobIndustry !== "" && item.jobIndustry !== undefined ? item.jobIndustry : "NA"}</span></div>
                          </div>
                        </div>
                      )) :
                     null}
                  </div>
                </div>
                {this.state.organizationName1 !== null && this.state.organizationName1 !== "" && this.state.organizationName1 !== undefined ?
                  <div type="button" className="btn btn-outline d-flex justify-content-center text-darksuccess mt-0 pt-1 " onClick={() => this.addmoreFun()} >
                    <img src={Images.addIcon} alt="Addicon" className="img" height = '20' width = '20' />
                    <p className="text-darksuccess">{Strings.addMoreExperience}</p>
                  </div>
                  : null}
              </div>
            }
          </div>
        </div>
       
          <ModalDiv modalReact={true} showModal={this.state.show} msg={this.state.msg} crossBtnModal={true} btn1Text={Strings.oK} btn1Click={()=>this.hideModal()} hideModal={()=>this.hideModal()}/>
          <Link to="/profile">
        <ModalDiv modalReact={true} showModal={this.state.complete} successModal={true} msg={this.state.msgcomplete} crossBtnModal={true} btn1Text={Strings.oK} btn1Click={()=>this.hideModalComplete()} hideModal={()=>this.hideModalComplete()}/>
        </Link>
        <ModalDiv modalReact={true} showModal={this.state.delete} successModal={true} crossBtnModal={true} msg={this.state.deleteMsg} btn1Text={Strings.oK} btn1Click={()=>this.hideDeleteModal()} hideModal={()=>this.hideDeleteModal()}/>
        <ModalDiv modalReact={true} showModal={this.state.update}  successModal={true} crossBtnModal={true} msg={this.state.updateMsg} btn1Text={Strings.oK} btn1Click={()=>this.hideUpdateModal()} hideModal={()=>this.hideUpdateModal()}/>

      </div>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = state => {

  return {
    jobseekerData: state.profileReducer.jobseekerData,
    jsDataLoading: state.profileReducer.jsDataLoading,
    jsDataError: state.profileReducer.jsDataError,
    getIndustryData: state.experienceReducer.getIndustryData,
    getIndustryLoading: state.experienceReducer.getIndustryLoading,
    getIndustryError: state.experienceReducer.getIndustryError,
    setExpData: state.experienceReducer.setExpData,
    setExpLoading: state.experienceReducer.setExpLoading,
    setExpError: state.experienceReducer.setExpError,
    updateExpData: state.experienceReducer.updateExpData,
    updateExpLoading: state.experienceReducer.updateExpLoading,
    updateExpError: state.experienceReducer.updateExpError,
    getExperienceData:state.experienceReducer.getExperienceData,
    getExperienceLoading:state.experienceReducer.getExperienceLoading,
    getExperienceError:state.experienceReducer.getExperienceError,
    delExperienceData:state.experienceReducer.delExperienceData,
    delExperienceLoading:state.experienceReducer.delExperienceLoading,
    delExperienceError:state.experienceReducer.delExperienceError
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getJobseekerProfile: jobseekerId => dispatch(getJobseekerProfile(jobseekerId)),
    getExpData: jobseekerId => dispatch(getExpData(jobseekerId)),
    addExperienceByJobseeker: body => dispatch(addExperienceByJobseeker(body)),
    getSectorData: () => dispatch(getSectorData()),
    updatedExp: bodyExp => dispatch(updatedExp(bodyExp)),
    deleteExp: item => dispatch(deleteExp(item)),

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Experience);