import helpers from "../config";

export const SHOW_RECENT_POPULAR_SEARCH ='SHOW_RECENT_POPULAR_SEARCH';
export const SHOW_RECENT_POPULAR_SUCCESS ='SHOW_RECENT_POPULAR_SUCCESS';
export const SHOW_RECENT_POPULAR_FAILED ='SHOW_RECENT_POPULAR_FAILED';

export const SHOW_SEARCH_DATA ='SHOW_SEARCH_DATA';
export const SHOW_SEARCH_DATA_SUCCESS ='SHOW_SEARCH_DATA_SUCCESS';
export const SHOW_SEARCH_DATA_FAILED ='SHOW_SEARCH_DATA_FAILED';

var jobseekerId; 

 export const getRecentPopularSearch = () => {
   jobseekerId = localStorage.getItem("jobseekerId");
    return (dispatch) => {
    dispatch({type: SHOW_RECENT_POPULAR_SEARCH})
    helpers.getServerUrl().then(url =>   {
      var url = url.serverUrl
     
    fetch(url +"/jobseeker/recentPopularSearch", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        jobseekerId: jobseekerId,
         
      })
    })
      .then(response => response.json())
      .then(responseData => {
       dispatch ({
            type: SHOW_RECENT_POPULAR_SUCCESS,
            payload: responseData,
           })
      })
      .catch(err => {
        dispatch({
            type: SHOW_RECENT_POPULAR_FAILED,
            payload: err
        })
        console.log('error', err)
    })
    })
 }
    
}


export const getSearchData = (searchText) => {
jobseekerId = localStorage.getItem("jobseekerId");
 return (dispatch) => {
 dispatch({type: SHOW_SEARCH_DATA})
 helpers.getServerUrl().then(url =>   {
   var url = url.serverUrl
  
 fetch(url +"/jobseeker/masterSearchApp", {
   method: "POST",
   headers: {
     "Content-Type": "application/json"
   },
   body: JSON.stringify({
     jobseekerId: jobseekerId,
          searchText: searchText.trim(),
        searchFrom: "CandidatePanel",
   })
 })
   .then(response => response.json())
   .then(responseData => {
    dispatch ({
         type: SHOW_SEARCH_DATA_SUCCESS,
         payload: responseData,
        })
   })
   .catch(err => {
     dispatch({
         type: SHOW_SEARCH_DATA_FAILED,
         payload: err
     })
     console.log('error', err)
 })
 })
}
 
}
