import * as actionTypes from '../actions/educationDetailAction';

const initialState = {
    pgDegreeData: [],
    pgDegreeDataLoading: false,
    pgDegreeDataError: null,
    ugDegreeData: [],
    ugDegreeDataLoading: false,
    ugDegreeDataError: null,
    passingYearData: [],
    passingYearDataLoading: false,
    passingYearDataError: null,
    universityNameData : [],
    universityNameLoading : false,
    universityNameError : null,
    universityNameData : [],
    universityNameLoading : false,
    universityNameError : null,
    allEducationData : [],
    allEducationDataLoading : false,
    allEducationDataError : null,
    addEducationData : [],
    addEducationLoading : false,
    addEducationError : null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SHOW_PG_DEGREE_DATA:
            return {
                ...state,
                pgDegreeDataLoading: true,
                pgDegreeDataError: null
            };

        case actionTypes.SHOW_PG_DEGREE_DATA_SUCCESS:
            return {
                ...state,
               
                pgDegreeData : action.payload,
                pgDegreeDataError: null,
                pgDegreeDataLoading : false,
            };

        case actionTypes.SHOW_PG_DEGREE_DATA_FAILED:
            return {
                ...state,
                pgDegreeDataLoading: false,
                pgDegreeData: [],
                pgDegreeDataError: action.payload
            };

            case actionTypes.SHOW_UG_DEGREE_DATA:
                return {
                    ...state,
                    ugDegreeDataLoading: true,
                    ugDegreeDataError: null
                };
    
            case actionTypes.SHOW_UG_DEGREE_DATA_SUCCESS:
                return {
                    ...state,
                   
                    ugDegreeData : action.payload,
                    ugDegreeDataError: null,
                    ugDegreeDataLoading : false,
                };
    
            case actionTypes.SHOW_UG_DEGREE_DATA_FAILED:
                return {
                    ...state,
                    ugDegreeDataLoading: false,
                    ugDegreeData: [],
                    ugDegreeDataError: action.payload
                };
           
            case actionTypes.PASSING_YEAR_DATA:
                return {
                    ...state,
                    passingYearDataLoading: true,
                    passingYearDataError: null
                };
    
            case actionTypes.PASSING_YEAR_DATA_SUCCESS:
                return {
                    ...state,
                   
                    passingYearData : action.payload,
                    passingYearDataError: null,
                    passingYearDataLoading : false,
                };
    
            case actionTypes.PASSING_YEAR_DATA_FAILED:
                return {
                    ...state,
                    passingYearDataLoading: false,
                    passingYearData: [],
                    passingYearDataError: action.payload
                };

                case actionTypes.SHOW_UNIVERSITY_DATA:
                    return {
                        ...state,
                        universityNameLoading : true,
                        universityNameError: null
                    };
        
                case actionTypes.SHOW_UNIVERSITY_DATA_SUCCESS:
                    return {
                        ...state,
                       
                        universityNameData : action.payload,
                        universityNameError: null,
                        universityNameLoading : false,
                    };
        
                case actionTypes.SHOW_UNIVERSITY_DATA_FAILED:
                    return {
                        ...state,
                        universityNameLoading: false,
                        universityNameData: [],
                        universityNameError: action.payload
                    };

                    case actionTypes.GET_ALL_EDUCATION_DATA:
                        return {
                            ...state,
                            allEducationDataLoading : true,
                            allEducationDataError: null
                        };
            
                    case actionTypes.GET_ALL_EDUCATION_SUCCESS:
                        return {
                            ...state,
                           
                            allEducationData : action.payload,
                            allEducationDataError: null,
                            allEducationDataLoading : false,
                        };
            
                    case actionTypes.GET_ALL_EDUCATION_FAILED:
                        return {
                            ...state,
                            allEducationDataLoading: false,
                            allEducationData: [],
                            allEducationDataError: action.payload
                        };

                        case actionTypes.ADD_EDUCATION_DATA:
                        return {
                            ...state,
                            addEducationLoading : true,
                            addEducationError: null
                        };
            
                    case actionTypes.ADD_EDUCATION_DATA_SUCCESS:
                        return {
                            ...state,
                           
                            addEducationData : action.payload,
                            addEducationError: null,
                            addEducationLoading : false,
                        };
            
                    case actionTypes.ADD_EDUCATION_DATA_FAILED:
                        return {
                            ...state,
                            addEducationLoading: false,
                            addEducationData: [],
                            addEducationError: action.payload
                        };


               
           default:
            return state;
    }
};

export default reducer;