import React  from 'react';
import Images from "../Images";

export default class Loading extends React.PureComponent{
  render(){
      return(
        <div className="loaderWhite " >
        <center className=" bg-dark rounded mt-5 col-md-6 p-2 col-12">
     <h4 className="text-white pt-1 pb-3 d-none d-sm-block d-md-block">{this.props.name}</h4>
     <h6 className="text-white pt-1 pb-3 d-block d-sm-none d-md-none">{this.props.name}</h6>
       <img src={Images.loaderWhite} alt="White loading" className="pb-2" width="30" />
     </center>
       </div>         
      )
  }
}