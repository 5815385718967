/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import helpers from "../config";
import Images from "../Images";
import "../_style/footer.css";
var allCities = [];
var url;
var imageUrl;
class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    let apiUrl = await helpers.getServerUrl()
    url = apiUrl.serverUrl
    imageUrl = apiUrl.imageUrl
    this.popularCities();
  }

  selectCity(item) {
    localStorage.setItem("city", item.cityName)
  }

  popularCities = async (e) => {
    allCities = [];
    try {
      let response = await fetch(url + "/jobseeker/getCities", {
        method: "GET"
      })
      response = await response.json();
      if (response.success) {
        response.cities.forEach((data, index) => {
          allCities.push({
            "cityName": data.cityName,
          })
        })
        this.setState({ cities: allCities });
      }
    }
    catch (err) {
      console.log(err);
    }
  }

  render() {
    return (
      <div className="footer ml-0 mb-0 mr-0 pl-0 pr-0 d-none d-md-block d-sm-block d-lg-block d-xl-block pt-4">
        <div className="col-md-12 bg-dark col-12">
          <div className="row pl-3 pt-3 border-bottom">
            <h5 className="text-white font-weight-600">Search Jobs by City</h5>
            <ul className="list-unstyled">
              <div className="row text-left">
                {this.state.cities != null && this.state.cities != "" && this.state.cities != undefined ?
                  this.state.cities.map((item, index) =>
                    <Link
                      key={index}
                      to={{
                        pathname: "/joblist",
                        state: {
                                cityName: item.cityName,
                                fromFooter:"fromFooter"
                               },
                        search: "jobs-in-" + item.cityName
                      }} className="text-decoration-none col-md-4 col-5 mb-1">
                      <div className="mr-3" onClick={(i) => this.selectCity(item)}>
                        <span className="text-white text-capitalize">{item.cityName}</span>
                      </div>
                    </Link>
                  ) : null}
              </div>
            </ul>
          </div>
        </div>
        <div className="border-bottom col-md-12 bg-dark col-12">
          <h5 className="text-white mb-2 text-left font-weight-600 pt-2">Sitemap</h5>
          <div className="row">
            <ul className="list-unstyled text-white text-left col-md-4 col-5">
              <Link to="/mainPage" className="pointer text-decoration-none">
                <li className="text-white ml-1">Home</li> </Link>
              <Link to="/mainPage" className="pointer text-decoration-none">
                <li className="text-white ml-1">Mission</li></Link>
              <Link to="/mainPage" className="pointer text-decoration-none">
                <li className="text-white ml-1">Features</li></Link>
              
            </ul>
            <ul className="list-unstyled text-white text-left col-md-4 col-5">
              <li className="text-white ml-2">
                <a
                  href="https://jobam.co/employerzone/login.php"
                  target="_blank"
                  className="text-decoration-none text-white"
                >
                  {" "}
                  Post a Job
                </a>
              </li>
              <Link to="/mainPage" className="pointer text-decoration-none">
                <li className="text-white ml-2">Find Jobs</li> </Link>
              <Link to="/interviewtips" className="pointer text-decoration-none">
                <li className="text-white ml-2">Interview Tips</li></Link>
            </ul>
            <ul className="list-unstyled text-white text-left col-md-4 col-12">
              <Link to="/privacypolicy" className="pointer text-left text-decoration-none">
                <li className="text-white ml-md-3 ml-2">Privacy</li></Link>
              <Link to="/faq" className="pointer text-left text-decoration-none">
                <li className="text-white ml-md-3 ml-2">FAQ</li></Link>
              <Link to="/mainPage" className="pointer text-left text-decoration-none">
                <li className="text-white ml-3">Contact Us</li></Link>
            </ul>
          </div>
        </div>
        <div className="col-md-12 bg-dark p-1 pb-5">
          <h5 className="text-white mt-2 ml-0 text-left font-weight-600 ml-2">Follow Us</h5>
          <div className="row">
            <ul className="list-unstyled text-white text-left col-md-4 footerSocial col-5">
              <li className="text-white ">
                <img src={Images.fafacebook} alt="Facebook" /> &nbsp;{""}
                <a
                  href="https://www.facebook.com/jobam.co/?modal=admin_todo_tour"
                  target="_blank"
                  className="text-decoration-none text-white"
                >
                  Facebook
                </a>
              </li>
              <li className="text-white">
              <img src={Images.fainstagram} alt="Instagram" /> &nbsp;{""}
                <a
                  href="https://www.instagram.com/jobam.co/"
                  target="_blank"
                  className="text-decoration-none text-white"
                >
                  Instagram
              </a>
              </li>
              {/*<li className="text-white">
              <img src={Images.fayoutube} alt="Youtube"/> &nbsp;{""}
                <a
                  href="https://www.youtube.com/channel/UC8X2XJW54WFMkWjrYnVJFLg"
                  target="_blank"
                  className="text-decoration-none text-white"
                >
                  YouTube
              </a>
              </li>*/}
            </ul>
            <ul className="list-unstyled text-white text-left  footerSocial col-md-4 col-5">
              <li className="text-white">
              <img src={Images.fatwitter} alt="Twitter" /> {" "}
                <a
                  href="https://twitter.com/jobam_co"
                  target="_blank"
                  className="text-decoration-none text-white"
                >
                  Twitter
              </a>
              </li>

              <li className="text-white">
              <img src={Images.falinkedin} alt="Linkedin"/>&nbsp; {""}
                <a
                  href="https://www.linkedin.com/company/jobam/"
                  target="_blank"
                  className="text-decoration-none text-white"
                >
                  Linked In
              </a>
              </li>
              {/*<li className="text-white">
              <img src={Images.fapinterest} alt="Pinterest" />&nbsp;{" "}
                <a
                  href="https://www.pinterest.ca/jhatpatjobs/"
                  target="_blank"
                  className="text-decoration-none text-white"
                >
                  Pinterest
              </a>
                    </li>*/}
            </ul>
            {/*<ul className="list-unstyled text-white footerSocial text-left col-md-4 col-5">
              <li className="text-white">
                <img src={Images.fatumblr} alt="Tumblr" />{" "}
                <a
                  href="https://www.tumblr.com/blog/jhatpatjobs"
                  target="_blank"
                  className="text-decoration-none text-white"
                >
                  Tumblr
              </a>
              </li>

                  </ul>*/}
          </div>

        </div>
        <div className="copyright-background p-1">
          <div className="row">
          <div className="col-md-4 ml-1 mt-2">
            <img src={Images.headerLogo} className="img-responsive w-100" alt="Logo"/>
          </div>
          <div className="col-md-7 text-right">
          <p className=" text-white pt-4">© Copyright 2022 <a href="https://jobam.co/" target="_blank" className=" text-white">Jobam</a>. All Rights Reserved</p>
          </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Footer;
