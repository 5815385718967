import React  from 'react';
import '../_style/webheader.css';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import CloseIcon from '@material-ui/icons/Close';
import { Link, Route } from "react-router-dom";
class webheader extends React.PureComponent{
    constructor(props) {
        super(props);
        this.state = {};
      }
  render(){
    this.state.isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
      return(
        <div className="bg-success col-md-12 p-1 fixedtop header-height">
       <div className="row">
       <div className="text-white text-decoration-none col-2 col-md-2 pointer pt-0 ml-0 pl-1" onClick={this.props.handleBack}>
       {this.props.settingState?
                  <Link to={{ pathname: this.props.backLink, state: { isAvailable: this.state.isAvailable } }}
                      className="text-white text-decoration-none col-2 pl-4 col-md-2">
                      <KeyboardBackspaceIcon />
                  </Link>:
                  !this.props.aboutMeState ?
									<Link to={{pathname: this.props.backLink}}
										className="text-white text-decoration-none pl-3 col-2 col-md-2">
										<KeyboardBackspaceIcon />
                  </Link> : <Link className="pl-3 col-md-2"><KeyboardBackspaceIcon className="text-white text-decoration-none pointer" data-toggle="modal" data-target="#valid" /></Link>}                
                  </div>
                  {this.props.normalText
                    ?
                <div className="col-md-8 col-7">
								<h5 className="text-white text-center pt-1 textLimit text-uppercase d-none d-sm-block d-lg-block d-md-block mt-1 font-weight-bold pageNameSize">{this.props.pageName}</h5>
                <h6 className="text-center text-white text-uppercase textLimit  d-block d-sm-none d-md-none d-lg-none mt-1 pt-1 font-weight-bold pageNameSize">{this.props.pageName}</h6>
                </div>
                :
                <div className="col-md-8 col-8 pr-0">	
                <h5 className="text-white text-center pt-1 d-none d-sm-block d-lg-block d-md-block mt-1 nameSize">{this.props.pageName}</h5>
                <h6 className="text-center text-white  d-block d-sm-none d-md-none d-lg-none mt-1 pt-1 nameSize">{this.props.pageName}</h6>
                </div>}
                {this.props.notificationIcon ?
                <div className="notiHeader pt-1">
                <i className="fa fa-ellipsis-v text-white fa-1x pointer" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                <div className="dropdown-menu disableNoti" aria-labelledby="dropdownMenuButton">
                    <a className="dropdown-item pointer" data-toggle="modal" data-target="#disNotiModal" >Disable Notification</a>
                </div></div>
               : null
                }
                {this.props.filterIcon ?
                <div className="col-md-1 col-2 text-right"  onClick={this.props.handleBack}>
                  <CloseIcon className="pointer mt-1 text-white"/>
                  </div>
              :null}
              {this.props.shareIcon ?
                <div className="col-md-1 col-2 text-right shareIconClass text-white pointer" onClick={this.props.shareClick}>
                  <ShareOutlinedIcon />
                </div> : null}
                {this.props.jobReport 
                ?
                (this.state.isUserLoggedIn ?
                <div className="col-md-1 col-2 reportButton pt-1 pl-5" data-toggle="modal" data-target="#myModalReportJob">
                <i className="fas fa-exclamation-circle fa-1x text-white rounded pointer bgChange iconText"></i>
              </div>:
               <div className="col-md-1 col-2 reportButton pt-1 text-right pl-5" data-toggle="modal" data-target="#loginReportJob">
               <i className="fas fa-exclamation-circle fa-1x text-white rounded pointer bgChange iconText"></i>
             </div>)
            : null}
							</div>
       </div>   
      )
  }
}
export default webheader;