import * as actionTypes from '../actions/myJobsAction';
const initialState= {
    myJobsData: [],
    myJobsDataLoading: false,
    myJobsDataError: null,
    saveJobsData:[],
    saveJobsLoading:false,
    saveJobsError: null,
    jobseekerVerifyData:[],
    jobseekerverifyLOading:false,
    jobseekerverifyError:null

};

 
const myJobsD = (state = initialState, action) => {
    switch ( action.type ) {
        case actionTypes.MYJOBS_DATA_LOADING: 
            return{...state, myJobsDataLoading: true, myJobsDataError: null};
        case actionTypes.SHOW_MYJOBS_DATA: 
            return{...state, myJobsDataLoading: false, myJobsData: action.payload, myJobsDataError: null};
        case actionTypes.MYJOBS_LOAD_FAIL: 
            return{...state, myJobsDataLoading: false, myJobsData: [], myJobsDataError: action.payload};
       
        case actionTypes.SAVEJOBS_DATA_LOADING: 
            return{...state, saveJobsLoading: true, saveJobsError: null};   
        case actionTypes.SHOW_SAVEJOBS_DATA: 
            return{...state, saveJobsLoading: false, saveJobsData: action.payload, saveJobsError: null};
        case actionTypes.SAVEJOBS_LOAD_FAIL: 
            return{...state, saveJobsLoading: false, saveJobsData: [], saveJobsError: action.payload};
            
        case actionTypes.jobseekerVerify_DATA_LOADING: 
            return{...state, jobseekerverifyLoading: true, jobseekerverifyError: null};
        case actionTypes.SHOW_jobseekerVerify_DATA: 
            return{...state, jobseekerverifyLoading: false, jobseekerVerifyData: action.payload, jobseekerverifyError: null};
        case actionTypes.jobseekerVerify_LOAD_FAIL: 
            return{...state, jobseekerverifyLoading: false, jobseekerVerifyData: [], jobseekerverifyError: action.payload};
        default: 
            return state;
    }  
};
 
export default myJobsD;




