import helpers from "../config";

export const CITY_DATA_LOADING = "CITY_DATA_LOADING";
export const SHOW_CITY_DATA = "SHOW_CITY_DATA";
export const CITY_LOAD_FAIL = "CITY_LOAD_FAIL";
export const MAP_DATA_LOADING = "MAP_DATA_LOADING";
export const SHOW_MAP_DATA = "SHOW_MAP_DATA";
export const MAP_LOAD_FAIL = "MAP_LOAD_FAIL";

export const citylist = () => {
    return (dispatch) => {
      dispatch({type: CITY_DATA_LOADING})
      helpers.getServerUrl().then(url =>   {
       var url = url.serverUrl
      fetch(url + "/jobseeker/getCities", {
       method: "GET",
       headers: {
         "Content-Type": "application/json"
       },
     })
     .then(response => response.json())
     .then(responseData => {
       dispatch ({
           type:SHOW_CITY_DATA,
           payload:responseData.cities
       })
     })
     .catch(err => {
       dispatch({
           type: CITY_LOAD_FAIL,
           payload: err
       })
   })
  })
}
  }

  export const filterCitySearch = (selLat,selLong) => {
    let jobseekerId=localStorage.getItem("jobseekerId")
    return (dispatch) => {
      dispatch({type: MAP_DATA_LOADING})
      helpers.getServerUrl().then(url =>   {
       var url = url.serverUrl
      fetch(url + "/jobs/filterJobsCandidatePanel", {
       method: "POST",
       headers: {
         "Content-Type": "application/json"
       },
       body: JSON.stringify({
        latitude: selLat,
        longitude: selLong,
        radius: 30,
        jobseekerId: jobseekerId
      })
     })
     .then(response => response.json())
     .then(responseData => {
       dispatch ({
           type:SHOW_MAP_DATA,
           payload:responseData.filtered
       })
     })

     .catch(err => {
       dispatch({
           type: MAP_LOAD_FAIL,
           payload: err
       })
   })
  })
}
  }
