import helpers from "../config";

export const SET_CONTACTSUPPORT = "SET_CONTACTSUPPORT";
export const SET_CONTACTSUPPORT_DATA = "SET_CONTACTSUPPORT_DATA";
export const SET_CONTACTSUPPORT_FAIL = "SET_CONTACTSUPPORT_FAIL";


var jobseekerId;
var msg;
  export const setContactSupport = (msg) => {
    jobseekerId = localStorage.getItem("jobseekerId");
      return (dispatch) => {
       dispatch({type: SET_CONTACTSUPPORT})
       helpers.getServerUrl().then(url =>   {
        var url = url.serverUrl
       fetch( url + "/jobseeker/submitFeedback", {
        method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            msg: msg,
            jobseekerId: jobseekerId,
          })
        })
      .then(response => response.json())
      .then(responseData => {
        dispatch ({
            type: SET_CONTACTSUPPORT_DATA,
            payload: responseData
        })
      })
      .catch(err => {
        dispatch({
            type: SET_CONTACTSUPPORT_FAIL,
            payload: err
        })
        console.log('error', err)
    })
  })
 }
}

