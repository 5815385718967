import * as actionTypes from "../actions/notificationAction";
const initialState= {
    notiData: [],
    notiDataLoading: false,
    notiDataError: null,
    notiTypeData:[],
    notiTypeLoading:false,
    notiTypeError:null,
    clearLoading:false,
    clearSuccess:[],
    clearFail:null,
    delLoading:false,
    delSuccess:[],
    delFail:null,
};
const notiResult = (state = initialState, action) => {
    switch ( action.type ) {
        case actionTypes.NOTI_DATA_LOADING: 
            return{...state, notiDataLoading: true, notiDataError: null};
        case actionTypes.NOTI_DATA: 
            return{...state, notiDataLoading: false, notiData: action.payload, notiDataError: null};
        case actionTypes.NOTI_FAIL: 
            return{...state, notiDataLoading: false, notiData: [], notiDataError: action.payload};
        case actionTypes.NOTI_TYPE_LOADING: 
            return{...state, notiTypeLoading: true, notiTypeError: null};
        case actionTypes.NOTI_TYPE: 
            return{...state, notiTypeLoading: false, notiTypeData: action.payload, notiTypeError: null};
        case actionTypes.NOTITYPE_FAIL: 
            return{...state, notiTypeLoading: false, notiTypeData: [], notiTypeError: action.payload};
        case actionTypes.CLEAR_LOADING: 
            return{...state, clearLoading: true, clearFail: null};
        case actionTypes.CLEAR_SUCC: 
            return{...state, clearLoading: false, clearSuccess: action.payload, clearFail: null};
        case actionTypes.CLEAR_FAIL: 
            return{...state, clearLoading: false, clearSuccess: [], clearFail: action.payload};
        case actionTypes.DEL_LOADING: 
            return{...state, delLoading: true, delFail: null};
        case actionTypes.DEL_SUCC: 
            return{...state, delLoading: false, delSuccess: action.payload, delFail: null};
        case actionTypes.DEL_FAIL: 
            return{...state, delLoading: false, delSuccess: [], delFail: action.payload};
        default: 
            return state;
    }  
};
 
export default notiResult;




