import * as actionTypes from "../actions/blogAction";
const initialState= {
    blogs: [],
    blogsLoading: false,
    blogsError: null,
    blogDetail : [],
    blogDetailLoading : false,
    blogDetailError: null
};
const blogsR = (state = initialState, action) => {
    switch ( action.type ) {
        case actionTypes.BLOGS_LOADING: 
            return{...state, blogsLoading: true, blogsError: null};
        case actionTypes.SHOW_BLOGS_DATA: 
            return{...state, blogsLoading: false, blogs: action.payload, blogsError: null};
        case actionTypes.BLOGS_FAILED: 
            return{...state, blogsLoading: false, blogs: [], blogsError: action.payload};

            case actionTypes.BLOG_DETAIL_LOADING: 
            return{...state, blogDetailLoading: true, blogDetailError: null};
        case actionTypes.SHOW_BLOG_DETAIL: 
            return{...state, blogDetailLoading: false, blogDetail: action.payload, blogDetailError: null};
        case actionTypes.BLOG_DETAIL_FAILED: 
            return{...state, blogDetailLoading: false, blogDetail: [], blogDetailError: action.payload};

        default: 
            return state;
    }  
};
 
export default blogsR;




