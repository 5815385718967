import * as actionTypes from '../actions/profileAction';
const initialState= {
    jobseekerData: [],
    jsDataLoading: false,
    jsDataError: null,
    resumeData:[],
    resumeLoading:false,
    resumeDataError:null,
    resumeDeleteData:[],
    resumeDeleteLoading:false,
    resumeDeleteDataError:null,
    videoDeleteLoading: false, 
    videoDeleteData: [], 
    videoDeleteDataError:null,
    updateProfileR : [],
    updateError: null
};
const profile = (state = initialState, action) => {
    switch ( action.type ) {
        case actionTypes.PROFILE_DATA_LOADING: 
            return{...state, jsDataLoading: true, jsDataError: null};
        case actionTypes.SHOW_PROFILE_DATA: 
            return{...state, jsDataLoading: false, jobseekerData: action.payload, jsDataError: null};
        case actionTypes.PROFILE_LOAD_FAIL: 
            return{...state, jsDataLoading: false, jobseekerData: [], jsDataError: action.payload};
        case actionTypes.RESUME_DATA_LOADING: 
            return{...state, resumeLoading: true, resumeDataError: null};
        case actionTypes.SHOW_RESUME_DATA: 
            return{...state, resumeLoading: false, resumeData: action.payload, resumeDataError: null};
        case actionTypes.RESUME_LOAD_FAIL: 
            return{...state, resumeLoading: false, resumeData: [], resumeDataError: action.payload};
        case actionTypes.DELETERESUME_DATA_LOADING: 
            return{...state, resumeDeleteLoading: true, resumeDeleteDataError: null};
        case actionTypes.SHOW_DELETERESUME_DATA: 
            return{...state, resumeDeleteLoading: false, resumeDeleteData: action.payload, resumeDeleteDataError: null};
        case actionTypes.DELETERESUME_LOAD_FAIL: 
            return{...state, resumeDeleteLoading: false, resumeDeleteData: [], resumeDeleteDataError: action.payload};
        case actionTypes.DELETEVIDEO_DATA_LOADING: 
            return{...state, videoDeleteLoading: true, videoDeleteDataError: null};
        case actionTypes.SHOW_DELETEVIDEO_DATA: 
            return{...state, videoDeleteLoading: false, videoDeleteData: action.payload, videoDeleteDataError: null};
        case actionTypes.DELETEVIDEO_LOAD_FAIL: 
            return{...state, videoDeleteLoading: false, videoDeleteData: [], videoDeleteDataError: action.payload};
            case actionTypes.DATA_UPDATED: 
            return{...state, updateProfileR: action.payload, updateError: null};
        case actionTypes.DATA_UPDATED_FAIL: 
            return{...state, updateProfileR: [], updateError: action.payload};
        default: 
            return state;
    }  
};
 
export default profile;



