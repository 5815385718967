import React, { Component } from "react";
import Images from "../Images";
import helpers from "../config";
import Strings from "../utilities/stringlanguage";
import { connect } from "react-redux";
import { getPostedJobs } from "../actions/companyProfileAction";
import CardComponent from "../components/cardComponent";
import { Modal } from "react-bootstrap";

var jobseekerName;
var postedJobs = [];
var jobseekerId;
var companyId;
var compShareId;
var jobId;
var url;
var imageUrl;

class PostedJobs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      jobseekerId: "",
      show: false,
      modalText: "",  
      showLoginPopup: false,
      isSaved: false,
      postedJobsList: "",
    };
  }

  async componentDidMount() {
    let apiUrl = await helpers.getServerUrl()
    url = apiUrl.serverUrl
    imageUrl = apiUrl.imageUrl
    this.state.isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
    companyId = localStorage.getItem("companyId");
    compShareId = localStorage.getItem("compShareId");
    if (companyId == null && compShareId == null) {
      window.location.reload();
    }
    jobId = localStorage.getItem("jobId");
    jobseekerId = localStorage.getItem("jobseekerId");
    jobseekerName = localStorage.getItem("givenName");
    this.props.getPostedJobs(companyId);
  }

  showModal = (e) => {
    this.setState({
      show: true
    });
  };

  showLoginModal = (e) => {
    this.setState({
      showLoginPopup: true
    });
  };

  hideLoginModal = (e) => {
    this.setState({ showLoginPopup: false });
  };

  hideModal = (e) => {
    this.setState({ show: false });
  };

  async saveJobs(item) {
    this.setState({ isSaved: !this.state.isSaved, loading: true });
    if (jobseekerId != null && jobseekerId != undefined) {
      try {
        let response = await fetch(url + "/jobs/addJobsToFavourites", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            jobseekerId: jobseekerId,
            jobId: item.jobId,
            isSaved: item.isSaved,
          })
        })
        response = await response.json()
        if (response.success) {
          this.setState({
            msg: response.msg,
            loading: false
          });
          if(item.isSaved !=true)
          {
          this.showModal()
          }
          this.props.getPostedJobs(companyId)
        }
      } catch (err) {
        console.log(err)
      }
    }
    else {
      this.showLoginModal()
    }
  }

  render() {
    let { postedJobsData, postedJobsLoading, postedJobsError } = this.props;
    let postedJobs = [];
    if (postedJobsData.length > 0) {
      postedJobsData.forEach(jobs => {
        postedJobs.push(
          {
            createdDate: jobs.createdDate,
            jobId: jobs.jobId,
            jobLink: jobs.jobLink,
            jobTitle: jobs.jobTitle,
            companyName: jobs.companyName,
            companyImage: jobs.companyImage,
            salary: jobs.salary,
            isSaved: jobs.isSaved,
            isShortlisted: jobs.isShortlisted,
          }
        )
      })
    }
    if (this.state.loading) {

      return (
        <div>
          <center className="mt-12">
            <img src={Images.loader} alt="JJloader" />
          </center>
        </div>
      );
    }
    return (
      <div>
        <div className="modal fade in" id="saveJobs">
          <div className="modal-dialog">
            <div className="modal-content contentRadius">
              <div className="modal-header modal-success p-4 headerRadius">
                <div className="shadow-sm p-3 mb-5 bg-white rounded-circle position-absolute center-icon">
                  <img src={Images.jjLogo} width="50" alt="JJlogo" height="50" />
                </div>
              </div>
              <div className="modal-body mt-4 mb-4">
                {this.state.msg}
              </div>
            </div>
          </div>
        </div>
        <div className=" col-md-12 col-lg-12 col-sm-12 col-12">
          {postedJobs &&
            postedJobs != "" &&
            postedJobs != undefined
            ? postedJobs.map((item) =>
              <div key={item.jobId}>
                <CardComponent companyName={item.companyName} jobType={item.jobType} jobTitle={item.jobTitle} salary={item.salary}
                  createdDate={item.createdDate} jobLink={item.jobLink} jobId={item.jobId}
                  isShortlisted={item.isShortlisted} companyImage={imageUrl + item.companyImage}
                  isSaved={item.isSaved}
                  callSaved={i => this.saveJobs(item)}
                />
                <Modal show={this.state.show} centered>
                  <div className="modal-header bg-success borderRadiusModal SaveUnsaveHeader">
                    <div className="col-md-12">
                      <div className="row  justify-content-center">
                        <div className="shadow-sm p-3 mb-5 bg-white rounded-circle">
                          <i className="fas fa-check fa-3x text-success-gradient "></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-body">
                    <center>
                      <div className="mt-4 mb-2">{this.state.msg}</div></center>
                    <div className="tab-content" id="pills-tabContent">
                      <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-4 col-4 col-sm-4"></div>
                            <div type="submit" className="btn btn-success col-md-4 mt-2 col-sm-4 col-4 roundedModal" data-dismiss="modal-header" onClick={this.hideModal} >{Strings.oK}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
                <Modal show={this.state.showLoginPopup} centered>
                  <div className="modal-header bg-success borderRadiusModal SaveUnsaveHeader">
                    <div className="col-md-12">
                      <div className="row  justify-content-center">
                        <div className="shadow-sm p-3 mb-5 bg-white rounded-circle">
                          <i className="fas fa-exclamation fa-3x text-success-gradient "></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-body">
                    <center>
                      <div className="mt-4 mb-2">Kindly login to save this job</div></center>
                    <div className="tab-content" id="pills-tabContent">
                      <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-4 col-4 col-sm-4"></div>
                            <div type="submit" className="btn btn-success col-md-4 mt-2 col-sm-4 col-4 roundedModal" data-dismiss="modal-header" onClick={this.hideLoginModal} >{Strings.oK}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            ) : null}
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    postedJobsLoading: state.companyProfileReducer.postedJobsLoading,
    postedJobsData: state.companyProfileReducer.postedJobsData,
    postedJobsError: state.companyProfileReducer.postedJobsError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getPostedJobs: (companyId) => dispatch(getPostedJobs(companyId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostedJobs);
