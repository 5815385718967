import React, { Component } from 'react';
import Images from '../Images';
import { Link } from "react-router-dom";
import { Route } from 'react-router-dom';
import Footer from "../components/footer";
import Strings from "../utilities/stringlanguage";
import Slider from "react-slick";
import helpers from "../config";
import WebBottomNav from "../components/webBottomNavigation";
import LoadingOverlay from 'react-loading-overlay';
import YouTube from '@u-wave/react-youtube';
import { connect } from "react-redux";
import { Modal, Button, ThemeProvider } from "react-bootstrap";
import { getVideoTips, getInterviewTips } from "../actions/interviewTipsAction";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ModalDiv from '../components/modal';
import MetaTags from 'react-meta-tags';
import Navbar from "../components/navBar";
import "../_style/interviewTips.css";
import {
  EmailShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
var jobseekerName;
var jobseekerId;
var tokenId;
var url;
var imageUrl;
var selectedLang;
var i = 0;
var getInterview;
class InterviewTips extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      category: "General",
      intCategory: "general",
      interviewId: 0,
    };
  }

  async componentDidMount() {
    let apiUrl = await helpers.getServerUrl()
    url = apiUrl.serverUrl;
    imageUrl = apiUrl.imageUrl;
    selectedLang = localStorage.getItem("selectedLang")
    if (selectedLang != null && selectedLang != undefined && selectedLang != "") {
      Strings.setLanguage(selectedLang);
    }
    this.state.isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
    jobseekerId = localStorage.getItem("jobseekerId");
    tokenId = localStorage.getItem("tokenId");
    this.jobseekerVerify();
    this.props.getInterviewTips();
    this.props.getVideoTips();
    jobseekerName = localStorage.getItem("givenName");
    this.changeColor();
    window.scroll(0,0);
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };


  changeColor() {
    var icon = document.getElementById('XMLID_49_');
    icon.style.fill = "00c7b6";
  }

  jobseekerVerify = async () => {
    try {
      let response = await fetch(url + "/admin/getAppSetDetails1", {
        method: "POST",
        headers: {
          "content-Type": "application/json"
        },
        body: JSON.stringify({
          jobseekerId: jobseekerId,
          tokenId: tokenId,
          loginFrom: "Web"
        })
      })
      response = await response.json()
      let ismobileVerified = response.ismobileVerified
      if (this.state.isUserLoggedIn && ismobileVerified !== true) {
        this.updateProfileModal()
      }

    } catch (err) {
    }
  }

  updateProfileModal = e => {
    this.setState({
      showUpdateModel: true
    });
  };

  hideProfileModal = e => {
    this.setState({ showUpdateModel: false });
    this.props.history.push('/updateProfile')
  };
  setVideo() {
    this.setState({ vLid: i });
  }

  render() {
    let { getVideoTipsData, getVideoTipsLoading, getVideoTipsError } = this.props;
    let { getInterviewLoading, getInterviewData, getInterviewError } = this.props;

    if (getInterviewData.allTips != "" && getInterviewData.allTips != undefined) {
      getInterview = getInterviewData.allTips;
    }

    if (getInterviewData != "" && getInterviewData != undefined && getVideoTipsData != "" && getVideoTipsData != undefined) {
      this.state.isLoading = false;
    }
    if (getInterviewData.title != undefined) {
      document.title = getInterviewData.title;
      document.getElementsByTagName("META")[2].content = getInterviewData.description;
      document.getElementsByTagName("META")[3].content = getInterviewData.keywords
    }
    this.state.isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
    const shareUrl =
      "https://jobam.co/interviewTips";
    const title = "Jobam";
    const settings = {
      centerMode: true,
      centerPadding: "40px",
      slidesToShow: 1,
      beforeChange: function (index) {
        i = index;
      },
      responsive: [
        {
          breakpoint: 768,

          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '40px',
            slidesToShow: 3
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '40px',
            slidesToShow: 1
          }
        }
      ]
    };
    const { value, pathMap } = this.state;


    return (
      <LoadingOverlay
        active={this.state.isLoading}
        spinner={<div className="loading-interviewtips">
          <center className=" bg-dark2 rounded mt-5 col-md-6 col-sm-6 p-2 col-12">
            <h4 className="loadingText pt-1 mb-1 d-sm-block d-md-block">{Strings.loadingInterviewTips}</h4>
            <h6 className="text-white pt-1 pb-1 d-block d-sm-none d-md-none"></h6>
            <img src={Images.loaderWhite} alt="img" className="pb-2" width="30" />
          </center>
        </div>}
      >
        <MetaTags>
          {/* for browser */}
          <title>We Help You To Grow & Overcome Every Diffculties In Interview - Jobam</title>
          {/* For Link sharing in social from url */}
          <meta property="og:title" content="We Help You To Grow & Overcome Every Diffculties In Interview - Jobam" />
          <meta property="og:url" content="https://jobam.co/interviewtips" />
          <meta property="og:image" content="../assets/logo192.png" />
          <meta property="og:site_name" content="Jobam" />
          {/* For twitter sharing from url  */}
          <meta name="twitter:title" content="We Help You To Grow & Overcome Every Diffculties In Interview - Jobam" />
          <meta name="twitter:image" content="../assets/logo192.png" />
          <meta name="twitter:site" content="@JhatpatJ" />
          <meta name="twitter:creator" content="@JhatpatJ" />
        </MetaTags>
        <div className="loaderoverlay">

          <div className="container col-md-6 col-lg-6 col-sm-6 col-12 No-Pading">
            <Navbar />
            <div className="barColor p-1 d-block d-sm-none d-md-none d-lg-none">
              <h5 className="text-center text-white text-uppercase pt-1 mb-0 pb-1">{Strings.tabInterviewTips}</h5>
            </div>
            {this.state.isUserLoggedIn ?
              <div className="menuFixed">

                <Tabs>
                  <TabList className="nav nav-pills">
                    <Tab className="nav-item col-md-6 col-sm-6 col-6 tabViewMobile m-0 p-0 pointer">

                      <div className="nav-link active text-center tab pt-2 pb-2 pt-md-3 pb-md-3 rounded-0 text-uppercase mr-0 ml-0" data-toggle="pill"
                        href="#interviewTips"><h6 className="mb-0">{Strings.interviewTipsCaps}</h6></div>

                    </Tab>
                    <Tab className="nav-item col-md-6 m-0 p-0 col-sm-6 col-6 tabViewMobile pointer">
                      <div className="nav-link  text-center tab pt-2 pb-2 pt-md-3 pb-md-3 rounded-0 text-uppercase mr-0 ml-0" data-toggle="pill"
                        href="#videoTips"><h6 className="mb-0">{Strings.videoTipsInter}</h6></div></Tab>
                  </TabList>

                  <TabPanel>
                    <div id="interviewTips" className="tab-pane active">
                      <div className="userScroll1 shadow-sm p-1 mb-5 bg-lightgrey " >
                        <ul className="nav nav-pills nav-scroll1 pb-1 pt-1 nabcolor">
                          {
                            getInterview !== null &&
                              getInterview !== "" &&
                              getInterview !== undefined
                              ?
                              getInterview.map((item) => (
                                <li
                                  className="nav-item rounded-pills mb-1">
                                  <a className={item.category == "general" ? "nav-link  pt-1 pb-1 text-uppercase active" : "nav-link  pt-1 pb-1 text-uppercase"} data-toggle="pill"
                                    href="#intCategory" onClick={() => this.setState({ intCategory: item.category })} >{item.category}</a>
                                </li>

                              ))
                              : <div className="d-none">
                                <img src={Images.load} alt="Load" />
                              </div>
                          }
                        </ul>
                      </div>
                      <div className="tab-content mt-4">
                        <div id="intCategory" className="container tab-pane active">
                          {
                            getInterview != null &&
                              getInterview != "" &&
                              getInterview != undefined
                              ?

                              getInterview.map(item => (
                                this.state.intCategory == item.category ?
                                  <div key={this.state.intCategory}>

                                    <Slider {...settings} infinite={false}>

                                      {item.tips.map((point, index) => (
                                        <div key={item.pId} className="col-md-12 col-sm-12 col-12">
                                          <div className="card intercard m-0 p-0">
                                            <div className="card-title mt-0 pt-0">
                                              <img
                                                className="w-100 p-3  mx-auto d-block rounded-top tipimage mt-0 pt-0"
                                                src={
                                                  imageUrl + point.tipImage
                                                }
                                                alt="First slide" />
                                            </div>
                                            <div className="card-content pointScroll">
                                              <h6 className="text-dark font-weight-bolder ml-2 mr-2  pl-2">{point.title}</h6>
                                              <div className="ml-2 mr-2 mb-0 itemScroll" key={point.points}
                                              >
                                                {point.points != null && point.points != "" && point.points != undefined ?
                                                  point.points.map(intPoint => (
                                                    <div className="ml-2 mr-2 mb-0" key={intPoint}>
                                                      <p className="text-dark ml-3 mb-1">{intPoint}</p>
                                                    </div>

                                                  )) : null}

                                              </div>


                                            </div>
                                            <div className="bg-success">
                                              <button className="col-md-12 font-weight-bold text-center sharetext border-0 bg-success" data-toggle="modal" data-target="#shareModal">
                                                {Strings.ShareWithYourFriends}
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      ))
                                      }
                                    </Slider>
                                  </div>
                                  : null
                              ))
                              : null
                          }
                        </div>
                      </div>
                      <ModalDiv modalReact={true} showModal={this.state.showUpdateModel} msg={Strings.updateProfileText} btn1Text={Strings.oK} btn1Click={()=>this.hideProfileModal()}/>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div id="videoTips" className="tab-pane newVideo">
                      <div className="userScroll1 shadow-sm p-1 mb-5 bg-lightgrey" >
                        <ul className="nav nav-pills nav-scroll1 pb-1 pt-1 nabcolor">
                          {
                            getVideoTipsData != null &&
                              getVideoTipsData != "" &&
                              getVideoTipsData != undefined
                              ? getVideoTipsData.map(item => (
                                <li key={item.category} className="nav-item rounded-pills mb-1">
                                  <a className={item.category == "General" ? "nav-link  pt-1 pb-1 text-uppercase active" : "nav-link  pt-1 pb-1 text-uppercase"} data-toggle="pill"
                                    href="#videoCategory" onClick={() => this.setState({ category: item.category })} >{item.category}</a>
                                </li>
                              ))
                              : <div className="d-none">
                                <img src={Images.load} alt="Load" />
                              </div>
                          }
                        </ul>
                      </div>
                      <div className="tab-content mt-4">
                        <div id="videoCategory" className="container tab-pane active">
                          {getVideoTipsData != null && getVideoTipsData != "" && getVideoTipsData != undefined ?

                            (
                              getVideoTipsData.map((item) => (
                                this.state.category == item.category ?
                                  <div key={this.state.category}>
                                    <Slider {...settings} infinite={false}>
                                      {item.videoList.map((point, index) => (
                                        <div>
                                          <div className="col-md-12 col-sm-12 col-12">
                                            <div className="card intercard m-0 p-0">
                                              <div className="card-header header-rounded">
                                                <center>
                                                  <h6 className="text-dark font-weight-bolder pt-3">{point.videoTitle}</h6>
                                                </center>
                                              </div>
                                              <div className="card-content videoItemScroll p-3" >

                                                <YouTube width='100%'
                                                  height='350px'
                                                  video={point.videoId}
                                                />
                                              </div>
                                              <div>
                                                <button className="bg-success col-md-12 font-weight-bold text-center sharetext  border-0 mt-1 " data-toggle="modal" data-target="#shareModal">
                                                  {Strings.ShareWithYourFriends}
                                                </button>
                                              </div>
                                            </div>

                                          </div>

                                        </div>
                                      ))
                                      }
                                    </Slider>
                                  </div>
                                  : null
                              ))

                            )

                            :
                            null
                          }
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
                <div className="modal fade" id="shareModal">
                  <div className="modal-dialog modal-dialog-centered modal-sm">
                      <div className="modal-content rounded modal-shift-center">
                        <div className="modal-header bg-success borderRadiusModal pt-5">
                            <div className="modal-title circleSet">
                              <div className="row ml-1">
                                <img src="../logo192.png" alt="Logo" className="position-fixed shadow-sm p-3 mb-5 bg-white rounded-circle mt-md-3 mt-4 ml-1" height="60" width="60" alt=""/>
                              </div>
                            </div>
                        </div>
                        <div className="modal-body mt-3">
                            <p className="text-center">Build you interview skills with Jobam and get trick to crack the interview.</p>
                            <div className="row mt-2 col-12 col-md-12 col-sm-12">
                              <span className="col-md-1 col-1  text-center ml-3 shareButtons">
                                <WhatsappShareButton
                                  url={shareUrl}
                                  quote={title}
                                  className="Demo__some-network__share-button"
                                >
                                  <WhatsappIcon size={34} round />
                                </WhatsappShareButton>
                              </span>
                              <span className="col-md-1 col-1 ml-3 text-center shareButtons">
                                <FacebookShareButton
                                  url={shareUrl}
                                  quote={title}
                                  className="Demo__some-network__share-button"
                                >
                                  <FacebookIcon size={34} round />
                                </FacebookShareButton>
                              </span>
                              <span className="col-md-1 col-1 ml-3 text-center shareButtons">
                                <LinkedinShareButton
                                  url={shareUrl}
                                  quote={title}
                                  imageurl={imageUrl + this.state.companyImage}
                                  className="Demo__some-network__share-button"
                                >
                                  <LinkedinIcon size={34} round />
                                </LinkedinShareButton>
                              </span>
                              <span className="col-md-1 col-1 ml-3 text-center shareButtons">
                                <EmailShareButton
                                  url={shareUrl}
                                  subject="Download Jobam and get job opportunities"
                                  className="Demo__some-network__share-button" >
                                  <img src={Images.gmail} width="34" alt="Gmail" className="mr-1"></img>
                                </EmailShareButton>
                              </span>
                            </div>
                            <hr className="mt-2 mb-0"/>
                            <div className="text-center pt-1"><a data-dismiss="modal" className="pointer"><u>Cancel</u></a></div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
              :        
              <div  className="mt-md-0 loginPopup">     
            <Link to="/register" className="text-decoration-none">
            <ModalDiv loginPopup={true} getImage={Images.noRecord} textMsg={Strings.logintoSeeinterviewTips} />
            </Link>
            </div>
            }
            <Route component={Footer} className="d-none d-md-block d-lg-block d-sm-block d-xl-block" />
          </div>
          <WebBottomNav homeText={Strings.homeTabs} myJobText={Strings.myJobTabs} interviewText={Strings.interviewTabs} interviewActive={true} profileData={this.state.isUserLoggedIn ? jobseekerName : Strings.loginTabs} />
        </div>
      </LoadingOverlay>
    )
  }
}

const mapStateToProps = state => {
  return {
    getInterviewData: state.interviewTipsR.getInterviewData,
    getInterviewLoading: state.interviewTipsR.getInterviewLoading,
    getInterviewError: state.interviewTipsR.getInterviewError,
    getVideoTipsData: state.interviewTipsR.getVideoTipsData,
    getVideoTipsLoading: state.interviewTipsR.getVideoTipsLoading,
    getVideoTipsError: state.interviewTipsR.getVideoTipsError,
  };

};
const mapDispatchToProps = dispatch => {
  return {
    getVideoTips: () => dispatch(getVideoTips()),
    getInterviewTips: () => dispatch(getInterviewTips())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(InterviewTips);