import React, { Component } from 'react';
import ScrollToBottom from 'react-scroll-to-bottom';
import socketIOClient from "socket.io-client";
import Webheader from "../components/webheader";
import helpers from "../config";
import $ from 'jquery';
import "../_style/chatcompany.css";
var url, imageUrl,chatUrl, Id, name, adminId, adminName, userImage, jsName, sentTo,image, adminImage,userType;
var Data = [];
export default class ChatAdmin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      text: "",
      messages: [],
      you: "",
      textValue: "",
      response: false,
      endpoint: "https://jobam.co:8000/"
    }
  }

  async componentDidMount() {
    await helpers.getServerUrl().then(data => {
      url = data.serverUrl
      imageUrl = data.imageUrl
      chatUrl=data.chatUrl
      // chatUrl="http://localhost:8000"
    });
    Id = localStorage.getItem('jsObjectId');
    name = localStorage.getItem('jobseekerName');
    adminImage="logo.png";
    adminId=localStorage.getItem('adminId');
    adminName="Jobam";
    userImage = localStorage.getItem('userImage');
    jsName = localStorage.getItem('givenName');
    this.setState({ you: name });
    
    const socket = socketIOClient(chatUrl);
    socket.on("connection", socket);
     socket.on("newAdminMessage", msg => this.onReceivedMessage(msg));
    socket.emit(
      "add user",
      Id,
    );
    setTimeout(() => {
      this.getChat();
    }, 500);
    document.addEventListener("visibilitychange", function() {
      if(document.hidden){
      socket.disconnect()
      }
      else{
    socket.on("connection", socket);
    socket.on("newAdminMessage", msg => this.onReceivedMessage(msg));
    socket.emit(
      "add user",
      Id,
      );
      window.location.reload()
      }
    });
  }


  onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      if(this.state.text.trim() != "" && this.state.text.trim() != null){
      this.sendMessage();
      }
    }
  }

  setMessage = (e) => {
    e.preventDefault();
    this.setState({ text: e.target.value })
  }

  sendMessage() {
    document.getElementById("formChat").reset();
    const message = this.state.text.trim();
    this.setState({ text: "" })
    image=adminImage
    sentTo=adminId
    userType="Jobseeker"
    adminId=localStorage.getItem('adminId');
      const socket = socketIOClient(chatUrl);
      socket.emit(
        "adminAndJs",
        message,
        adminId,
        Id,
        image,
        sentTo,
        userType //admin Name 
      );
    Data.push({
      msg: message,
      userType: userType
    })
    if (Data != null && Data != undefined && Data != "") {
      this.setState({ messages: Data, loading: false });
    }
  };

  onReceivedMessage = async (msg = []) => {
    Data.push({
      msg: msg.message,
      userType: msg.userType,
      createdDate: msg.createdDate

    })
    if (Data != null && Data != undefined && Data != "") {
      this.setState({ messages: Data, loading: false });
    }
  };

   getChat = () => {
    console.log("......")
    fetch(url+ "/admin/getJsAdminChat", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        jobseekerId: Id
      })
    })
      .then(response => response.json())
      .then(responseData => {
        Data = [];
        if (responseData !== null) {
            let chatData=responseData.chatList
          for (var i = 0; i < chatData.length; i++) {
            if (
              chatData[i].userType == null ||
              chatData[i].userType == "" ||
              chatData[i].userType == undefined
            ) {
              chatData[i].userType = "NA";
            }
            if (
              chatData[i].message == null ||
              chatData[i].message == "" ||
              chatData[i].message == undefined
            ) {
              chatData[i].message = "NA";
            }
            Data.push({
              msg: chatData[i].message,
              userType: chatData[i].userType,
              createdDate: chatData[i].createdDate
            });
          }
          if (Data != null && Data != undefined && Data != "") {
            this.setState({ messages: Data, loading: false });
          } else {
            this.setState({ loading: false });
          }
        }
      })
      .catch(error => { });
  }
  
  handleBack = () => {
    this.props.history.push('joblist')
  }
  render() {
    return (
      <center>
      <div className="outerContainerChat bg-light col-md-6 pl-0 pr-0">
        <div className="containerChat">
        <Webheader pageName="CHAT WITH US" handleBack={this.handleBack}/>
        <div className="row mt-3 ml-3">
        <img src={imageUrl + adminImage} width="40" height="40" alt="Admin image" />
               <span className="ml-2 mt-2">{adminName}</span>
               </div>
          <ScrollToBottom className="messagesChat pt-3 pb-5 h-100">
            {this.state.messages ? this.state.messages.map((item, index) => (
              <div className="pt-2" key={item.index}>
                {item.userType =="Admin"
                  ? (
                    <div className="messageContaineChatr pb-1 justifyEndChat">
                      <div className="row">
                        <p className="sentTextChat pr-10"></p>
                        {/* <div className="p-2 bg-white rounded-circle shadow-sm mr-2 h-25">
                          <img src={imageUrl + adminImage} width="20" height="20" alt="Admin Image" />
                        </div> */}
                        <div className="messageBoxChat bg-light col-md-4 rounded-pill my-auto p-1 pl-3 border">
                          <p className="messageTextChatAdmin text-dark my-auto">{item.msg}</p>
                          <span className="messageTextChatNew text-dark my-auto">{item.createdDate}</span>
                        </div>
                      </div>
                    </div>
                  )
                  : (
                    <div className="messageContainerChat">
                      <div className="messageBoxChat bg-success col-md-4 rounded-pill my-auto p-1 pl-3">
                        <p className="messageTextChatAdmin  text-white my-auto">{item.msg}</p>
                        <span className="messageTextChatNew  text-white my-auto">{item.createdDate}</span>
                      </div>
                      {/* <div className="p-2 bg-white rounded-circle shadow-sm ml-2 h-25">
                        <img src={userImage} width="20" height="20" alt="UserImage" />
                      </div> */}
                      <p className="sentTextChat"></p>
                    </div>
                  )
                }
              </div>
            )) : <p>no data</p>}
          </ScrollToBottom>
          <form id="formChat" className="w-100">
            <div className="input-group pb-md-2 fixed-bottom col-md-6">
            <input className="form-control border border-success" type="text" placeholder="Type a message..."
               onChange={(e) => this.setMessage(e)} onKeyDown={this.onKeyDown} />
              <div className="input-group-append pointer border border-success ml-1">
                {this.state.text.trim() != "" && this.state.text.trim() != null ?(
                <span className="input-group-text bg-light" onClick={() => this.sendMessage()}><i class="fas fa-paper-plane text-success"></i></span> ):( <span className="input-group-text"><i class="fas fa-paper-plane text-success"></i></span>)}
              </div>
            </div>
          </form>
        </div>
      </div>
      </center>
    )
  }
}