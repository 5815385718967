import * as actionTypes from '../actions/chatCompanyAction';
const initialState= {
    chatData: [],
    chatDataLoading: false,
    chatDataError: null,
};
const chat = (state = initialState, action) => {
    switch ( action.type ) {
        case actionTypes.GET_CHAT: 
            return{...state, chatDataLoading: true, chatDataError: null};
        case actionTypes.GET_CHAT_DATA: 
            return{...state, chatDataLoading: false, chatData: action.payload,  chatDataError: null};
        case actionTypes.GET_CHAT_FAIL: 
            return{...state, chatDataLoading: false, chatData: [], chatDataError: action.payload};
        default: 
            return state;
    }  
};
 
export default chat;




