import React from 'react';
import '../_style/mapjobs.css';
import LoadingOverlay from 'react-loading-overlay';
import helpers from "../config";
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import Images from '../Images';
import MetaTags from 'react-meta-tags';
import { Link, BrowserRouter, NavLink } from "react-router-dom";
import SearchIcon from '@material-ui/icons/Search';
import Strings from "../utilities/stringlanguage";
import { Map as LeafletMap, TileLayer, Marker, Popup } from 'react-leaflet';
import CardComponent from "../components/cardComponent";
import ModalDiv from '../components/modal';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import MarkerClusterGroup from "react-leaflet-markercluster";
import { connect } from "react-redux";
import { citylist,filterCitySearch } from "../actions/mapJobsAction";
var url;
var imageUrl;
var selLat = '';
var isUserLoggedIn;
var selLong = '';
var jobseekerId, latitude, longitude;
var stores=[];
var mycityData=[];
const L = require('leaflet');
const customMarker = L.icon({ iconUrl: "../logo192.png", iconSize: [24, 24], })
class mapjobs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cityName: localStorage.getItem("city"),
      currentLat: localStorage.getItem("cityLat"),
      currentLong: localStorage.getItem("cityLong"),
      stores: []
    }  
  }
  async componentDidMount() {
    let apiUrl = await helpers.getServerUrl()
    url = apiUrl.serverUrl
    imageUrl = apiUrl.imageUrl
    let selectedLang = localStorage.getItem("selectedLang")
    if (selectedLang != null && selectedLang != undefined && selectedLang != "") {
      Strings.setLanguage(selectedLang);
    }
    if(localStorage.getItem("cityLat")!=null && localStorage.getItem("cityLat") !=undefined && localStorage.getItem("cityLat")!=""){
      this.setState({currentLat:localStorage.getItem("cityLat"),currentLong:localStorage.getItem("cityLong"),cityName:localStorage.getItem("city")})
      selLat = this.state.currentLat;
      selLong = this.state.currentLong;
      this.props.filterCitySearch(selLat,selLong)
    }
    else{
      this.getMyLocation();
    }
    jobseekerId = localStorage.getItem("jobseekerId");
    this.props.citylist();
    isUserLoggedIn = localStorage.getItem("isUserLoggedIn");

  }

  getMyLocation=() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      latitude = position.coords.latitude.toFixed(4);
      longitude = position.coords.longitude.toFixed(4);
     localStorage.setItem("userLatitude", latitude);
     localStorage.setItem("userLongitude", longitude);  
   });
   if(localStorage.getItem("userLatitude") !== null && localStorage.getItem("userLatitude") !== undefined && localStorage.getItem("userLatitude") !== "" && localStorage.getItem("userLongitude") !== null && localStorage.getItem("userLongitude") !== undefined && localStorage.getItem("userLogitude") !== ""){
   let mapLat = parseFloat(localStorage.getItem("userLatitude"));
   let mapLong = parseFloat(localStorage.getItem("userLongitude"));
   selLat = mapLat;
   selLong = mapLong;
    this.props.filterCitySearch(selLat,selLong)
    this.setState({
      currentLat: selLat,
      currentLong: selLong,
      cityName:"City"
    })
     }
     else{
    selLat = this.state.currentLat;
   selLong = this.state.currentLong;
   this.props.filterCitySearch(selLat,selLong)
     }
 }
 saveLoginModal = (e) => {
  this.props.history.push("/register")
};
 hideLoginModal = (e) => {
  this.setState({ showLoginPopup: false });
 
};
 showLoginModal = (e) => {
  this.setState({
    showLoginPopup: true
  });
};
showChatModal = (e) => {
  this.setState({
    showChatPopup: true
  });
};
hideChatModal = (e) => {
  this.setState({ showChatPopup: false });
};
  getSelectdName =(e) => {
    if (mycityData != undefined) {
      let c = mycityData;
      c.forEach(city => {
        if (city.cityName == e.target.value) {
          selLat = city.position.coordinates[1];
          selLong = city.position.coordinates[0];
          localStorage.setItem("cityLat", selLat)
          localStorage.setItem("cityLong", selLong)
        }
      })
    }
    localStorage.setItem("city",e.target.value)
    this.setState({
      currentLat: selLat,
      currentLong: selLong
    })
    this.props.filterCitySearch(selLat,selLong);
  }
  
  handleSearch() {
          this.props.history.push('/searchjob');
  }
  handleJobList() {
    this.props.history.push('/joblist');
  }
  handleChatList() {
    this.props.history.push('/chatadmin');
  }
  displayMarkers = () => {
    return stores.map((store, index) => {
      return <Marker key={store.jobId} id={index} google={this.props.google} position={{
        lat: store.latitude,
        lng: store.longitude
      }} icon={customMarker}>
        <Popup>
        <a href={"/jobs?/"+store.jobLink}>{store.companyName}</a>
            </Popup>
      </Marker>
    })
  }
  savedJobsforAllJobs = async (item, state) => {
    if (jobseekerId != null && jobseekerId != undefined) {
      try {
        let responseData = await fetch(url + "/jobs/addJobsToFavourites", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            jobseekerId: jobseekerId,
            jobId: item.jobId,
            isSaved: item.isSave
          })
        })
        responseData = await responseData.json();
        this.props.filterCitySearch(selLat,selLong)
      } catch (err) {
      }
    } else {
      this.showLoginModal()
    }
  }
  
  render() {
    document.title = "Your step will decide the best job for you";
    let {cityData, cityDataLoading, cityDataError} = this.props;
    if(cityData !==undefined){
    mycityData=cityData
    }
    let {mapData,mapDataLoading,mapDataError} = this.props;
    mapDataLoading=false
    if(mapData !== undefined && mapData !== null && mapData.length > 0 )
    {
      mapDataLoading =false
      stores=mapData;
    }
    return (
      <center>
        <MetaTags>
          {/* for browser */}
          <title>Your Step Will Decide The Best Job For You - Jobam</title>
          {/* For Link sharing in social from url */}
          <meta property="og:title" content="Your Step Will Decide The Best Job For You - Jobam" />
          <meta property="og:url" content="https://jobam.co/interviewtips"/>
          <meta property="og:image" content="../assets/logo192.png" />
          <meta property="og:site_name" content="Jobam" />
          {/* For twitter sharing from url  */}
          <meta name="twitter:title" content="Your Step Will Decide The Best Job For You - Jobam"/>
          <meta name="twitter:image" content="../assets/logo192.png"/>
          <meta name="twitter:site" content="@JhatpatJ"/>
          <meta name="twitter:creator" content="@JhatpatJ"/>
        </MetaTags>
        <LoadingOverlay
          active={mapDataLoading}
          spinner={<div className="loading-map">
            <center className=" bg-dark2 rounded mt-5 col-md-6 col-sm-6 p-2 col-12">
              <h4 className="loadingText pt-1 mb-1 d-sm-block d-md-block">Loading Map</h4>
              <h6 className="text-white pt-1 pb-1 d-block d-sm-none d-md-none"></h6>
              <img src={Images.loaderWhite} alt="img" className="pb-2" width="30" />
            </center>
          </div>}
        >
          <div className="col-md-6 bg-light bg-height">
          <LeafletMap
              className="markercluster-map"
              center={[this.state.currentLat, this.state.currentLong]}
              zoom={5}
              maxZoom={17}
              minZoom={10}
              attributionControl={true}
              zoomControl={true}
              doubleClickZoom={true}
              scrollWheelZoom={true}
              dragging={true}
              animate={true}
              easeLinearity={0.35}
            >
              <TileLayer
                url='http://{s}.tile.osm.org/{z}/{x}/{y}.png'
              />
              <MarkerClusterGroup className="marker-cluster-medium">
              {this.displayMarkers()}
              </MarkerClusterGroup>
            </LeafletMap>
            <img src={Images.mappin} width="40" alt="mapPin" className="pin-center"/>
              <div className="searchBar mt-2 mt-md-2 mt-sm-3 mt-lg-3 mb-3 mapSearchBar fixed-top1 ml-md-0 ml-2 mr-2 mr-md-0">
                <form className="form-inline">
                  <select className="form-control col-md-3 col-sm-3 col-3" value={this.state.city}id="select1" onChange={e => this.getSelectdName(e)}>
                  <option selected disabled hidden>{this.state.cityName}</option>
                    {cityData.map((item, index) =>
                     (this.state.cityName == item.cityName ?
                      <option className="cityOptionCls" key={item.cityName} value={item.cityName}>{item.cityName}</option>
                      :
                      <option key={item.cityName} value={item.cityName}>{item.cityName}</option>
                    ))}
                  </select>
                  <div className="input-group col-md-5 col-6 input-search-text pl-0 pr-0">
                    <input type="text" className="form-control newinput" placeholder={Strings.searchJobsAndCompaniesHere} onClick={() => this.handleSearch()} aria-describedby="basic-addon2" readOnly />
                    <div className="input-group-append pointer" onClick={() => this.handleSearch()}>
                      <span className="input-group-text" id="basic-addon2"><SearchIcon /></span>
                    </div>
                  </div>
                  <div className="row ml-0 font-icon-size">
                  {isUserLoggedIn == 'true' ? (
                         <Link
                            className="nav-link text-decoration-none text-secondary pointer p-0 pt-1 pl-2"
                            onClick={() => this.handleChatList()}
                          >
                            <ChatBubbleOutlineIcon/>
                            {" "}
                            <span className="d-none d-sm-inline-flex font-nonitalic">
                              {Strings.chatText}
                            </span>
                          </Link> 
                          ):(
                          <Link
                            className="nav-link text-decoration-none text-secondary pointer p-0 pt-1 pl-2"
                            onClick={() => this.showChatModal()}
                          >
                            <ChatBubbleOutlineIcon/>
                            {" "}
                            <span className="d-none d-sm-inline-flex font-nonitalic">
                              {Strings.chatText}
                            </span>
                          </Link>
                       )}
                      <Link
                        className="nav-link text-decoration-none text-secondary pointer p-0 pt-1 pl-2"
                        to="/notification"
                      >
                        <NotificationsNoneIcon/>
                        {" "}
                        <span className="d-none d-sm-inline-flex font-nonitalic">
                          {Strings.notificationText}
                          {this.state.notiCount > 0 ?
                            <sup className="bg-success rounded-circle text-white text-bold notibadge">{this.state.notiCount}</sup>
                            : null}
                        </span>
                      </Link>
                      <i
                        className="nav-link text-decoration-none text-secondary pointer p-0 pl-2 filterpadding"
                        onClick={() => this.setState({ isFilterOpen: true })}
                      >
                        {
                          this.state.appliedFilter ?
                            <img src={Images.clearFilter} width="18" height="18" alt="clearFilter" /> :
                            <img src={Images.filterIconDashbaord} width="18" alt="filterIconDashbaord" height="18" />
                        }
                        {" "}
                        {
                          this.state.appliedFilter ?
                            <span className="d-none d-sm-inline-flex font-nonitalic text-success">
                              {Strings.filterText}

                            </span> :
                            <span className="d-none d-sm-inline-flex font-nonitalic ">
                              {Strings.filterText}
                            </span>
                        }
                      </i>
                      {/* <Link
                        className="nav-link text-decoration-none text-secondary pointer p-0 pt-2 pl-2"
                        to="/changeLanguage"
                      >
                      <i className="fa fa-language"></i>
                      </Link> */}
                    </div>
                </form>
                
                <ModalDiv modalReact={true} twoButton={true} showModal={this.state.showLoginPopup} crossBtnModal={true} msg={Strings.kindlyLoginTextForSave} hideModal={()=>this.hideLoginModal()} btnFirst={Strings.loginCaps} btnFirstClick={() => this.saveLoginModal()} btnSecondClick={() => this.hideLoginModal()} btnSecond={Strings.cancelCaps}/>
                <ModalDiv modalReact={true} twoButton={true} showModal={this.state.showChatPopup} crossBtnModal={true} msg="Kindly login to chat with admin" hideModal={()=>this.hideChatModal()} btnFirst={Strings.loginCaps} btnFirstClick={() => this.saveLoginModal()} btnSecondClick={() => this.hideChatModal()} btnSecond={Strings.cancelCaps}/>
              </div>
             {/* List button */}
            <div className="btn btn-success text-white list-button" onClick={() => this.handleJobList()}><i className="fas fa-list"></i> {Strings.list}</div>
            {/* <div className="btn btn-success text-white chat-button" onClick={() => this.handleChatList()}><i className="far fa-comment-alt"></i>  Chat</div> */}
            {/* Card Carousel */}
            <div className="fixed-bottom col-md-6">
                    <Carousel showThumbs={false} showArrows={false} showStatus={false}
                   showIndicators={false}  showArrows={true} autoPlay={false} className="pl-1 pr-1">
                      {stores != null && stores != "" && stores != undefined ? stores.map((item,index) => {
                          return (
                            <CardComponent status={item.status} jobType={item.jobType} mapState={true} key={item.jobId} companyName={item.companyName} jobTitle={item.jobTitle} salary={item.salary}
                              createdDate={item.createdDate} jobLink={item.jobLink} jobId={item.jobId}
                              isShortlisted={item.isShortlisted} companyImage={imageUrl + item.companyImage}
                              isSaved={item.isSaved}
                              callSaved={i => this.savedJobsforAllJobs(item) 
                              }
                            />
                          )
                        }) :
                        null
                      }
                    </Carousel>
                  </div>
          </div>
        </LoadingOverlay>
      </center>
    );
  }
}

const mapStateToProps = state => {
  return {
    cityData: state.mapJobsData.cityData,
    cityDataLoading: state.mapJobsData.cityDataLoading,
    cityDataError: state.mapJobsData.cityDataError,
    mapData: state.mapJobsData.mapData,
    mapDataLoading:state.mapJobsData.mapDataLoading,
    mapDataError:state.mapJobsData.mapDataError,
  };    
};
const mapDispatchToProps = dispatch => {
  return {
    filterCitySearch:(selLat,selLong) => dispatch(filterCitySearch(selLat, selLong)),
    citylist:() =>dispatch(citylist())

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(mapjobs);



