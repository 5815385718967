import React from 'react';
import { Link, Route } from "react-router-dom";
import helpers from "../config";
import $ from 'jquery';
import Images from "../Images";
import "../_style/searchjob.css";
import Webheader from "../components/webheader";
import LoadingOverlay from "react-loading-overlay";
import Footer from "../components/footer";
import Strings from "../utilities/stringlanguage";
import MetaTags from 'react-meta-tags';
import { getRecentPopularSearch } from "../actions/searchJobAction";
import { connect } from "react-redux";
import ModalDiv from "../components/modal";
var jobseekerId;
var url;
var city;
var imageUrl;
var title;
var keywords;
var description;

class SearchJob extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataFound: false,
      searchLoading: false,
      onchange: false,
      searchText: "",
      selectedOption: "",
      loading: true,
      searchJobs: [],
      searchCompanies: [],
      noData: false
    };
    this.goBack = this.goBack.bind(this);
  }

  async componentDidMount() {
    let selectedLang;
    let apiUrl =  await helpers.getServerUrl()
    url = apiUrl.serverUrl
    imageUrl = apiUrl.imageUrl
    selectedLang = localStorage.getItem("selectedLang")
    if(selectedLang != null && selectedLang != undefined && selectedLang != "")
    {
      Strings.setLanguage(selectedLang);
    }
    this.state.isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
    jobseekerId = localStorage.getItem("jobseekerId");
    city = localStorage.getItem("city");

    this.props.getRecentPopularSearch();
    $('body').removeClass('modal-open')
    $('.modal-backdrop').remove()
  }
   
  goBack(){
    this.props.history.go(-2);
  }

   getSearchResults = async(searchText) => {
    this.setState({ searchText: searchText, onChange: true, noData: false, searchLoading: true })
    if (searchText == null && searchText == "" && searchText.length < 2) {
      this.setState({ onchange: false });
    }
    let companies = [];
    let jobs = [];
    this.setState({ searchCompanies: [] })
    this.setState({ searchJobs: [] })
    let response = await fetch(url + "/jobseeker/masterSearchApp", {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        searchText: searchText.trim(),
        searchFrom: "CandidatePanel",
        jobseekerId: jobseekerId,
        city:city

      })
    })
        response = await response.json();
        if (response != null && response != "") {
          if (response.jobs != null) {
            let onSearchJob = response.jobs
            onSearchJob.forEach(onSearchJob => {
              jobs.push({
                id: onSearchJob.jobId,
                title: onSearchJob.jobTitle,
                name: onSearchJob.companyName,
                status: onSearchJob.status,
                image: onSearchJob.companyImage,
                link: onSearchJob.jobLink,
                from: "job"
              });
            });
           
            let uniqueJobItems = Array.from(new Set(jobs))
            if (this.state.searchJobs.length == 0) {
              this.setState({ searchJobs: uniqueJobItems })
            }
          }

          if (response.companies != null && response.companies != "") {
            let onSearchCompany = response.companies
            onSearchCompany.forEach(onSearchCompany => {
              companies.push({
                id: onSearchCompany.companyId,
                name: onSearchCompany.companyName,
                image: onSearchCompany.companyImage,
                link: onSearchCompany.companyLink,
                followers: onSearchCompany.followers + " " + "Followers",
                from: "company"
              });
            });
            let uniqueComItems = Array.from(new Set(companies))
            if (this.state.searchCompanies.length == 0) {
              this.setState({ searchCompanies: uniqueComItems })
            }
          }
          if (
            (this.state.searchCompanies == undefined ||
              this.state.searchCompanies == null ||  this.state.searchCompanies == "") && (
                this.state.searchJobs == undefined ||
                this.state.searchJobs == null ||  this.state.searchJobs == "" || searchText == "")
          ) {
            this.setState({ noData: true });
          }
          this.setState({ searchLoading: false })
        }
  
  };
  static getDerivedStateFromProps(props, state) {
    $('body').removeClass('modal-open')
   $('.modal-backdrop').remove()
}
  render() {
     let { recentPopularData, recentPopularLoading } = this.props;
     title=recentPopularData.title
     description=recentPopularData.description
     keywords=recentPopularData.keywords
    if(title != undefined)
    {
      document.title = title;
    document.getElementsByTagName("META")[2].content = description;
    document.getElementsByTagName("META")[3].content = keywords
    }
    let recentCompanies = [];
    let latestCompanies = [];
    let recentJobs = [];
    let latestJobs = [];
    if(recentPopularData.companies && recentPopularData.companies.length > 0)
    {
      recentPopularLoading = false;
      recentCompanies = recentPopularData.companies[0].recentSearch;
      latestCompanies =  recentPopularData.companies[0].latest;
    }
    if(recentPopularData.jobs && recentPopularData.jobs.length > 0)
    {
      recentJobs = recentPopularData.jobs[0].recentSearch;
      latestJobs =  recentPopularData.jobs[0].latest;
     
    }
    return (
      <LoadingOverlay
        active={recentPopularLoading}
        spinner={
          <div className="loading-search">
            <center className=" bg-dark1 rounded mt-5 col-md-6 col-sm-6 p-2 col-12">
              <h4 className="loadingText pt-1  mb-1 d-sm-block d-md-block">
                {Strings.searchJobsAndCompanies}
            </h4>
              <h6 className="text-white pt-1 pb-1 d-block d-sm-none d-md-none"></h6>
              <img
                src={Images.loaderWhite}
                alt="img"
                className="pb-2" 
                width="30"
              />
            </center>
          </div>
        }
      >
        <MetaTags>
          {/* for browser */}
          <title>Don't Get Confused, Search Your favourite Jobs & Companies Here - Jobam</title>
          {/* For Link sharing in social from url */}
          <meta property="og:title" content="Don't Get Confused, Search Your favourite Jobs & Companies Here - Jobam" />
          {/* <meta property="og:description" content=""/> */}
          <meta property="og:url" content="https://jobam.co/"/>
          <meta property="og:image" content="../assets/logo192.png" />
          <meta property="og:site_name" content="Jobam" />
          {/* For twitter sharing from url  */}
          <meta name="twitter:title" content="Don't Get Confused, Search Your favourite Jobs & Companies Here - Jobam"/>
          {/* <meta name="twitter:description" content="content"/> */}
          <meta name="twitter:image" content="../assets/logo192.png"/>
          <meta name="twitter:site" content="@JhatpatJ"/>
          <meta name="twitter:creator" content="@JhatpatJ"/>
        </MetaTags>
        <div className="container col-md-6 col-sm-6 col-12">
          <Webheader pageName={Strings.search} handleBack={this.goBack}/>
          <div className="input-group mt-2 pl-1 pr-1">
            <input type="text" className="form-control" placeholder={Strings.jobsAndCompanies} aria-label="Username" aria-describedby="basic-addon1"
              value={this.state.searchText}
              onChange={e => this.getSearchResults(e.target.value)}
            />
          </div>
          {recentPopularLoading ?
            null :
            this.state.onChange ?
              this.state.searchLoading == false ?
                this.state.noData == false ? (
                  <div>
                    <div className="mt-3 ml-2">
                      {this.state.searchCompanies != null &&
                        this.state.searchCompanies != "" &&
                        this.state.searchCompanies != undefined ?
                        <div>
                          <h5 className="font-weight-bold mb-0">{Strings.SearchedCompanies}</h5>
                          {this.state.searchCompanies.map((item, index) =>
                            <Link
                              key={index}
                              to={{
                                pathname: "/companyprofile",
                                search: "/" + item.link,
                                state: { companyId: item.id, searchCompany: true }
                              }}
                              className="text-decoration-none"
                            > 
                              <div className="row serchCard">
                                <div className="rounded-circle circleWidth shadow-sm bg-white col-md-1 col-1 m-md-2 ml-3 mt-3">
                                  <img src={imageUrl + item.image} alt="company image" className="search-company-image" />
                                </div>
                                <div className="col-md-10 pt-md-3 p-3 col-8 pt-1">
                                  <h5 className="mb-md-0 mb-0 textlimit textLimit searchTitleClass" >{item.name}</h5>
                                  <h6 className="text-muted ">{item.followers}</h6>
                                </div>
                              </div>
                            </Link>)}
                        </div>
                        : null}
                    </div>
                    <div className="mt-4 ml-2">
                      {this.state.searchJobs != null &&
                        this.state.searchJobs != "" &&
                        this.state.searchJobs != undefined ?
                        <div>
                          <h5 className="font-weight-bold mb-0">{Strings.SearchedJobs}</h5>
                          {this.state.searchJobs.map((item, index) =>
                            <Link
                              key={index}
                              to={{
                                pathname: "/jobs",
                                search: "/" + item.link,
                                state: { jobId: item.id, searchJob: true },
                              }}
                              className="text-decoration-none"
                            >
                              {item.status=="Active" ?
                              <div className="row serchCard">
                                <div className="rounded-circle circleWidth shadow-sm bg-white col-md-1 col-1 m-md-2 ml-3 mt-3">
                                  <img src={imageUrl + item.image} alt="company image"   className="search-company-image" />
                                </div>
                                <div className="col-md-10 pt-md-3 p-3 col-8 pt-1">
                                  <h5 className="mb-md-0 mb-0 textlimit textLimit searchTitleClass" >{item.title}</h5>
                                  <h6 className="text-muted textlimit textLimit">{item.name}</h6>
                                </div>
                              </div>:
                                <div className="row serchCardNew">
                              <div className="rounded-circle circleWidth shadow-sm bg-white col-md-1 col-1 m-md-2 ml-3 mt-3">
                                <img src={imageUrl + item.image} alt="company image"   className="search-company-image" />
                              </div>
                              <div className="col-md-10 pt-md-3 p-3 col-8 pt-1">
                                <h5 className="mb-md-0 mb-0 textlimit textLimit searchTitleClass" >{item.title}</h5>
                                <h6 className="text-muted textlimit textLimit">{item.name}</h6>
                              </div>
                            </div>}
                            </Link>
                          )}
                        </div>
                        : null}
                    </div>
                  </div>
                ) :
              <ModalDiv getImage={Images.noRecord} textMsg={Strings.noDataFound}/>
                  
                :
                <div className="loading-search" >
                <center className="bg-dark1 rounded mt-5 col-md-6 col-sm-6 p-2 col-12">
                  <h4 className="loadingText pt-1  mb-1 d-sm-block d-md-block">
                    {Strings.searchJobsAndCompanies}
                </h4>
                  <h6 className="text-white pt-1 pb-1 d-block d-sm-none d-md-none"></h6>
                  <img
                    src={Images.loaderWhite}
                    alt="img"
                    className="pb-2" 
                    width="30"
                  />
                </center>
              </div>
              :
              // <p>No data found</p>
              <div>
                {recentCompanies != null && recentCompanies != "" && recentCompanies != undefined
                  ? <div className="mt-3 ml-2">
                    <h5 className="headStyle">{Strings.recentSearchCompany}</h5>
                    {recentCompanies.map((item, index) =>
                      <Link
                        key={index}
                        to={{
                          pathname: "/companyprofile",
                          search: "/" + item.companyLink,
                          state: { companyId: item.companyId, searchCompany: true }
                        }}
                        className="text-decoration-none"
                      >
                        <div className="row serchCard">
                          <div className="rounded-circle circleWidth shadow-sm bg-white col-md-1 col-1 m-md-2 ml-3 mt-3">
                            <img src={imageUrl + item.companyImage} alt="company image"  className="search-company-image" />
                          </div>
                          <div className="col-md-10 pt-md-3 p-3 col-8 pt-1">
                            <h5 className="mb-md-0 mb-0  textlimit textLimit searchTitleClass" >{item.companyName}</h5>
                            <h6 className="text-muted">{item.followers + " Followers"}</h6>
                          </div>
                        </div>
                      </Link>
                    )}
                  </div> : null
                }
                 {latestCompanies != null && latestCompanies != "" && latestCompanies != undefined
                  ?
                <div className="mt-3 ml-2">
              <h5 className="headStyle font-weight-bold">{Strings.popularCompanies}</h5>
                  {latestCompanies.map((item, index) =>
                    <Link
                      key={index}

                      to={{
                        pathname: "/companyprofile",
                        search: "/" + item.companyLink,
                        state: { companyId: item.companyId, searchCompany: true }
                      }}
                      className="text-decoration-none"
                    >
                      <div className="row serchCard">
                        <div className="rounded-circle circleWidth shadow-sm bg-white col-md-1 col-1 m-md-2 ml-3 mt-3">
                          <img alt="company image" src={imageUrl + item.companyImage} className="search-company-image" />
                        </div>
                        <div className="col-md-10 pt-md-3 p-3 col-8 pt-1">
                          <h5 className="mb-md-0 mb-0 textLimit textlimit searchTitleClass" >{item.companyName}</h5>
                          <h6 className="text-muted">{item.followers + " Followers"}</h6>
                        </div>
                      </div>
                    </Link>
                  ) }
                  </div> : null }
                {recentJobs != null && recentJobs != "" && recentJobs != undefined
                  ? <div className="mt-3 ml-2">
                    <h5 className="headStyle font-weight-bold">{Strings.recentSearchJobs}</h5>
                    {recentJobs.map((item, index) =>
                      <Link
                        key={index}
                        to={{
                          pathname: "/jobs",
                          search: "/" + item.jobLink,
                          state: { jobId: item.jobId, searchJob: true },
                        }}
                        className="text-decoration-none"
                      >
                        {item.status=="Active" ?
                        <div className="row serchCard">
                          <div className="rounded-circle circleWidth shadow-sm bg-white col-md-1 col-1 m-md-2 ml-3 mt-3">
                            <img src={imageUrl + item.companyImage} alt="company image"  className="search-company-image" />
                          </div>
                          <div className="col-md-10 pt-md-3 p-3 col-8 pt-1">
                            <h5 className="mb-md-0 mb-0 textLimit textlimit searchTitleClass" >{item.jobTitle}</h5>
                            <h6 className="text-muted textLimit textlimit">{item.companyName}</h6>
                          </div>
                        </div>:
                        <div className="row serchCardNew">
                          <div className="rounded-circle circleWidth shadow-sm bg-white col-md-1 col-1 m-md-2 ml-3 mt-3">
                            <img src={imageUrl + item.companyImage} alt="company image"  className="search-company-image" />
                          </div>
                          <div className="col-md-10 pt-md-3 p-3 col-8 pt-1">
                            <h5 className="mb-md-0 mb-0 textLimit textlimit searchTitleClass" >{item.jobTitle}</h5>
                            <h6 className="text-muted textLimit textlimit">{item.companyName}</h6>
                          </div>
                        </div>
                        }
                      </Link>
                    )}
                  </div> : null
                }
                 {latestJobs != null && latestJobs != "" && latestJobs != undefined
                  ?
                <div className="mt-3 ml-2">
              <h5 className="headStyle font-weight-bold">{Strings.LatestJobs}</h5>
                  {latestJobs.map((item, index) =>
                    <Link
                      key={index}
                      to={{
                        pathname: "/jobs",
                        search: "/" + item.jobLink,
                        state: { jobId: item.jobId, searchJob: true }
                      }}
                      className="text-decoration-none"
                    >
                      <div className="row serchCard">
                        <div className="rounded-circle circleWidth shadow-sm bg-white col-md-1 col-1 m-md-2 ml-3 mt-3">
                          <img src={imageUrl + item.companyImage} alt="company image" className="search-company-image" />
                        </div>
                        <div className="col-md-10 pt-md-3 p-3 col-8 pt-1">
                          <h5 className="mb-md-0 mb-0 textLimit textlimit searchTitleClass" >{item.jobTitle}</h5>
                          <h6 className="text-muted textLimit textlimit">{item.companyName}</h6>
                        </div>
                      </div>
                    </Link>
                  ) }
                  </div> : null}
              </div>
          }
        </div>
        <div className="container-fluid bg-light col-md-6 col-lg-6 col-sm-6 col-12 No-Pading">
          <Route component={Footer} className="d-none d-md-block d-lg-block d-sm-block d-xl-block" />
        </div>
      </LoadingOverlay>
    )
  }
} 
const mapStateToProps = state => {
   return {
    recentPopularData: state.searchJobReducer.recentPopularData,
      recentPopularLoading: state.searchJobReducer.recentPopularLoading,
    
  };    
};
const mapDispatchToProps = dispatch => {
  return {
    getRecentPopularSearch:() => dispatch(getRecentPopularSearch()), 
    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchJob);