import React, { Component } from "react";
import helpers from '../config';
import Webheader from "../components/webheader";
import swal from 'sweetalert';
import { Link, Route } from "react-router-dom";
import Strings from "../utilities/stringlanguage";
import Switch from "react-switch";
import Images from "../Images";
import '../_style/profile.css';
import Footer from "../components/footer";
import ModalDiv from "../components/modal";
var data = [];
var jobseekerId;
var isAvailableValue;
var isAvailableIcon;
var url;
var imageUrl;
var selectedLang;
export default class Setting extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      feedback: "",
      isAvailable : true,
      changeIcon : false,
    }
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    let apiUrl =  await helpers.getServerUrl()
    url = apiUrl.serverUrl
    imageUrl = apiUrl.imageUrl
    selectedLang = localStorage.getItem("selectedLang")
		if(selectedLang != null && selectedLang != undefined && selectedLang != "")
		{
		  Strings.setLanguage(selectedLang);
		}
    jobseekerId = localStorage.getItem("jobseekerId")
    window.scrollTo(0, 0);
  }

  handleChange = (isAvailable) =>{
    this.setState({ changeIcon : true, onCancel : false });
  }

 async availablityStatus() {
   try{
   let response = await fetch(url + "/jobseeker/isAvailableStatus", {
      method: "POST",
      headers: { "Content-Type": "application/json" }
      ,
      body: JSON.stringify({
        jobseekerId: jobseekerId,
        isAvailable: isAvailableIcon
      })
    })
      response = await response.json();
      if(response.success)
      {
        this.props.history.push('/profile');
      }
     }catch(err) {
        console.log(err)
      }
  }

 canceldialog = () =>{
    this.setState({ onCancel : true });
  }

 async deleteAccount() {
   try{
   let response = await fetch(url + "/jobseeker/deleteAccount", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        jobseekerId: jobseekerId
      })
    })
    response = await response.json()
        if (response.success) {
          this.setState({});
          localStorage.removeItem("isUserLoggedIn");
          localStorage.removeItem("givenName");
          localStorage.removeItem("jobseekerId");
          localStorage.removeItem("jobseekerName");
          this.props.history.push('/joblist')
        }
      }catch(err) {
        console.log(err)
      }
  }
  render() {
     document.title = "Setting Is The First Step which turns invisible into visible";
   
    isAvailableValue = localStorage.getItem("isAvailable");
    if (isAvailableValue == "true") {
      isAvailableIcon = true;
    }
    else {
      isAvailableIcon = false;
     }

    if(this.state.changeIcon)
    {
      isAvailableIcon = !isAvailableIcon;
    }
     
    if(this.state.onCancel)
    { 
      isAvailableIcon = !isAvailableIcon;
    }
    return (
      <div className="container-fluid col-md-6 No-Pading col-lg-6 col-sm-6 col-12 mb-4 myFluid bg-light">
        <Webheader backLink="/profile" pageName={Strings.settingsCaps} />
        <div className="pt-2">
          <Link
            to={{ pathname: "companyfeedback" }}
            className="text-decoration-none text-dark mt-3 mt-3">
            <div className="card mb-2 mycard bg-white  rounded-pill">
              <div className="card-body p-2 pl-4">
                <div className="row">
          <h4 className="col-md-11 col-10 textSetting mb-0 pt-0 text-dark">{Strings.companyFeedback}</h4>
                  <div className="col-md-1 col-2 pt-1">
                    <img src={Images.setChatIcon} alt="img" width="20" height="20" />

                  </div>
                </div>
              </div>
            </div>
          </Link>
          <Link
            to={{ pathname: "/candidatefaq", state: { isAvailable: this.state.isAvailable } }}
            className="text-decoration-none text-dark mt-3">
            <div className="card mb-2 mycard bg-white  rounded-pill">
              <div className="card-body p-2 pl-4">
                <div className="row">
           <h4 className="col-md-11 col-10 mb-0 textSetting pt-0 text-dark">{Strings.faQCaps}</h4>
                  <div className="col-md-1 col-2">
                    <i className="far fa-question-circle text-success-gradient fa-1x"></i>
                  </div>
                </div>
              </div>
            </div>
          </Link>
          <Link className="text-decoration-none text-dark mt-3" to={{ pathname: "/contactsupport"}}>
            <div className="card mycard mb-2 bg-white rounded-pill">
              <div className="card-body p-2 pl-4">
                <div className="row">
          <h4 className="col-md-11 col-10 mb-0 textSetting pt-0 text-dark">{Strings.contactSpprt}</h4>
                  <div className="col-md-1 col-2">
                    <img src={Images.setContactIcon} alt="img" width="20" height="20" />
                  </div>
                </div>
              </div>
            </div>
          </Link>
          <div data-toggle="modal" data-target="#availableModal" className="text-dark text-decoration-none">
            <div className="card mb-2 mycard bg-white  rounded-pill">
              <div className="card-body p-2 pl-4">
                <div className="row">
                  <h4 className="col-md-11 col-10 mb-0 textSetting pt-0 text-dark">{isAvailableIcon ? Strings.Available : Strings.NotAvailable}</h4>

                  <div className="col-md-1 col-2 pl-0" > <Switch onChange={this.handleChange}
                    checked={isAvailableIcon}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={20}
                    width={38}
                    onColor="#68E3CD"
                  /></div>
                </div>
              </div>
            </div>
          </div>
          <div data-toggle="modal" data-target="#deleteModal" className="text-dark text-decoration-none">
            <div className="card mb-2 mycard bg-white  rounded-pill">
              <div className="card-body p-2 pl-4">
                <div className="row">
           <h4 className="col-md-11 col-10 mb-0 textSetting pt-0 text-dark">{Strings.deleteCard}</h4>
                  <div className="col-md-1 col-2">
                    <i className="far fa-trash-alt text-success-gradient fa-1x"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* available modal */}
        <ModalDiv singleBtnModal={true} modalId="availableModal" twoBtn={true} modalText={isAvailableIcon ? Strings.wantToEnableProfile : Strings.wantToDisableProfile} crossBtn={true} discardFun={()=>this.availablityStatus()} discardText={Strings.yes} buttonClick={()=> this.canceldialog()} discardSaveText={Strings.cancelCaps} />
        {/* logout modal */}
        <ModalDiv singleBtnModal={true} modalId="deleteModal" twoBtn={true} modalText={Strings.deleteYourAcc} crossBtn={true} discardText={Strings.cancelCaps} buttonClick={() => this.deleteAccount()} discardSaveText={Strings.Delete} />         
        <div className="container-fluid bg-light col-md-12 col-lg-12 col-sm-12 col-12 No-Pading">
          <Route component={Footer} className="d-none d-md-block d-lg-block d-sm-block d-xl-block" />
        </div>
        <center className="col-12 col-sm-12 fixed-bottom d-md-none d-lg-none d-xl-none d-sm-block d-block">
          <p><a href="/Faq" className="text-success text-decoration-none" target="_blank">{Strings.agreeTerms}</a> & <a href="/privacypolicy" className="text-success text-decoration-none" target="_blank">{Strings.privacyPolicy}</a></p>
        </center>
      </div>
    );
  }
}