import React, { Component } from 'react';
import ScrollToBottom from 'react-scroll-to-bottom';
import socketIOClient from "socket.io-client";
import Webheader from "../components/webheader";
import helpers from "../config";
import "../_style/chatcompany.css";
var url, imageUrl,chatUrl, Id, name, cmpnyId, cmpnyName, userImage, jsName, cmpnyImage;
var Data = [];
export default class ChatCompany extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      text: "",
      messages: [],
      you: "",
      textValue: "",
      response: false,
      jobseekerId: "",
      jobseekerName: "",
      companyId: "",
      endpoint: "https://www.jhatpatjobs.io:8000/"
    }
  }
  async componentDidMount() {
    await helpers.getServerUrl().then(data => {
      url = data.serverUrl
      imageUrl = data.imageUrl
      chatUrl=data.chatUrl
    });
    Id = localStorage.getItem('jobseekerId');
    name = localStorage.getItem('jobseekerName');
    if(localStorage.getItem('companyImage')!=undefined && localStorage.getItem('companyImage')!=""){
      cmpnyId = localStorage.getItem('companyId');
      cmpnyName = localStorage.getItem('companyName');
      cmpnyImage = localStorage.getItem('companyImage');
    }
    else{
      cmpnyId = this.props.location.state.companyId;
      cmpnyName = this.props.location.state.companyName;
      cmpnyImage = this.props.location.state.companyImage;
    }
    userImage = localStorage.getItem('userImage');
    jsName = localStorage.getItem('givenName');
    this.setState({ you: name });
    const socket = socketIOClient(chatUrl);
    socket.on("connection", socket);
     socket.on("news message", msg => this.onReceivedMessage(msg));
    socket.emit(
      "add user",
      Id,
    );
    setTimeout(() => {
      this.getChat();
    }, 500);
    window.onpopstate = (e) => {
      socket.on('disconnect');
    }
  }

  onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      if(this.state.text.trim() != "" && this.state.text.trim() != null){
      this.sendMessage();
      }
    }
  }

  setMessage = (e) => {
    e.preventDefault();
    this.setState({ text: e.target.value })
  }

  sendMessage() {
    document.getElementById("formChat").reset();
    const message = this.state.text.trim();
    this.setState({ text: "" })
      const socket = socketIOClient(chatUrl);
      socket.emit(
        "new message",
        message,
        cmpnyId,
        Id,
        name,
        cmpnyName,
        cmpnyId //CompanyName 
      );
    Data.push({
      msg: message,
      you: name,
      username: name
    })
    if (Data != null && Data != undefined && Data != "") {
      this.setState({ messages: Data, loading: false });
    }
  };

  onReceivedMessage = async (msg = []) => {
    Data.push({
      msg: msg.message,
      username: msg.username,
      you: this.state.you
    })
    if (Data != null && Data != undefined && Data != "") {
      this.setState({ messages: Data, loading: false });
    }
  };

  getChat() {
    fetch(url+ "/chat/getChatforcompany", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        jobseekerId: Id,
        companyId: cmpnyId
      })
    })
      .then(response => response.json())
      .then(responseData => {
        Data = [];
        if (responseData !== null) {
          for (var i = 0; i < responseData.length; i++) {
            if (
              responseData[i].username == null ||
              responseData[i].username == "" ||
              responseData[i].username == undefined
            ) {
              responseData[i].username = "NA";
            }
            if (
              responseData[i].msg == null ||
              responseData[i].msg == "" ||
              responseData[i].msg == undefined
            ) {
              responseData[i].msg = "NA";
            }
            Data.push({
              msg: responseData[i].msg,
              username: responseData[i].username,
              createdDate: responseData[i].createdDate,

              you: this.state.you
            });
          }
          Data.reverse();
          if (Data != null && Data != undefined && Data != "") {
            this.setState({ messages: Data, loading: false });
          } else {
            this.setState({ loading: false });
          }
        }
      })
      .catch(error => { });
  }

  handleBack() {
    this.props.history.goBack()
  }

  render() {
    return (
      <center>
      <div className="outerContainerChat bg-light col-md-6 pl-0 pr-0">
        <div className="containerChat">
        <Webheader pageName={cmpnyName} backLink="/profile"/>
          <ScrollToBottom className="messagesChat pt-5 h-100">
            {this.state.messages ? this.state.messages.map((item, index) => (
              <div className="pt-2" key={item.index}>
                {item.username !== item.you
                  ? (
                    <div className="messageContaineChatr pb-1 justifyEndChat">
                      <div className="row">
                        <p className="sentTextChat pr-10"></p>
                        <div className="p-2 bg-white rounded-circle shadow-sm mr-2 h-25">
                          <img src={imageUrl + cmpnyImage} width="20" height="20" alt="Admin Image" />
                        </div>
                        <div className="messageBoxChat bg-light col-md-4 rounded-pill my-auto p-1 pl-3 border">
                          <p className="messageTextChat text-dark my-auto">{item.msg}</p>
                        </div>
                      </div>
                    </div>
                  )
                  : (
                    <div className="messageContainerChat">
                      <div className="messageBoxChat bg-success col-md-4 rounded-pill my-auto p-1 pl-3">
                        <p className="messageTextChat  text-white my-auto">{item.msg}</p>
                      </div>
                      <div className="p-2 bg-white rounded-circle shadow-sm ml-2 h-25">
                        <img src={userImage} width="20" height="20" alt="UserImage" />
                      </div>
                      <p className="sentTextChat"></p>
                    </div>
                  )
                }
              </div>
            )) : <p>no data</p>}

          </ScrollToBottom>
          <form id="formChat" className="w-100">
            <div className="input-group pb-md-2 fixed-bottom col-md-6">
            <input className="form-control border border-success" type="text" placeholder="Type a message..."
               onChange={(e) => this.setMessage(e)} onKeyDown={this.onKeyDown} />
              <div className="input-group-append pointer border border-success ml-1">
                {this.state.text.trim() != "" && this.state.text.trim() != null ?(
                <span className="input-group-text bg-light" onClick={() => this.sendMessage()}><i class="fas fa-paper-plane text-success"></i></span> ):( <span className="input-group-text"><i class="fas fa-paper-plane text-success"></i></span>)}
              </div>
            </div>
          </form>
        </div>
      </div>
      </center>
    )
  }
}