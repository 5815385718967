import helpers from "../config";

export const GET_FEEDBACK = "GET_FEEDBACK";
export const GET_FEEDBACK_DATA = "GET_FEEDBACK_DATA";
export const GET_FEEDBACK_FAIL = "GET_FEEDBACK_FAIL";


var jobseekerId;
  export const getReview = () => {
    jobseekerId = localStorage.getItem("jobseekerId");
      return (dispatch) => {
       dispatch({type: GET_FEEDBACK})
       helpers.getServerUrl().then(url =>   {
        var url = url.serverUrl
       fetch( url + "/jobseeker/getJobseekerReview", {
        method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            jobseekerId: jobseekerId,
          })
        })
      .then(response => response.json())
      .then(responseData => {
        dispatch ({
            type: GET_FEEDBACK_DATA,
            payload: responseData.review
        })
      })
      .catch(err => {
        dispatch({
            type: GET_FEEDBACK_FAIL,
            payload: err
        })
        console.log('error', err)
    })
  })
 }
}

