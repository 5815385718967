import React, { Component } from 'react';
import Images from '../Images';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Strings from '../utilities/stringlanguage';
import MetaTags from 'react-meta-tags';
import WebBottomNav from '../components/webBottomNavigation';
import CardComponent from '../components/cardComponent';
import { Route } from 'react-router-dom';
import { Modal, Button, ThemeProvider } from 'react-bootstrap';
import Footer from '../components/footer';
import { connect } from 'react-redux';
import { getJobs, savedJobFun } from '../actions/myJobsAction';
import '../_style/myJobs.css';
import helpers from '../config';
import LoadingOverlay from 'react-loading-overlay';
import ModalDiv from '../components/modal';
import Navbar from '../components/navBar';
var jobseekerName;
var jobseekerId;
var tokenId;
var url;
var selectedLang;
var imageUrl;
var newMyjobsData = [];
class MyJobs extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dataFound: false,
			loading: false,
			jobseekerId: '',
			myIndex: 0,
			status: 'All',
			isLoading : true,
			showUpdateModel: false,
			myAppliedData: [],
			appliedData: [
				{
					index: 0,
					name: 'All',
					name1: Strings.showAll,
					isSelected: true,
				},
				{ index: 1, name: 'APPLIED', name1: Strings.appliedCaps, isSelected: false },
				{ index: 2, name: 'SHORTLISTED', name1: Strings.ShortlistedCaps, isSelected: false },
				{ index: 3, name: 'SCHEDULED', name1: Strings.scheduledCaps, isSelected: false },
				{ index: 4, name: 'INTERVIEWED', name1: Strings.interviewedCaps, isSelected: false },
				{ index: 5, name: 'HIRED', name1: Strings.Hired, isSelected: false },
				{ index: 6, name: 'REJECTED', name1: Strings.rejectedCaps, isSelected: false },
				{ index: 7, name: 'SAVED JOBS', name1: Strings.savedJOBS, isSelected: false },
			],
		};
	}

	async componentDidMount() {
		this.abortController = new AbortController();
		let apiUrl = await helpers.getServerUrl();
		url = apiUrl.serverUrl;
		imageUrl = apiUrl.imageUrl;
		selectedLang = localStorage.getItem('selectedLang');
		if (selectedLang != null && selectedLang != undefined && selectedLang != '') {
			Strings.setLanguage(selectedLang);
		}
		this.state.isUserLoggedIn = localStorage.getItem('isUserLoggedIn');
		jobseekerId = localStorage.getItem('jobseekerId');
		let ismobileVerified = localStorage.getItem('ismobileVerified');
		tokenId = localStorage.getItem('tokenId');
		jobseekerName = localStorage.getItem('givenName');

		this.appliedStatus('');
		if (!this.state.isUserLoggedIn) {
			setTimeout(() => {
				this.setState({ loading: false });
			}, 4000);
		} else {
			this.jobseekerVerify('');
			this.props.getJobs('');
		}
	}
	jobseekerVerify = async () => {
		try {
			let response = await fetch(url + '/admin/getAppSetDetails1', {
				method: 'POST',
				headers: {
					'content-Type': 'application/json',
				},
				body: JSON.stringify({
					jobseekerId: jobseekerId,
					tokenId: tokenId,
					loginFrom: "Web"
				}),
			});
			response = await response.json();
			let ismobileVerified = response.ismobileVerified;
			if (this.state.isUserLoggedIn && ismobileVerified !== true) {
				this.updateProfileModal();
			}
		} catch (err) {}
	};
	appliedStatus(status) {
		this.setState({isLoading : true})
		let noDataText =
			status == 'APPLIED'
				? Strings.appliedNoData
				: status == 'SHORTLISTED'
				? Strings.shortlistedNoData
				: status == 'HIRED'
				? Strings.hiredNoData
				: status == 'SCHEDULED'
				? Strings.scheduledNoData
				: status == 'REJECTED'
				? Strings.rejectedNoData
				: status == 'INTERVIEWED'
				? Strings.interviewNoData
				: status == 'SAVED JOBS'
				? Strings.saveNoData
				: Strings.allNoData;
		this.setState({ status: noDataText });
		localStorage.setItem('status', status);
		if (status == 'All') {
			var jobStatus = '';
		} else {
			var jobStatus = status;
		}
		if (this.state.isUserLoggedIn) {
			this.props.getJobs(jobStatus);
		}
	}
	updateProfileModal = (e) => {
		this.setState({
			showUpdateModel: true,
		});
	};

	hideProfileModal = (e) => {
		this.setState({ showUpdateModel: false });
		this.props.history.push('/updateProfile');
	};
	handleChange = (event, value) => {
		this.setState({ value });
	};
	componentWillUnmount() {
		this.abortController.abort();
	  }

	render() {
		let { myJobsData, myJobsDataError } = this.props;
		newMyjobsData = myJobsData.myApplications ? myJobsData.myApplications : myJobsData.savedJobs;
		let { saveJobsData, saveJobsLoading, saveJobsError } = this.props;
		this.state.isUserLoggedIn = localStorage.getItem('isUserLoggedIn');
		const { value, pathMap } = this.state;
		if (myJobsData.title != undefined) {
			document.title = myJobsData.title;
			document.getElementsByTagName('META')[2].content = myJobsData.description;
			document.getElementsByTagName('META')[3].content = myJobsData.keywords;
		}
		if(myJobsData != "" && myJobsData != undefined || !this.state.isUserLoggedIn)
		{
			saveJobsLoading=false;
			this.state.isLoading = false;
		}
		return (
			<div>
				<div className="container col-md-6 col-lg-6 col-sm-6 col-12 pl-md-0 pr-md-0">
					<LoadingOverlay
						active={this.state.isLoading || saveJobsLoading}
						spinner={
							<div className="loading-myjobs">
								<center className=" bg-dark2 rounded mt-5 col-md-6 col-sm-6 p-2 col-12">
									<h4 className="loadingText pt-1 mb-1 d-sm-block d-md-block">
										{Strings.loadingYourJobs}
									</h4>
									<h6 className="text-white pt-1 pb-1 d-block d-sm-none d-md-none"></h6>
									<img src={Images.loaderWhite} alt="Loading White" className="pb-2" width="30" />
								</center>
							</div>
						}
					>
						<MetaTags>
							{/* for browser */}
							<title>Compare Your Skills & Abilities With Job & Get Hired Easily - Jobam</title>
							{/* For Link sharing in social from url */}
							<meta
								property="og:title"
								content="Compare Your Skills & Abilities With Job & Get Hired Easily  - Jobam"
							/>
							<meta property="og:url" content="https://jobam.co/interviewtips" />
							<meta property="og:image" content="../assets/logo192.png" />
							<meta property="og:site_name" content="Jobam" />
							{/* For twitter sharing from url  */}
							<meta
								name="twitter:title"
								content="Compare Your Skills & Abilities With Job & Get Hired Easily  - Jobam"
							/>
							<meta name="twitter:image" content="../assets/logo192.png" />
							<meta name="twitter:site" content="@JhatpatJ" />
							<meta name="twitter:creator" content="@JhatpatJ" />
						</MetaTags>
						  <ModalDiv modalReact={true} showModal={this.state.showUpdateModel} msg={Strings.updateProfileText} btn1Text={Strings.oK} btn1Click={()=>this.hideProfileModal()}/>
						<Navbar />
						<div className="menuFixed ">
							<div className="barColor p-1 d-block d-sm-none d-md-none d-lg-none panel-top mobile-top">
								<h5 className="text-center text-white text-uppercase pt-1 mb-0 pb-1">
									{Strings.myJobsCaps}
								</h5>
							</div>
							<div className="userScroll shadow-sm p-1 panel-top bg-white1">
								<ul className="nav nav-pills nav-scroll pb-1 pt-1 ">
									{this.state.appliedData.map((item, index) => (
										<li key={item.index} className="nav-item rounded-pills mr-1 ml-1">
											<a
												className={
													item.index == 0
														? 'nav-link tab active pl-2 pr-2  pt-2 pb-2 text-white'
														: 'nav-link pl-2 pr-2  pt-2 pb-2'
												}
												data-toggle="pill"
												href="#myjobs"
												onClick={() => this.appliedStatus(item.name)}
											>
												{item.name1}
											</a>
										</li>
									))}
								</ul>
							</div>

							{/* Tab panes  */}
							<div>
							{this.state.isUserLoggedIn ?
								(<div className="tab-content myjobsTab No-Pading mt-5">
									<div id="myjobs" className="container tab-pane active pt-md-4 pt-0">
										 {newMyjobsData != null &&
											newMyjobsData != '' &&
											newMyjobsData != undefined &&
											this.state.isLoading == false ? (
												newMyjobsData.map((item) => (
													<CardComponent
														key={item.jobId}
														companyName={item.companyName}
														jobTitle={item.jobTitle}
														jobType={item.jobType}
														salary={item.salary}
														createdDate={item.createdDateNew}
														jobLink={item.jobLink}
														jobId={item.jobId}
														status={item.status}
														isShortlisted={item.isShortlisted}
														companyImage={imageUrl + item.companyImage}
														isSaved={item.isSaved}
														callSaved={(i) => this.props.savedJobFun(item)}
													/>
												))
											):
											<ModalDiv getImage={Images.noRecord} textMsg={this.state.status} />
										}
											</div>
								</div>) 
								: 
								<div  className="mt-md-0 mt-5 loginPopup">
									<Link to="/register" className="text-decoration-none">
									<ModalDiv loginPopup={true}  getImage={Images.noRecord} textMsg={Strings.logintoSeeJobStatus} />
									</Link>
								</div>
								}
							</div>
						</div>
					</LoadingOverlay>
					<div className="navSpace"></div>
					<Route component={Footer} className="d-none d-md-block d-lg-block d-sm-block d-xl-block" />
					<WebBottomNav
						homeText={Strings.homeTabs}
						myJobText={Strings.myJobTabs}
						interviewText={Strings.interviewTabs}
						myjobsActive={true}
						profileData={this.state.isUserLoggedIn ? jobseekerName : Strings.loginTabs}
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		myJobsData: state.myJobsR.myJobsData,
		myJobsDataLoading: state.myJobsR.myJobsDataLoading,
		myJobsDataError: state.myJobsR.myJobsDataError,
		saveJobsData: state.myJobsR.saveJobsData,
		saveJobsLoading: state.myJobsR.saveJobsLoading,
		saveJobsError: state.myJobsR.saveJobsError,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getJobs: (jobStatus) => dispatch(getJobs(jobStatus)),
		savedJobFun: (item) => dispatch(savedJobFun(item)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(MyJobs);
