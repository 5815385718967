import React, { Component } from "react";
import helpers from "../config";
import LoadingOverlay from "react-loading-overlay";
import Images from "../Images";
import { Modal } from "react-bootstrap";
import '../_style/educationdetail.css';
import Strings from "../utilities/stringlanguage";
import { getUgDegree, getPassingYearData, getUniversityData, getEducationByJobseekerID } from "../actions/educationDetailAction";
import { connect } from "react-redux";
import ModalDiv from "../components/modal";
var jobseekerId;
var url, imageUrl;
var title;
var keywords;
var description;
var values = false;
class UgDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataFound: false,
      loading: true,
      searchUg: "",
    };
  }

  async componentDidMount() {
    let apiUrl = await helpers.getServerUrl()
    url = apiUrl.serverUrl
    imageUrl = apiUrl.imageUrl
    this.state.isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
    jobseekerId = localStorage.getItem("jobseekerId");
    this.props.getUniversityData();
    this.props.getUgDegree();
    this.props.getEducationByJobseekerID();
    this.props.getPassingYearData();
  }

  setUgYear(selectedValue) {
    this.setState({ UgPassYear: selectedValue })
  }

  getUgDegreeFun = async (searchUgDegree) => {
    this.setState({ searchUgDegree: searchUgDegree })
  }

  getUniversityDataFun = async (searchUg) => {
    this.setState({ searchUg: searchUg })
  }

  showModal = (e) => {
    this.setState({ show: true });
  };

  hideModal = (e) => {
    this.setState({ show: false });
  };

  showModalComplete = (e) => {
    this.setState({ complete: true });
  }

  hideModalComplete = (e) => {
    this.setState({ complete: false });
  }

  setUgPercent = (e) => {
    this.setState({ ugPercent: e.target.value })
  }

  validationUg = () => {
    let ugClgName = this.state.searchUg != null && this.state.searchUg != undefined && this.state.searchUg != "" ? this.state.searchUg.trim() : "";
    if (
      ugClgName != "" &&
      ugClgName != null &&
      ugClgName != undefined
    ) {
      this.addUGEducation();
    } else {
      this.showModal();
      this.setState({ msg: Strings.fillUniversity });
    }
  }

  addUGEducation = async () => {
    try {
      let response = await fetch(url + "/jobseeker/updateProfile", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          jobseekerId: jobseekerId,
          UGExamName: this.state.searchUgDegree,
          UGPassingYear: this.state.UgPassYear,
          UGPercentage: this.state.ugPercent,
          clgNameUg: this.state.searchUg.trim()
        })
      })
      response = await response.json()
      if (response != null) {
        let res = response[0];
        if (res.success) {
          this.showModalComplete();
          this.setState({ msgcomplete: Strings.educationaddedsuccess });
        }
        else {
          this.showModal();
          this.setState({ msg: res.msg });
        }
      }
    }
    catch (err) {
      console.log(err)
    }
  }


  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.allEducationData[0] != undefined && nextProps.allEducationData[0] != "") {
      if (values) {
        this.setState({
          searchUg: this.state.searchUg,
          searchUgDegree: this.state.searchUgDegree,
          ugPercent: this.state.ugPercent,
          UgPassYear: this.state.UgPassYear
        })
      }
      else {
        this.setState({
          searchUg: nextProps.allEducationData[0].ugCollegeName,
          searchUgDegree: nextProps.allEducationData[0].UGExamName,
          ugPercent: nextProps.allEducationData[0].UGPercentage,
          UgPassYear: nextProps.allEducationData[0].UGPassingYear
        })
      }

    }
  }


  render() {
    if (title != undefined) {
      document.title = title;
      document.getElementsByTagName("META")[2].content = description
      document.getElementsByTagName("META")[3].content = keywords
    }
    let { ugDegreeData, passingYearData, universityNameData, allEducationData, allEducationDataLoading } = this.props;
    if (this.state.hideModal) {
      this.state.complete = false;
    }
    return (
      <LoadingOverlay
        active={allEducationDataLoading}
        spinner={<div className="loading-educationdetail">
          <center className=" bg-dark3 rounded mt-5 col-md-10 col-sm-6 p-2 col-12">
            <h4 className="loadingText pt-1 mb-1 d-sm-block d-md-block">{Strings.loadingEducation}</h4>
            <h6 className="text-white pt-1 pb-1 d-block d-sm-none d-md-none"></h6>
            <img src={Images.loaderWhite} alt="img" className="pb-2" width="30" />
          </center>
        </div>}
      >
        <div>
          <div className="container-fluid col-md-12 col-lg-12 col-sm-12 col-12 mt-3">
            <div className="tab-pane fade show active" id="pills-pg" role="tabpanel" aria-labelledby="pills-pg-tab">
              <div className="form-group">
                <label className="inputHeadingText">{Strings.universityName}</label>
                <input type="text"  placeholder={Strings.EnterYourUniversity}
                  value={this.state.searchUg}
                  name="example" list="exampleList" className="form-control shadow-sm inputHeadClass" onChange={(e) => this.getUniversityDataFun(e.target.value)} />
                <datalist id="exampleList" className="shadow-sm">
                  {universityNameData != null && universityNameData != "" && universityNameData != undefined ?
                    universityNameData.map((item, index) => (
                      <option key={index}>{item.universityName.toLowerCase()}
                      </option>
                    )) : null}
                </datalist>
              </div>
              <div className="form-group">
                <label className="inputHeadingText">{Strings.courseCaps}</label>
                <input type="text" placeholder={Strings.chooseDegree} list="exampleListData"
                  value={this.state.searchUgDegree}
                  className="form-control shadow-sm inputHeadClass" onChange={(e) => this.getUgDegreeFun(e.target.value)} />
                <datalist id="exampleListData" className="shadow-sm">
                  {ugDegreeData != null && ugDegreeData != "" && ugDegreeData != undefined ?
                    ugDegreeData.map((item, index) => (
                      <option key={index}>{item.degreeName.toUpperCase().replace(/[^a-zA-Z ]/g, "")}
                      </option>
                    )) : null}
                </datalist>
              </div>
              <div className="form-group">
                <label className="inputHeadingText">{Strings.marksObtainedCaps}</label>
                <input onChange={this.setUgPercent}
                  value={this.state.ugPercent}
                  type="number" className="form-control shadow-sm inputHeadClass" placeholder={Strings.EnterPercentage} />
              </div>
              <div className="form-group">
                <label className="inputHeadingText">{Strings.passingYear}</label>
                <select value={this.state.UgPassYear}  className="form-control shadow-sm inputHeadClass" onChange={e => this.setUgYear(e.target.value)}>
                  {passingYearData != null && passingYearData != "" && passingYearData != undefined ?
                    passingYearData.map((item, index) => (
                      <option key={index}>{item.year}</option>
                    ))
                    : null
                  }
                </select>
              </div>
              <div className="d-flex justify-content-center">
                <div onClick={this.validationUg} type="submit" className="btn btn-success  col-md-12 col-12 mt-3 rounded">{Strings.update}</div>
              </div>
            </div>
          </div>

          <ModalDiv modalReact={true} showModal={this.state.show}  msg={this.state.msg} crossBtnModal={true} btn1Text={Strings.oK} hideModal={()=>this.hideModal()} btn1Click={()=>this.hideModal()}/>
         
         <ModalDiv modalReact={true} showModal={this.state.complete} crossBtnModal={true} successModal={true} msg={this.state.msgcomplete} btn1Text={Strings.oK}  hideModal={()=>this.hideModalComplete()} btn1Click={()=>this.hideModalComplete()}/>

       
        </div>
      </LoadingOverlay>
    );
  }
}
const mapStateToProps = state => {
  return {
    ugDegreeData: state.educationDetailReducer.ugDegreeData,
    passingYearData: state.educationDetailReducer.passingYearData,
    universityNameData: state.educationDetailReducer.universityNameData,
    allEducationData: state.educationDetailReducer.allEducationData,
    allEducationDataLoading: state.educationDetailReducer.allEducationDataLoading,
    allEducationDataError: state.educationDetailReducer.allEducationDataError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUgDegree: () => dispatch(getUgDegree()),
    getPassingYearData: () => dispatch(getPassingYearData()),
    getUniversityData: () => dispatch(getUniversityData()),
    getEducationByJobseekerID: () => dispatch(getEducationByJobseekerID()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UgDetail);
