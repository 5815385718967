import helpers from "../config";
import { getJobseekerProfile } from "../actions/profileAction";

export const GET_INDUSTRYDATA_LOADING = "GET_INDUSTRYDATA_LOADING";
export const GET_INDUSTRY_DATA = "GET_INDUSTRY_DATA";
export const GET_INDUSTRYDATA_FAIL = "GET_INDUSTRYDATA_FAIL";

export const SET_EXPERIENCE = "SET_EXPERIENCE";
export const SET_EXPERIENCE_DATA = "SET_EXPERIENCE_DATA";
export const SET_EXPERIENCE_FAIL = "SET_EXPERIENCE_FAIL";

export const UPDATE_EXPERIENCE = "UPDATE_EXPERIENCE";
export const UPDATE_EXPERIENCEDATA_DATA = "UPDATE_EXPERIENCEDATA_DATA";
export const UPDATE_EXPERIENCEDATA_FAIL = "UPDATE_EXPERIENCEDATA_FAIL";

export const GET_EXPERIENCE_LOADING = "GET_EXPERIENCE_LOADING";
export const GET_EXPERIENCE_DATA = "GET_EXPERIENCE_DATA";
export const GET_EXPERIENCE_FAIL = "GET_EXPERIENCE_FAIL";

export const DELETE_EXPERIENCE_LOADING = "DELETE_EXPERIENCE_LOADING";
export const DELETE_EXPERIENCE_DATA = "DELETE_EXPERIENCE_DATA";
export const DELETE_EXPERIENCE_FAIL = "DELETE_EXPERIENCE_FAIL";


var jobseekerId;

export const getSectorData = () => {
  return (dispatch) => {
    dispatch({ type: GET_INDUSTRYDATA_LOADING })
    helpers.getServerUrl().then(url => {
      var url = url.serverUrl
      fetch(url + "/jobseeker/getIndustrySectors", {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        },
      })
        .then(response => response.json())
        .then(responseData => {
          dispatch({
            type: GET_INDUSTRY_DATA,
            payload: responseData
          })
        })
        .catch(err => {
          dispatch({
            type: GET_INDUSTRYDATA_FAIL,
            payload: err
          })
        })
    })
  }
}


export const addExperienceByJobseeker = (body) => {
  jobseekerId = localStorage.getItem("jobseekerId");
  return (dispatch) => {
    dispatch({ type: SET_EXPERIENCE })
    helpers.getServerUrl().then(url => {
      var url = url.serverUrl
      fetch(url + "/jobseeker/addExperienceByJobseeker", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(
          body
        )
      })
        .then(response => response.json())
        .then(responseData => {

          dispatch({
            type: SET_EXPERIENCE_DATA,
            payload: responseData
          })
          if (responseData.success) {
            return dispatch(getJobseekerProfile(body.jobseekerId), getExpData(body.jobseekerId));

          }
        })
        .catch(err => {
          dispatch({
            type: SET_EXPERIENCE_FAIL,
            payload: err
          })
        })
    })
  }
}


export const updatedExp = (bodyExp) => {
  jobseekerId = localStorage.getItem("jobseekerId");
  return (dispatch) => {
    dispatch({ type: UPDATE_EXPERIENCE })
    helpers.getServerUrl().then(url => {
      var url = url.serverUrl
      fetch(url + "/jobseeker/updateExp", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(
          bodyExp
        )
      })
        .then(response => response.json())
        .then(responseData => {
          dispatch({
            type: UPDATE_EXPERIENCEDATA_DATA,
            payload: responseData
          })
          if (responseData.success) {
            return dispatch(getExpData(bodyExp.jobseekerId));
            window.location.reload();
          }
        })
        .catch(err => {
          dispatch({
            type: UPDATE_EXPERIENCEDATA_FAIL,
            payload: err
          })
        })
    })
  }
}

export const getExpData = (jobseekerId) => {
  jobseekerId = localStorage.getItem("jobseekerId");
  return (dispatch) => {
    dispatch({ type: GET_EXPERIENCE_LOADING })
    helpers.getServerUrl().then(url => {
      var url = url.serverUrl
      fetch(url + "/jobseeker/getExperienceData", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          jobseekerId: jobseekerId,
        })
      })
        .then(response => response.json())
        .then(response => {
          dispatch({
            type: GET_EXPERIENCE_DATA,
            payload: response
          })
          if (response.experience.length==0) {
          return dispatch(getJobseekerProfile(jobseekerId))
          }
        })
        .catch(err => {
          dispatch({
            type: GET_EXPERIENCE_FAIL,
            payload: err
          })
        })

    })
  }
}


export const deleteExp = (item) => {
  jobseekerId = localStorage.getItem("jobseekerId");
  return (dispatch) => {
    dispatch({ type: DELETE_EXPERIENCE_LOADING })
    helpers.getServerUrl().then(url => {
      var url = url.serverUrl
      fetch(url + "/jobseeker/deleteExpOfJs", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          jobseekerId: jobseekerId,
          expId: item.expId
        })
      })
        .then(response => response.json())
        .then(response => {
          dispatch({
            type: DELETE_EXPERIENCE_DATA,
            payload: response
          })
          if (response.success) {
            return dispatch( getExpData(jobseekerId),getJobseekerProfile(jobseekerId));
          }
        })
        .catch(err => {
          dispatch({
            type: DELETE_EXPERIENCE_FAIL,
            payload: err
          })
        })

    })
  }
}

