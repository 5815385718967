import * as actionTypes from '../actions/jobListAction';
const initialState= {
    jobsData: [],
    jobsLoading: false,
    jobsError: null,
    jobDetailData : null,
    jobsDataLoading: false,
    jobsDataError: null,
    relatedJobsLoading: false,
    relatedJobs : [],
    relatedJobsError: null,
    reportData: [],
    reportDataError: null,
    topPicksData : [],
    topPicksLoading : false,
    topPicksError : null,
    getCityData : [],
    getCityError : null,
    getFilterIconData : [],
    getFilterIconError : null,
    filterSearchData : [],
    updateToken : [],
    tokenError: null,
    updateHr : [],
    hrError: null,
    filterSearchLoading : false,
    filterSearchError : null,
};

const allJobs = (state = initialState, action) => {
    switch ( action.type ) {
        case actionTypes.REMOVE_JOB_DATA: return {...state,jobDetailData:null};

        case actionTypes.SHOW_JOB_LIST: 
            return{...state, jobsLoading: true, jobsError: null};
        case actionTypes.SHOW_LIST_SUCCESS: 
            return{...state, jobsLoading: false, jobsData: action.payload, jobsError: null};
        case actionTypes.SHOW_LIST_FAILED: 
            return{...state, jobsLoading: false, jobsData: [], jobsError: action.payload};
              
        case actionTypes.SHOW_DETAIL_LOADING: 
            return{...state, jobsDataLoading: true, jobsDataError: null};
        case actionTypes.SHOW_JOB_DETAIL: 
            return{...state, jobsDataLoading: false, jobDetailData: action.payload, jobsDataError: null};
        case actionTypes.SHOW_JOB_FAILED: 
            return{...state, jobsDataLoading: false, jobDetailData: [], jobsDataError: action.payload};

         case actionTypes.RELATED_JOB_LOADING: 
            return{...state, relatedJobsLoading: true, relatedJobsError: null};
        case actionTypes.SHOW_RELATED_JOBS: 
            return{...state, relatedJobsLoading: false, relatedJobs: action.payload, relatedJobsError: null};
        case actionTypes.RELATED_JOB_FAIL: 
            return{...state, relatedJobsLoading: false, relatedJobs: [], relatedJobsError: action.payload};

      case actionTypes.REPORT_DATA_LOAD: 
            return{...state, relatedJobsError: null};
            case actionTypes.GET_REPORT_DATA: 
            return{...state,  reportData: action.payload, reportDataError: null};
        case actionTypes.REPORT_DATA_FAILED: 
            return{...state, reportData: [], reportDataError: action.payload};


            case actionTypes.SHOW_TOP_PICKS: 
            return{...state, topPicksLoading: true, topPicksError: null};
        case actionTypes.SHOW_TOP_PICKS_SUCCESS: 
            return{...state, topPicksLoading: false, topPicksData: action.payload, topPicksError: null};
        case actionTypes.SHOW_TOP_PICKS_FAILED: 
            return{...state, topPicksLoading: false, topPicksData: [], topPicksError: action.payload};
        

            case actionTypes.GET_CITIES: 
            return{...state, getCityError: null};
        case actionTypes.GET_CITIES_SUCCESS: 
            return{...state, getCityData: action.payload, getCityError: null};
        case actionTypes.GET_CITIES_FAILED: 
            return{...state, getCityData: [], getCityError: action.payload};
        

        case actionTypes.SHOW_FILTER_ICON: 
            return{...state, getFilterIconError: null};
        case actionTypes.SHOW_FILTER_ICON_SUCCESS: 
            return{...state, getFilterIconData: action.payload, getFilterIconError: null};
        case actionTypes.SHOW_FILTER_ICON_FAILED: 
            return{...state, getFilterIconData: [], getFilterIconError: action.payload};


            case actionTypes.SHOW_FILTER_SEARCH: 
            return{...state, filterSearchLoading: true, filterSearchError: null};
        case actionTypes.SHOW_FILTER_SEARCH_SUCCESS: 
            return{...state, filterSearchLoading: false, filterSearchData: action.payload, filterSearchError: null};
        case actionTypes.SHOW_FILTER_SEARCH_FAILED: 
            return{...state, filterSearchLoading: false, filterSearchData: [], filterSearchError: action.payload};
            case actionTypes.TOKEN_UPDATED: 
            return{...state, updateToken: action.payload, tokenError: null};
        case actionTypes.TOKEN_UPDATED_FAIL: 
            return{...state, updateToken: [], tokenError: action.payload}; 
            case actionTypes.HRDATA_UPDATED: 
            return{...state, updateHr: action.payload, tokenError: null};
        case actionTypes.HRDATA_UPDATED_FAIL: 
            return{...state, updateHr: [], hrError: action.payload};
        default: 
            return state;

    }
};

export default allJobs;