import React  from 'react';
import Images from "../Images";
import "../_style/internetConnect.css";

export default class InternetConnect extends React.PureComponent{
  render(){
      return(
        <div className="container col-md-6 No-Pading col-lg-6 col-sm-6 col-12" >
            <div className = "noInternetBg">
        <center>
     <h4 className="text-dark pt-1 pb-3 d-none d-sm-block d-md-block">Please check your internet connection!</h4>
     <h6 className="text-dark pt-1 pb-3 d-block d-sm-none d-md-none">Please check your internet connection!</h6>
       <img src={Images.redLogo} alt="Red logo" className="pb-2" width="50" height = "60" />
     </center>
     </div>
       </div>         
      )
  }
}