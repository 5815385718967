import * as actionTypes from '../actions/companyFeedbackAction';
const initialState= {
    getFeedbackData: [],
    getFeedbackLoading: false,
    getFeedbackError: null,
};
const feedback = (state = initialState, action) => {
    switch ( action.type ) {
        case actionTypes.GET_FEEDBACK: 
            return{...state, getFeedbackLoading: true, getFeedbackError: null};
        case actionTypes.GET_FEEDBACK_DATA: 
            return{...state, getFeedbackLoading: false, getFeedbackData: action.payload, getFeedbackError: null};
        case actionTypes.GET_FEEDBACK_FAIL: 
            return{...state, getFeedbackLoading: false, getFeedbackData: [], getFeedbackError: action.payload};
        default: 
            return state;
    }  
};
 
export default feedback;




