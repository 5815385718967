import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Route, Router,Redirect, Switch} from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware , compose} from 'redux';
import thunk from 'redux-thunk';
import reducer from './reducers/index';
import JobList from './screens/joblist';
import searchJob from './screens/searchjob';
import JobDetail from './screens/jobs';
import Blog from './screens/blog';
import BlogDetail from './screens/blogdetail';
import Register from './screens/register';
import MyJobs from './screens/myjobs';
import MainPage from './screens/mainPage';
import InterviewTips from './screens/interviewtips';
import Profile from './screens/profile';
import UpdateProfile from './screens/updateProfile';
import Interest from './screens/interest';
import CompanyFeedback from './screens/companyfeedback';
import CandidateFaq from './screens/candidatefaq';
import Setting from './screens/settings';
import CompanyProfile from './screens/companyprofile';
import Postedjobs from './screens/postedjobs';
import AboutMe from './screens/aboutme';
import EditProfile from './screens/editprofile';
import ContactSupport from './screens/contactsupport';
import Qrcode from './screens/qrcode';
import Notification from './screens/notification';
import ViewResume from './screens/viewresume';
// import { history } from './actions/jobListAction';
import AppliedJob from './screens/appliedjob';
import faq from './screens/faq';
import PrivacyPolicy from './screens/privacypolicy';
import { createBrowserHistory } from 'history';
import EducationDetailTab from './screens/educationdetailtab';
import SscDetail from './screens/sscDetail';
import HsscDetail from './screens/hsscDetail';
import UgDetail from './screens/ugDetail';
import PgDetail from './screens/pgDetail';
import companyWebsite from './screens/companyWebsite';
import WorkExperienceTab from './screens/workexperiencetab';
import Fresher from './screens/fresher';
import mapjobs from './screens/mapjobs';
import ChatCompany from './screens/chatcompany';
import ChatAdmin from './screens/chatadmin';
import VideoResume from './screens/videoresume';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/js/all.js';
import Experience from './screens/experience';
import ChangeLanguage from './screens/changeLanguage';
import SelectLanguage from './screens/selectLanguage';
import ResumeLink from './screens/resumelink';
import CompanyReview from './screens/companyReview';
import NotFoundPage from './screens/404';
import { initializeFirebase } from './firebaseConfig';
import './_style/loader.css';
import { askForPermissioToReceiveNotifications } from './firebaseConfig';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = composeEnhancer(applyMiddleware(thunk))(createStore)(reducer);


export const history = createBrowserHistory();
ReactDOM.render(
  <Provider store= {store}> 
    <Router history={history}>
            <Switch>  
            <Route exact path='/' component={App}/>
            <Route exact path='/mainPage' component={MainPage} />
            <Route exact path='/joblist' component={JobList} />
            <Route exact path='/jobs' component={JobDetail} />
            <Route exact path='/register' component={Register} />
            <Route exact path='/myjobs' component={MyJobs} />
            <Route exact path='/faq' component={faq}/>
            <Route exact path='/privacypolicy' component={PrivacyPolicy}/>
            <Route exact path="/interviewtips" component={InterviewTips}/>
            <Route exact path="/profile" component={Profile}/>
            <Route exact path="/updateProfile" component={UpdateProfile}/>
            <Route exact path="/interest" component={Interest}/>
            <Route exact path="/companyprofile" component={CompanyProfile}/>
            <Route exact path='/postedjobs' component={Postedjobs}/>
            <Route exact path="/setting" component={Setting}/>
            <Route exact path="/editprofile" component={EditProfile}/>
            <Route exact path="/appliedjob" component={AppliedJob}/>
            <Route exact path="/qrcode" component={Qrcode}/>
            <Route exact path="/notification" component={Notification}/>
            <Route exact path="/companyfeedback" component={CompanyFeedback}/>
            <Route exact path="/candidatefaq" component={CandidateFaq}/>
            <Route exact path="/contactsupport" component={ContactSupport}/>
            <Route exact path="/viewresume" component={ViewResume}/>
            <Route exact path="/aboutme" component={AboutMe}/>
            <Route exact path="/educationdetailtab" component={EducationDetailTab}/>
            <Route exact path="/hsscDetail" component={HsscDetail}/>
            <Route exact path="/sscDetail" component={SscDetail}/>
            <Route exact path="/ugDetail" component={UgDetail}/>
            <Route exact path="/pgDetail" component={PgDetail}/>
            <Route exact path="/companyWebsite" component={companyWebsite}/>
            <Route exact path="/workexperiencetab" component={WorkExperienceTab}/>
            <Route exact path="/fresher" component={Fresher}/>
            <Route exact path="/experience" component={Experience}/>
            <Route exact path="/searchjob" component={searchJob}/>
            <Route exact path="/blog" component={Blog}/>
            <Route exact path="/blogdetail" component={BlogDetail}/>
            <Route exact path="/mapjobs" component={mapjobs}/>
            <Route exact path="/chatcompany" component={ChatCompany}/>
            <Route exact path="/chatadmin" component={ChatAdmin}/>
            <Route exact path="/videoresume" component={VideoResume}/>
            <Route exact path="/selectlanguage" component={SelectLanguage}/>
            <Route exact path="/changelanguage" component={ChangeLanguage}/>
            <Route exact path="/companyreview" component={CompanyReview}/>
            <Route exact path="/ResumeLink" component={ResumeLink}/>
            <Route  path="/404" component={NotFoundPage} />
            <Redirect to="/404" />
            </Switch>
    </Router>
    </Provider>  , document.getElementById('root'));
initializeFirebase();
askForPermissioToReceiveNotifications();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
