import * as actionTypes from '../actions/companyProfileAction';

const initialState = {
    companyDetailData: [],
    companyData: [],
    companyDetailError: null,
    companyDetailLoading: false,
    followCompany: [],
    followCompanyError: null,
    companySearchData: [],
    companySearchError: null,
    postedJobsData : [],
    postedJobsLoading : false,
    postedJobsError : null,
    companyReviewData : [],
    companyReviewError : null,
    companyReviewLoading : false,
   
};

const reducer = (state = initialState, action) => {
   // console.log("company profile REDUCER CALLED =======" + JSON.stringify(action.payload))
    switch (action.type) {
        case actionTypes.SHOW_COMPANY_DETAIL:
            return {
                ...state,
                companyDetailLoading: true,
                companyDetailError: null
            };

        case actionTypes.SHOW_COMPANY_DETAIL_SUCCESS:
            return {
                ...state,
                companyDetailData: action.payload.companyObj,
                companyData:action.payload,
                companyDetailError: null,
                companyDetailLoading: false,
            };

        case actionTypes.SHOW_COMPANY_DETAIL_FAILED:
            return {
                ...state,
                companyDetailLoading: false,
                companyDetailData: [],
                companyData:[],
                companyDetailError: action.payload
            };

        case actionTypes.FOLLOW_COMPANY:
            return {
                ...state,
                followCompanyError: null,
            };

        case actionTypes.FOLLOW_COMPANY_SUCCESS:
            return {
                ...state,
                followCompany: action.payload,
                followCompanyError: null
            };

        case actionTypes.FOLLOW_COMPANY_FAILED:
            return {
                ...state,
                followCompanyError: action.payload,
                followCompany: []
            };


        case actionTypes.SEARCH_HISTORY_COMPANY:
            return {
                ...state,
                companySearchError: null
            };

        case actionTypes.SEARCH_HISTORY_COMPANY_SUCCESS:
            return {
                ...state,
                companySearchError: null,
                companySearchData: action.payload
            };

        case actionTypes.SEARCH_HISTORY_COMPANY_FAILED:
            return {
                ...state,
                companySearchData: [],
                companySearchError: action.payload,

            };
            case actionTypes.SHOW_POSTED_JOBS:
                return {
                    ...state,
                    postedJobsError : null,
                    postedJobsLoading : true
                };
    
            case actionTypes.SHOW_POSTED_JOBS_SUCCESS:
                return {
                    ...state,
                    postedJobsLoading : false,
                    postedJobsError: null,
                    postedJobsData : action.payload
                };
    
            case actionTypes.SHOW_POSTED_JOBS_FAILED:
                return {
                    ...state,
                    postedJobsData : [],
                    postedJobsError : action.payload,
    
                };
                case actionTypes.SHOW_COMPANY_REVIEW:
                    return {
                        ...state,
                             companyReviewError : null,
                             companyReviewLoading : true,
                    };
        
                case actionTypes.SHOW_COMPANY_REVIEW_SUCCESS:
                    return {
                        ...state,
                        companyReviewData : action.payload,
                             companyReviewError : null,
                             companyReviewLoading : false,
                    };
        
                case actionTypes.SHOW_COMPANY_REVIEW_FAILED:
                    return {
                        ...state,
                        companyReviewData : [],
                        companyReviewError : action.payload,
                        companyReviewLoading : true,
                    };
               
         default:
            return state;
    }
};

export default reducer;