import React from 'react';
import { Route } from "react-router-dom";
import Webheader from "../components/webheader";
import helpers from '../config';
import MetaTags from 'react-meta-tags';
import Images from '../Images';
import Strings from "../utilities/stringlanguage";
import Snackbar from "@material-ui/core/Snackbar";
import Footer from "../components/footer";
import LoadingOverlay from 'react-loading-overlay';
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { notiDataList,getNotiTypes,deleteNotification,clearAllNoti } from "../actions/notificationAction";
import ModalDiv from "../components/modal";
const moment = require("moment");
var url;
var notificationList = [];
var notificationtypes = [];
var jobseekerId;
var selectedLang;
var tempData=[];
var enabled;
class notification extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            notiDisable:false,
            msgNoti:"",
            notiState: [],
            notiTypes:[],
        }
        this.goBack = this.goBack.bind(this);
    }
    async componentDidMount() {
        let apiUrl = await helpers.getServerUrl()
        url = apiUrl.serverUrl;
        selectedLang = localStorage.getItem("selectedLang")
        if (selectedLang != null && selectedLang != undefined && selectedLang != "") {
            Strings.setLanguage(selectedLang);
        }
        this.state.isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
        jobseekerId = localStorage.getItem("jobseekerId");
        this.props.notiDataList(jobseekerId);
        this.props.getNotiTypes(jobseekerId);
        window.scroll(0, 0);
    }  
    goBack(){
      this.props.history.go(-2);
  }
    handleDisableNoti(item) {
        if(item.enabled===true){
            enabled=false
        }
        if(item.enabled===false){
            enabled=true
        }
        tempData.push({_id:item._id,
            enabled:enabled});
    }

   async updateNoti() {
        try {
            let response = await fetch(url + "/jobseeker/updateNotiPref", {
                method: "POST",
                headers: {
                    "content-Type": "application/json"
                },
                body: JSON.stringify({
                    jobseekerId:jobseekerId,
                    notiTypes:tempData,
                })
            })
            response = await response.json();
            if(response.success===true){
              if(tempData[0].enabled==false || tempData[0].enabled==false || tempData[0].enabled==false || tempData[0].enabled==false) {
               this.setState({msgNoti:"Disable notification successfully"})
               tempData=[]
              }
              else {
               this.setState({msgNoti:"Enable notification successfully"})
               tempData=[]
              }
               this.showNotiModal()
            }
        } catch (err) {
        }
      }
      showNotiModal(){
        this.setState({notiDisable:true})
      }
      hideNotiModal(){
        this.setState({notiDisable:false})
      }
   
    render() {
        let {notiDataLoading,notiData,notiDataError}=this.props;
        if(notiData !== null)
        {
          notiDataLoading=false
        notificationList=notiData.result;
        }
        if(notiData.title != undefined){
       document.title = notiData.title;
         document.getElementsByTagName("META")[2].content = notiData.description;
        }
        let {notiTypeLoading,notiTypeData,notiTypeError} = this.props;
        if(notiTypeData !== null && notiTypeData !== undefined && notiTypeData.length>0)
        {
            notificationtypes=notiTypeData;
        }
        return (
          
            <div >
                <LoadingOverlay
                    active={notiDataLoading}
                    spinner={<div className="loading-notification">
                        <center className=" bg-dark2 rounded mt-5 col-md-6 col-sm-6 p-2 col-12">
                            <h4 className="loadingText pt-1 mb-1 d-sm-block d-md-block">{Strings.loadingNotification}</h4>
                            <h6 className="text-white pt-1 pb-1 d-block d-sm-none d-md-none"></h6>
                            <img src={Images.loaderWhite} alt="img" className="pb-2" width="30" />
                        </center>
                    </div>}
                    >
                    <MetaTags>
                        {/* for browser */}
                        <title>Get Regular Updates Of Jobs, Companies, Job Status etc.... - Jobam</title>
                        {/* For Link sharing in social from url */}
                        <meta property="og:title" content="Get Regular Updates Of Jobs, Companies, Job Status etc.... - Jobam" />
                        <meta property="og:url" content="https://jobam.co/interviewtips"/>
                        <meta property="og:image" content="../assets/logo192.png" />
                        <meta property="og:site_name" content="Jobam" />
                        {/* For twitter sharing from url  */}
                        <meta name="twitter:title" content="Get Regular Updates Of Jobs, Companies, Job Status etc.... - Jobam"/>
                        <meta name="twitter:image" content="../assets/logo192.png"/>
                        <meta name="twitter:site" content="@JhatpatJ"/>
                        <meta name="twitter:creator" content="@JhatpatJ"/>
                    </MetaTags>
                    <div className="container col-md-6 No-Pading col-lg-6 col-sm-6 col-12 bg-light">
                      <Webheader pageName={Strings.Notification} notificationIcon={ this.state.isUserLoggedIn ?true:false} handleBack={this.goBack}/>
              
              <div className="modal" id="disNotiModal">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
        <h5 className="modal-title col-md-12 text-center font-weight-bold d-none d-md-block d-sm-block d-lg-block d-xl-block">{Strings.selectTypeOfNoti}</h5>
                    <h5 className="modal-title col-md-12 text-center font-weight-bold d-block d-md-none d-sm-none d-lg-none d-xl-none">{Strings.selectTypeOfNoti}</h5>
                  </div>
                  <div className="modal-body">
                    <div>
                      {notificationtypes !== null && notificationtypes !== undefined && notificationtypes.length>0 ? (
                        notificationtypes.map(item => (
                          <div className="col-md-12 col-sm-12 col-12 mb-1" key={item.notiType}>
                            <input type="checkbox" className="form-check-input" name="exampleCheck1" onClick={(e) => this.handleDisableNoti(item)} defaultChecked={!item.enabled} />
                            <label className="form-check-label" htmlFor="exampleCheck1">{item.notiType}</label>
                          </div>
                        ))
                      ) : (
                       null
                        )
                      }
                      <center>
                        <div className="col-md-12 col-sm-12 col-12 mt-2">
                          <div className="bg-success col-md-4 col-sm-4 col-6 roundedModal row justify-content-center pointer" onClick={() => this.updateNoti()}>
                            <span className="p-2 font-weight-bold text-white" data-dismiss="modal">{Strings.submit}</span>
                          </div>
                        </div>
                      </center>
             {/* <ModalDiv modalReact={true} crossBtnModal={true} showModal={this.state.notiDisable} successModal={true} msg={this.state.msgNoti} btn1Text={Strings.oK} btn1Click={()=>this.hideNotiModal()} hideModal={()=>this.hideNotiModal()}
            /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
                        
                        <div className="container-fluid">
                            {notificationList != undefined && notificationList.length >0  ?
                                <div id="clearAllNoti">
                                    <u className="float-right pr-2 text-success-gradient pointer text-decoration-none" onClick={i => this.props.clearAllNoti(jobseekerId)}>{Strings.notifiClearAll}</u><p className="border-bottom pt-4"></p></div>
                                : null}
                            {/* notification list */}
                            { this.state.isUserLoggedIn ?
                            (notificationList !== "" && notificationList !== undefined && notificationList.length > 0 ? notificationList.map(notiitem => (
                                    <div key={notiitem.notificationId}>
                                        <div className="row">
                                            <div className="col-md-10"></div>
                                            <div className="text-success pb-0 col-md-2 pointer" onClick={i => this.props.deleteNotification(notiitem.notificationId,jobseekerId)}>
                                                <span className="ml-4 float-right">{Strings.notificationDel}&nbsp;&nbsp;<i className="far fa-trash-alt text-success"></i></span>
                                            </div>
                                        </div>
                                        <div className="card rounded">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-12 col-12">
                                                        <p className="card-title text-bold">{notiitem.message}</p>
                                                        <p className="card-text text-muted"><i className="far fa-calendar-check text-success-gradient fa-1x"></i>&nbsp;&nbsp;<span className="text-bold">{notiitem.createdDate}</span> 
                                                        {/* <i className="far fa-building text-success-gradient fa-1x"></i>&nbsp;&nbsp;<span className="text-bold">{notiitem.companyName}</span> */}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                : 
                                <ModalDiv getImage={Images.noRecord} textMsg={Strings.notificationDialogText}/>
        )
        :
        <div>
         <ModalDiv getImage={Images.noRecord} textMsg={Strings.logintoSeeNotification}/>
          </div>
    }
                        </div>
                        <div className="container-fluid bg-light col-md-12 col-lg-12 col-sm-12 col-12 No-Pading">
                            <Route component={Footer} className="d-none d-md-block d-lg-block d-sm-block d-xl-block" />
                        </div>
                    </div>
                </LoadingOverlay>
              {this.state.notiDisable ?
            <Snackbar
            message={this.state.msgNoti}
            open={true}
            autoHideDuration={3000}
            onClose={() => this.setState({ notiDisable: false })}
          /> :null}
            </div>
           
        )
      }
} 
const mapStateToProps = state => {
    return {
        notiData: state.notificationData.notiData,
        notiDataLoading: state.notificationData.notiDataLoading,
        notiDataError: state.notificationData.notiDataError,
        notiTypeData: state.notificationData.notiTypeData,
        notiTypeLoading: state.notificationData.notiTypeLoading,
        notiTypeError: state.notificationData.notiTypeError,
        delSuccess: state.notificationData.delSuccess,
        delLoading:state.notificationData.delLoading,
        delFail:state.notificationData.delFail,
        clearSuccess:state.notificationData.clearSuccess,
        clearLoading:state.notificationData.clearLoading,
        clearFail:state.notificationData.clearFail,
    };    
  };
  const mapDispatchToProps = dispatch => {
    return {
        notiDataList:(jobseekerId) => dispatch(notiDataList(jobseekerId)), 
        getNotiTypes: (jobseekerId) => dispatch(getNotiTypes(jobseekerId)),
        deleteNotification: (notiIdData,jobseekerId) => dispatch(deleteNotification(notiIdData,jobseekerId)),
        clearAllNoti: (jobseekerId) => dispatch(clearAllNoti(jobseekerId)),
    };
  };
  export default connect(mapStateToProps, mapDispatchToProps)(notification);



 