import React, { Component } from 'react';
import Images from '../Images';
import helpers from "../config";
import Strings from "../utilities/stringlanguage";
import { connect } from "react-redux";
import { getCompanyDetail } from "../actions/companyProfileAction";

var companyId;
var jobseekerId;
var url;
var compShareId;
var imageUrl;

class CompanyWebsite extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      isWebsite: false
    }
  }

  async componentDidMount() {
    await helpers.getServerUrl().then(data => {
      url = data.serverUrl
      imageUrl = data.imageUrl
    });
    jobseekerId = localStorage.getItem("jobseekerId");
    companyId = localStorage.getItem("companyId");
    compShareId = localStorage.getItem("compShareId");
    if (companyId == null && compShareId == null) {
      window.location.reload();
    }
    this.props.getCompanyDetail();
  }

  render() {
    let { companyDetailData, companyDetailLoading, companyDetailError } = this.props;
    var companyWeb = companyDetailData.companyWebsite;
    var isWebsite;
    if (companyWeb != null &&
      companyWeb != "" &&
      companyWeb != undefined) {
      isWebsite = true;
      if (companyWeb.includes("https") || companyWeb.includes("http")) {
        companyWeb = companyWeb;
      } else {
        companyWeb = "http://" + companyWeb;
      }
    }
    else {
      isWebsite = false;
    }
    if (companyDetailLoading) {
      return (
        <center>
          <img src={Images.loader} alt="Loader" />
        </center> 
      )
    }
    return (
      <div >
        {isWebsite ?
          <div>
            <iframe src={companyDetailData.companyWebsite} className="w-100 border-0" height="520">
              {Strings.companyWebsite}
            </iframe>
          </div>
          :
          <center>
            <h5 className="text-success mt-10">{Strings.noWebsiteFound}</h5></center>
        }
      </div>
    )
  }
}
const mapStateToProps = state => {
   return {
    companyDetailData: state.companyProfileReducer.companyDetailData,
    companyDetailLoading: state.companyProfileReducer.companyDetailLoading,
    companyDetailError: state.companyProfileReducer.companyDetailError,

  };
};

const mapDispatchToProps = dispatch => {
 return {
    getCompanyDetail: () => dispatch(getCompanyDetail()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyWebsite);