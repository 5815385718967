import helpers from "../config";

export const FAQ_DATA_LOADING = "FAQ_DATA_LOADING";
export const SHOW_FAQ_DATA = "SHOW_FAQ_DATA";
export const FAQ_LOAD_FAIL = "FAQ_LOAD_FAIL";

  export const faq = () => {
      return (dispatch) => {
       dispatch({type: FAQ_DATA_LOADING})
       helpers.getServerUrl().then(url =>   {
        var url = url.serverUrl
       fetch( url + "/jobseeker/getFaqs", {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        },
      })
      .then(response => response.json())
      .then(responseData => {
        dispatch ({
            type: SHOW_FAQ_DATA,
            payload: responseData
        })
      })
      .catch(err => {
        dispatch({
            type: FAQ_LOAD_FAIL,
            payload: err
        })
        console.log('error', err)
    })
  })
 }
}