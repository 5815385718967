import React, { Component } from "react";
import helpers from "../config";
import LoadingOverlay from "react-loading-overlay";
import Images from "../Images";
import { Modal } from "react-bootstrap";
import '../_style/educationdetail.css';
import Strings from "../utilities/stringlanguage";
import {getPassingYearData , getEducationByJobseekerID} from "../actions/educationDetailAction";
import {connect} from 'react-redux';
import ModalDiv from "../components/modal";
const Compress = require('compress.js');
var jobseekerId;
var url, imageUrl,  yearsData = [];
var values = false;
class SscDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataFound: false,
      loading: true,

    };
    this.selectSSCFile = this.selectSSCFile.bind(this);
  }

  async componentDidMount() {
    let apiUrl = await helpers.getServerUrl()
    url = apiUrl.serverUrl
    imageUrl = apiUrl.imageUrl
    this.state.isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
    jobseekerId = localStorage.getItem("jobseekerId");
    this.props.getEducationByJobseekerID();
    this.props.getPassingYearData();
    
    window.scrollTo(0, 0);
  }

  selectSSCFile(e) {
   let compress = new Compress();
    let files = e.target.files[0];
     let resizedImage = compress.compress([files], {
      size: 0.10, // the max size in MB, defaults to 2MB
      quality: 0.75, // the quality of the image, max is 1,
      maxWidth: 300, 
      maxHeight: 300, 
      resize: false 
    }).then((data) => {
      // returns an array of compressed images
     this.setState({ baseTenthMark: data[0].data });
    })
    this.setState({
      tenthImage: URL.createObjectURL(e.target.files[0])
    });
  }

  showModal = (e) => {
    this.setState({ show: true });
  };
  
  hideModal = (e) => {
    this.setState({ show: false });
  };

  hideModalComplete = (e) => {
    this.setState({ complete: false });
  }

  validationSsc = () => {
    let sscName = this.state.SscSchool != null && this.state.SscSchool != undefined && this.state.SscSchool != "" ? this.state.SscSchool.trim() : "";
    if (
      sscName != "" &&
      sscName != null &&
      sscName != undefined
    ) {
      this.addSSCEducation();
    } else {
      this.showModal();
      this.setState({ msg: Strings.fillSchool });
    }
  }

   addSSCEducation = async() => {
    try {
      let response = await fetch(imageUrl + "jobseeker/updateProfile2", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          jobseekerId: jobseekerId,
          sscExamName: "SSC",
          sscPassingYear: this.state.SscPassYear,
          sscPercentage: this.state.SscPercent,
          sscSchoolName: this.state.SscSchool,
          tenthMark: this.state.baseTenthMark,
          imageName: jobseekerId + "_10mark.jpg"
        })
      })
      response = await response.json();
      if (response != null) {
        let res = response[0];
        if (res.success) {
          this.setState({ complete: true, msgcomplete: Strings.educationaddedsuccess, });
         
        } else {
          this.showModal();
          this.setState({ msg: res.msg });
        }
      }
    }
    catch (err) {
    }
  }

  
  SscPercentageHandle = (e) => {
    this.setState({ SscPercent: e.target.value })
  }
  setSscYear(selectedValue) {
    this.setState({ SscPassYear: selectedValue })
  }
  handleSscName = (e) => {
    this.setState({ SscSchool: e.target.value })
  }

   deleteMarksheet = async() => {
    this.setState({ showDeleteModal: false, });
    try {
      let response = await fetch(imageUrl + "jobseeker/deleteTenthMarksheet", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          jobseekerId: jobseekerId
        })
      })
      response = await response.json();
      if (response[0].success) {
        this.setState({ markDeleteDialog: true, tenthImage: "", tenthMark: "" })
      }
    }
    catch (err) {
    }
  }

  markDialog = () => {
    this.setState({ markDeleteDialog: false })
  }
  deleteModal = () => {
    this.setState({ showDeleteModal: true })
  }
  hideDeleteModal = () => {
    this.setState({ showDeleteModal: false })
  }

  UNSAFE_componentWillReceiveProps(nextProps)
  {

  if (nextProps.allEducationData[0] != undefined && nextProps.allEducationData[0] != "") {
    if (values) {
      this.setState({
        SscSchool: this.state.SscSchool,
        SscPercent: this.state.SscPercent,
        SscPassYear: this.state.SscPassYear,
        tenthMark: this.state.tenthMark
      })
    }
    else {
        this.setState({
      SscSchool : nextProps.allEducationData[0].sscSchoolName,
      SscPercent : nextProps.allEducationData[0].sscPercentage,
      SscPassYear : nextProps.allEducationData[0].sscPassingYear,
      // tenthImage : nextProps.allEducationData[0].twelthMark,
      tenthMark: nextProps.allEducationData[0].tenthMark,
      // imageName: jobseekerId + "_10mark.jpg"
    })
    }

  }
  }

  render() {
    let {allEducationData , passingYearData , allEducationDataLoading , allEducationDataError} = this.props;
    let responseDataYear = passingYearData;
    responseDataYear.forEach(responseDataYear => {
      yearsData.push({
        value: responseDataYear.year,
      });
    });
   
    return (
      <LoadingOverlay
        active={allEducationDataLoading}
        spinner={<div className="loading-educationdetail">
          <center className=" bg-dark3 rounded mt-5 col-md-10 col-sm-6 p-2 col-12">
            <h4 className="loadingText pt-1 mb-1 d-sm-block d-md-block">{Strings.loadingEducation}</h4>
            <h6 className="text-white pt-1 pb-1 d-block d-sm-none d-md-none"></h6>
            <img src={Images.loaderWhite} alt="img" className="pb-2" width="30" />
          </center>
        </div>}
          >
        <div>
          <div className="container-fluid col-md-12 col-lg-12 col-sm-12 col-12 mt-3">
            <div className="tab-pane fade show active" id="pills-pg" role="tabpanel" aria-labelledby="pills-pg-tab">
              <div className="form-group">
                <label className="inputHeadingText">{Strings.SchoolNAME}</label>
                <input onChange={this.handleSscName} type="text" value={this.state.SscSchool} className="form-control shadow-sm inputHeadClass" placeholder={Strings.EnterYourSchool} />
              </div>
              <div className="form-group">
                <label className="inputHeadingText">{Strings.marksObtainedCaps}</label>
                <input value={this.state.SscPercent} onChange={this.SscPercentageHandle} type="number" className="form-control shadow-sm inputHeadClass" placeholder={Strings.EnterPercentage} />
              </div>
              <div className="form-group">
                <label className="inputHeadingText">{Strings.passingYear}</label>
                <select value={this.state.SscPassYear} className="form-control shadow-sm inputHeadClass" onChange={e => this.setSscYear(e.target.value)}>
                  { yearsData  != null && yearsData != "" && yearsData != undefined ?
                    yearsData.map((item, index) => (
                      <option key={index}>{item.value}</option>
                    ))
                    : null
                  }
                </select>
              </div>
             
              <div className="form-group">
                <div className="d-flex justify-content-between">
                  <label className="inputHeadingText">{Strings.uploadYourSSCMarksheet}</label>
                  {this.state.tenthMark != null && this.state.tenthMark != "" && this.state.tenthMark != undefined ?
                    <span className="text-success pointer" onClick={this.deleteModal}>{Strings.Delete}</span>
                    : null}
                </div>
                <label className="custom-file-upload roundedField">
                  <input type="file" accept="image/*" onChange={this.selectSSCFile} />
                  <center>
                    {this.state.tenthImage != null && this.state.tenthImage != "" && this.state.tenthImage != undefined ? <img height="100" className="col-md-12 col-12" alt="tenthMark" src={this.state.tenthImage}></img> : this.state.tenthMark != null && this.state.tenthMark != "" && this.state.tenthMark != undefined
                      ? <img height="100" className="col-md-12 col-12 shadow-sm" alt="tenthMark" src={imageUrl + "jobseeker/getImg/" + this.state.tenthMark + "?" + Date.now()}></img> : <div className="w-100 p-3 dashed-success"><i className="fa fa-plus"></i> Add File</div>}
                  </center>
                </label>
              </div>
              <div className="d-flex justify-content-center">
                <div onClick={this.validationSsc} type="submit" className="btn btn-success  col-md-12 col-12 mt-3 rounded">{Strings.update}</div>
              </div>
            </div>
          </div>
        
          <ModalDiv modalReact={true} showModal={this.state.show} crossBtnModal={true} msg={this.state.msg} btn1Text={Strings.oK} btn1Click={()=>this.hideModal()} hideModal={()=>this.hideModal()}/>
         
          <ModalDiv modalReact={true} showModal={this.state.complete} successModal={true} crossBtnModal={true} msg={this.state.msgcomplete} btn1Text={Strings.oK} btn1Click={()=>this.hideModalComplete()} hideModal={()=>this.hideModalComplete()}/>
       
          <ModalDiv modalReact={true} showModal={this.state.showDeleteModal} successModal={true} crossBtnModal={true} twoButton={true} msg={Strings.deletemarkSheetDialog} btnFirst={Strings.oK} btnFirstClick={()=>this.deleteMarksheet()}  btnSecond={Strings.cancelCaps}  btnSecondClick={this.hideDeleteModal} hideModal={()=>this.hideDeleteModal()}/>
        
          <ModalDiv modalReact={true} showModal={this.state.markDeleteDialog} crossBtnModal={true}  successModal={true} msg={Strings.markSheetDeletesuccessfully} btn1Text={Strings.oK} btn1Click={()=>this.markDialog()} hideModal={()=>this.markDialog()}/>
        </div>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = state => {
  return {
    passingYearData : state.educationDetailReducer.passingYearData,
    passingYearDataLoading: state.educationDetailReducer.passingYearDataLoading,
    allEducationData : state.educationDetailReducer.allEducationData,
    allEducationDataError: state.educationDetailReducer.allEducationDataError,
    allEducationDataLoading : state.educationDetailReducer.allEducationDataLoading,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getEducationByJobseekerID : () => dispatch(getEducationByJobseekerID()),
    getPassingYearData : () => dispatch(getPassingYearData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SscDetail);