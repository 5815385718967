import helpers from "../config";

export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_LANGUAGE_DATA = "SET_LANGUAGE_DATA";
export const SET_LANGUAGE_FAIL = "SET_LANGUAGE_FAIL";

var jobseekerId;
  export const onUpdateLanguage = (selectedLang) => {
    jobseekerId = localStorage.getItem("jobseekerId");
      return (dispatch) => {
       dispatch({type: SET_LANGUAGE})
       helpers.getServerUrl().then(url =>   {
        var url = url.serverUrl
       fetch( url + "/jobseeker/updateLanguage", {
        method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            jobseekerId: jobseekerId,
            selectedLang: selectedLang
          })
        })
      .then(response => response.json())
      .then(responseData => {
        dispatch ({
            type: SET_LANGUAGE_DATA,
            payload: responseData
        })
      })
      .catch(err => {
        dispatch({
            type: SET_LANGUAGE_FAIL,
            payload: err
        })
        console.log('error', err)
    })
  })
 }
}

