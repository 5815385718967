import React from 'react';
import Webheader from "../components/webheader";
import Footer from "../components/footer";
import { Route } from "react-router-dom";
import Images from '../Images';


export default class VideoResume extends React.Component{
    async componentDidMount() {      
        window.scrollTo(0, 0);
      }
    handleNotiBack() {
        this.props.history.goBack()
    }
    render(){
        return(
            <div className="container col-md-6 No-Pading col-lg-6 col-sm-6 col-12">
                <Webheader pageName="VIDEO RESUME" backLink="/profile" handleBack={i=>this.handleNotiBack}/>
                <div className="bg-light pt-5 text-center col-md-12 col-12">
                    <img src={Images.infomessage} alt="Download the app now" className="img-responsive col-12 col-md-12 pt-md-0 pt-5 pb-5 pb-md-0"/>
                    <h5 className="mx-auto">Download Jobam mobile application to use this feature</h5>
                    <a className="btn btn-success btn-outline-light btn-lg text-white mb-5 mb-md-0" target="_black" href="https://play.google.com/store/apps/details?id=dataflakes.jobs.jhatpatjobs.jobsearch&hl=en">Click Here</a>
                </div>
                <div className="container-fluid bg-light col-md-12 col-lg-12 col-sm-12 col-12 No-Pading">
                    <Route component={Footer} className="d-none d-md-block d-lg-block d-sm-block d-xl-block" />
                </div>
            </div>
        )
    }
}
