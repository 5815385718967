import * as actionTypes from '../actions/fresherAction';
const initialState= {
    getFresherData: [],
    getFresherLoading: false,
    getFresherError: null,
};
const workFresher = (state = initialState, action) => {
    // console.log(" fresher REDUCER CALLED =======" + JSON.stringify(action.payload))
    switch ( action.type ) {
        case actionTypes.GET_FRESHER: 
            return{...state, getFresherLoading: true, getFresherError: null};
        case actionTypes.GET_FRESHER_DATA: 
            return{...state, getFresherLoading: false, getFresherData: action.payload, getFresherError: null};
        case actionTypes.GET_FRESHER_FAIL: 
            return{...state, getFresherLoading: false, getFresherData: [], getFresherError: action.payload};
        default: 
            return state;
    }  
};
 
export default workFresher;




