import * as actionTypes from '../actions/interviewTipsAction';

const initialState = {
    getInterviewData: [],
    getInterviewLoading: false,
    getInterviewError: null,
    getVideoTipsData: [],
    getVideoTipsLoading: false,
    getVideoTipsError: null
};


const interviewTipsD = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_INTERVIEW:
            return { ...state, getInterviewLoading: true, getInterviewError: null };
        case actionTypes.GET_INTERVIEW_DATA:
            return { ...state, getInterviewLoading: false, getInterviewData: action.payload, getInterviewError: null };
        case actionTypes.GET_INTERVIEW_FAIL:
            return { ...state, getInterviewLoading: false, getInterviewData: [], getInterviewError: action.payload };

        case actionTypes.GET_VIDEOTIPS:
            return { ...state, getVideoTipsLoading: true, getVideoTipsError: null };
        case actionTypes.GET_VIDEOTIPS_DATA:
            return { ...state, getVideoTipsLoading: false, getVideoTipsData: action.payload, getVideoTipsError: null };
        case actionTypes.GET_VIDEOTIPS_FAIL:
            return { ...state, getVideoTipsLoading: false, getVideoTipsData: [], getVideoTipsError: action.payload };
        default:
            return state;
    }
};



export default interviewTipsD;



