import React, { Component } from "react";
import helpers from "../config";
import LoadingOverlay from "react-loading-overlay";
import Images from "../Images";
import '../_style/educationdetail.css';
import { Modal } from "react-bootstrap";
import Strings from "../utilities/stringlanguage";
import { getPgDegree, getPassingYearData, getUniversityData, getEducationByJobseekerID } from "../actions/educationDetailAction";
import { connect } from "react-redux";
import ModalDiv from "../components/modal";
var jobseekerId;
var url, imageUrl;
var title;
var keywords;
var description;
var values = false;
class PgDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataFound: false,
      loading: true,
      hideModal: false,

    };
  }

  async componentDidMount() {
    let apiUrl = await helpers.getServerUrl()
    url = apiUrl.serverUrl
    imageUrl = apiUrl.imageUrl
    this.state.isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
    jobseekerId = localStorage.getItem("jobseekerId");
    this.props.getEducationByJobseekerID();
    this.props.getPassingYearData();
    this.props.getUniversityData();
    this.props.getPgDegree();
    window.scrollTo(0, 0);
    let { pgDegreeData, passingYearData, universityNameData, allEducationData, allEducationDataLoading } = this.props;
  }

  setPgPercent = (e) => {
    this.setState({ pgPercent: e.target.value })
  }

  getPgDegreeFun = async (selectedOption) => {
    this.setState({ selectedOption: selectedOption })
  }

  getPgCollegeData = async (selectedValue) => {
    this.setState({ selectedValue: selectedValue })
  }

  showModal = e => {
    this.setState({ show: true });
  };

  hideModal = e => {
    this.setState({ show: false });
  };

  showModalComplete = e => {
    this.setState({ complete: true });
  }

  hideModalComplete = e => {
    this.setState({ hideModal: true, });
  }

  setPassingYear(selectedValue) {
    this.setState({ PgPassYear: selectedValue })
  }

  validationPg = () => {
    let pgClgName = this.state.selectedValue != null && this.state.selectedValue != undefined && this.state.selectedValue != "" ? this.state.selectedValue.trim() : "";
    if (
      pgClgName != "" &&
      pgClgName != null &&
      pgClgName != undefined
    ) {
      this.addPGEducation();
    } else {
      this.showModal();
      this.setState({ msg: Strings.fillUniversity });
    }

  }

  addPGEducation = async () => {
    try {
      let response = await fetch(url + "/jobseeker/updateProfile", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          jobseekerId: jobseekerId,
          PGExamName: this.state.selectedOption,
          PGPassingYear: this.state.PgPassYear,
          PGPercentage: this.state.pgPercent,
          clgNamePg: this.state.selectedValue.trim(),
        })
      })
      response = await response.json()
      if (response != null) {

        let res = response[0];
        if (res.success) {
          this.showModalComplete();
          this.setState({ msgcomplete: Strings.educationaddedsuccess });
        }
        else {
          this.showModal();
          this.setState({ msg: res.msg });
        }

      }
    }
    catch (err) {
      console.log(err)
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.allEducationData[0] != undefined && nextProps.allEducationData[0] != "") {
      if (values) {
        this.setState({
          selectedValue: this.state.selectedValue,
          selectedOption: this.state.selectedOption,
          pgPercent: this.state.pgPercent,
          PgPassYear: this.state.PgPassYear
        })
      }
      else {
        this.setState({
          selectedValue: nextProps.allEducationData[0].pgCollegeName,
          selectedOption: nextProps.allEducationData[0].PgExamName,
          pgPercent: nextProps.allEducationData[0].PgPercentage,
          PgPassYear: nextProps.allEducationData[0].PGPassingYear
        })
      }

    }
  }

  render() {
    if (title != undefined) {
      document.title = title;
      document.getElementsByTagName("META")[2].content = description
      document.getElementsByTagName("META")[3].content = keywords
    }
    let { pgDegreeData, passingYearData, universityNameData, allEducationData, allEducationDataLoading } = this.props;

    if (this.state.hideModal) {
      this.state.complete = false;
    }
    return (

      <LoadingOverlay
        active={allEducationDataLoading}
        spinner={<div className="loading-educationdetail">
          <center className=" bg-dark3 rounded mt-5 col-md-10 col-sm-6 p-2 col-12">
            <h4 className="loadingText pt-1 mb-1 d-sm-block d-md-block">{Strings.loadingEducation}</h4>
            <h6 className="text-white pt-1 pb-1 d-block d-sm-none d-md-none"></h6>
            <img src={Images.loaderWhite} alt="img" className="pb-2" width="30" />
          </center>
        </div>}
      >
        <div>
          <div className="container-fluid col-md-12 col-lg-12 col-sm-12 col-12 mt-3">
            <div className="tab-pane fade show active" id="pills-pg" role="tabpanel" aria-labelledby="pills-pg-tab">
              <div className="form-group">
                <label className="inputHeadingText">{Strings.universityName}</label>
                <input type="text" className="roundedField" placeholder={Strings.EnterYourUniversity} name="example" list="exampleList" value={this.state.selectedValue} className="form-control shodaow-sm inputHeadClass" onChange={(e) => this.getPgCollegeData(e.target.value)} />
                <datalist id="exampleList" className="shodaow-sm">
                  {universityNameData != "" && universityNameData != undefined ?
                    universityNameData.map((item, index) => (
                      <option key={index}>{item.universityName.toLowerCase()}
                      </option>
                    )) : null}
                </datalist>
              </div>
              <div className="form-group">
                <label className=" inputHeadingText">{Strings.courseCaps}</label>
                <input type="text" placeholder={Strings.chooseDegree} list="pgListData" className="form-control shodaow-sm inputHeadClass"
                  value={this.state.selectedOption}
                  onChange={(e) => this.getPgDegreeFun(e.target.value)} />
                <datalist id="pgListData">
                  {pgDegreeData != "" && pgDegreeData != undefined ?
                    pgDegreeData.map((item, index) => (
                      <option key={index}>{item.degreeName.toUpperCase().replace(/[^a-zA-Z ]/g, "")}
                      </option>
                    )) : null}
                </datalist>
              </div>
              <div className="form-group">
                <label className="inputHeadingText shodaow-sm">{Strings.marksObtainedCaps}</label>
                <input onChange={this.setPgPercent} type="number" className="form-control inputHeadClass" value={this.state.pgPercent} placeholder={Strings.EnterPercentage} required />
              </div>
              <div className="form-group">
                <label className=" inputHeadingText">{Strings.passingYear}</label>
                <select className="form-control shodaow-sm inputHeadClass"

                  value={this.state.PgPassYear} onChange={e => this.setPassingYear(e.target.value)}>
                  {passingYearData != null && passingYearData != "" && passingYearData != undefined ?
                    passingYearData.map((item, index) => (
                      <option key={index}>{item.year}</option>
                    ))
                    : null
                  }
                </select>
              </div>
              <div className="d-flex justify-content-center">
                <div onClick={this.validationPg} type="submit" className="btn btn-success  col-md-12 col-12 mt-3 rounded">{Strings.update}</div>
              </div>

            </div>
          </div>

          <ModalDiv modalReact={true} showModal={this.state.show}  msg={this.state.msg} btn1Text={Strings.oK} crossBtnModal={true} btn1Click={()=>this.hideModal()} hideModal={()=>this.hideModal()}/>
         
         <ModalDiv modalReact={true} showModal={this.state.complete} successModal={true} msg={this.state.msgcomplete} btn1Text={Strings.oK} btn1Click={()=>this.hideModalComplete()} crossBtnModal={true} hideModal={()=>this.hideModalComplete()}/>
        </div>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = state => {
  return {
    pgDegreeData: state.educationDetailReducer.pgDegreeData,
    passingYearData: state.educationDetailReducer.passingYearData,
    universityNameData: state.educationDetailReducer.universityNameData,
    allEducationData: state.educationDetailReducer.allEducationData,
    allEducationDataLoading: state.educationDetailReducer.allEducationDataLoading,
    allEducationDataError: state.educationDetailReducer.allEducationDataError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getPgDegree: () => dispatch(getPgDegree()),
    getPassingYearData: () => dispatch(getPassingYearData()),
    getUniversityData: () => dispatch(getUniversityData()),
    getEducationByJobseekerID: () => dispatch(getEducationByJobseekerID()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PgDetail);
