import helpers from "../config";
import swal from "sweetalert";

export const PROFILE_DATA_LOADING = "PROFILE_DATA_LOADING";
export const SHOW_PROFILE_DATA = "SHOW_PROFILE_DATA";
export const PROFILE_LOAD_FAIL = "PROFILE_LOAD_FAIL";

export const RESUME_DATA_LOADING = "RESUME_DATA_LOADING";
export const SHOW_RESUME_DATA = "SHOW_RESUME_DATA";
export const RESUME_LOAD_FAIL = "RESUME_LOAD_FAIL";

export const DELETERESUME_DATA_LOADING = "DELETERESUME_DATA_LOADING";
export const SHOW_DELETERESUME_DATA = "SHOW_DELETERESUME_DATA";
export const DELETERESUME_LOAD_FAIL = "DELETERESUME_LOAD_FAIL";

export const DELETEVIDEO_DATA_LOADING = "DELETEVIDEO_DATA_LOADING";
export const SHOW_DELETEVIDEO_DATA = "SHOW_DELETEVIDEO_DATA";
export const DELETEVIDEO_LOAD_FAIL = "DELETEVIDEO_LOAD_FAIL";

export const LOADING_UPDATED_DATA = "LOADING_UPDATED_DATA";
export const DATA_UPDATED = "DATA_UPDATED";
export const DATA_UPDATED_FAIL = "DATA_UPDATED_FAIL";

export const getJobseekerProfile = (jobseekerId) => {
      return (dispatch) => {
      dispatch({type: PROFILE_DATA_LOADING})
      helpers.getServerUrl().then(url =>   {
        var url = url.serverUrl;
        var fileUrl=url.imageUrl;
      fetch(url + "/admin/getJsDetailById", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
           jobseekerId : jobseekerId,
           loginFrom: "Web"
        })
      })
        .then(response => response.json())
        .then(responseData => {
          dispatch ({
              type: SHOW_PROFILE_DATA,
              payload: responseData
          })
        })
        .catch(err => {
          dispatch({
              type: PROFILE_LOAD_FAIL,
              payload: err
          })
      })
    })
   }
  }

  export const getResume = (jobseekerId) => {
    return (dispatch) => {
    dispatch({type: RESUME_DATA_LOADING})
    helpers.getServerUrl().then(url =>   {
      var fileUrl = url.imageUrl
    fetch(fileUrl + "jobseeker/defaultResume", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
         jobseekerId : jobseekerId  
      })
    })
      .then(response => response.json())
      .then(response => {
        dispatch ({
            type: SHOW_RESUME_DATA,
            payload: response
        })
        if (response.success) {
          return dispatch(getJobseekerProfile(jobseekerId));
        }
      })
      .catch(err => {
        dispatch({
            type: RESUME_LOAD_FAIL,
            payload: err
        })
    })
  })
 }
}

export const deleteResume = (jobseekerId) => {
  return (dispatch) => {
  dispatch({type: DELETERESUME_DATA_LOADING})
  helpers.getServerUrl().then(url =>   {
    var fileUrl = url.imageUrl
  fetch(fileUrl + "jobseeker/deleteResume", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
       jobseekerId : jobseekerId  
    })
  })
    .then(response => response.json())
    .then(response => {
      dispatch ({
          type: SHOW_DELETERESUME_DATA,
          payload: response
      })
      if (response[0].success) {
        return dispatch(getJobseekerProfile(jobseekerId));
      } else {
        swal(response[0].msg);
        return dispatch(getJobseekerProfile(jobseekerId));
      }
    })
    .catch(err => {
      dispatch({
          type: DELETERESUME_LOAD_FAIL,
          payload: err
      })
  })
})
}
}

export const deleteVideoResume = (jobseekerId) => {
  return (dispatch) => {
  dispatch({type: DELETEVIDEO_DATA_LOADING})
  helpers.getServerUrl().then(url =>   {
    var fileUrl = url.imageUrl
  fetch(fileUrl + "jobseeker/deleteVideoResume", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
       jobseekerId : jobseekerId  
    })
  })
    .then(response => response.json())
    .then(responseData => {
      dispatch ({
          type: SHOW_DELETERESUME_DATA,
          payload: responseData
      })
      if (responseData[0].success) {
        return dispatch(getJobseekerProfile(jobseekerId));
      } else {
        swal(responseData[0].msg);
        return dispatch(getJobseekerProfile(jobseekerId));
      }
    })
    .catch(err => {
      dispatch({
          type: DELETEVIDEO_LOAD_FAIL,
          payload: err
      })
  })
})
}
}

export const updateProfileApi = (body) => {
  return (dispatch) => {
   dispatch({ type: LOADING_UPDATED_DATA })
   helpers.getServerUrl().then(url =>   {
    var url = url.serverUrl
   helpers.getServerUrl().then(url => {
     var url = url.serverUrl
   fetch(url + "/jobseeker/updateProfile", {
     method: "POST",
     headers: {
       "Content-Type": "application/json"
     },
     body: JSON.stringify(body)
   })
     .then(response => response.json())
     .then(responseData => {   
       dispatch({
         type: DATA_UPDATED,
         payload : responseData
       })
     })
     .catch(err => {
       dispatch({
         type: DATA_UPDATED_FAIL,
         payload: err
       })
     })
   })
  })
 }
}


