import React from "react";
import Images from "../Images";
import helpers from "../config";
import 'rc-slider/assets/index.css';
import Strings from "../utilities/stringlanguage";
import stringlanguage from "../utilities/stringlanguage";
import '../_style/profile.css';
import '../_style/animate.css';
import LoadingOverlay from 'react-loading-overlay';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Link } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { getJobseekerProfile,getResume, deleteResume,deleteVideoResume} from "../actions/profileAction";
import { connect } from "react-redux";
import Footer from "../components/footer";
import WebBottomNav from "../components/webBottomNavigation";
import ReactPlayer from 'react-player';
import axios from 'axios';
import { Modal } from "react-bootstrap";
import ModalDiv from '../components/modal';
import Navbar from "../components/navBar";
import {
  EmailShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import Loading from '../components/loadingBar';
var url;
var imageUrl;
var moment = require("moment");
var userImage;
var jobseekerName;
var jobseekerId;
var selectedLang;
var referralCode;
var tokenId;
var videoDuration;
class profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      volume: 0,
      value: 0,
      jobseekerImage: "",
      isLoading : true,
      jobseekerName: "",
      videoAlert: false,
      alertMessage: "",
      showModal: true,
      resumeLoader: false,
      loaderMsg: "",
      style: "menu",
      menuStatus: "open",
      popupVisible: false,
      pathMap: [
        '/joblist',
        '/myjobs',
        '/interviewtips',
        '/profile',
        '/register'
      ]
    }
    this.handleClick = this.handleClick.bind(this);
    this.handleAboutMe = this.handleAboutMe.bind(this);
    this.handleEducation = this.handleEducation.bind(this);
    this.handleExperience = this.handleExperience.bind(this);
  }
  async componentDidMount() {
    selectedLang = localStorage.getItem("selectedLang")
    if (selectedLang != null && selectedLang != undefined && selectedLang != "") {
      stringlanguage.setLanguage(selectedLang);
    }
    this.state.isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
    userImage = localStorage.getItem("userImage");
    jobseekerName = localStorage.getItem("givenName");
    jobseekerId = localStorage.getItem("jobseekerId");
    referralCode = localStorage.getItem("referralCode");
    tokenId = localStorage.getItem("tokenId");
    if ((userImage === undefined || userImage === null) && (jobseekerId === undefined || jobseekerId === null) && (jobseekerName === undefined || jobseekerName === null)) {
      this.props.history.push('/joblist');
    }
    else {
      let apiUrl = await helpers.getServerUrl()
      url = apiUrl.serverUrl
      imageUrl = apiUrl.imageUrl
      if (this.state.isUserLoggedIn) {
        this.jobseekerVerify();
        this.props.getJobseekerProfile(jobseekerId);
        document.getElementById('mainDiv').onclick = function (e) {
          if (e.target != document.getElementById('overlay')) {
            document.getElementById("overlay").style.display = "none";
          }
        }
      }
      else {
        this.props.history.push('/joblist')
      }
    }
    window.scrollTo(0, 0);
  }

  jobseekerVerify = async () => {
    try {
      let response = await fetch(url + "/admin/getAppSetDetails1", {
        method: "POST",
        headers: {
          "content-Type": "application/json"
        },
        body: JSON.stringify({
          jobseekerId: jobseekerId,
          tokenId: tokenId,
          loginFrom: "Web"
        })
      })
      response = await response.json()
      let ismobileVerified = response.ismobileVerified
      if (this.state.isUserLoggedIn && ismobileVerified !== true ) {
        this.updateProfileModal()
      }

    } catch (err) {
    }
  }

  updateProfileModal = e => {
    this.setState({
      showUpdateModel: true
    });
  };

  hideProfileModal = e => {
    this.setState({ showUpdateModel: false });
    this.props.history.push('/updateProfile')
  };

  handleClick() {
    if (this.state.menuStatus == "open") {
      document.getElementById("overlay").style.display = "block";
    }
    else {
      document.getElementById("overlay").style.display = "none";
    }
    switch (this.state.menuStatus) {
      case "open":
        this.setState({
          menuStatus: "close",
          style: "menu active"
        });
        break;
      case "close":
        this.setState({
          menuStatus: "open",
          style: "menu"
        });
        break;
    }
  }
  componentWillReceiveProps(newProps) {
    const { pathname } = newProps.location;
    const { pathMap } = this.state;

    const value = pathMap.indexOf(pathname);

    if (value > -1) {
      this.setState({
        value
      });
    }
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };


  handleAboutMe() {
    this.props.history.push('/aboutme');
  }


  showModal = () => {
    this.setState({
      show: true,
      videoResumeModal: false
    });
  };
  hideModal = () => {
    this.setState({ show: false });
  };

  showVideoAlert = () => {
    this.setState({
      videoAlert: true,
      videoResumeModal: false
    });
  };
  hideVideoAlert = () => {
    this.setState({ videoAlert: false });
  };

  getDefaultResume() {
    this.setState({ showModal: false, loaderMsg: Strings.creatingResume });
    this.props.getResume(jobseekerId);
    setTimeout(() => {
      this.reloadFunc();
    }, 1000);
  }

  deletePdfDocResume() {
    this.setState({ showModal: false,  loaderMsg: Strings.deletingResume });
    this.props.deleteResume(jobseekerId);
    setTimeout(() => {
      this.reloadFunc();
    }, 1000);
  }
reloadFunc(){
  window.location.reload();
}

  deleteVideoReume() {
    this.setState({  showModal: false, loaderMsg: Strings.deletingVideoResume });
    this.props.deleteVideoResume(jobseekerId);
    setTimeout(() => {
      this.reloadFunc();
    }, 1000);
  }

  onFileChangeHandler = (e) => {
    e.preventDefault();
    this.setState({
      selectedFile: e.target.files[0]
    });
    this.setState({ showModal: false, resumeLoader: true, loaderMsg: Strings.uploadingResume });
    let file = e.target.files[0];
    var name = e.target.files[0].name;
    var fileName = jobseekerId + "_resume." + name.split('.')[1];
    const formData = new FormData();
    formData.append('file', file, fileName);
    axios.post(imageUrl + "jobseeker/" + jobseekerId + "/insertJobseekerImage",
      formData, {
    })
      .then(res => {
        this.setState({ resumeLoader: false });
        this.props.getJobseekerProfile(jobseekerId);
        window.location.reload();
      });
  };

  handleEducation() {
    this.props.history.push('/educationDetailTab')
  }

  handleExperience() {
    this.props.history.push('/workexperiencetab')
  }

  redirectToVideo() {
    this.props.history.push('/videoresume')
  }

  logout() {
    localStorage.removeItem("isUserLoggedIn");
    localStorage.removeItem("givenName");
    localStorage.removeItem("jobseekerId");
    localStorage.removeItem("jobseekerName");
    localStorage.removeItem("tokenId");
    localStorage.setItem("city", "");
    localStorage.setItem("jobProfile", "");
    localStorage.setItem("cityLat", "");
    localStorage.setItem("cityLong", "");
    localStorage.setItem("selectedLang", "en")
    this.setState({});
    this.props.history.push('/joblist')
    window.location.reload();
  }

  onFileChangeImage = (e) => {
    e.preventDefault();
    this.setState({
      selectedImageFile: e.target.files[0]
    });
    this.setState({ showModal: false, resumeLoader: true, loaderMsg: Strings.uploadingProfile });
    let file = e.target.files[0];
    var name = e.target.files[0].name;
    var fileName = jobseekerId + "_profile." + name.split('.')[1];
    const formData = new FormData();
    formData.append('file', file, fileName);
    axios.post(imageUrl + "jobseeker/" + jobseekerId + "/insertJobseekerImage",
      formData, {
    })
      .then(res => {
        this.setState({ resumeLoader: false });
        this.props.getJobseekerProfile(jobseekerId);
        window.location.reload();
      });
  };


  previewVideo = (e) => {
    var file = e.target.files[0];
    var name = e.target.files[0].name;
    var videoType = e.target.files[0].type;
    if(videoType.startsWith("video")){
    var fileName = jobseekerId + "_profile." + name.split('.')[1];
    this.setState({ file: file, fileName: fileName, fname: name });
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);
    reader.onloadend = function (e) {
      this.setState({
        videoUrl: [reader.result]
      })
      let aud = new Audio(reader.result);
      aud.onloadedmetadata = function () {
        videoDuration = aud.duration
      }
    }.bind(this);
    this.showModal();
  } 
  else{
    this.showVideoAlert()
    this.setState({alertMessage: "You can upload only video" })
  }
  }

  videoResume = async () => {
    this.hideModal();
    let name = this.state.fname;
    let fileName = jobseekerId + "_profile." + name.split('.')[1];
    let videoSize = (this.state.file.size / (1024 * 1024)).toFixed(2)
    if (videoDuration > 90) {
      this.setState({ alertMessage: "Video duration should not be more then 90 seconds" })
    }
    else {
      this.setState({ alertMessage: "Video size should not be more then 20 MB" })
    }
    if (videoDuration < 90) {
      this.setState({ resumeLoader: true, loaderMsg: "Processing video" });
      const formData = new FormData();
      formData.append('file', this.state.file, fileName);
      axios.post(imageUrl + "jobseeker/" + jobseekerId + "/uploadVideo",
        formData, {
      })
        .then(res => {
          this.setState({ resumeLoader: false });
          window.location.reload();
        });
    }else {
      this.showVideoAlert()
    }
  }

  render() {
    let { jobseekerData, jsDataError, jsDataLoading ,resumeData,resumeLoading,resumeDataError,resumeDeleteData,resumeDeleteLoading,resumeDeleteDataError,videoDeleteLoading,videoDeleteData,videoDeleteDataError} = this.props;
    if (jobseekerData.highestDegree !== null && jobseekerData.highestDegree !== undefined) {
      this.state.examNameEd = jobseekerData.highestDegree[0].examName;
      this.state.passingYearEd = jobseekerData.highestDegree[1].passingYear;
      this.state.instituteEd = jobseekerData.highestDegree[2].institute;
      this.state.percentageEd = jobseekerData.highestDegree[3].percentage;
    }
    if(jobseekerData.dob !=null) {
      
      var dob = new Date(jobseekerData.dob)
      dob.setHours(dob.getHours() + 13)
    }
    localStorage.setItem("isAvailable", jobseekerData.isAvailable);
    if (jobseekerData.jobseekerImage) {
      this.state.jobseekerImage = jobseekerData.jobseekerImage;
    }
if(jobseekerData!= "" && jobseekerData != undefined)
{
  this.state.isLoading = false;
}
    const shareUrl =
      "Have you tried Jobam mobile app yet ? You will definitely like it: https://play.google.com/store/apps/details?id=dataflakes.jobs.jhatpatjobs.jobsearch. Use this code " + referralCode + " to earn more credits and improve your chances to get hired.";
    const title = "";
    var emailBody = "Have you tried Jobam mobile app yet ? You will definitely like it: https://play.google.com/store/apps/details?id=dataflakes.jobs.jhatpatjobs.jobsearch. Use this code " + referralCode + " to earn more credits and improve your chances to get hired.";
    if ( this.state.isLoading ) {
      document.body.style.overflow = "hidden";
    }
    else {
      document.body.style.overflow = "unset";
    }
    if (this.state.resumeLoader || resumeLoading||resumeDeleteLoading|| videoDeleteLoading) {
      return (
        <div className="loading-profile">
        <center className=" bg-dark3 rounded mt-5 col-md-6 col-sm-6 p-2 col-12">
          <h4 className="loadingText pt-1 mb-1 d-sm-block d-md-block">{this.state.loaderMsg}</h4>
          <h6 className="text-white pt-1 pb-1 d-block d-sm-none d-md-none"></h6>
          <img src={Images.loaderWhite} alt="img" className="pb-2" width="30" />
        </center>
      </div>
      )
    }
    const { value, pathMap } = this.state;
    return (
      <LoadingOverlay
        active={ this.state.isLoading }
        spinner={<div className="loading-profile">
          <center className=" bg-dark3 rounded mt-5 col-md-6 col-sm-6 p-2 col-12">
            <h4 className="loadingText pt-1 mb-1 d-sm-block d-md-block">{Strings.loadingYourProfile}</h4>
            <h6 className="text-white pt-1 pb-1 d-block d-sm-none d-md-none"></h6>
            <img src={Images.loaderWhite} alt="img" className="pb-2" width="30" />
          </center>
        </div>}
      >
        <div className="container col-md-6 col-lg-6 col-sm-6 col-12 ">
          <Navbar/>
          </div>

          <div>
            <div className="menuFixedProfile">
              <div className="container col-md-6 col-lg-6 col-sm-6 col-12 myProfile" id="mainDiv">
                <div className="myImageProfile">
                </div>
                <div className="col-md-12 col-12">
                  <div className="row">
                    <h5 className="col-md-11 col-11 pt-3 font-weight-bold ml-md-3 jsName">{jobseekerData.jobseekerName}</h5>
                    <div className="pt-3">
                      <div className="text-success-gradient pointer" id="openDiv" onClick={() => this.handleClick()}><i className="fas fa-bars fa-1x"></i></div>
                      <div className={this.state.style} id="overlay">
                        <div onClick={() => this.handleClick()} className="float-right text-white w3-modal"><i className="mr-3 mt-2 fas fa-times"></i></div>
                        <ul className="menuUl mt-4">
                          <li className="menuList">
                            <Link className="menuA pointer" to={{ pathname: "/setting", state: { isAvailable: jobseekerData.isAvailable} }}  >
                              <i className="fas fa-cogs"></i> {Strings.settings}
                            </Link>
                          </li>

                          <li className="menuList"><Link to="/changelanguage" className="menuA pointer text-white"><i className="fa fa-language"></i> {Strings.language}</Link></li>
                          <li className="menuList"><Link to="/qrcode" className="menuA pointer"><i className="fa fa-qrcode"></i> {Strings.myQrCodeDrawer}</Link></li>
                          <li className="menuList"><Link to={{ pathname: "/interest", data:  true  }}
                          className="menuA pointer"><i className="fas fa-user-edit"></i> {Strings.JobProfiles}</Link></li>
                          <li className="menuList"><Link to="/notification" className="menuA pointer"><i className="fas fa-bell"></i> {Strings.notificationSmall}</Link></li>
                          <li className="menuList"><a data-toggle="modal" data-target="#logoutmodal" className="menuA pointer text-white"><i className="fas fa-sign-out-alt"></i> {Strings.logOut}</a></li>

                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 col-4 text-center ml-2">
                    <label className="pointer d-flex">
                      <input type="file" accept="image/*" onChange={(e) => this.onFileChangeImage(e)} />
                      <img className="profileImage" src=
                        {this.state.isLoading ? Images.userDefaultImage : jobseekerData.jobseekerImage !== null && jobseekerData.jobseekerImage !== undefined && jobseekerData.jobseekerImage !== ""
                          && this.state.jobseekerImage.startsWith("https://lh3.googleusercontent.com") || this.state.jobseekerImage.startsWith("https://platform-lookaside.fbsbx.com") || this.state.jobseekerImage.startsWith("https://lh5.googleusercontent.com") || this.state.jobseekerImage.startsWith("https://lh6.googleusercontent.com")
                          ?
                          jobseekerData.jobseekerImage
                          : imageUrl + "jobseeker/getImg/" + jobseekerData.jobseekerImage}
                        alt="profileImage" />
                      <i className="fas fa-pencil-alt bg-success text-white pt-2 fa-2x pb-2 pl-1 pr-1 rounded position-abosule editButtonTop"></i>
                    </label>
                  </div>
                  <div className="col-md-1 col-1"></div>
                  <div className="col-md-3 col-3 creditClass">
                    <CircularProgressbarWithChildren
                      value={jobseekerData.profilePercentage}
                      strokeWidth={3}
                      background
                      styles={buildStyles({
                        pathColor: "#0DD3AF",
                        trailColor: "#d0d0d0d4",
                        backgroundColor: "#FFFFFF ",
                      })} >
                      {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                      <div className="text-center">
                        <h6 className="text-success-gradient text-uppercase pt-3 font-weight-bold fontProgress">{Strings.profileTabs}&nbsp;</h6>
                        {jobseekerData.profilePercentage!=null && jobseekerData.profilePercentage!=""&&
                        jobseekerData.profilePercentage!=undefined ?
                        <h5 className="font-weight-bold text-success-gradient fontProgressPer">{jobseekerData.profilePercentage + "%"}</h5>
                        :null}
                      </div>
                    </CircularProgressbarWithChildren>
                  </div>
                  <div className="col-md-3 col-3 creditClass" data-toggle="modal" data-target="#creditmodal">
                    <CircularProgressbarWithChildren
                      value={jobseekerData.candidateCredits}
                      strokeWidth={3}
                      maxValue={10}
                      background
                      styles={buildStyles({
                        pathColor: "#0DD3AF",
                        trailColor: "#d0d0d0d4",
                        backgroundColor: "#FFFFFF ",
                      })} >
                      {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                      <div className="text-center">
                        <h6 className="text-success-gradient text-uppercase pt-3 font-weight-bold fontProgress">{jobseekerData.candidateCredits === 0 || jobseekerData.candidateCredits === 1 ? Strings.credit : Strings.credits}&nbsp;</h6>
                        <h5 className="font-weight-bold text-success-gradient fontProgressPer">{jobseekerData.candidateCredits}</h5>
                      </div>
                    </CircularProgressbarWithChildren>
                  </div>
                </div>
                <div className="row pb-2 statusDiv">
                  <div className="col-md-5 col-4"></div>
                  <div className="patchPills shadow-sm ml-3">
                    <Link className="text-decoration-none p-3" to={{ pathname: "/appliedjob", state: { isApplied: true } }}  >
                      <div className="text-center font-weight-bold">
                        <h4 className="numberText font-weight-bold mb-0">{jobseekerData.applied}</h4>
                        <span className="statusText ">{Strings.appliedCaps}</span>
                      </div>
                    </Link>                   
                  </div>
                  <div className="patchPills shadow-sm">
                    <Link className="text-decoration-none p-3" to={{ pathname: "/appliedjob", state: { isShortlisted: true } }}>
                      <div className="text-center font-weight-bold">
                        <h4 className="numberText font-weight-bold mb-0">{jobseekerData.shortlisted}</h4>
                        <span className="statusText">{Strings.ShortlistedCaps}</span>
                      </div>
                    </Link>
                  </div>
                  <div className="patchPills shadow-sm">
                    <Link className="text-decoration-none p-3" to={{ pathname: "/appliedjob", state: { isScheduled: true } }}>
                      <div className="text-center font-weight-bold">
                        <h4 className="numberText font-weight-bold mb-0">{jobseekerData.scheduled}</h4>
                        <span className="statusText">{Strings.scheduledCaps}</span>
                      </div>
                    </Link>
                  </div>
                  <div className="patchPills shadow-sm">
                    <Link className="text-decoration-none p-3" to={{ pathname: "/appliedjob", state: { isHired: true } }} >
                      <div className="text-center font-weight-bold">
                        <h4 className="numberText font-weight-bold mb-0">{jobseekerData.hired}</h4>
                        <span className="statusText ">{Strings.Hired}</span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              {/* Logout modal start */}
              <ModalDiv singleBtnModal={true} modalId="logoutmodal" twoBtn={true} crossBtn={true} modalText={Strings.AreYouSureYouWantToLogout} discardText={Strings.cancelCaps} 
               discardSaveText={Strings.yes} buttonClick={() => this.logout()} />
              {/* logout modal ends */}
              {/* For video duration and size alert */}
              <ModalDiv modalReact={true} showModal={this.state.videoAlert} msg={this.state.alertMessage} crossBtnModal={true} btn1Text={Strings.oK} btn1Click={()=>this.hideVideoAlert()}  hideModal={()=>this.hideVideoAlert()}/>
              <ModalDiv modalReact={true} showModal={this.state.showUpdateModel} crossBtnModal={true} msg={Strings.updateProfileText} btn1Text={Strings.oK} btn1Click={()=>this.hideProfileModal()}  hideModal={()=>this.hideProfileModal()}/>
              {/* Cerdit modal start */}
              <div className="modal fade" id="creditmodal">
                <div className="modal-dialog modal-dialog-centered modal-sm">
                  <div className="modal-content rounded modal-shift-center">
                    <div className="modal-header bg-success borderRadiusModal pt-5">
                      <div className="modal-title circleSet">
                        <div className="row ml-1">
                          <img src="../logo192.png" className="position-fixed shadow-sm p-3 mb-5 bg-white rounded-circle mt-md-3 mt-4 ml-1" height="60" width="60" alt="Logo" />
                        </div>
                      </div>
                    </div>
                    <div className="modal-body mt-3">
                      <p className="text-center"> {Strings.HiText} {jobseekerName}, {Strings.youHave} {jobseekerData.candidateCredits} {jobseekerData.candidateCredits === 0 || jobseekerData.candidateCredits === 1 ? Strings.creditInYourAcc : Strings.creditsInYourAcc }</p>
                      <div className="text-center mb-3">
                        <span className="">
                          <WhatsappShareButton
                            url={shareUrl}
                            quote={title}
                            className="Demo__some-network__share-button"
                          >
                            <WhatsappIcon size={34} round />
                          </WhatsappShareButton>
                        </span>
                        <span className="ml-2 text-center shareButtons">
                          <FacebookShareButton
                            url={shareUrl}
                            quote={title}
                            className="Demo__some-network__share-button"
                          >
                            <FacebookIcon size={34} round />
                          </FacebookShareButton>
                        </span>
                        <span className="ml-2 text-center shareButtons">
                          <LinkedinShareButton
                            url={shareUrl}
                            quote={title}
                            // imageurl={imageUrl + this.state.companyImage}
                            className="Demo__some-network__share-button"
                          >
                            <LinkedinIcon size={34} round />
                          </LinkedinShareButton>
                        </span>
                        <span className="ml-2 text-center shareButtons">
                          <EmailShareButton
                            url={shareUrl}
                            subject="Download Jobam and get job opportunities"
                            className="Demo__some-network__share-button">
                            <img src={Images.gmail} width="34" alt="Gmail" className="mr-1"></img>
                          </EmailShareButton>
                        </span>
                      </div>
                      <hr className="mt-0 mb-0" />
                      <div className="text-center pt-1">
                        <a data-dismiss="modal" className="pointer shareMenuButoon"><u>{Strings.oK}</u></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Credit modal ends */}
              <div className="container col-md-6 col-lg-6 col-sm-6 col-12 d-flex pt-3 d-flex justify-content-between">
                <p className="text-uppercase font-weight-bold mb-0 w-75 ml-md-0 ml-2">{Strings.personalDetails}</p>
                <div className="col-md-2 text-right">
                  <Link to='/editprofile' className="text-success-gradient d-inline-flex">
                    <i className="far fa-edit pdEdit mr-1"></i>
                    {Strings.Edit}
                  </Link>
                </div>
              </div>
              <div className="container-fluid bg-light col-md-6 col-lg-6 col-sm-6 col-12">
                <div className="card shadow-sm p-2 rounded">
                  <p className="mb-0 p-1"><i className="fas fa-phone-alt text-success-gradient"></i>
                    <span className="mr-2">&nbsp; +91 {jobseekerData.phone}</span>
                    <span className="badge badge-success rounded p-1 pl-2 pr-2 verifyText">{Strings.Verified}</span>
                  </p>
                  <p className="mb-0 pb-1 pl-1"><i className="fas fa-envelope text-success-gradient"></i> <span>&nbsp;{jobseekerData.email}</span></p>
                  <p className="mb-0 pl-1"><i className="far fa-calendar-check text-success-gradient"></i>{dob !=null ? <span> &nbsp;{moment(dob).format(
                    "DD MMM YYYY"
                  )}</span> : null
                  }
                  </p>
                </div>
              </div>

              {/* ///////////////      RESUME START     /////////////// */}
              <div className="container-fluid bg-light col-md-6 col-lg-6 col-sm-6 col-12 pt-3">
                <div className="row">
                  <div className="col-md-6 col-6 pr-0 pl-0">
                    <div className="row">
                      <p className="text-uppercase font-weight-bold mb-0 p-1 ml-md-3 ml-md-4 ml-4 pl-2 col-md-8 col-6">{Strings.resumeCaps}</p>
                      {(jobseekerData.resumePdfThumb != null && jobseekerData.resumePdfThumb != "" && jobseekerData.resumePdfThumb != undefined) || (jobseekerData.resume != null && jobseekerData.resume != "''" && jobseekerData.resume != undefined) ?
                        <a href="#resumemodal" className="text-success-gradient p-1 mb-0" data-toggle="modal" data-target="#resumemodal"><i className="far fa-edit  mr-1"></i>{Strings.Edit}</a> : null}
                    </div>
                  </div>
                  <div className="col-md-6 col-6 pr-0 pl-0">
                    <div className="row">
                      <p className="text-uppercase font-weight-bold mb-0  ml-md-0  ml-3 pt-1 pb-1 pl-0 pr-0 col-md-7 col-7">{Strings.videoResumeCaps}</p>
                      {jobseekerData.videoResume != null && jobseekerData.videoResume != "" && jobseekerData.videoResume != undefined ?
                        <a href="#videoresumemodal" className="text-success-gradient p-1 mb-0" data-toggle="modal" data-target="#videoresumemodal"><i className="far fa-edit  mr-1"></i>{Strings.Edit}</a> : null}
                    </div>
                  </div>
                </div>
                <div className="row">
                  {(jobseekerData.resume !== null &&
                    jobseekerData.resume !== undefined &&
                    jobseekerData.resume !== "") &&
                    (jobseekerData.resumePdfThumb !== null &&
                      jobseekerData.resumePdfThumb !== "" &&
                      jobseekerData.resumePdfThumb !== undefined) ? (
                      <Link to={{
                        pathname: "/viewresume", state: { resume: imageUrl + jobseekerData.resume }
                      }} className="pointer rounded card ml-3  w-30 resumeDisplay">
                        <img alt="ResumePdf" src={imageUrl + "jobseeker/getThumbnail/" + jobseekerData.resumePdfThumb} height="200" className="p-2" />
                      </Link>
                    )
                    : jobseekerData.resume !== null &&
                      jobseekerData.resume !== "" &&
                      jobseekerData.resume !== undefined ? (
                        <Link to={{
                          pathname: "/viewresume", state: { resume: imageUrl + jobseekerData.resume }
                        }} className="pointer rounded card ml-3  w-30 resumeDisplay">
                          <div className="pointer rounded card w-60 resumeDisplay" data-toggle="modal" data-target="#resumemodal">
                            <img alt="Defaultresume" src={Images.defaultResume} className="p-2 resumeImage" />
                          </div>
                        </Link>
                      )
                      :
                      (<div className="bg-success pointer rounded card ml-3 w-40" data-toggle="modal" data-target="#resumemodal">
                        <div className='text-center display-1 card-body d-inline-flex mt-3'>
                          <i className="fas fa-file-pdf  mx-auto text-white mr-4 resumeWord"></i><br />
                          <i className="fas fa-file-word mx-auto text-white resumeFile"></i>
                        </div>
                        <div className="card-footer border-0">
                          <h6 className="text-white text-center resumeFont font-weight-bold">UPLOAD RESUME</h6>
                        </div>
                      </div>)
                  }
                  {
                    (jobseekerData.videoResume !== null &&
                      jobseekerData.videoResume !== "" &&
                      jobseekerData.videoResume !== undefined) ? (
                        <div className="pointer rounded card ml-3  w-30 resumeDisplay">
                          <ReactPlayer
                            className='react-player' controls={true}
                            url={imageUrl + 'jobseeker/getVideo/' + jobseekerData.videoResume}
                            width='100%'
                            height='250px'
                          />
                        </div>
                      ) : (
                        <div className="bg-success pointer rounded card ml-2 w-40" data-toggle="modal" data-target="#videoresumemodal">
                          <div className="text-center card-body mt-3">
                            <i className="fas fa-file-video mx-auto text-white display-1">
                            </i>
                          </div>
                          {/* ///////////////////// VIDEO RESUME START /////////////// */}
                          <div className="card-footer border-0">
                            <h6 className="text-white text-center font-weight-bold">{Strings.createVideoResume}</h6>
                          </div>
                        </div>
                      )}
                </div>
                <div className="col-md-1 col-1"></div>
                {/* Resume MODAL START */}
                <div className={this.state.showModal ? "modal fade show" : "modal fade"} id="resumemodal">
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content col-md-8 rounded pl-0 pr-0 ml-6">
                    <div className="modal-header bg-success p-4 borderRadiusModal SaveUnsaveHeader">
                        <div className="col-md-10">
                          <div className="row justify-content-center ">
                        <div className="shadow-sm pl-3 pr-3 pt-3 pb-2 mt-2 mb-2 ml-md-5 ml-4 mt-md-3 bg-white rounded-circle">
                            <img src="../logo192.png"  height="27" width="27" alt="Logo" />
                            </div>
                          </div>
                        </div>
                        <button type="button" className="close text-white" data-dismiss="modal">&times;</button>
                      </div>
                      <div className="modal-body mt-3">
                        <div className="row justify-content-center mt-3">
                          <div className="btn  btn-success rounded d-inline-flex pl-3 pr-3">
                            <img alt="Resume Builder" src={Images.resumeBuilder} height="20" className="mr-2 pt-1" />
                            <div onClick={() => this.getDefaultResume()} className="resumeTextBtn">{Strings.ResumeBuilder}</div>
                          </div>
                        </div>
                        <div className="row justify-content-center mb-2 mr-1 ml-1 mt-2">
                          <div className="file btn  btn-success position-relative overflow-hidden rounded pointer">
                          <label className="pointer d-flex mb-0">
                            <img alt="uploadfile" src={Images.uploadFile} height="20" className="mr-2" />
                           <div className="resumeTextBtn">{Strings.uploadFromFile}</div> 
                            <input type="file" name="file" className="file opacity-0 display-4 position-absolute mx-auto pointer"
                              onChange={(e) => this.onFileChangeHandler(e)} accept=".pdf,.docx,.doc" />
                              </label>
 
                          </div>
                        </div>
                        {(jobseekerData.resume !== null &&
                          jobseekerData.resume !== undefined &&
                          jobseekerData.resume !== "") ||
                          (jobseekerData.resumePdfThumb !== null &&
                            jobseekerData.resumePdfThumb !== "" &&
                            jobseekerData.resumePdfThumb !== undefined) ? (
                            <div className="row justify-content-center">
                              <div className="btn  btn-success rounded d-inline-flex pl-3 pr-3">
                                <img alt="Deleteicon" src={Images.deleteIcon} height="25" className="mr-2" />
                                <div onClick={() => this.deletePdfDocResume()}>{Strings.deleteResume}</div>
                              </div>
                            </div>) : null}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Resume MODAL ENDS */}

                {/* Video resume modal start */}
                <div className={this.state.videoResumeModal ? "modal  show" : "modal fade"} id="videoresumemodal">
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content col-md-7 rounded pl-0 pr-0">
                      <div className="modal-header bg-success p-4 borderRadiusModal SaveUnsaveHeader">
                        <div className="col-md-10">
                          <div className="row justify-content-center ">
                        <div className="shadow-sm pl-3 pr-3 pt-3 pb-2 mt-2 mb-2 ml-md-5 ml-4 mt-md-3 bg-white rounded-circle">
                            <img src="../logo192.png"  height="27" width="27" alt="Logo" />
                            </div>
                          </div>
                        </div>
                        <button type="button" className="close text-white" data-dismiss="modal">&times;</button>
                      </div>
                      <div className="modal-body mt-4">
                        <div className="row justify-content-center mb-2 mr-1 ml-1 mt-2">
                          <div className="file btn  btn-success position-relative overflow-hidden rounded pointer">
                            <label className="pointer d-flex mb-0">
                              <img alt="uploadfile" src={Images.uploadFile} height="20" className="mr-1" />{Strings.uploadVideo}
                            <input type="file" accept="video/*" name="file"
                                onChange={(e) => this.previewVideo(e)} />
                            </label>
                          </div>
                        </div>
                        {jobseekerData.videoResume !== null &&
                          jobseekerData.videoResume !== undefined &&
                          jobseekerData.videoResume !== ""
                          ? (
                            <div className="row justify-content-center">
                              <div className="btn  btn-success rounded d-inline-flex pl-3 pr-3">
                                <img  src={Images.deleteIcon} alt="Deleteicon" height="25" className="mr-2" />
                                <div onClick={() => this.deleteVideoReume()}>{Strings.deleteResume}</div>
                              </div>
                            </div>) : null}
                      </div>
                    </div>
                  </div>
                </div>
                {/* video resume modal start */}
                {/* Modal for ressume preview */}
                <Modal show={this.state.show} centered>
                  <div className="modal-body">
                    <center>
                      <ReactPlayer
                        className='react-player' controls={true}
                        url={this.state.videoUrl}
                        width='100%'
                        height='250px'
                      />
                      {/* <div className="mt-4 mb-2">{this.state.msg}</div> */}
                    </center>
                    <div className="tab-content" id="pills-tabContent">
                      <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-2 col-1 col-sm-2"></div>
                            <div type="submit" className="btn btn-success col-md-4 mt-2 col-sm-4 col-4 roundedModal"
                              data-dismiss="modal-header" onClick={this.hideModal} >
                              CANCEL
                            </div>
                            <div className="col-md-1 col-1 col-sm-1"></div>

                            <div type="submit" className="btn btn-success col-md-4 mt-2 col-sm-4 col-4 roundedModal"
                              data-dismiss="modal-header" onClick={this.videoResume} >
                              UPLOAD
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
                {/* Modal for resume preview */}
              </div>
              <div className="container bg-light col-md-6 col-lg-6 col-sm-6 col-12 d-flex pt-3">
                <p className="text-uppercase w-75 font-weight-bold mb-0 ml-md-0 pl-2">{Strings.professionalExperience}</p>
                {jobseekerData.experience != null && jobseekerData.experience != '' && jobseekerData.experience != undefined ? <Link to='/workexperiencetab' className="text-success-gradient col-md-3 col-3 editExp text-right"><i className="far fa-edit  mr-1"></i>{Strings.Edit}</Link> : null}
              </div>
              <div className="container-fluid bg-light col-md-6 col-lg-6 col-sm-6 col-12 pt-1">
                <div className="card rounded pb-0 pt-0 pl-2 shadow-sm ">
                  {(jobseekerData.organizationName != null &&
                    jobseekerData.organizationName != "" &&
                    jobseekerData.organizationName != undefined) ||
                    (jobseekerData.jobPosition != null &&
                      jobseekerData.jobPosition != "" &&
                      jobseekerData.jobPosition != undefined) ||
                    (jobseekerData.jobIndustry != null &&
                      jobseekerData.jobIndustry != "" &&
                      jobseekerData.jobIndustry != undefined)
                    ? (
                      <div>
                        <p className="mb-1 p-1"><i className="fa fa-city text-success-gradient" aria-hidden="true"></i>
                          <span className="ml-1">&nbsp;
                        {jobseekerData.organizationName != null && jobseekerData.organizationName != '' && jobseekerData.organizationName != undefined ? jobseekerData.organizationName : "NA"}
                          </span> <span className="badge badge-success"></span>
                        </p>
                        <p className="mb-1 pl-1"><i className="fa fa-user text-success-gradient" aria-hidden="true"></i><span className="ml-1">&nbsp;{jobseekerData.jobPosition != null && jobseekerData.jobPosition != '' && jobseekerData.jobPosition != undefined ? jobseekerData.jobPosition : "NA"}</span></p>
                        <p className="mb-1 pl-1"><i className="far fa-calendar-check text-success-gradient "></i> <span className="ml-1"> &nbsp;{jobseekerData.jobStartDate != null && jobseekerData.jobStartDate != '' && jobseekerData.jobStartDate != undefined ? moment(jobseekerData.jobStartDate).format(
                          "DD MMM YYYY") : "NA"}</span> {jobseekerData.currentlyWorking ? <span className="bg-success text-white pl-2 pr-2 pt-1 pb-1 ml-2 curWork">Currently Working</span> :
                            <span> {jobseekerData.jobEndDate != null && jobseekerData.jobEndDate != '' && jobseekerData.jobEndDate != undefined ? "To " + moment(jobseekerData.jobEndDateOnProfile).format(
                              "DD MMM YYYY") : null}</span>}
                        </p>
                        <div>
                          <p className="mb-1 pl-1"><i className="fa fa-industry text-success-gradient"></i> <span className="ml-1"> {jobseekerData.jobIndustry != null && jobseekerData.jobIndustry != '' && jobseekerData.jobIndustry != undefined ? jobseekerData.jobIndustry : "NA"}</span>
                          </p></div>
                      </div>) : (jobseekerData.profileSummary != null &&
                        jobseekerData.profileSummary != "" &&
                        jobseekerData.profileSummary != undefined) ?
                      (<div>
                        <p className="mb-1"><i className="far fa-address-card text-success-gradient ml-2" aria-hidden="true"></i>
                          <span className="ml-1">&nbsp;{jobseekerData.profileSummary != null & jobseekerData.profileSummary != '' && jobseekerData.profileSummary != undefined ? jobseekerData.profileSummary : "NA"}</span> <span className="badge badge-success"></span>
                        </p>
                        <p className="mb-2"><i className="fa fa-user-cog  text-success-gradient ml-2" aria-hidden="true"></i> <span>&nbsp;{jobseekerData.skills != null & jobseekerData.skills != '' && jobseekerData.skills != undefined ? jobseekerData.skills : "NA"}</span></p>
                      </div>) :
                      <button type="button" onClick={this.handleExperience} className="btn btn-outline text-success-gradient">
                        <img src={Images.addIcon} alt="addIcon" className="plus-icon" />{Strings.AddExperience}</button>}
                </div>
              </div>
              {/* Edit EXPERIENCE MODAL ENDS */}
              {/* Edit new EXPERIENCE MODAL START */}
              {/* Edit New EXPERIENCE MODAL ENDS */}
              <div className="container bg-light col-md-6 col-lg-6 col-sm-6 col-12 d-flex pt-3">
                <p className="text-uppercase w-75 font-weight-bold mb-0 ml-md-0 pl-2">{Strings.educationCaps}</p>
                {(this.state.examnameEd != null != null &&
                  this.state.examnameEd != null != "" &&
                  this.state.examnameEd != null != undefined)
                  ||
                  (this.state.passingYearEd != null &&
                    this.state.passingYearEd != "" &&
                    this.state.passingYearEd != undefined)
                  ||
                  (this.state.instituteEd != null &&
                    this.state.instituteEd != "" &&
                    this.state.instituteEd != undefined) ||
                  (this.state.percentageEd != null &&
                    this.state.percentageEd != "" &&
                    this.state.percentageEd != undefined)
                  ? (
                    <Link to='/educationdetailtab' className="text-success-gradient col-md-3 col-3 editExp text-right"><i className="far fa-edit  mr-1"></i>{Strings.Edit}</Link>
                  )
                  : null}
              </div>
              <div className="container-fluid bg-light col-md-6 col-lg-6 col-sm-6 col-12 pt-1">
                <div className="card shadow-sm rounded">
                  {(this.state.examnameEd != null != null &&
                    this.state.examnameEd != null != "" &&
                    this.state.examnameEd != null != undefined)
                    ||
                    (this.state.passingYearEd != null &&
                      this.state.passingYearEd != "" &&
                      this.state.passingYearEd != undefined)
                    ||
                    (this.state.instituteEd != null &&
                      this.state.instituteEd != "" &&
                      this.state.instituteEd != undefined) ||
                    (this.state.percentageEd != null &&
                      this.state.percentageEd != "" &&
                      this.state.percentageEd != undefined)
                    ? (
                      <div>
                        <div className="bg-success profile-bg-success">
                          <p className="text-center text-white text-uppercase align-middle pt-1">
                            {jobseekerData.highestDegree[0].examName != null && jobseekerData.highestDegree[0].examName != '' && jobseekerData.highestDegree[0].examName != undefined ? jobseekerData.highestDegree[0].examName : "NA"}
                          </p>
                        </div>
                        <div className="pt-2 pl-3 pr-3 pb-1">
                          <p className="mb-1"><i className="fa fa-university text-success-gradient" aria-hidden="true"></i>
                            <span className="ml-1">&nbsp;{jobseekerData.highestDegree[2].institute != null && jobseekerData.highestDegree[2].institute != '' && jobseekerData.highestDegree[2].institute != undefined ? jobseekerData.highestDegree[2].institute : "NA"}</span> <span className="badge badge-success"></span>
                          </p>
                          <p className="mb-1"><i className="fas fa-percentage text-success-gradient"></i> <span className="ml-1">&nbsp;{jobseekerData.highestDegree[3].percentage != null && jobseekerData.highestDegree[3].percentage != '' && jobseekerData.highestDegree[3].percentage != undefined ? jobseekerData.highestDegree[3].percentage : "NA"}</span></p>

                          <p className="mb-1"><i className="far fa-calendar-check text-success-gradient"></i><span className="ml-1">&nbsp;{jobseekerData.highestDegree[1].passingYear != null && jobseekerData.highestDegree[1].passingYear != '' && jobseekerData.highestDegree[1].passingYear != undefined ? jobseekerData.highestDegree[1].passingYear : "NA"}</span></p></div>
                      </div>
                    )
                    :
                    <button type="button" className="btn btn-outline text-success-gradient mt-0 pt-1" onClick={this.handleEducation}>
                      <img src={Images.addIcon} alt="addIcon" className="plus-icon" />{Strings.addEducation}</button>
                  }

                </div>
              </div>
              <div className="container bg-light col-md-6 col-lg-6 col-sm-6 col-12 d-flex pt-3">
                <p className="text-uppercase w-75 font-weight-bold mb-0 ml-md-0 pl-2">{Strings.aboutMe}</p>
                {jobseekerData.aboutMe != null && jobseekerData.aboutMe != '' && jobseekerData.aboutMe != undefined ? <Link to='/aboutme' className="text-success-gradient col-md-3 col-3 editExp text-right"><i className="far fa-edit  mr-1"></i>{Strings.Edit}</Link> : null}
              </div>
              <div className="container-fluid bg-light col-md-6 col-lg-6 col-sm-6 col-12 pb-2">
                <div className="card shadow-sm mb-3 rounded">
                  {jobseekerData.aboutMe != null && jobseekerData.aboutMe != "" && jobseekerData.aboutMe != undefined ?
                    <div >
                      <p className="p-1 mb-0 ml-2">{jobseekerData.aboutMe}</p>
                    </div>
                    :
                    <button type="button" id="btnDiv" className="btn btn-outline text-success-gradient" onClick={this.handleAboutMe}>
                      <img src={Images.addIcon} alt="addIcon" className="plus-icon" />{Strings.aboutMe}</button>
                  }
                  {/* About Me Modal */}
                </div>
              </div>
              <div className="container-fluid bg-light col-md-6 col-lg-6 col-sm-6 col-12 No-Pading">
                <Route component={Footer} className="d-none d-md-block d-lg-block d-sm-block d-xl-block" />
              </div>
              <div className="nabSpace"></div>

            </div>
          </div>
          <WebBottomNav homeText={Strings.homeTabs} myJobText={Strings.myJobTabs} interviewText={Strings.interviewTabs} profileActive={true} profileData={this.state.isUserLoggedIn  ? jobseekerName : Strings.loginTabs} />
         
      </LoadingOverlay>
    );
  }
}


const mapStateToProps = state => {
  return {
    jobseekerData: state.profileReducer.jobseekerData,
    jsDataLoading: state.profileReducer.jsDataLoading,
    jsDataError: state.profileReducer.jsDataError,
    resumeData : state.profileReducer.resumeData,
    resumeLoading : state.profileReducer.resumeLoading,
    resumeDataError: state.profileReducer.resumeDataError,
    resumeDeleteData : state.profileReducer.resumeDeleteData,
    resumeDeleteLoading : state.profileReducer.resumeDeleteLoading,
    resumeDeleteDataError: state.profileReducer.resumeDeleteDataError,
    videoDeleteLoading : state.profileReducer.videoDeleteLoading,
    videoDeleteData : state.profileReducer.videoDeleteData,
    videoDeleteDataError: state.profileReducer.videoDeleteDataError
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getJobseekerProfile: jobseekerId => dispatch(getJobseekerProfile(jobseekerId)),
    getResume: jobseekerId => dispatch(getResume(jobseekerId)),
    deleteResume: jobseekerId => dispatch(deleteResume(jobseekerId)),
    deleteVideoResume: jobseekerId => dispatch(deleteVideoResume(jobseekerId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(profile);
