import helpers from "../config";
export const  BLOGS_LOADING = 'BLOGS_LOADING';
export const  SHOW_BLOGS_DATA = 'SHOW_BLOGS_DATA';
export const  BLOGS_FAILED = 'BLOGS_FAILED';

export const  BLOG_DETAIL_LOADING = 'BLOG_DETAIL_LOADING';
export const  SHOW_BLOG_DETAIL = 'SHOW_BLOG_DETAIL';
export const  BLOG_DETAIL_FAILED = 'BLOG_DETAIL_FAILED';

 export const getBlogs = () => {
    return (dispatch) => {
        dispatch({type: BLOGS_LOADING})
        helpers.getServerUrl().then(url =>   {
         var url = url.serverUrl
        fetch( url + "/jobseeker/getAllBlogPostsForJs", {
         method: "GET",
           headers: {
             "Content-Type": "application/json"
           },  
        })
          .then(response => response.json())
          .then(responseData => {     
            dispatch({ 
              type: SHOW_BLOGS_DATA,
              payload: responseData
            })
          }) 
          .catch(err => {
            dispatch({
              type: BLOGS_FAILED,
              payload: err
            })
            console.log('error', err)
          })
        })
      }
    }

    export const getBlogDetail = (blogId) => {
      var blogId = localStorage.getItem("blogId")
       return (dispatch) => {
           dispatch({type: BLOG_DETAIL_LOADING})
           helpers.getServerUrl().then(url =>   {
            var url = url.serverUrl
           fetch( url + "/jobseeker/getBlogPostById", {
            method: "POST",
              headers: {
                "Content-Type": "application/json"
              },
              body : JSON.stringify({
                blogId: blogId
            })
           })
             .then(response => response.json())
             .then(responseData => {
               dispatch({ 
                 type: SHOW_BLOG_DETAIL,
                 payload: responseData.blogDetails
               })
             })
             .catch(err => {
               dispatch({
                 type: BLOG_DETAIL_FAILED,
                 payload: err
               })
               console.log('error', err)
             })
           })
         }
       }

