import React, { Component } from "react";
import Webheader from "../components/webheader";
import helpers from "../config";
import '../_style/workExperienceTab.css';
import Fresher from "../screens/fresher";
import Strings from "../utilities/stringlanguage";
import Experience from "../screens/experience";
import { Route } from 'react-router-dom';
import Footer from "../components/footer";
var url;
var imageUrl;
var jobseekerId;
var selectedLang;
export default class WorkExperienceTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }
  async componentDidMount() {
    let apiUrl = await helpers.getServerUrl()
    url = apiUrl.serverUrl
    imageUrl = apiUrl.imageUrl
    selectedLang = localStorage.getItem("selectedLang")
    if (selectedLang !== null && selectedLang !== undefined && selectedLang !== "") {
      Strings.setLanguage(selectedLang);
    }
    this.state.isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
    jobseekerId = localStorage.getItem("jobseekerId");
    this.getJobseekerProfile();
    window.scrollTo(0, 0);

  }
   getJobseekerProfile= async(e) => {
    try {
      let response = await fetch(url + "/admin/getJsDetailById", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          jobseekerId: jobseekerId,
          loginFrom: "Web"
        })
      });
      response = await response.json();
      if (response !== null) {
        let responseData = response;
        this.setState({
          jobseekerName: responseData.jobseekerName,
          profileSummary: responseData.profileSummary,
          profileSummaryOnProfile: responseData.profileSummary,
          professionalSummary: responseData.professionalSummary,
          aboutMe: responseData.aboutMe,
          aboutmeProfile: responseData.aboutMe,
          loading: false,
        });
      }
    } catch (err) {
    }

  }
  render() {
    return (
      <div>
        <div className="container col-md-6 No-Pading col-lg-6 col-sm-6 col-12">
          <Webheader backLink="/profile" pageName={Strings.workExperienceCaps}/>
          {this.state.professionalSummary == null || this.state.professionalSummary == "" || this.state.professionalSummary == undefined ?
            <div className="pt-0">
              <ul className="nav nav-pills-tab nav-fill border-bottom  pt-0 fixedtop w-100">
                <li className="nav-item">
                  <a className="nav-link active font-weight-bold freExpClass"  defaultactivekey="pg" data-toggle="pill" href="#fresher">{Strings.imFresher}</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link font-weight-bold freExpClass"  data-toggle="pill" href="#experience">{Strings.imExperienced}</a>
                </li>
              </ul>
              <div className="col-md-12">
                <div className="tab-content">
                  <div className="tab-pane container fade show active" id="fresher">
                    <div>
                      <Fresher />
                    </div>
                  </div>
                  <div className="tab-pane container fade" id="experience">
                    <div>
                      <Experience />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            <div>
              <ul className="nav nav-pills-tab nav-fill border-bottom  ptop-5 fixedtop1 w-100">
                <li className="nav-item">
                  <a className="nav-link  font-weight-bold freExpClass"  defaultactivekey="pg" data-toggle="pill" href="#fresher">{Strings.imFresher}</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link active font-weight-bold freExpClass"  data-toggle="pill" href="#experience">{Strings.imExperienced}</a>
                </li>
              </ul>
              <div className="col-md-12">
                <div className="tab-content">
                  <div className="tab-pane container fade " id="fresher">
                    <div>
                      <Fresher />
                    </div>
                  </div>
                  <div className="tab-pane container fade show active" id="experience">
                    <div>
                      <Experience />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
        <div className="container-fluid bg-light col-md-6 col-lg-6 col-sm-6 col-12 No-Pading">
          <Route component={Footer} className="d-none d-md-block d-lg-block d-sm-block d-xl-block" />
        </div>
      </div>
    );
  }
}
