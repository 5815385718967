import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Loading from "../components/loadingBar";
import $ from 'jquery';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import { Link, NavLink } from "react-router-dom";
import Footer from "../components/footer";
import Strings from "../utilities/stringlanguage";
import CardComponent from "../components/cardComponent";
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import SearchIcon from '@material-ui/icons/Search';
import "../_style/jobList.css";
import { Offline, Online } from "react-detect-offline";
import Webheader from "../components/webheader";
import { allJobList, getTopPicks, getCities, getFiltersWithIcons, updateTokenApi, getFilterSearch } from "../actions/jobListAction";
import { connect } from "react-redux";
import { Modal, Button, ThemeProvider } from "react-bootstrap";
import swal from "sweetalert";
import socketIOClient from "socket.io-client";
import InternetConnect from "../components/internetConnect";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import WebBottomNav from "../components/webBottomNavigation";
import Images from "../Images";
import helpers from "../config";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import LoadingOverlay from 'react-loading-overlay';
import { Route } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import * as endPointUrl from '../utilities/endpoint';
import ModalDiv from '../components/modal';
import Navbar from '../components/navBar';
var myMetaData;
var base64imageNew;
var isSearched = false;
var iForSave = 0;
var temp = [];
var profile;
var fakeIcon;
var fakeIconTopPicks;
var cities;
var fakeId;
let allJobProfile = [];
var filterData = [];
var jobsSearch = [];
var choosenCity = "";
var topPicked = [];
var url;
var imageUrl;
var City = "City";
var previousBody = {};
var searchCity = '';
var body = {
  jobProfile: "",
  jobType: "",
  experience: "",
  experienceLevel: [0, 1],
  salary: [0, 100000],
  qualification: "",
  jobseekerId: "",
  city: "",
  radius: 30,
  isFromList: true,
  applyClicked: false,
  // isLoading: true
};
var allCities = [];
var jobseekerName;
var jobseekerId;
var tokenId;
var selectedLang;
var isUserLoggedIn;
var currentLat;
var currentLng;
const endpoint = endPointUrl.END_POINT_URL;

class JobList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      fakeIcon: false,
      jobId: "",
      selectedOption: "",
      appliedFilter: false,
      city: localStorage.getItem("city"),
      isFilterSelected: true,
      limit: Number(localStorage.getItem("limit")),
      selectedValue: null,
      isFilterOpen: false,
      visible: 5,
      locationRadius: 30,
      salary: [0, 100000],
      experienceLevel: [0, 1],
      isSearch: false,
      showAllJobsText : false,
      showChatPopup:false,
      playStoreLink:false,
      allJobsData: [],
      jobsSearchData: [],
      isModalOpen: false,
      selectedCity: "",
      isConnected : true,  
      resetFilterValue: false,
      citySearch: false,    
      loadMoreButton: true,
      getJobTypeList: [
        {
          type: "Part Time",
          isClicked: false,
          jobTypeIcon: "fas fa-user-clock"
        },
        { type: "Full Time", isClicked: false, jobTypeIcon: "fas fa-user-tie fullTypeIcon" },
        { type: "Internship", isClicked: false, jobTypeIcon: "fas fa-business-time" }

      ],
      expData: [
        { exp: "Fresher", isClicked: false, expIcon: "fa fa-user" },
        {
          exp: "Experience",
          isClicked: false,
          expIcon: "fas fa-user-graduate"
        }
      ],
      eduData: [
        { std: "10", name: "10TH", isClicked: false },
        { std: "12", name: "12TH", isClicked: false },
        { std: "graduate",name: "UG",  isClicked: false },
        { std: "post graduate",name: "PG",  isClicked: false }
      ],
      pathMap: [
        "/joblist",
        "/myjobs",
        "/interviewtips",
        "/profile",
        "/register"
      ],

      notiCount: 0,
    };
  }

  async componentDidMount() {
    this.abortController = new AbortController();
    let apiUrl = await helpers.getServerUrl()
    url = apiUrl.serverUrl
    imageUrl = apiUrl.imageUrl
    // window.scrollTo(0, 0);
    // window.addEventListener('online', this.updateOnlineStatus);
    // window.addEventListener('offline', this.updateOfflineStatus); 
    iForSave = 0;
    jobseekerId = localStorage.getItem("jobseekerId");
    tokenId = localStorage.getItem("tokenId");
    this.props.updateTokenApi()
    this.jobseekerVerify()
    if(localStorage.getItem("limit")==null && localStorage.getItem("limit") == undefined){
      this.setState({limit:10})
    }
    // this.showPlaystoreLink()
    selectedLang = localStorage.getItem("selectedLang")
    if (selectedLang != null && selectedLang != undefined && selectedLang != "") {
      Strings.setLanguage(selectedLang);
    }
    this.getMyLocation();
    this.props.getFiltersWithIcons();

    if ((localStorage.getItem("searchText") == "" || localStorage.getItem("searchText") == undefined) && (localStorage.getItem("city") == "" || localStorage.getItem("city") == undefined) && (localStorage.getItem("jobProfile") == undefined || localStorage.getItem("jobProfile") == "") && (localStorage.getItem("jobtype") == undefined || localStorage.getItem("jobtype") == "") && (localStorage.getItem("experience") == undefined || localStorage.getItem("experience") == "" && (localStorage.getItem("qualification") == undefined || localStorage.getItem("qualification") == "" ))
    ) {
      this.props.allJobList(1);
      this.props.getTopPicks(body);
      this.closeFilters();
    }
    
    isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
    if (isUserLoggedIn) {
      this.getNotiCount();
    }
    else{
      localStorage.setItem("selectedLang", "en")
    }
    if (this.props.jobsData.length <= 0 || this.props.jobsData.length == undefined) {
    
      this.props.getCities();
      choosenCity = localStorage.getItem("city");
      if (this.props.location.state !== undefined && this.props.location.state !== null && this.props.location.state !== '') {
        if (this.props.location.state.cityName !== undefined) {
          searchCity = this.props.location.state.cityName;
        }
        if (searchCity != '' && searchCity != null) {
          searchCity = searchCity.replace('?', '');
          searchCity = searchCity.trim();
          choosenCity = null;
          this.getCityValue(searchCity)
        }
      } else {
        let x = window.location.href;
        let s = x.slice(x.lastIndexOf('/') + 1);
        if (s.includes("jobs-in") == true) {
          let get_city = s.slice(s.lastIndexOf('-') + 1);
          window.history.replaceState({ search: get_city }, { cityName: get_city }, "/joblist?jobs-in-" + get_city);
          this.setState({ city: get_city });
        } else {
          window.history.replaceState({ search: localStorage.getItem("city") }, { cityName: localStorage.getItem("city") }, "/joblist");
        }
      }
      
      this.setState({ selectedOption: localStorage.getItem("jobProfile") });
      var jobTypeFilter = localStorage.getItem("jobtype")
      var experienceFilter = localStorage.getItem("experience");
      var qualificationFilter = localStorage.getItem("qualification");
      var jobProfileFilter = localStorage.getItem("jobProfile");
      var expLevelFilter= localStorage.getItem("experienceLevel");
      var salaryFirstFilter= localStorage.getItem("salaryNew");
      salaryFirstFilter=salaryFirstFilter.split(",")
      expLevelFilter=expLevelFilter.split(",")
      this.setState({salary: salaryFirstFilter,experienceLevel: expLevelFilter})
      // eslint-disable-next-line react/no-direct-mutation-state
      jobseekerId = localStorage.getItem("jobseekerId")
      // jobseekerName = localStorage.getItem("givenName")
      if(localStorage.getItem("searchText") !="" || localStorage.getItem("city") !="" ){
        body.searchText = localStorage.getItem("searchText");
        body.city = localStorage.getItem("city");
        this.filterSearch();
        this.setState({ appliedFilter: false });
      }
      if((jobTypeFilter != undefined && jobTypeFilter != "") || (experienceFilter != undefined && experienceFilter != "") || (qualificationFilter != undefined && qualificationFilter != "") || (jobProfileFilter  != undefined && jobProfileFilter  != "") || (expLevelFilter != "0,1") || (salaryFirstFilter != "0,100000"))
      {
        this.setState({ appliedFilter: true });
            body.jobType = jobTypeFilter;
            body.jobProfile = jobProfileFilter;
            body.experience = experienceFilter;
            body.qualification = qualificationFilter;
            body.experienceLevel= expLevelFilter;
            body.salary= salaryFirstFilter;
            this.filterSearch();
      }
    }
    else {
      this.props.getCities();
      if (localStorage.getItem("searchText") != "" && localStorage.getItem("searchText") != ""
        && localStorage.getItem("searchText") != undefined) {
        body.searchText = localStorage.getItem("jobProfile");
        body.jobProfile = localStorage.getItem("jobProfile");
        body.jobType = localStorage.getItem("jobtype");
        body.experience = localStorage.getItem("experience");
        body.qualification = localStorage.getItem("qualification");
        body.experienceLevel = localStorage.getItem("experiencelevel");
        body.salary[0] = localStorage.getItem("salary[0]");
        body.salary[1] = localStorage.getItem("salary[1]");
        body.radius = localStorage.getItem("radius");
        this.filterSearch();
      }
      if (localStorage.getItem("city") !== null && localStorage.getItem("city") !== undefined && localStorage.getItem("city") !== "") {
        this.getCityValue(localStorage.getItem("city"));
      }
    }
    $('body').removeClass('modal-open')
    $('.modal-backdrop').remove()
  }

  getMyLocation() {
    navigator.geolocation.getCurrentPosition(function (position) {
      currentLat = position.coords.latitude.toFixed(4);
      currentLng = position.coords.longitude.toFixed(4);
      localStorage.setItem("currentLat", currentLat);
      localStorage.setItem("currentLong", currentLng);
    });
  }

  hideLoginModal = (e) => {
    this.setState({ showLoginPopup: false });
  };
  hideModal = (e) => {
    this.setState({ showLoginPopup: false });
  };

  saveLoginModal = (e) => {
    this.props.history.push("/register")
 };

  showLoginModal = (e) => {
    this.setState({
      showLoginPopup: true
    });
  };
  showChatModal = (e) => {
    this.setState({
      showChatPopup: true
    });
  };
  hideChatModal = (e) => {
    this.setState({ showChatPopup: false });
  };
  
  loadMoreData = () => {
    if (this.state.limit < 200) {
      var addPoint= Number(this.state.limit+10)
      localStorage.setItem("limit",addPoint)
      this.setState({ limit: addPoint});
      iForSave = 0;
    }
  }

  resetFilter = () => {
    iForSave = 0;
    this.setState({ appliedFilter: false, isFilterSelected: false, resetFilterValue: true });
    this.setState({
      salary: [0, 100000], experienceLevel: [0, 1],
      locationRadius: 30,
      expIndex: 0,
      getJobTypeList: [
        {
          type: "Part Time",
          isClicked: false,
          jobTypeIcon: "fas fa-user-clock"
        },
        { type: "Full Time", isClicked: false, jobTypeIcon: "fas fa-user-tie fullTypeIcon" },
        { type: "Internship", isClicked: false, jobTypeIcon: "fas fa-business-time" }
      ],
      expData: [
        { exp: "Fresher", isClicked: false, expIcon: "fa fa-user" },
        {
          exp: "Experience",
          isClicked: false,
          expIcon: "fas fa-user-graduate"
        }
      ],
      eduData: [
        { std: "10", name: "10TH",  isClicked: false },
        { std: "12", name: "12TH", isClicked: false },
        { std: "graduate", name: "UG", isClicked: false },
        { std: "post graduate", name: "PG",  isClicked: false }
      ]
    });
    this.props.getFiltersWithIcons();
    body = {
      jobProfile: "",
      jobType: "",
      experience: "",
      experienceLevel: [0, 1],
      salary: "",
      radius: 30,
      qualification: "",
      jobseekerId: "",
      isFromList: true
    };
    localStorage.setItem("jobProfile", body.jobProfile);
    localStorage.setItem("jobtype", body.jobType);
    localStorage.setItem("experience", body.experience);
    localStorage.setItem("experienceLevel", body.experienceLevel);
    localStorage.setItem("qualification", body.qualification);
    localStorage.setItem("salary[0]", body.salary[0]);
    localStorage.setItem("salary[1]", body.salary[1]);
    localStorage.setItem("salaryNew", body.salary);
    localStorage.setItem("radius", body.radius);
    // window.location.reload();
  }
  
  onBackButtonEvent(event) {
    event.preventDefault();
    window.history.replaceState(null, null, "/joblist");
    window.location.reload();
    // the user shouldn’t be able to move backward or forward
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.location.state !=undefined && nextProps.location.state){
    if (nextProps.location.state != this.props.location.state) {
       let cityNameFromFooter = nextProps.location.state.cityName;
      this.getCityValue(cityNameFromFooter);
    }
  }
  }

  
  jobseekerVerify = async () => {
    try {
      let response = await fetch(url + "/admin/getAppSetDetails1", {
        method: "POST",
        headers: {
          "content-Type": "application/json"
        },
        body: JSON.stringify({
          jobseekerId: jobseekerId,
          tokenId: tokenId,
          loginFrom: "Web"
        })
      })
      response = await response.json()
      let ismobileVerified = response.ismobileVerified
      if (isUserLoggedIn && ismobileVerified !== true) {
        this.updateProfileModal()
      }

    } catch (err) {
    }
  }

  updateProfileModal = e => {
    this.setState({
      showUpdateModel: true
    });
  };
  hideProfileModal = e => {
    this.props.history.push('/updateProfile');
    window.location.reload();
    this.setState({ showUpdateModel: false });
  };


  handleChange = (value) => {
    this.setState({ value });
  };

  passJobId = () => {
    this.props.jobDetailApi();
  }
  loadMoreFun = () => {
    return (
      swal(" load more")
    )
  }

  updateOnlineStatus = () => {
    this.setState({isConnected : true});
    }
    
    updateOfflineStatus = () => {
      this.setState({isConnected : false});
    }
  savedJobs = async (item) => {
    iForSave = 0;
    var i = 0;

    if (jobseekerId != null && jobseekerId != "" && jobseekerId != undefined) {
      body.jobseekerId = jobseekerId;
    }
    if (jobseekerId != null && jobseekerId != undefined) {
      fakeIconTopPicks = true;
      this.setState({ fakeId: item.jobId });
      try {
        let responseData = await fetch(url + "/jobs/addJobsToFavourites", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            jobseekerId: jobseekerId,
            jobId: item.jobId,
            isSaved: item.isSaved
          })
        })
        responseData = await responseData.json();
        if (responseData.success) {
          fakeIconTopPicks = false;
          this.props.getTopPicks(body);
        }

      } catch (err) {
      }
    } else {
      this.showLoginModal()
    }

  }

  savedJobsforAllJobs = async (item, state) => {
    if (jobseekerId != null && jobseekerId != "" && jobseekerId != undefined) {
      body.jobseekerId = jobseekerId;
    }
    if (jobseekerId != null && jobseekerId != undefined) {
      try {
        fakeIcon = true;
        iForSave = 0;
        var i = 0;

        fakeId = item.jobId;
        let responseData = await fetch(url + "/jobs/addJobsToFavourites", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            jobseekerId: jobseekerId,
            jobId: item.jobId,
            isSaved: item.isSave
          })
        })
        responseData = await responseData.json();
        iForSave = 0;
        fakeIcon = false;
        if (this.state.data == "alljobdata") {
          var x = [];
          x = this.state.allJobsData;
          const newIds = this.state.allJobsData.slice(); //copy the array
          x.forEach(jobsPick => {
            if (jobsPick.jobId == item.jobId) {
              if (responseData.msg == "Job saved successfully") {
                newIds[i]['isSaved'] = true; //execute the manipulations
              } else {
                newIds[i]['isSaved'] = false; //execute the manipulations
              }
            }
            i++;
          })

          this.setState({ allJobsData: newIds }) //set the new state
        }
        if (this.state.data == "jobsearch") {

          var y = [];
          y = this.state.jobsSearchData;
          const jobSeachIds = this.state.jobsSearchData.slice() //copy the array
          y.forEach(jobSearchdata => {
            if (jobSearchdata.jobId == item.jobId) {
              if (responseData.msg == "Job saved successfully") {
                jobSeachIds[i]['isSaved'] = true; //execute the manipulations
              } else {
                jobSeachIds[i]['isSaved'] = false; //execute the manipulations
              }
            }
            i++;
          })
          this.setState({ jobsSearchData: jobSeachIds })
        }
      } catch (err) {
      }
    } else {
      this.showLoginModal()
    }
  }

  getNotiCount = async () => {
    try {
      let response = await fetch(url + "/jobs/getNotiCountForJs", {
        method: "POST",
        headers: {
          "content-Type": "application/json"
        },
        body: JSON.stringify({
          jobseekerId: jobseekerId,
        })
      })
      response = await response.json()
      this.setState({ notiCount: response.notiCount })
    } catch (err) {
    }
  }
  refreshPage = () => {
    window.location.reload()
  }

  closeFilters = () => {
    if ((body.jobProfile == "" || body.jobProfile == null) && (body.jobType == "" || body.jobType == null) && (body.experience == "" || body.experience == null) && (body.qualification == "" || body.qualification == null ) && body.salary[0] == 0 && body.salary[1] == 100000 && body.radius == 30) 
    {
      this.setState({appliedFilter : false});
    }
    if (this.state.appliedFilter) {
      this.setState({ isFilterOpen: false });
    }
    else {
      iForSave = 0;
      this.setState({ isFilterOpen: false, appliedFilter: false });
      this.setState({
        appliedFilter: false, isLoading: false, salary: [0, 100000], experienceLevel: [0, 1],
        locationRadius: 30,
        expIndex: 0,
        getJobTypeList: [
          {
            type: "Part Time",
            isClicked: false,
            jobTypeIcon: "fas fa-user-clock"
          },
          { type: "Full Time", isClicked: false, jobTypeIcon: "fas fa-user-tie fullTypeIcon" },
          { type: "Internship", isClicked: false, jobTypeIcon: "fas fa-business-time" }
        ],
        expData: [
          { exp: "Fresher", isClicked: false, expIcon: "fa fa-user" },
          {
            exp: "Experience",
            isClicked: false,
            expIcon: "fas fa-user-graduate"
          }
        ],
        eduData: [
          { std: "10",name: "10TH",  isClicked: false },
          { std: "12", name: "12TH", isClicked: false },
          { std: "graduate", name: "UG", isClicked: false },
          { std: "post graduate",name: "PG",  isClicked: false }
        ]
      });
      this.props.getFiltersWithIcons();
      // window.scrollTo(0, 0)
      body = {
        jobProfile: "",
        jobType: "",
        experience: "",
        experienceLevel: [0, 1],
        salary: [0, 100000],
        radius: 30,
        qualification: "",
        jobseekerId: "",
        isFromList: true
      };
      localStorage.setItem("jobProfile", body.jobProfile);
      localStorage.setItem("jobtype", body.jobType);
      localStorage.setItem("experience", body.experience);
      localStorage.setItem("experienceLevel", body.experienceLevel);
      localStorage.setItem("qualification", body.qualification);
      localStorage.setItem("salary[0]", body.salary[0]);
      localStorage.setItem("salary[1]", body.salary[1]);
      localStorage.setItem("salaryNew", body.salary);
      localStorage.setItem("radius", body.radius);
      if (this.state.resetFilterValue) {
        this.setState({ appliedFilter: false })
        this.applyFilters();
      }
    }
  }

  applyFilters = () => {
    this.setState({ isFilterOpen: false, resetFilterValue: false, allJobsData: [] });
    if ((body.jobProfile == "" || body.jobProfile == null) && (body.jobType == "" || body.jobType == null) && (body.experience == "" || body.experience == null) && (body.qualification == "" || body.qualification == null ) && body.salary[0] == 0 && body.salary[1] == 100000 && body.radius == 30) 
    {
      this.setState({ isFilterOpen: false, appliedFilter : false });
      if (this.state.city == "" || this.state.city == undefined) {
        this.props.allJobList(1);
      }
      else {
        this.filterSearch();
      }
     }
    else {
      this.setState({ appliedFilter: true });
      this.filterSearch();
    }
    window.scrollTo(0, 0);
  }

  loadMore = () => {
    this.setState({
      visible: this.state.visible + this.state.response.length
    });
  }

  filterSearch() {
    if (body.city != null && body.city != "") {
      this.setState({ city: body.city })
    }
    if (jobseekerId != null && jobseekerId != "" && jobseekerId != undefined) {
      body.jobseekerId = jobseekerId;
    }

    if (localStorage.getItem("cityLat") != "" && localStorage.getItem("cityLat") != null & localStorage.getItem("cityLat") != undefined) {
      body.latitude = localStorage.getItem("cityLat")
      body.longitude = localStorage.getItem("cityLong")
    }
    else {
      body.latitude = localStorage.getItem("currentLat")
      body.longitude = localStorage.getItem("currentLong")
    }
    if (cities != undefined) {
      let c = cities;
      c.forEach(city => {
        if (city.value == body.city) {
          body.latitude = city.latitude;
          body.longitude = city.longitude;
          localStorage.setItem("cityLat", body.latitude)
          localStorage.setItem("cityLong", body.longitude)
        }
      })
    }

    if (localStorage.getItem("searchText") != "" && localStorage.getItem("searchText") != undefined) {
      localStorage.setItem("jobProfile", "");
      this.setState({ appliedFilter: false });
      body.jobProfile = ""
      body.searchText = localStorage.getItem("searchText")
    }
    else {
      body.searchText = ""
      body.jobProfile = localStorage.getItem("jobProfile")
      localStorage.setItem("jobProfile", body.jobProfile)
    }
    localStorage.setItem("jobProfile", body.jobProfile)
    localStorage.setItem("jobtype", body.jobType);
    localStorage.setItem("experience", body.experience);
    localStorage.setItem("qualification", body.qualification);
    localStorage.setItem("experienceLevel", body.experienceLevel);
    localStorage.setItem("salary[0]", body.salary[0]);
    localStorage.setItem("salary[1]", body.salary[1]);
    localStorage.setItem("salaryNew", body.salary);
    localStorage.setItem("radius", body.radius);
    localStorage.setItem("salaryNew", body.salary);
    body.currentLat=localStorage.getItem("currentLat")
    body.currentLng=localStorage.getItem("currentLong")
    body.loginFrom="Web"
    if(body.experience == "Experience" &&  body.experienceLevel[1]==0 ){
      body.experience ="Fresher"
      body.experienceLevel[0]=0
      body.experienceLevel[1]=0
    }
    this.props.getFilterSearch(body);
    this.props.getTopPicks(body);
  }

  checkBoxJobProfile(index, item) {
    let res = allJobProfile.map((profile, indx) => {
     
      if (indx === index) {
        profile.isClicked = !profile.isClicked;
        if(localStorage.getItem("jobProfile") === profile.jobName)
        {
          profile.isClicked = false;
        }
        if (!profile.isClicked) {
          this.setState({ jobName: null });
           localStorage.setItem("jobProfile", "");
          body.jobProfile = ""
        } else {
          this.setState({ jobName: profile.jobName });
          body.jobProfile = profile.jobName;
          localStorage.setItem("jobProfile", profile.jobName);
          localStorage.setItem("searchText", "");
        }
      } else {
        profile.isClicked = false;
      }
      return profile;
    });
    allJobProfile = res;
  }

  checkBoxJobType(index) {
    let resJobType = this.state.getJobTypeList.map((jobType, indx) => {
      if (indx == index) {
        jobType.isClicked = !jobType.isClicked;
        if(localStorage.getItem("jobtype") == jobType.type)
        {
          jobType.isClicked = false;
        }
        if (!jobType.isClicked) {
          localStorage.setItem("jobtype", "");
          body.jobType = "";
         
        } else {
          body.jobType = jobType.type;
          localStorage.setItem("jobtype", jobType.type);
        }
      } else {
        jobType.isClicked = false;
      }
      return jobType;
    });
    this.setState({ getJobTypeList: resJobType });
  }

  checkBoxExpLevel(index) {
    this.setState({ expIndex: index })
    let resExpLevel = this.state.expData.map((exp, indx) => {
      if (indx == index) {
        exp.isClicked = !exp.isClicked;
        if(localStorage.getItem("experience") == exp.exp)
        {
          exp.isClicked = false;
        }
        if (!exp.isClicked) {
          this.setState({ jobExp: null });
          body.experience = "";
          localStorage.setItem("experience", "");
        } else {
          this.setState({ jobExp: exp.exp });
          body.experience = exp.exp;
          localStorage.setItem("experience", exp.exp);
        }
      } else {
        exp.isClicked = false;
      }
      return exp;
    });
    this.setState({ expData: resExpLevel, expVal: this.state.expData[1].isClicked });
  }

  checkBoxEducation(index) {
    let resEducation = this.state.eduData.map((edu, indx) => {
      if (indx == index) {
        edu.isClicked = !edu.isClicked;
        if(localStorage.getItem("qualification") == edu.std)
        {
          edu.isClicked = false;
        }
        if (!edu.isClicked) {
          this.setState({ filterEdu: null });
          body.qualification = "";
          localStorage.setItem("qualification", "");
        } else {
          this.setState({ filterEdu: edu.std });
          body.qualification = edu.std;
          localStorage.setItem("qualification", edu.std);
        }
      } else {
        edu.isClicked = false;
      }
      return edu;
    });
    this.setState({ eduData: resEducation });
  }

  async getCityValue(selectedValue) {
    localStorage.setItem("city", selectedValue);
    this.setState({ city: selectedValue });
    body.city = selectedValue
    window.scrollTo(0, 0);
    this.filterSearch();
    window.history.replaceState({ search: selectedValue }, { cityName: selectedValue }, "?jobs-in-" + selectedValue);
  }

  setSalary = () => {
    body.salary = this.state.salary;
  }

  setRadius = () => {
    body.radius = this.state.locationRadius;
  }

  setExplevel = () => {
    body.experienceLevel = this.state.experienceLevel
  }

  handleSearch = () => {
    this.props.history.push('/searchjob');
  }

  handleMapList() {
    this.props.history.push('/mapjobs');
  }
  handleChatList() {
    this.props.history.push('/chatadmin');
  }
  hidePlayStoreLink = (e) => {
    this.setState({ playStoreLink: false });
  };
  showPlaystoreLink = (e) => {
    this.setState({
      playStoreLink: true
    });
  }

  playStoreModal = (e) => {
    window.open('https://play.google.com/store/apps/details?id=dataflakes.jobs.jhatpatjobs.jobsearch', '_blank');
  };
  componentWillUnmount() {
    this.abortController.abort();
  }
 
  render() {
    // <Offline> <InternetConnect /></Offline>

    jobseekerName = localStorage.getItem("givenName")
    const { value, pathMap } = this.state;
    const { selectedOption } = this.state;
    let { jobsData, jobsError, topPicksData, getFilterIconData, getCityData, filterSearchData, filterSearchLoading,updateToken } = this.props;
    let isSearch = false;
    let citySearch = false;
    let jobsLoading = false;
    jobsSearch = [];
    this.state.jobsSearchData = [];
    let loadMoreButton = true;
     if(filterSearchData.filtered != undefined)
     {
       if(filterSearchData.filtered == "")
       {
        this.state.showAllJobsText = true;
       }
       else {
        this.state.showAllJobsText = false;
       }
     }
    
    if (jobsData != "" && jobsData != undefined) {
      jobsLoading = false;
    }
    else {
      jobsLoading = true;
    }
    if (filterSearchData.filtered != undefined) {
      if (filterSearchData.filtered != "") {
        this.state.jobsSearchData = filterSearchData.filtered
        filterSearchLoading = false;
        isSearch = true;
        jobsLoading = false;
        citySearch = true;
        jobsData = [];
        this.state.allJobsData = [];
      }
      else {
        this.state.jobsSearchData = [];
        isSearch = true
        jobsLoading = false;
        citySearch = true
        loadMoreButton = false

      }
    }
 
    if (getFilterIconData != "" && getFilterIconData != undefined) {
      allJobProfile = getFilterIconData;
    }
    allCities = [];
    if (getCityData != "" && getCityData != undefined) {
      var responseCities = getCityData.cities;
      responseCities.forEach(city => {
        allCities.push({
          value: city.cityName,
          latitude: city.position.coordinates[1],
          longitude: city.position.coordinates[0]
        });
      });
      cities = allCities;
    }

    topPicked = [];
    if ( topPicksData.length > 0 && topPicksData !== undefined  && topPicksData !== null) {
      for (let i = 0; i < 4; i++) {
        topPicked.push({
          jobId: topPicksData[i].jobId,
          jobTitle: topPicksData[i].jobTitle,
          companyName: topPicksData[i].companyName,
          companyImage: topPicksData[i].companyImage,
          createdDate: topPicksData[i].createdDateNew,
          salary: topPicksData[i].salary,
          status: topPicksData[i].status,
          jobLink: topPicksData[i].jobLink,
          isSaved: topPicksData[i].isSaved,
          jobType: topPicksData[i].jobType,
          no: i
        });
      }
    }
    this.state.topPickState = topPicked;
    let tempJobs = jobsData.jobs;
    let finalJobsData = [];
    if (!jobsLoading && tempJobs !== undefined) {
      finalJobsData = [...temp, ...tempJobs];
      temp = finalJobsData;
    }
    if (!isSearched) {
      this.state.allJobsData = finalJobsData;
    }
    iForSave = 0;
    if (filterSearchData.length == 0) {
      myMetaData = jobsData.metaElems
    }
    else {
      myMetaData = filterSearchData.metaElems
    }
    if (localStorage.getItem("searchText") == "" && localStorage.getItem("city") == "" && localStorage.getItem("jobtype") == "" && localStorage.getItem("experience") == "" && localStorage.getItem("qualification") == "" && localStorage.getItem("jobProfile") == "") {
      filterSearchData.filtered = undefined
      this.state.allJobsData = finalJobsData
    }
    else {
      this.state.jobsSearchData = filterSearchData.filtered
      this.state.allJobsData = []
    }
    function getMetaTags(metaTag, dataToRetrieve) {
      return dataToRetrieve
        .split('.')
        .reduce(function (o, k) {
          return o && o[k];
        }, metaTag)
    }
    let title = getMetaTags(myMetaData, "title")
    if (title != undefined) {
      document.title = getMetaTags(myMetaData, "title");
      document.getElementsByTagName("META")[2].content = getMetaTags(myMetaData, "description")
      document.getElementsByTagName("META")[3].content = getMetaTags(myMetaData, "keywords");
    }
    if (jobsError) {
      return (
        <div>
          <p>Error</p>
        </div>
      );
    }
    return (
      <center className="overflw">
        <MetaTags>
          {/* for browser */}
          <title>Capture multiple Jobs without missing opportunity - Jobam</title>
          {/* For Link sharing in social from url */}
          <meta property="og:title" content="Capture multiple Jobs without missing opportunity - Jobam" />
          <meta property="og:description" content="" />
          <meta property="og:url" content="https://jobam.co/joblist" />
          <meta property="og:image" content="../assets/logo192.png" />
          <meta property="og:site_name" content="Jobam" />
          {/* For twitter sharing from url  */}
          <meta name="twitter:title" content="Capture multiple Jobs without missing opportunity - Jobam" />
          <meta name="twitter:description" content="content" />
          <meta name="twitter:image" content="../assets/logo192.png" />
          <meta name="twitter:site" content="@JhatpatJ" />
          <meta name="twitter:creator" content="@JhatpatJ" />
        </MetaTags>
        {this.state.isFilterOpen == true ? (
          <center className="overflow-y">
           <div className="col-md-6 pl-0 pr-0">
              <Webheader pageName={Strings.JobFilters} filterIcon={true} handleBack={this.closeFilters} />
            </div>
            <div className="bg-light col-md-6 col-lg-6 col-xl-6 col-12 text-left  mb-5">
              <div className="col-md-12 col-12">
                <div className="row">
                  <h6 className="text-uppercase font-weight-bold text-dark ml-2 mt-3 ">
                    {Strings.jobProfiles}
                  </h6>
                  <ul className="list-unstyled col-md-12 col-xs-12 col-12 col-sm-12 ml-0 pl-1" data-toggle="buttons">
                    <div className="row">
                      {
                        allJobProfile != "" &&
                          allJobProfile != undefined
                          ? allJobProfile
                            .map((item, index) => (
                              <li key={item.index} className="col-md-2 col-sm-2 col-3 mb-1 pl-md-2 pl-1 pr-0 btn-group btn-group-toggle  " >
                                <label className={item.isClicked || localStorage.getItem("jobProfile") === item.jobName ? "btn btn-outline-grey rounded-sm   shadow-sm  mb-2 active" : "btn btn-outline-grey  rounded-sm shadow-sm bg-white mb-2"} >
                                  <input
                                    className="col-md-2 col-sm-2 col-2"
                                    type="radio"
                                    checked={localStorage.getItem("jobProfile") === item.jobName ? true : false}
                                    onClick={i =>
                                      this.checkBoxJobProfile(index, item)
                                    }
                                  />
                                  <img
                                    src={
                                      item.isClicked || localStorage.getItem("jobProfile") === item.jobName
                                        ? imageUrl + item.jobIcon_white
                                        : imageUrl + item.jobIcon_blue
                                    }
                                    className=" mt-2 filterIcons"
                                    height="60" alt="Job Profile" />
                                  {item.isClicked || localStorage.getItem("jobProfile") === item.jobName ?
                                    <h6 className="filter-job filterText text-white">
                                      {item.jobName}

                                    </h6> :
                                    <h6 className="filter-job filterText text-dark">
                                      {item.jobName}

                                    </h6>}
                                </label>
                              </li>
                            ))
                          : null}
                    </div>
                  </ul>
                </div>
              </div>
              <div className="">
                <div className="row">
                  <h6 className="text-uppercase font-weight-bold text-dark ml-2">
                    {Strings.jobType}
                  </h6>
                  <ul
                    className="list-unstyled col-md-12 col-xs-12"
                    data-toggle="buttons"
                  >
                    <div className="row pl-0">
                      {this.state.getJobTypeList.map((item, index) => (
                        <li key={item.index} className="col-md-4 col-sm-4 col-4 btn-group btn-group-toggle pl-md-2 pl-1 pr-0 pr-md-2">
                          <label className={item.isClicked || localStorage.getItem("jobtype") === item.type ? " btn btn-outline-grey  rounded-sm shadow-sm bg-white d-flex flex-row  justify-content-center align-items-center active text-dark checkBoxCls" : "btn btn-outline-grey  rounded-sm shadow-sm bg-white d-flex flex-row  justify-content-center text-dark align-items-center checkBoxCls"}>
                            <input
                              className="mt-2"
                              name="checkmark"
                              type="radio"
                              checked={localStorage.getItem("jobtype") == item.type ? true : false}
                              onClick={i => this.checkBoxJobType(index)}
                            />
                            <i className={item.jobTypeIcon}></i>
                            <h5 className="font-weight-normal  mt-1 inline d-none d-sm-block d-md-block d-lg-block">
                              <small className="ml-1 align-middle text-uppercase"> {item.type}</small>
                            </h5>
                            <h6 className="font-weight-normal  mt-1 inline d-block d-sm-none d-md-none d-lg-none">
                              <small className="ml-1 align-middle text-uppercase"> {item.type}</small>
                            </h6>
                          </label>
                        </li>
                      ))}
                    </div>
                  </ul>
                </div>
              </div>
              <div className="">
                <div className="row pl-0">
                  <h6 className="text-uppercase font-weight-bold text-dark ml-2">
                    {Strings.experienceLevelCaps}
                  </h6>
                  <ul className="list-unstyled col-md-12 col-xs-12" data-toggle="buttons">
                    <div className="row">
                      {this.state.expData.map((item, index) => (
                        <li className="col-md-6 col-sm-6  col-6 btn-group btn-group-toggle " >
                          <label className={item.isClicked || localStorage.getItem("experience") === item.exp ? "btn btn-outline-grey  rounded-sm shadow-sm bg-white  d-flex flex-row  justify-content-center align-items-center active text-dark checkBoxCls" : "btn btn-outline-grey text-dark  rounded-sm shadow-sm bg-white d-flex flex-row  justify-content-center align-items-center checkBoxCls"}>
                            <div className="p-2 ">
                              <i className={item.expIcon}></i>
                            </div>

                            <input
                              className="mt-2"
                              name="checkmark"
                              checked={localStorage.getItem("experience") == item.exp ? true : false}
                              type="radio"
                              onClick={i => this.checkBoxExpLevel(index)}
                            />{" "}
                            <div className="p-2 text-uppercase experienceText ">
                              {item.exp}
                            </div>
                          </label>
                        </li>
                      ))}
                    </div>
                  </ul>
                </div>
              </div>

              {localStorage.getItem("experience")=="Experience" ?
                <div className="">
                  <h6 className="text-uppercase font-weight-bold text-dark ">
                    WORk EXPERIENCE
                   </h6>
                  <div>
                    <div className="ml-2 mr-2 mb-2" onClick={this.setExplevel()}>
                      <Range
                        min={0}
                        max={10}
                        value={this.state.experienceLevel}
                        onChange={(experienceLevel) => this.setState({ experienceLevel: experienceLevel })}
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-2  col-2 col-sm-2 pl-md-3 pr-md-3 p-0">
                          {this.state.experienceLevel != undefined ?
                            <div className=" text-success">{this.state.experienceLevel[0]}
                              {this.state.experienceLevel[0] == "0" || this.state.experienceLevel[0] == "1" ?
                                " Year" :
                                " " + Strings.Years
                              }
                            </div> : null}
                        </div>
                        <div className="col-md-8  col-8 col-sm-8"></div>
                        <div className="col-md-2  col-2 col-sm-2 mobileSalary pl-md-3 pr-md-3 p-0">
                          {this.state.experienceLevel != undefined ?
                            <div className="ml-2 text-success">{this.state.experienceLevel[1]}
                              {this.state.experienceLevel[1] == "0" || this.state.experienceLevel[1] == "1" ?
                                " Year" :
                                " " + Strings.Years
                              }
                            </div> : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                : null
              }
              <div className="">
                <div className="row">
                  <h6 className="text-uppercase font-weight-bold text-dark ml-2">
                    {Strings.EducationalQualification}
                  </h6>
                  <ul className="list-unstyled col-md-12 col-xs-12" data-toggle="buttons">
                    <div className="row pl-0">
                      {this.state.eduData.map((item, index) => (
                        <li className="col-md-3 col-sm-3 col-3  btn-group-toggle mb-1">
                          <label className={item.isClicked || localStorage.getItem("qualification") == item.std ? "btn btn-outline-grey  rounded-sm shadow-sm bg-white d-flex flex-row  justify-content-center align-items-center active text-white" : "btn btn-outline-grey  rounded-sm shadow-sm bg-white  d-flex flex-row  justify-content-center align-items-center text-dark"}>
                            <input
                              className="mt-1"
                              name="checkmark"
                              type="radio"
                              checked={localStorage.getItem("qualification") == item.std ? true : false}
                              onClick={i => this.checkBoxEducation(index)}
                            />{" "}
                            <div className="p-1">
                              {/* <small>  */}
                              {item.name}
                              {/* </small>  */}
                            </div>
                          </label>
                        </li>
                      ))}
                    </div>
                  </ul>
                </div>
              </div>
              <div className="">
                <h6 className="text-uppercase font-weight-bold text-dark ">
                  {Strings.salaryCaps}
                </h6>
                <div className="ml-2" onClick={this.setSalary()}>
                  <Range
                    min={0}
                    max={100000}
                    step={500}
                    value={this.state.salary}
                    onChange={salary => this.setState({ salary: salary })}
                  />
                </div>
                <div className="col-md-12 col-12 col-sm-12 mb-5">
                  <div className="row ">
                    <div className="col-md-2  col-2 col-sm-2">{Strings.Rs}{this.state.salary[0]} </div>
                    <div className="col-md-8  col-8 col-sm-8"></div>
                    <div className="col-md-2  col-2 col-sm-2 mb-2 pl-0">{Strings.Rs}{this.state.salary[1]}</div>
                  </div>
                </div>
              </div>

              <div className="">
                <h6 className="text-uppercase font-weight-bold text-dark ">
                  {Strings.locationRadius}
                </h6>
                <div className="ml-2" onClick={this.setRadius()}>
                  <Slider
                    min={0}
                    max={30}
                    step={1}
                    value={this.state.locationRadius}
                    onChange={locationRadius => this.setState({ locationRadius: locationRadius })}
                  />
                </div>
                <div className="col-md-12 col-12 col-sm-12 mb-5">
                  <div className="row">
                    <div className="col-md-4 col-4 col-sm-4 mb-4">{this.state.locationRadius}{" " + Strings.km}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 container-fluid ">
              <div className="row col-md-6 fixed-bottom container checkBoxCls">
                <div className="bg-light col-md-6 p-0 col-6 border">
                  <div onClick={this.resetFilter}>
                    <p className="textNot pointer  font-weight-bold applyFont text-center text-uppercase p-2" > {Strings.reset}</p>
                  </div>
                </div>
                <div className="bg-success col-md-6 p-0 col-6 border" onClick={this.applyFilters}>
                  <p className="textNot pointer text-white font-weight-bold applyFont text-center text-uppercase p-2" >{Strings.apply}</p>
                </div>
              </div>
            </div>
          </center>
        ) : (
            <LoadingOverlay
              active={jobsLoading || filterSearchLoading}
              spinner={
                <div className="loading-joblist">
                  <center className=" bg-dark1 rounded mt-5 col-md-4 col-sm-5 p-2 col-10">
                    <h4 className="loadingText pt-1 mb-1 d-sm-block d-md-block">{Strings.serachingYourJobs}</h4>
                    <h6 className="text-white pt-1 pb-1 d-block d-sm-none d-md-none">{""}</h6>
                    <img src={Images.loaderWhite} alt="Loader White" className="pb-2 pt-1" width="30" />
                  </center>
                </div>
              }
            >
              <div className="bg-light col-md-6 col-lg-6 col-sm-6 col-12 No-PadingList pb-5 mb-2">
                <Navbar />
                <div className="pt-md-2 mb-md-4 pb-md-5 pb-5 mb-4">
                  <div className="pt-md-5">
                    <div className="searchBar mt-0 mt-md-3 mt-sm-3 mt-lg-3 mb-3 searchbar-top">
                      <form className="form-inline">
                        <select className="form-control select-city mr-md-2 mr-1" id="select1"
                          value={this.state.city == "" || this.state.city == undefined ? City : this.state.city} onChange={e => this.getCityValue(e.target.value)} >
                          <option selected disabled hidden>{this.state.city == "" || this.state.city == undefined ? City : this.state.city}</option>
                          {allCities.map((item) =>
                            (this.state.city == item.value ?
                              <option key={item.value} className="cityValueCls">{item.value}</option>
                              :
                              <option key={item.value}>{item.value}</option>
                            )
                          )}
                        </select>
                        <div className="input-group col-md-5 col-6 input-search-text pl-0 pr-0">
                          <input type="text" className="form-control newinput border-right-0" placeholder={Strings.searchJobsAndCompaniesHere} onClick={() => this.handleSearch()} aria-describedby="basic-addon2" readOnly />
                          <div className="input-group-append pointer" onClick={() => this.handleSearch()}>
                            <span className="input-group-text" id="basic-addon2"><SearchIcon /></span>
                          </div>
                        </div>
                        <div className="row ml-0 font-icon-size ml-2">
                       {isUserLoggedIn == 'true' ? (
                         <Link
                            className="nav-link text-decoration-none text-secondary pointer py-auto px-auto pr-1 pl-1"
                            onClick={() => this.handleChatList()}
                          >
                            {/* <ChatBubbleOutlineIcon/> */}
                            <img src={Images.chaticon} width="18" alt="Chat Icon"/>
                            {" "}
                            <span className="d-none d-sm-inline-flex font-nonitalic">
                              {Strings.chatText}
                            </span>
                          </Link> 
                          ):(
                          <Link
                            className="nav-link text-decoration-none text-secondary pointer py-auto px-auto pr-1 pl-1"
                            onClick={() => this.showChatModal()}
                          >
                           <img src={Images.chaticon} width="18" alt="Chat Icon"/>
                            {" "}
                            <span className="d-none d-sm-inline-flex font-nonitalic">
                              {Strings.chatText}
                            </span>
                          </Link>
                       )}
                          <Link
                            className="nav-link text-decoration-none text-secondary pointer py-auto px-auto pr-1 pl-1"
                            to="/notification"
                          >
                            <img src={Images.notification} width="18" alt="Notification Icon"/>
                            {" "}
                            <span className="d-none d-sm-inline-flex font-nonitalic">
                              {Strings.notificationText}
                              {this.state.notiCount > 0 ?
                                <sup className="bg-success rounded-circle text-white text-bold notibadge">{this.state.notiCount}</sup>
                                : null}
                            </span>
                          </Link>
                          <i
                            className="nav-link text-decoration-none text-secondary pointer py-auto px-auto pr-1 pl-1 filterpadding"
                            onClick={() => this.setState({ isFilterOpen: true })}
                          >
                            {
                              this.state.appliedFilter ?
                                <img src={Images.clearFilter} width="18" alt="Clear Filter"/> :
                                <img src={Images.filterIconDashbaord} width="18" alt="Filter Icon"/>
                            }
                            {" "}
                            {
                              this.state.appliedFilter ?
                                <span className="d-none d-sm-inline-flex font-nonitalic text-success">
                                  {Strings.filterText}

                                </span> :
                                <span className="d-none d-sm-inline-flex font-nonitalic ">
                                  {Strings.filterText}
                                </span>
                            }
                          </i>
                          {/* <Link
                            className="nav-link text-decoration-none text-secondary pointer p-0 pt-2 pl-2"
                            to="/changeLanguage"
                          >
                          <i className="fa fa-language"></i>
                          </Link> */}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div >
                    <div>
                      {this.state.topPickState != null &&
                          this.state.topPickState != "" &&
                          this.state.topPickState != undefined ?
                      <h1 className="font-weight-bold topPickSText  text-left mb-2 mt-3 mt-md-5">{Strings.topPicks} </h1> : null}
                      <Carousel showThumbs={false} showArrows={false} showStatus={false} infiniteLoop={false}  >
                        {this.state.topPickState != null &&
                          this.state.topPickState != "" &&
                          this.state.topPickState != undefined ?
                          this.state.topPickState.map((item) => {
                            return (
                              <CardComponent key={item.index} companyName={item.companyName} jobType={item.jobType} jobTitle={item.jobTitle} salary={item.salary}
                                createdDate={item.createdDate} status={item.status} jobLink={item.jobLink} jobId={item.jobId}
                                isShortlisted={item.isShortlisted} companyImage={imageUrl + item.companyImage}
                                isSaved={item.isSaved} fakeId={this.state.fakeId} fakeIcon={fakeIconTopPicks}
                                callSaved={i => this.savedJobs(item)
                                }
                              />
                            )
                          }) :
                          null
                        }
                      </Carousel>
                    </div>
                  {jobsLoading ? (
                    null
                  ) : (
                      <div className="bg-light cardBottom">
                       { this.state.showAllJobsText ?
                        null : <h2 className="font-weight-bold topPickSText text-left mb-2">{Strings.allJobs}</h2>}
                        {!isSearch
                          ? this.state.allJobsData.length > 0
                            ? this.state.allJobsData.map((item) =>
                              iForSave < this.state.limit && this.state.limit <= 200 ?
                                <div key={item.index}>
                                  <CardComponent companyName={item.companyName} jobType={item.jobType} jobTitle={item.jobTitle} salary={item.salary}
                                    createdDate={item.createdDate} status={item.status} jobLink={item.jobLink} jobId={item.jobId} isShortlisted={item.isShortlisted} companyImage={imageUrl+ item.companyImage}
                                    isSaved={item.isSaved} fakeId={fakeId} fakeIcon={fakeIcon}
                                    callSaved={i => this.savedJobsforAllJobs(item, this.setState({ data: "alljobdata" }))}
                                  />
                                  <span className="d-none">{iForSave++}</span>
                                </div>
                                : null
                            )
                            : null
                          : this.state.jobsSearchData.length > 0
                            ? (this.state.jobsSearchData.map((item) =>
                              <CardComponent key={item.index} companyName={item.companyName} jobType={item.jobType} jobTitle={item.jobTitle} salary={item.salary}
                                createdDate={item.createdDateNew} status={item.status} jobLink={item.jobLink} jobId={item.jobId} fakeId={fakeId}
                                isShortlisted={item.isShortlisted} companyImage={imageUrl + item.companyImage}
                                isSaved={item.isSaved} fakeIcon={fakeIcon}
                                callSaved={i => this.savedJobsforAllJobs(item, this.setState({ data: "jobsearch" }))}
                              />
                            ))
                            :
                            <div className="center-modal">
                              <ModalDiv getImage={Images.noRecord} textMsg={Strings.noJobFoundForLocation} />
                            </div>
                        }
                        {
                          !citySearch && loadMoreButton && this.state.limit < 200 ?
                            <div className="text-center mb-1 mt-1">
                              <div
                                className="btn bg-success col-md-4 col-lg-4 col-xl-4 col-12 "
                                onClick={() => this.loadMoreData()}
                              >
                              <div className="text-white"><i className="fas fa-redo"></i>{" "}{Strings.loadMoreItems}</div>
                              </div>
                            </div>
                            :
                            null
                        }
                      </div>
                    )}
                </div>
                <div className="btn btn-success text-white map-button" onClick={() => this.handleMapList()}><i className="fa fa-map"></i> {Strings.map}</div>

                {/* <ModalDiv modalReact={true} twoButton={true} showModal={this.state.playStoreLink} crossBtnModal={true} msg="Download Jobam App for better experience" hideModal={()=>this.hidePlayStoreLink()}  btnFirst="Ok" btnFirstClick={() => this.playStoreModal()} btnSecondClick={() => this.hidePlayStoreLink()} btnSecond={Strings.cancelCaps}/> */}

                <ModalDiv modalReact={true} twoButton={true} showModal={this.state.showLoginPopup} crossBtnModal={true} msg={Strings.kindlyLoginTextForSave} hideModal={()=>this.hideLoginModal()} btnFirst={Strings.loginCaps} btnFirstClick={() => this.saveLoginModal()} btnSecondClick={() => this.hideLoginModal()} btnSecond={Strings.cancelCaps}/>
                
                <ModalDiv modalReact={true} twoButton={true} showModal={this.state.showChatPopup} crossBtnModal={true} msg="Kindly login to chat with admin" hideModal={()=>this.hideChatModal()} btnFirst={Strings.loginCaps} btnFirstClick={() => this.saveLoginModal()} btnSecondClick={() => this.hideChatModal()} btnSecond={Strings.cancelCaps}/>

                <ModalDiv modalReact={true} showModal={this.state.showUpdateModel} crossBtnModal={true} msg={Strings.updateProfileText} btn1Text={Strings.oK} btn1Click={() => this.hideProfileModal()} hideModal={() => this.hideProfileModal()}/>

                {/* For Back To Top Button Start*/}

                {/* For Back To Top Button End*/}
                <WebBottomNav homeText={Strings.homeTabs} myJobText={Strings.myJobTabs} interviewText={Strings.interviewTabs} homeActive={true} profileData={this.state.isUserLoggedIn ? jobseekerName : Strings.profileTabs} />

                <Route component={Footer} />
                {/* <Footer /> */}

              </div>
            </LoadingOverlay>
          )
        }
      </center>
    );
  }
}
const mapStateToProps = state => {
  return {
    jobsData: state.allJobsReducer.jobsData,
    jobsLoading: state.allJobsReducer.jobsLoading,
    jobsError: state.allJobsReducer.jobsError,
    topPicksData: state.allJobsReducer.topPicksData,
    topPicksError: state.allJobsReducer.topPicksError,
    getCityData: state.allJobsReducer.getCityData,
    getFilterIconData: state.allJobsReducer.getFilterIconData,
    filterSearchData: state.allJobsReducer.filterSearchData,
    filterSearchLoading: state.allJobsReducer.filterSearchLoading,
    filterSearchError: state.allJobsReducer.filterSearchError,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    allJobList: () => dispatch(allJobList()),
    updateTokenApi: () => dispatch(updateTokenApi()),
    getTopPicks: () => dispatch(getTopPicks(body)),
    getCities: () => dispatch(getCities()),
    getFiltersWithIcons: () => dispatch(getFiltersWithIcons()),
    getFilterSearch: (body) => dispatch(getFilterSearch(body)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(JobList);

