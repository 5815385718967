import React, { Component } from "react";
import { Link, Route } from "react-router-dom";
import helpers from "../config";
import Strings from "../utilities/stringlanguage";
import stringlanguage from "../utilities/stringlanguage";
import Webheader from "../components/webheader";
import Images from '../Images';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from "react-redux";
import { onUpdateLanguage } from "../actions/changeLanguageAction";
import Footer from "../components/footer";
import "../_style/changeLanguage.css";

var jobseekerId;
var url;
var imageUrl;
var tokenId , ismobileVerified;
class ChangeLanguage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            selectedIndex: 0,
            lang: [
                { lang: "en", Img: Images.engLang, fImg: Images.engWhite },
                { lang: "hi", Img: Images.hindiLang, fImg: Images.hindiWhite },
                { lang: "mr", Img: Images.marathiLang, fImg: Images.marathiWhite }
            ]
        }
    }
    async componentDidMount() {
        var selectedLang;
        let apiUrl = await helpers.getServerUrl()
        url = apiUrl.serverUrl
        imageUrl = apiUrl.imageUrl
        jobseekerId = localStorage.getItem("jobseekerId");
        selectedLang = localStorage.getItem("selectedLang");
        tokenId = localStorage.getItem("tokenId");
         this.jobseekerVerify();
        if (selectedLang == "hi") {
            Strings.setLanguage(selectedLang);
            this.setState({ selectedIndex: 1, selectedLang: selectedLang, loading: false })
        } else if (selectedLang == "mr") {
            Strings.setLanguage(selectedLang);
            this.setState({ selectedIndex: 2, selectedLang: selectedLang, loading: false })
        } else {
            this.setState({ selectedIndex: 0, selectedLang: selectedLang, loading: false })
        }
    }

    jobseekerVerify = async () => {
        try {
          let response = await fetch(url + "/admin/getAppSetDetails1", {
            method: "POST",
            headers: {
              "content-Type": "application/json"
            },
            body: JSON.stringify({
              jobseekerId: jobseekerId,
              tokenId: tokenId,
              loginFrom: "Web"
            })
          })
          response = await response.json()
           ismobileVerified = response.ismobileVerified
        } catch (err) {
        }
      }

    onLangClick = (lang, index) => {
        this.setState({ selectedIndex: index, selectedLang: lang })
    }

    backClicked()
    {
        this.props.history.go(-2);
    }
    ChangeLanguage = () => {
        localStorage.setItem("selectedLang", this.state.selectedLang)
        localStorage.setItem("userLang", this.state.selectedLang)
        stringlanguage.setLanguage(this.state.selectedLang)
        if (jobseekerId !== "" && jobseekerId !== null && jobseekerId !== undefined && ismobileVerified == true
        ) {
            this.props.onUpdateLanguage(this.state.selectedLang)
            this.props.history.go(-1)
        }
       else if (jobseekerId == "" || jobseekerId == null || jobseekerId == undefined ) {
            this.props.onUpdateLanguage(this.state.selectedLang)
            this.props.history.push('/joblist')
        }
        else {
            this.props.onUpdateLanguage(this.state.selectedLang)
            this.props.history.push('/updateProfile')
        }
    }

    render() {
        let { languageData, languageDataLoading, languageDataError } = this.props;
        document.title = "Your Job search in your language of choice with Jobam";
        // document.getElementsByTagName("META")[2].content = description;
        // document.getElementsByTagName("META")[3].content = keywords
        return (
            <LoadingOverlay
                active={this.state.loading || languageDataLoading}
                spinner={
                    <div className="loading-languages">
                        <center className=" bg-dark1 rounded mt-5 col-md-6 col-sm-6 p-2 col-12">
                            <h4 className="loadingText pt-1  mb-1 d-sm-block d-md-block">
                                {Strings.loadingLanguage}
                            </h4>
                            <h6 className="text-white pt-1 pb-1 d-block d-sm-none d-md-none"></h6>
                            <img
                                src={Images.loaderWhite}
                                alt="img"
                                className="pb-2"
                                width="30"
                            />
                        </center>
                    </div>
                }
            >
                <div className="container col-md-6 col-sm-6 col-12 language-background">
                    <Webheader backLink="/profile" pageName={Strings.ChangeLanguage} handleBack={() =>this.backClicked()}/>
                    <div className="col-md-12 mt-3">
                        <h4 className="text-dark font-weight-bold">{Strings.ChooseALanguage}</h4>
                        <div className="col-md-2 bg-success p-1 mt-4 col-3"></div>
                        <div className="row" >
                            {this.state.lang.map((item, index) =>
                                <div key={index} className=" mt-4 pointer col-md-3 col-4 col-sm-3" onClick={() => this.onLangClick(item.lang, index)}>
                                    <img className="img-fluid w-100" alt="Language" src={this.state.selectedIndex != index
                                        ? item.fImg
                                        : item.Img}>
                                    </img>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-12 text-center mt-2">
                        <div onClick={() => this.ChangeLanguage()} type="button" className="btn btn-success rounded-pill col-md-5 col-8">{Strings.apply}</div>
                    </div>
                </div>

                <div className="container-fluid bg-light col-md-6 col-lg-6 col-sm-6 col-12 No-Pading">
                    <Route component={Footer} className="d-none d-md-block d-lg-block d-sm-block d-xl-block" />
                </div>
            </LoadingOverlay>
        );
    }
}

const mapStateToProps = state => {
    return {
        languageData: state.changeLanguageReducer.languageData,
        languageDataLoading: state.changeLanguageReducer.languageDataLoading,
        languageDataError: state.changeLanguageReducer.languageDataError,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onUpdateLanguage: (selectedLang) => dispatch(onUpdateLanguage(selectedLang))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ChangeLanguage);