/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import Images from "../Images";
import helpers from "../config";
import { Link } from "react-router-dom";
import '../_style/webFooter.css';
var allCities = [];
var url;
var imageUrl;

export default class navBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  async componentDidMount() {
    let apiUrl = await helpers.getServerUrl()
    url = apiUrl.serverUrl
    imageUrl = apiUrl.imageUrl
    this.popularCities();
  }
  cityValue(item) {
    localStorage.setItem("city", item.cityName)
    if(this.state.cities !== undefined)
    {
      let c = this.state.cities;
      c.forEach(city=>{
        if(city.cityName == item.cityName){
          localStorage.setItem("cityLat", city.latitude);
         localStorage.setItem("cityLong", city.longitude);
        }
      })
    }

  }
  popularCities = async () => {
    allCities = [];
    try {
      let response = await fetch(url + "/jobseeker/getCities", {
        method: "GET"
      })
      response = await response.json();
      if (response.success) {
        response.cities.forEach((data, index) => {
          allCities.push({
            cityName: data.cityName,
            latitude : data.position.coordinates[1],
            longitude : data.position.coordinates[0]

          })
        })
        this.setState({ cities: allCities });
      }
    }
    catch (err) {
      console.log(err);
    }
  }

  render() {
    return (
      <div>
        <section id="contact">
          <div className="container-fluid w-75">
            <div className="row wow fadeInUp">
              <div className="col-lg-4 col-md-4 pl-0">
                <div className="contact-about">
                  <a href="/"><img className="img-responsive col-md-12 col-12 pb-2" src={Images.headerLogo} alt="Headerlogo" /></a>
                  <h4 className="text-white ml-3 d-none d-sm-block d-md-block"> <strong>Dream Job For Everyone!</strong></h4>
                  <h6 className="text-white ml-3 mt-1 d-block d-sm-none d-md-none text-uppercase"> <strong>Dream Job For Everyone!</strong></h6>
                  <div className="contact-detail ml-3">
                    <a className="text-white text-decoration-none" href="tel:+91 7089008132"><i className="fas fa-phone-alt text-success-gradient mr-1"></i> +91 7089008132</a><br></br>
                    <a className="text-white text-decoration-none" href="mailto:contact@jobam.co"><i className="fas fa-at text-success-gradient mr-1"></i> contact@jobam.co</a>
                  </div>
                  <div className="ml-3 mt-1 d-none d-sm-block d-lg-block d-xl-block d-md-block">
                    <a href="https://twitter.com/jobam_co" className="pointer" target="_blank"><img src={Images.twitter} width="45" alt="Twitter"></img></a>
                    <a href="https://www.facebook.com/jobam.co/" className="pointer mr-1" target="_blank">
                      <img src={Images.facebook} width="35" alt="Facebook" />
                      {/* <i className="fab fa-facebook-f text-success-gradient"></i> */}
                    </a>
                    <a href="https://www.instagram.com/jobam.co/" className="pointer" target="_blank"><img src={Images.instagram} width="35" className="mr-1" alt="Instagram"></img></a>
                    {/* <a  href="mailto:contact@jobam.co" className="google-plus" target="_blank"><img src={Images.gmail} width="35" alt="Email" className="mr-1"></img></a> */}
                    {/* <a href="https://www.youtube.com/channel/UC8X2XJW54WFMkWjrYnVJFLg" className="pointer mr-1" target="_blank"><img src={Images.youtube} width="35" alt="Youtube"></img></a> */}
                    <a href="hhttps://www.linkedin.com/company/jobam/" className="pointer" target="_blank"><img src={Images.linkedIn} width="36" alt="Linkedin"></img></a>
                    {/* <a href="https://www.pinterest.ca/jhatpatjobs/pins/" className="pointer ml-1" target="_blank"><img src={Images.pinterest} width="36" alt="Pinterest"></img></a> */}
                  </div>
                  {/* For Mobile device */}
                  <div className="ml-3 mt-1 d-block d-sm-none d-md-none d-lg-none d-xl-none">
                    <a href="https://twitter.com/jobam_co" className="pointer" target="_blank"><img src={Images.twitter} width="35" alt="Twitter"></img></a>
                    <a href="https://www.facebook.com/jobam.co/" className="pointer mr-1" target="_blank">
                      <img src={Images.facebook} width="28" alt="Facebook" />
                      {/* <i className="fab fa-facebook-f text-success-gradient"></i> */}
                    </a>
                    <a href="https://www.instagram.com/jobam.co/" className="pointer" target="_blank"><img src={Images.instagram} width="28" className="mr-1" alt="Instagram"></img></a>
                    {/* <a  href="mailto:contact@jobam.co" className="google-plus" target="_blank"><img src={Images.gmail} alt="Mail" width="35" className="mr-1"></img></a> */}
                    {/*<a href="https://www.youtube.com/channel/UC8X2XJW54WFMkWjrYnVJFLg" className="pointer mr-1" target="_blank"><img src={Images.youtube} width="28" alt="Youtube"></img></a>*/}
                    <a href="https://www.linkedin.com/company/jobam/" className="pointer" target="_blank"><img src={Images.linkedIn} width="28" alt="Linkedin"></img></a>
                    {/*<a href="https://www.pinterest.ca/jhatpatjobs/pins/" className="pointer ml-1" target="_blank"><img src={Images.pinterest} width="28" alt="Pinterest"></img></a>*/}
                  </div>
                  {/* For mobile device end */}
                </div>
                <br></br>
                <div className="ml-3">
                <h4 className="text-white d-none d-sm-block d-md-block"> <strong>Quick Links</strong></h4>
                  <h6 className="text-white mt-1 d-block d-sm-none d-md-none text-uppercase"> <strong>Quick Links </strong></h6>
                <div className="row col-md-12 pl-0">
                  <ul className="list-unstyled text-secondary col-md-5 col-5  p-0 text-left">
                    <a href="/" className="text-decoration-none pointer"> <li className="col-md-12 col-12 text-white">Home</li></a>
                    <a href="#about" className="text-decoration-none pointer"><li className="col-md-12 col-12 text-white">Mission</li></a>
                    <a href="#more-features" className="text-decoration-none pointer"><li className="col-md-12 col-12 text-white">Features</li></a>
                  </ul>
                  <ul className="list-unstyled text-secondary col-md-5 col-5  p-0 text-left">
                    <li className="col-md-12 col-12 text-secondary">
                      <a
                        href="https://jobam.co/employerzone/login.php"
                        target="_blank"
                        className="text-decoration-none text-white"
                      >
                        {" "}
                          Post a Job
                        </a>
                    </li>
                    <Link to="/mainPage" className="text-decoration-none pointer"><li className="col-md-12 col-12 text-white">Find Jobs</li></Link>
                    <Link to="/interviewtips" className="text-decoration-none pointer"><li className="col-md-12 col-12 text-white">Inteview Tips</li></Link>
                  </ul>
                  <ul className="list-unstyled text-secondary col-md-2 col-2  p-0 text-left">
                    <Link to="/privacypolicy" className="text-decoration-none pointer"> <li className="col-md-12 col-12 text-white">Privacy</li></Link>
                    <Link to="/faq" className="text-decoration-none pointer"> <li className="col-md-12 col-12 text-white">FAQ</li></Link>
                  </ul>
              </div>
              </div>
                {/* <div id="hubspotForm"></div> */}
              </div>
              <div className="col-lg-8 col-md-8 mt-3">
                <h5 className="text-white borderBottom pb-3"><strong>Search Jobs by City</strong></h5>
                <div border="0" className="table">
                  <div className="row">
                    {this.state.cities != null && this.state.cities != "" && this.state.cities != undefined ?
                      this.state.cities.map((item) =>
                        <Link
                          key={item.cityName}
                          to={{
                            pathname: "/joblist",
                            state: { cityName: item.cityName },
                            search: "jobs-in-" + item.cityName
                          }} className={item.cityName.length>16 ? "text-decoration-none col-md-4 col-5 mb-1" : "text-decoration-none col-md-3 col-6 mb-1"}>
                          <span className="text-white text-capitalize" onClick={(e) => this.cityValue(item)}>
                            {item.cityName}
                          </span>
                        </Link>
                      ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*For Footer Copyright*/}
        <footer id="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-lg-center text-center">
                <div className="copyright">
                  &copy; Copyright 2022 <Link to="/" className="text-decoration-none text-white"><strong>Jobam</strong></Link>. All Rights Reserved
                 </div>
              </div>
            </div>
          </div>
        </footer>
        <a href="#" className="back-to-top z-99"><i className="fa fa-chevron-up"></i></a>
      </div>
    );
  }
}
