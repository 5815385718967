import React, { Component } from "react";
import Images from "../Images";
import { Link, Route } from "react-router-dom";
import helpers from "../config";
import Strings from "../utilities/stringlanguage";
import CardComponent from "../components/cardComponent";
import LoadingOverlay from 'react-loading-overlay';
import { getJobs, savedJobFun } from '../actions/myJobsAction';
import { connect } from "react-redux";
import Footer from "../components/footer";
import Webheader from "../components/webheader";
import ModalDiv from "../components/modal";
const moment = require("moment");
var categoryChosen = "All";
var jobseekerId;
var dataApplied = [];
var isApplied;
var isShortlisted;
var isHired;
var isScheduled;
var isSaved;
var companyId;
var selectedLang;
var url;
var imageUrl;
var myJobsDataNew = [];
class AppliedJob extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataFound: false,
      loading: true,
      jobseekerId: "",
      show: false,
      myIndex: 0,
      myAppliedData: dataApplied,
    };
  }

  async componentDidMount() {
    let apiUrl = await helpers.getServerUrl()
    url = apiUrl.serverUrl
    imageUrl = apiUrl.imageUrl
    selectedLang = localStorage.getItem("selectedLang")
    if (selectedLang != null && selectedLang != undefined && selectedLang != "") {
      Strings.setLanguage(selectedLang);
    }
    this.state.isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
    isApplied = this.props.location.state.isApplied;
    isShortlisted = this.props.location.state.isShortlisted;
    isHired = this.props.location.state.isHired;
    isScheduled = this.props.location.state.isScheduled;
    isSaved = this.props.location.state.isSaved;
    companyId = localStorage.getItem("companyId");
    jobseekerId = localStorage.getItem("jobseekerId");
    this.appliedStatus();
    window.scroll(0, 0);
  }

  showModal = e => {
    this.setState({
      show: true
    });
  };

  hideModal = e => {
    this.setState({ show: false });
  };

  saveThisJob = async (item) => {
    try {
      let response = await fetch(url + "/jobs/addJobsToFavourites", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          jobseekerId: jobseekerId,
          jobId: item.jobId,
          isSaved: item.isSaved,
        })
      })
      response = await response.json();
      if (response.success) {
        if(response.msg=="Job saved successfully"){
          this.setState({
            msg: Strings.JobSavedSuccessfully,
          });
          this.showModal();
          }
        this.appliedStatus();
      }
    }
    catch (err) {
    }
  }

  appliedStatus = async (e) => {
    categoryChosen = isApplied
      ? "APPLIED"
      : isShortlisted
        ? "SHORTLISTED"
        : (isHired == true)
          ? "HIRED"
          : (isSaved == true)
            ? "SAVED JOBS"
            : (isScheduled == true)
              ? "SCHEDULED"
              : null;
    let jobStatus = categoryChosen
    this.props.getJobs(jobStatus);
  }

  render() {

    let { myJobsData, myJobsDataLoading, myJobsDataError, saveJobsLoading } = this.props;
    if (myJobsData != null && myJobsData != null && myJobsData != null) {
      myJobsDataNew = myJobsData.myApplications;
    }
    let loadingText = isApplied
      ? Strings.loadingAppliedJobs
      : isShortlisted
        ? Strings.loadingShortlistedJobs
        : isHired
          ? Strings.loadingHiredJobs
          : isScheduled
            ? Strings.loadingScheduledJobs
            : Strings.myJobsCaps;
    let pageHeading = isApplied
      ? Strings.AppliedJobs
      : isShortlisted
        ? Strings.ShortlistedJobs
        : isHired
          ? Strings.HiredJobs
          : isScheduled
            ? Strings.ScheduledJobs
            : Strings.myJobsCaps;
    let noDataText = (categoryChosen == "APPLIED")
      ? Strings.appliedNoData
      : categoryChosen == "SHORTLISTED"
        ? Strings.shortlistedNoData
        : categoryChosen == "HIRED"
          ? Strings.hiredNoData
          : categoryChosen == "SCHEDULED"
            ? Strings.scheduledNoData
            : Strings.allNoData;
    return (
      <div className="loaderoverlay">
        <div className="container col-md-6 No-Pading  col-lg-6 col-sm-6 col-12 pb-1">
          <Webheader backLink="/profile" pageName={pageHeading}/>
          <div className="pt-2">
          </div>
          {myJobsData == null ||
            myJobsData == "" ||
            myJobsData == undefined
            ?
            <ModalDiv getImage={Images.noRecord} textMsg={noDataText}/>
            :
            <LoadingOverlay
              active={myJobsDataLoading}
              spinner={<div className="loading-applied ">
                <center className=" bg-dark2 rounded mt-5 col-md-6 col-sm-6 p-2 col-12">
                  <h4 className="loadingText pt-1  mb-1 d-sm-block d-md-block">
                    {loadingText}
                  </h4>
                  <h6 className="text-white pt-1 pb-1 d-block d-sm-none d-md-none"></h6>
                  <img src={Images.loaderWhite} alt="Loading Img" className="pb-2" width="30" />
                </center>
              </div>}
            >
              {myJobsDataNew.length > 0 ? myJobsDataNew.map((item) =>
                <div key={item.jobId}>
                  <CardComponent key={item.jobId} jobType={item.jobType} companyName={item.companyName} jobTitle={item.jobTitle} salary={item.salary}
                    createdDate={item.createdDate} status={item.status} jobLink={item.jobLink} jobId={item.jobId}
                    isShortlisted={item.isShortlisted} companyImage={imageUrl + item.companyImage}
                    isSaved={item.isSaved}
                    callSaved={i => this.saveThisJob(item)}
                  />
                </div>
              ) : <ModalDiv getImage={Images.noRecord} textMsg={noDataText}/>}
            </LoadingOverlay>
          }
        </div>
        <ModalDiv modalReact={true}  hideModal={()=>this.hideModal()} crossBtnModal={true} showModal={this.state.show} msg={this.state.msg} btn1Click={() => this.hideModal()} btn1Text={Strings.oK}/>
        <div className="container-fluid bg-light col-md-6 col-lg-6 col-sm-6 col-12 No-Pading">
          <Route component={Footer} className="d-none d-md-block d-lg-block d-sm-block d-xl-block" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    myJobsData: state.myJobsR.myJobsData,
    myJobsDataLoading: state.myJobsR.myJobsDataLoading,
    myJobsDataError: state.myJobsR.myJobsDataError,
    saveJobsData: state.myJobsR.saveJobsData,
    saveJobsLoading: state.myJobsR.saveJobsLoading,
    saveJobsError: state.myJobsR.saveJobsError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getJobs: (jobStatus) => dispatch(getJobs(jobStatus)),
    savedJobFun: item => dispatch(savedJobFun(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppliedJob);