import React, { Component } from "react";
import helpers from "../config";
import LoadingOverlay from "react-loading-overlay";
import Images from "../Images";
import {Modal} from "react-bootstrap";
import Strings from "../utilities/stringlanguage";
import {getPassingYearData , getEducationByJobseekerID} from "../actions/educationDetailAction";
import {connect} from 'react-redux';
import ModalDiv from "../components/modal";
var jobseekerId;
var url, imageUrl,  yearsData = [], hsscData;
var values = false;
const Compress = require('compress.js');
class HsscDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataFound: false,
      loading: true,
      hsscSchool: "",
      hsscPercent: "",
      hsscPassYear: "",
      };

    this.selectHSSCFile = this.selectHSSCFile.bind(this);
  }

  async componentDidMount() {
    let apiUrl =  await helpers.getServerUrl()
    url = apiUrl.serverUrl
    imageUrl = apiUrl.imageUrl
    this.state.isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
    jobseekerId = localStorage.getItem("jobseekerId");
    this.props.getPassingYearData();
    this.props.getEducationByJobseekerID();
    let {allEducationData , passingYearData , allEducationDataLoading , allEducationDataError} = this.props;
  }


   selectHSSCFile(e) {
    let compress = new Compress();
    let files = e.target.files[0];
     let resizedImage = compress.compress([files], {
      size: 0.10, // the max size in MB, defaults to 2MB
      quality: 0.75, // the quality of the image, max is 1,
      maxWidth: 300,
      maxHeight: 300, 
      resize: false 
    }).then((data) => {
      // returns an array of compressed images
     
      this.setState({ baseMarksheet: data[0].data });
    })
    this.setState({
      image: URL.createObjectURL(e.target.files[0])
    });
   }
   
 
 
 addHSSCEducation = async() => {
    try {
    let response = await fetch(imageUrl + "jobseeker/updateProfile2", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        jobseekerId: jobseekerId,
        hsscExamName: "HSSC",
        hsscPassingYear: this.state.hsscPassYear,
        hsscPercentage: this.state.hsscPercent,
        hsscSchoolName: this.state.hsscSchool,
        twelthMark: this.state.baseMarksheet,
        imageName: jobseekerId + "_12mark.jpg"
      })
    })
    response = await response.json();
        if (response != null) {
          let res = response[0];
          if (res.success) {
            this.setState({ complete: true, msgcomplete:  Strings.educationaddedsuccess,});
           }
          else {
            this.showModal();
          this.setState({ msg: res.msg });
          }
        }
      }
      catch(err)
      {
      }
  }



  setPassingYearValue (selectedValue) {
    this.setState({ hsscPassYear: selectedValue })
  }

  setHsscPercentage = (e) => {
    this.setState({ hsscPercent: e.target.value })
  }

  handleHsscName = (e) => {
    this.setState({ hsscSchool: e.target.value })
  }

  showModal = (e) => {
    this.setState({ show: true });
  };

  hideModal = (e) => {
    this.setState({ show: false });
  };

  hideModalComplete = (e) => {
    this.setState({ complete: false });
  }
  validationHssc = () => {
    let hsscName = this.state.hsscSchool != null && this.state.hsscSchool != undefined && this.state.hsscSchool != "" ? this.state.hsscSchool.trim() : "";
    if (
      hsscName != "" &&
      hsscName != null &&
      hsscName != undefined
    ) {
      this.addHSSCEducation();
    } else {
      this.showModal();
      this.setState({ msg: Strings.fillSchool });
    }
  }

   deleteTwelthMarksheet = async() => {
    this.setState({ showDeleteModal: false, markDeleteDialog: true, });
    try {
    let response = await fetch(imageUrl + "jobseeker/deleteTwelthMarksheet", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        jobseekerId: jobseekerId 
      })
    })
    response = await response.json();
      if (response[0].success) {
          this.setState({image : "", twelthMark : ""})
         }
      }
      catch (err)
      {
      }
       
  }
  markTwelthDialog = () => {
    this.setState({ markDeleteDialog: false })
  }
  deleteTwelthModal = () => {
    this.setState({ showDeleteModal: true })
  }
  hideTwelthDeleteModal = () => {
    this.setState({ showDeleteModal: false })
  }


UNSAFE_componentWillReceiveProps(nextProps) {
  if (nextProps.allEducationData[0] != undefined && nextProps.allEducationData[0] != "") {
    if (values) {
      this.setState({
        hsscSchool : this.state.hsscSchool,
        hsscPercent : this.state.hsscPercent,
        hsscPassYear : this.state.hsscPassYear,
        twelthMark : this.state.twelthMark
      })
    }
    else {
      this.setState({
        hsscSchool : nextProps.allEducationData[0].hsscSchoolName,
        hsscPercent : nextProps.allEducationData[0].hsscPercentage,
        hsscPassYear : nextProps.allEducationData[0].hsscPassingYear,
        twelthMark : nextProps.allEducationData[0].twelthMark
      })
    }

  }
}

  render() {
    let {allEducationData , passingYearData , allEducationDataLoading , allEducationDataError} = this.props;
    let responseDataYear = passingYearData;
    responseDataYear.forEach(responseDataYear => {
      yearsData.push({
        value: responseDataYear.year,
      });
    });
   
   return (
      <LoadingOverlay
        active={allEducationDataLoading}
        spinner={<div className="loading-educationdetail">
          <center className=" bg-dark3 rounded mt-5 col-md-10 col-sm-6 p-2 col-12">
            <h4 className="loadingText pt-1 mb-1 d-sm-block d-md-block">{Strings.loadingEducation}</h4>
            <h6 className="text-white pt-1 pb-1 d-block d-sm-none d-md-none"></h6>
            <img src={Images.loaderWhite} alt="img" className="pb-2" width="30" />
          </center>
        </div>}
        >
        <div>
          <div className="container-fluid col-md-12 col-lg-12 col-sm-12 col-12 mt-3">
            <div className="tab-pane fade show active" id="pills-pg" role="tabpanel" aria-labelledby="pills-pg-tab">
              <div className="form-group">
          <label className="inputHeadingText">{Strings.SchoolNAME}</label>
                <input onChange={(e) => this.handleHsscName(e)} type="text"
                  value={this.state.hsscSchool} className="form-control shadow-sm inputHeadClass" placeholder={Strings.EnterYourSchool} />
              </div>
                <div className="form-group">
                <label className="inputHeadingText">{Strings.marksObtainedCaps}</label>
                <input value={this.state.hsscPercent} onChange={this.setHsscPercentage} type="number" className="form-control shadow-sm inputHeadClass" placeholder={Strings.EnterPercentage}/>
              </div>
              <div className="form-group">
          <label className="inputHeadingText">{Strings.passingYear}</label>
                <select value={this.state.hsscPassYear} className="form-control shadow-sm inputHeadClass" onChange={e => this.setPassingYearValue(e.target.value)}>
                  {yearsData != null && yearsData != "" && yearsData != undefined ?
                    yearsData.map((item, index) => (
                      <option key={index}>{item.value}</option>
                    ))
                    : null
                  }
                </select>
              </div>
            
              <div className="form-group">
                <div className="d-flex justify-content-between">
                <label className="inputHeadingText">{Strings.uploadYourHSSCMarksheet}</label>
                  {this.state.twelthMark != null && this.state.twelthMark != "" && this.state.twelthMark != undefined ?
                    <span className="text-success pointer" onClick={this.deleteTwelthModal}>{Strings.Delete}</span>
                    : null}
                </div>
                <label className="custom-file-upload roundedField">
                  <input type="file" accept="image/*"
                  onChange={this.selectHSSCFile} />
                  <center>
                    {this.state.image != null && this.state.image != "" && this.state.image != undefined ? <img height="100" alt="twelthMark" className="col-md-12 col-12" src={this.state.image}></img> :
                     this.state.twelthMark != null && this.state.twelthMark != "" && this.state.twelthMark != undefined
                      ? <img height="100" alt="twelthMark" className="col-md-12 col-12 shadow-sm" src={imageUrl + "jobseeker/getImg/" + this.state.twelthMark + "?" + Date.now()}></img> : <div className="w-100 p-3 dashed-success"><i className="fa fa-plus"></i> Add File</div>}
                  </center>
                </label>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div onClick={this.validationHssc} type="submit" className="btn btn-success  col-md-12 col-12 mt-3 rounded">{Strings.update}</div>
            </div>
          </div>
          <ModalDiv modalReact={true} showModal={this.state.show} crossBtnModal={true}  msg={this.state.msg} btn1Text={Strings.oK} btn1Click={()=>this.hideModal()}  hideModal={()=>this.hideModal()}/>
         
          <ModalDiv modalReact={true} showModal={this.state.complete} crossBtnModal={true} successModal={true} msg={this.state.msgcomplete} btn1Text={Strings.oK} btn1Click={()=>this.hideModalComplete()}  hideModal={()=>this.hideModalComplete()}/>

          <ModalDiv modalReact={true} showModal={this.state.showDeleteModal} crossBtnModal={true} successModal={true} twoButton={true} msg={Strings.deletemarkSheetDialog} btnFirst={Strings.oK} btnFirstClick={()=>this.deleteTwelthMarksheet()}  btnSecond={Strings.cancelCaps}  btnSecondClick={this.hideTwelthDeleteModal} hideModal={()=>this.hideTwelthDeleteModal()}/>

          <ModalDiv modalReact={true} showModal={this.state.markDeleteDialog} crossBtnModal={true}  successModal={true} msg={Strings.markSheetDeletesuccessfully} btn1Text={Strings.oK} btn1Click={()=>this.markTwelthDialog()} hideModal={()=>this.markTwelthDialog()}/>
        </div>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = state => {
  return {
    passingYearData : state.educationDetailReducer.passingYearData,
    passingYearDataLoading: state.educationDetailReducer.passingYearDataLoading,
    allEducationData : state.educationDetailReducer.allEducationData,
    allEducationDataError: state.educationDetailReducer.allEducationDataError,
    allEducationDataLoading : state.educationDetailReducer.allEducationDataLoading,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getEducationByJobseekerID : () => dispatch(getEducationByJobseekerID()),
    getPassingYearData : () => dispatch(getPassingYearData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HsscDetail);