/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import '../_style/mainpage.css';
import NavBar from '../components/webNavBar';
import Footer from '../components/webFooter';
import Images from "../Images";
import { Link, withRouter } from 'react-router-dom';
import helpers from "../config";
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import { getCities,getFiltersWithIcons } from "../actions/jobListAction";
import { connect } from "react-redux";
var allCities = [];
var url;
var imageUrl;
var title;
var description;
var keywords;
var cities;
var allProfiles = [];
class MainPage extends React.Component {
  constructor() {
    super();
    this.state = {
      jobTypeName: "",
      searchText:"",
      location: "",
      selectedCity: "",
      city: ""
    }
  }

  async componentDidMount() {
    this.abortController = new AbortController();
    let apiUrl = await helpers.getServerUrl()
    url = apiUrl.serverUrl
    imageUrl = apiUrl.imageUrl
    localStorage.setItem("city", this.state.city)
    localStorage.setItem("searchText", this.state.searchText)
    localStorage.setItem("cityLat", "")
    localStorage.setItem("cityLong", "")
    localStorage.setItem("jobProfile", "")
    localStorage.setItem("jobtype", "");
    localStorage.setItem("experience", "");
    localStorage.setItem("qualification", "");
    $("#pop1").on("click", function () {
      $('#imagepreview').attr('src', $('#imageresource1').attr('src'));
      $('#imagemodal').modal('show');
    });
    $("#pop2").on("click", function () {
      $('#imagepreview').attr('src', $('#imageresource2').attr('src'));
      $('#imagemodal').modal('show');
    });
    $("#pop3").on("click", function () {
      $('#imagepreview').attr('src', $('#imageresource3').attr('src'));
      $('#imagemodal').modal('show');
    });
    $("#pop4").on("click", function () {
      $('#imagepreview').attr('src', $('#imageresource4').attr('src'));
      $('#imagemodal').modal('show');
    });
    $("#pop5").on("click", function () {
      $('#imagepreview').attr('src', $('#imageresource5').attr('src'));
      $('#imagemodal').modal('show');
    });
    $("#pop6").on("click", function () {
      $('#imagepreview').attr('src', $('#imageresource6').attr('src'));
      $('#imagemodal').modal('show');
    });
    this.props.getCities(); 
    this.props.getFiltersWithIcons();
    window.scroll(0,0);
  }

  onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.handleSearch();
    }
  }

  handleSearch = () => {
    if (this.state.city == "") {
      localStorage.setItem("city", "");
      localStorage.setItem("cityLat", "");
      localStorage.setItem("cityLong", "");
    }
    this.props.history.push('/joblist');
  }

  onChangeHandler = (e) => {
    localStorage.setItem("searchText", e.target.value);
    this.setState({searchText : e.target.value})
  }

  cityValue = (item) => {
    localStorage.setItem("city", item.cityName);
    this.setState({ city: item.cityName })
    if (cities !== undefined) {
      let c = cities;
      c.forEach(city => {
        if (city.name == item.cityName) {
          localStorage.setItem("cityLat", city.position.coordinates[1]);
          localStorage.setItem("cityLong", city.position.coordinates[0]);
        }
      })
    }
  }
  componentWillUnmount() {
    this.abortController.abort();
  }
  render() {
    
    let { getCityData, getFilterIconData } = this.props;
    if (getCityData != "" && getCityData != undefined) {
      var responseCities = getCityData.cities;
      title = getCityData.title
      description = getCityData.description
      keywords = getCityData.keywords
      allCities = responseCities;
    }
    if (getFilterIconData != "" && getFilterIconData != undefined) {
      allProfiles = getFilterIconData;
    }
    if(title!=undefined){
    document.title = title;
    document.getElementsByTagName("META")[2].content = description;
    document.getElementsByTagName("META")[3].content = keywords;
    }
    return (
      <div>
        <NavBar />
        {/*For Intro Section*/}
        <section id="intro">
          <div className="intro-text">
            <h1 className="mb-4">
              <strong>Dream Job For Everyone!</strong>
            </h1>
            <br />
            <div className="searchBox w-75 mb-4">
              <div className="row pt-md-4 pb-md-3 pl-md-5 pr-md-0 ml-md-3 pt-2 pl-2 pr-2">
                <div className="col-md-4 col-sm-4 col-12 mb-md-0 mb-2">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text myInput-group" id="basic-addon1"><i className="fa fa-briefcase text-white"></i></span>
                    </div>
                      <input list="browsers" placeholder="Software Engineer, Sales Executive, Telecaller" name="browser" id="browser" className="form-control"  onChange={e => this.onChangeHandler(e)}/>
                    </div>
                </div>
                <div className="col-md-4 col-sm-4  col-12 mb-md-0 mb-2">
                  <div className="input-group">
                    <div className="input-group-prepend cityprepend">
                      <span className="input-group-text myInput-group" id="basic-addon1"><i className="fa fa-map text-white"></i></span>
                    </div>
                    <div className="form-control pointer cityHeight" data-toggle="modal" data-target="#selectCity" tabIndex="0">
                      <span className={this.state.city != '' && this.state.city != null && this.state.city != undefined ? "float-left city_color selectHolder selectedCityCls" : "float-left city_color selectHolder selectedNotCityCls"}>
                        {this.state.city != '' && this.state.city != null && this.state.city != undefined ? this.state.city : "Select City"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="modal fade " id="selectCity">
                  <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content main-modal-view">
                      <div className="modal-header bg-success citySelect col-md-12">
                        <div className="col-md-2 col-1"></div>
                        <div className="col-md-8 col-9"><p className="text-uppercase font-weight-bold mb-3" >Choose a city</p></div>
                        <div className="col-md-2 col-2"><button type="button" className="close text-white pt-3" data-dismiss="modal">&times;</button>
                        </div>  
                      </div>
                      <div className="modal-body">
                        <div>
                          <div className="row">
                            {allCities.map((item) =>
                              <div key={item.cityName} onClick={(e) => this.cityValue(item)} data-dismiss="modal" className="col-md-3 col-3 No-Pading icon-width">
                                <div className="card-body card-body-home text-center  pointer p-0">
                                  <img src={imageUrl + item.cityIcon} alt="City Image" className="col-md-6 col-8 pl-0 pr-0" />
                                  <h5 className={this.state.city == item.cityName ? "ml-md-0 ml-0 mt-md-0 mt-2 text-success-gradient city-name" : "ml-md-0 ml-0 mt-md-0 mt-2 city-name"}>{item.cityName}</h5>
                                </div>
                              </div>
                            )}
                            <div />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <label className="control-label text-left col-md-3 col-sm-4 col-7 searchButton">
                  <div onClick={() => this.handleSearch()} onKeyDown={this.onKeyDown}>
                    <div type="button" className="btn btn-success col-md-12" tabIndex="0"><i className="fa fa-search text-white"></i> Search</div>
                  </div>
                </label>
              </div>
            </div>
            <div className="naya-box">
              <div className="btn-get-started mobile-res btn-box" onClick={() => window.open("https://jobam.co/employerzone/login.php#signup", "_blank")}>
                <a href='https://jobam.co/employerzone/login.php#signup' style={{textDecoration:'none',color:'#1dc8cd'}} target='_blank' tabIndex="0">
                <i className="fa fa-building mt-4"></i>
                <h5 className="box-text">EMPLOYERS</h5>
                <span className="dividernew1"></span>
                <h5 className="font-weight-normal box-content">Post a Job now</h5>
                </a>
              </div>
              <div className="btn-get-started mobile-res btn-box" style={{textDecoration:'none',color:'#1dc8cd'}} onClick={() => window.open("https://play.google.com/store/apps/details?id=dataflakes.jobs.jhatpatjobs.jobsearch", "_blank")}>
                <a href='https://play.google.com/store/apps/details?id=dataflakes.jobs.jhatpatjobs.jobsearch' style={{textDecoration:'none',color:'#1dc8cd'}} target='_blank' tabIndex="0">
                <i className="fas fa-user-tie mt-4"></i>
                <h5 className="box-text">JOB SEEKERS</h5>
                <span className="dividernew2"></span>
                <h5 className="font-weight-normal box-content">Download App &nbsp;&nbsp;&nbsp;</h5>
                </a>
              </div>
            </div>
          </div>
        </section>
        {/*For Our Mission Section*/}
        <section id="about" className="section-bg">
          <div className="container-fluid">
            <div className="section-header">
              <h3 className="section-title">Our Mission</h3>
              <span className="section-divider"></span>
              <p className="section-description">
              Jobam is an AI driven platform designed for the unique Indian Hyperlocal Jobs.
              </p>
            </div>
            <div className="row">
              <div className="col-lg-6 about-img wow fadeInLeft">
                <img src={Images.aboutImage} alt="About Image" />
              </div>
              <div className="col-lg-6 content wow fadeInRight">
                <h2>Jobam acts as a seasoned matchmaker between Companies & Job Seekers.</h2>
                <h3>A highly intuitive, multilingual platform with conversational capabilities.
              </h3>
                <p>
                  The built-in intelligence simplifies the hiring with an unmatched 5-5-5 approach..
              </p>
                <ul>
                  <li>
                    <i className="far fa-check-circle"></i>
                  &nbsp;Easy Job Posting with as much as 5 clicks.</li>
                  <li>
                    <i className="far fa-check-circle"></i>
                  &nbsp;Closing a Job cycle from posting a Job to tailormade hiring in less than 5 days.</li>
                  <li>
                    <i className="far fa-check-circle"></i>
                  &nbsp;Offering 5 different checks to bring in authenticity and transparency in overall experience.</li>
                </ul>
                <p>The hiring process is usually very frustrating and time consuming. Jobam transforms this to a rewarding effortless experience. It acts as an inhouse recruiter for companies offering superfast closure on a Job posting with the right candidates. Jobam simplifies the process with built-in tools to cut down on logistical challenges to iterate through countless profiles and identify the right fits. The platform also offers rich analytics and reports to help in the decision making process for employers.</p>
              </div>
            </div>
          </div>
        </section>
        {/*For Team Section*/}
        {/*For parallax image 1*/}
        <section id="post-to-action">
          <div className="container container-transparent">
            <div className="row">
              <div className="col-lg-9 text-center text-lg-left">
                <h3 className="cta-title">Employee - Get Best of the Candidates Here!</h3>
                <p className="cta-text"> Click the Post A Job button now</p>
              </div>
              <div className="col-lg-3 cta-btn-container text-center z-99">
                <a className="cta-btn align-middle text-decoration-none" href="https://jobam.co/employerzone/login.php#signup" target="_blank"><i className="fas fa-user-plus"></i> POST A JOB</a>
              </div>
            </div>
          </div>
        </section>
        {/*For HR Compliances Section*/}
        <section id="more-features" className="section-bg">
          <div className="container">
            <div className="section-header">
              <h3 className="section-title">Our HR Compliances</h3>
              <span className="section-divider"></span>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="box wow fadeInLeft">
                  <div className="icon"><i className="fas fa-headphones-alt fa-4x text-success-gradient"></i></div>
                  <h4 className="title">
                    {/* <Link to=""> */}
                      Recruitment Support
                    {/* </Link> */}
                    </h4>
                  <p className="description">Job Posting, Candidate Search, Video Resume, etc.</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="box wow fadeInRight">
                  <div className="icon"><i className="fab fa-black-tie ml-2 fa-4x text-success-gradient"></i></div>
                  <h4 className="title">
                    {/* <Link to=""> */}
                      On Boarding Support
                    {/* </Link> */}
                  </h4>
                  <p className="description">Psychometric test, Service Agreements, Joining Formalities etc.</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="box wow fadeInLeft">
                  <div className="icon"><i className="fas fa-recycle fa-4x text-success-gradient"></i></div>
                  <h4 className="title">
                    {/* <Link to="">  */}
                    Employee Life Cycle Management
                    {/* </Link> */}
                    </h4>
                  <p className="description">HR Policy Design, Training & Development, Ready to use HR forms and templates, Employee Engagement, Disciplinary Action etc.</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="box wow fadeInRight">
                  <div className="icon"><i className="fas fa-money-bill-wave ml-2 fa-4x text-success-gradient" ></i></div>
                  <h4 className="title">
                    {/* <Link to=""> */}
                      Payroll Outsourcing
                    {/* </Link> */}
                    </h4>
                  <p className="description">Compensation structures, Benefit structures, Fringe benefits, payroll design and process</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="box wow fadeInLeft">
                  <div className="icon"><i className="fas fa-business-time fa-4x text-success-gradient"></i></div>
                  <h4 className="title">
                    {/* <Link to="">  */}
                    Compliance Management
                    {/* </Link> */}
                  </h4>
                  <p className="description">Registration and compliance under PF, ESIC, Labor Laws & various acts</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="box wow fadeInRight">
                  <div className="icon"><i className="fas fa-balance-scale-right
                   ml-2 fa-4x text-success-gradient" ></i></div>
                  <h4 className="title">
                    {/* <Link to=""> */}
                      Legal Services
                    {/* </Link> */}
                  </h4>
                  <p className="description">Litigation Management Support and so on.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="row col-md-12 mt-3 mb-2">
        
          <div className="col-md-6 myFb">
            <div className="fb-page w-100" data-href="https://www.facebook.com/jobam.co/" data-tabs="timeline" data-width="1000" data-height="400" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/jobam.co/" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/jobam.co/">Jobam</a></blockquote></div>
          </div>
          <div className="col-md-6">
            <TwitterTimelineEmbed
              sourceType="profile"
              screenName="jobam_co"
              options={{ height: 400, width: 620 }}
            />
          </div>
        </section>
        {/* For App Feature Section */}
        <section id="features">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-4">
                <div className="section-header wow fadeIn" data-wow-duration="1s">
                  <h3 className="section-title">App Features</h3>
                  <span className="section-divider"></span>
                </div>
              </div>
              <div className="col-lg-4 col-md-5 features-img">
                <img src={Images.hand} alt="Hand Icon" className="wow fadeInLeft" />
              </div>
              <div className="col-lg-8 col-md-7 ">
                <div className="row">
                  <div className="col-lg-6 col-md-6 box wow fadeInRight">
                    <div className="icon"><i className="fas fa-street-view fa-2x text-success-gradient"></i></div>
                    <h4 className="title">
                      {/* <Link to=""> */}
                        Real-Time Location-Based Jobs
                      {/* </Link> */}
                    </h4>
                    <p className="description">Jobam provide a map facility for Job Seekers.</p>
                  </div>
                  <div className="col-lg-6 col-md-6 box wow fadeInRight" data-wow-delay="0.2s">
                    <div className="icon"><i className="fas fa-video fa-2x text-success-gradient"></i></div>
                    <h4 className="title">
                      {/* <Link to=""> */}
                        Video Resume
                        {/* </Link> */}
                    </h4>
                    <p className="description">Showcase your skills with video resume.</p>
                  </div>
                  <div className="col-lg-6 col-md-6 box wow fadeInRight" data-wow-delay="0.3s">
                    <div className="icon"><i className="far fa-lightbulb fa-2x text-success-gradient"></i></div>
                    <h4 className="title">
                      {/* <Link to=""> */}
                        Interview Tips
                        {/* </Link> */}
                        </h4>
                    <p className="description">Make a great impression through useful interview tips.</p>
                  </div>
                  <div className="col-lg-6 col-md-6 box wow fadeInRight" data-wow-delay="0.2s">
                    <div className="icon"><i className="fas fa-comments fa-2x text-success-gradient"></i></div>
                    <h4 className="title">
                      {/* <Link to=""> */}
                        Direct Chat
                        {/* </Link> */}
                        </h4>
                    <p className="description">Direct connect & chat between Job Seekers & Companies.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*For Web Feature Section*/}
        <section id="more-features" className="section-bg">
          <div className="container">
            <div className="section-header">
              <h3 className="section-title">Web Features</h3>
              <span className="section-divider"></span>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="box wow fadeInLeft">
                  <div className="icon"><i className="fas fa-robot fa-4x text-success-gradient"></i></div>
                  <h4 className="title">
                    {/* <Link to=""> */}
                      Inbuilt Artificial Assistance 
                    {/* </Link> */}
                    </h4>
                  <p className="description">Chatbot is the new trend, 24*7 assistance available to answer your query.</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="box wow fadeInRight">
                  <div className="icon"><i className="fas fa-smile-beam ml-1 fa-4x text-success-gradient"></i></div>
                  <h4 className="title">
                    {/* <Link to=""> */}
                      Advanced Job Posting
                    {/* </Link> */}
                  </h4>
                  <p className="description">Post every detail of a Job on a single page without any hustle.</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="box wow fadeInLeft">
                  <div className="icon"><i className="fas fa-users fa-4x text-success-gradient"></i></div>
                  <h4 className="title">
                    {/* <Link to="">  */}
                    Reservoir of Applicants
                    {/* </Link> */}
                    </h4>
                  <p className="description">Employers will have a larger pool of applicants beyond the general code of recruitment.</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="box wow fadeInRight">
                  <div className="icon"><i className="fas fa-code-branch ml-2 fa-4x text-success-gradient" ></i></div>
                  <h4 className="title">
                    {/* <Link to=""> */}
                      Direct connection
                    {/* </Link> */}
                    </h4>
                  <p className="description">Ensures that at each instant users are able to connect with each other</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*For parallax image 2*/}
        <section id="call-to-action">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 text-center text-lg-left">
                <h3 className="cta-title">Available on Play Store for Job Seekers!</h3>
                <p className="cta-text"> Click the download button now</p>
              </div>
              <div className="col-lg-3 cta-btn-container text-center z-99" >
                <Link className="cta-btn align-middle text-decoration-none" onClick={() => window.open("https://play.google.com/store/apps/details?id=dataflakes.jobs.jhatpatjobs.jobsearch", "_blank")} ><i className="fab fa-google-play"></i> Download Now</Link>
              </div>
            </div>
          </div>
        </section>
        <section id="gallery">
          <div className="container-fluid">
            <div className="row no-gutters">
              <div className="col-lg-4 col-md-6">
                <div className="gallery-item wow fadeInUp">
                  <a id="pop1">
                    <img src={Images.gallery1} id="imageresource1" alt="Gallery-1" />
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="gallery-item wow fadeInUp">
                  <a id="pop2">
                    <img src={Images.gallery2} id="imageresource2" alt="Gallery-2" />
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="gallery-item wow fadeInUp">
                  <a id="pop3">
                    <img src={Images.gallery3} id="imageresource3" alt="Gallery-3" />
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="gallery-item wow fadeInUp">
                  <a id="pop4">
                    <img src={Images.gallery4} id="imageresource4" alt="Gallery-4" />
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="gallery-item wow fadeInUp">
                  <a id="pop5">
                    <img src={Images.gallery5} id="imageresource5" alt="Gallery-5" />
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="gallery-item wow fadeInUp">
                  <a id="pop6">
                    <img src={Images.gallery6} id="imageresource6" alt="Gallery-6" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="modal fade" id="imagemodal" role="dialog" aria-labelledby="GalleryModal" aria-hidden="true">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content main-modal-view">
              <img src="" id="imagepreview" alt="Inage Preview" className="img-responsive w-100" />
              <button type="button" className="btn btn-default bg-white position-absolute col-md-12 fixed-bottom" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
        {/*Footer*/}
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    getCityData: state.allJobsReducer.getCityData,
    getFilterIconData: state.allJobsReducer.getFilterIconData,

  };
};
const mapDispatchToProps = dispatch => {
  return {
    getCities: () => dispatch(getCities()),
    getFiltersWithIcons: () => dispatch(getFiltersWithIcons()),

  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainPage));