/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import '../_style/webNavBar.css';
import Images from "../Images";
import CheeseburgerMenu from "cheeseburger-menu";
import HamburgerMenu from 'react-hamburger-menu';
var jobseekerName = "";
var isUserLoggedIn = "";
export default class navBar extends React.Component {
  constructor(props) {
    super(props);

    this.listener = null;
    this.state = {
      status: "top",
      menuOpen: false,
    };
  }
  openMenu() {
    this.setState({ menuOpen: true })
  }

  closeMenu() {
    this.setState({ menuOpen: false })
  }

  
  componentDidMount() {
    isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
    jobseekerName = localStorage.getItem("jobseekerName")
    this.listener = document.addEventListener("scroll", e => {
      var scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 120) {
        if (this.state.status !== jobseekerName) {
          this.setState({ status: jobseekerName });
        }
      } else {
        if (this.state.status !== "top") {
          this.setState({ status: "top" });
        }
      }
    });
  }

  componentDidUpdate() {
    document.removeEventListener("scroll", this.listener);
  }
  render() {
    return (
      <div>
        <nav id="header" className={this.state.status === "top" ? "navbar  navbar-expand-lg webNavBarCls" : "navbar  navbar-expand-lg webNavBarClass"}>
          <div className="container">
            <a className="navbar-brand" href="/">
              <div id="logo" className="pull-left">
                <img src={Images.logo} width="225" alt="Header Logo" title="" />
              </div>
            </a>
            <div className="d-block d-md-none">
            <CheeseburgerMenu
              isOpen={this.state.menuOpen}
              closeCallback={this.closeMenu.bind(this)} backgroundColor={'black'}>
              <ul className="navbar-nav ml-2 pb-2" closeCallback={this.closeMenu.bind(this)}>
                <li className="nav-item emp-text active p-2 mr-md-2 border-bottom">
                  <a className="nav-link text-white myText" href="/"><i className="fas fa-home"></i> HOME</a>
                </li>
                {/* <li className="nav-item emp-text p-2 mr-md-2 border-bottom">
                  <a className="nav-link text-white myText" href="/blog"><i className="fas fa-rss"></i> BLOG</a>
                </li> */}
                <li className="nav-item emp-text p-2 mr-md-2 border-bottom">
                  <a className="nav-link text-white myText" href="https://jobam.co/joblist"><i className="fas fa-search"></i> SEARCH A JOB</a>
                </li>
                {isUserLoggedIn == "true" && jobseekerName != null ?
                  <li className="nav-item emp-text p-2 mr-md-2 border-bottom">
                    <a className="nav-link text-white myText" href="https://jobam.co/profile"><i className="fas fa-user"></i> {" "}{jobseekerName}</a>
                  </li> : <li className="nav-item emp-text p-2 mr-md-2 border-bottom">
                    <a className="nav-link text-white myText" href="https://jobam.co/register"><i className="fas fa-user"></i> SIGN IN</a>
                  </li>}
                <li className="nav-item empButton ml-md-2 mt-3 mt-md-0">
                  <a href="https://jobam.co/employerzone/login.php#signup" target="_blank"
                    className="nav-link text-white text-decoration-none pl-3">Employers | Post a Job</a>
                </li>
                <li className="nav-item empButton ml-md-2 mt-3 mt-md-0">
                  <a href="https://play.google.com/store/apps/details?id=dataflakes.jobs.jhatpatjobs.jobsearch" target="_blank"
                    className="nav-link text-white text-decoration-none pl-3">Download App</a>
                </li>
              </ul>
            </CheeseburgerMenu>
            
            <HamburgerMenu
              isOpen={this.state.menuOpen}
              menuClicked={this.openMenu.bind(this)}
              width={28}
              height={22}
              strokeWidth={3}
              rotate={0}
              color='white'
              borderRadius={0}
              animationDuration={0.5}
              className="new-menu-icon"
            />
            </div>
            <div className="justify-content-end d-none d-md-block">
              <ul className="navbar-nav ml-2 pb-2">
                <li className="nav-item emp-text active mr-0  mr-md-2">
                  <a className="nav-link text-white myText" href="/"><i className="fas fa-home"></i> HOME</a>
                </li>
                {/* <li className="nav-item emp-text mr-0  mr-md-2">
                  <a className="nav-link text-white myText" href="/blog"><i className="fas fa-rss"></i> BLOG</a>
                </li> */}
                <li className="nav-item emp-text mr-0 mr-md-2">
                  <a className="nav-link text-white myText" href="https://jobam.co/joblist"><i className="fas fa-search"></i> SEARCH A JOB</a>
                </li>
                {isUserLoggedIn == "true" && jobseekerName != null ?
                  <li className="nav-item emp-text mr-0 mr-md-2">
                    <a className="nav-link text-white myText" href="https://jobam.co/profile"><i className="fas fa-user"></i> {" "}{jobseekerName}</a>
                  </li> : <li className="nav-item emp-text mr-0 mr-md-2">
                    <a className="nav-link text-white myText" href="https://jobam.co/register"><i className="fas fa-user"></i> SIGN IN</a>
                  </li>}
                <li className="nav-item empButton ml-md-2 mt-2 mt-md-0 ">
                  <a href="https://jobam.co/employerzone/login.php#signup" target="_blank"
                    className="nav-link text-white text-decoration-none pl-3">Employers | Post a Job</a>
                </li>
                <li className="nav-item empButton ml-md-2 mt-3 mt-md-0">
                  <a style={{marginLeft: '10px'}} href="https://play.google.com/store/apps/details?id=dataflakes.jobs.jhatpatjobs.jobsearch" target="_blank"
                    className="nav-link text-white text-decoration-none pl-3"><i style={{marginRight: '11px'}} className="fas fa-download"></i>Download App</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}
