import * as actionTypes from '../actions/candidateFaqAction';
const initialState= {
    candidateData: [],
    faqData: [],
    candidateDataLoading: false,
    candidateDataError: null,
};
const candidateFaq = (state = initialState, action) => {
    switch ( action.type ) {
        case actionTypes.FAQ_DATA_LOADING: 
            return{...state, candidateDataLoading: true, candidateDataError: null};
        case actionTypes.SHOW_FAQ_DATA: 
            return{...state, candidateDataLoading: false, candidateData: action.payload.faqs, faqData: action.payload, candidateDataError: null};
        case actionTypes.FAQ_LOAD_FAIL: 
            return{...state, candidateDataLoading: false, candidateData: [], faqData:[], candidateDataError: action.payload};
        default: 
            return state;
    }  
};
 
export default candidateFaq;




