const Images = {
    whatsapp: require('./assets/whatsapp.png'),
    facebook: require('./assets/facebook.png'),
    redLogo: require('./assets/logo_red.png'),
    bookmarkWhite: require('./assets/bookmarkWhite.png'),
    bookmark: require('./assets/bookmark.png'),
    unsaveBookmark: require('./assets/unsaveBookmark.png'),
    saveBookmark: require('./assets/saveBookmark.png'),
    websiteLogo: require('./assets/websitelogo.png'),
    instagram: require('./assets/instagram.png'),
    gmail: require('./assets/gmail.png'),
    linkedIn: require('./assets/linkedIn.png'),
    pinterest: require('./assets/pinterest.png'),
    youtube: require('./assets/youtube.png'),
    twitter: require('./assets/twitter.png'),
    jjLogo :  require('./assets/logo192.png'),
    infomessage :  require('./assets/mobile.png'),
    headerLogo :  require('./assets/jabam2.png'),
    jjIcon : require('./assets/iconpatch.png'),
    loader: require('./assets/loader.gif'),
    logo :  require('./assets/jabam2.png'),
    clearFilter : require('./assets/clearfilters.png'),
    qrwall: require('./assets/qrwall.png'),
    shareWhiteImg :  require('./assets/shareWhite.png'),
    aboutImage: require('./assets/about-img.jpg'),
    hand:require('./assets/hand1.png'),
    footerLogo:require('./assets/footerlogo1.png'),
    gallery1:require('./assets/gallery/desktop-emp.jpg'),
    gallery2:require('./assets/gallery/locationbased.jpg'),
    mappin:require('./assets/pin.png'),
    gallery3:require('./assets/gallery/language.jpg'),
    gallery4:require('./assets/gallery/lapandmobile.png'),
    gallery5:require('./assets/gallery/interviewtips.jpg'),
    gallery6:require('./assets/gallery/twophone.jpg'),
    iconWallpaper: require('./assets/iconwallpaper.png'),
    addIcon : require('./assets/addIcon.png'),
    noRecord : require('./assets/norecordfound.webp'),
    defaultResume : require('./assets/resumeThumbnail.webp'),
    loaderWhite: require('./assets/loaderWhite.gif'),
    load: require('./assets/load.gif'),
    notificationBlack: require('./assets/notificationBlack.png'),
    filterIconDashbaord: require('./assets/filterIconDashbaord.png'),
    defaultPDF : require('./assets/HkZ4jFedH_resume(1).pdf'),
    resumeBuilder : require('./assets/resumeBuilder.png'),
    uploadFile : require('./assets/uploadResume.png'),
    deleteIcon : require('./assets/deleteResume.png'),
    setChatIcon : require('./assets/setting/feedback.png'),
    setContactIcon : require('./assets/setting/contactSupport.png'),
    setShareIcon : require('./assets/setting/share.png'),
    setLogoutIcon : require('./assets/setting/logout.png'),
    chatIcon : require('./assets/chatIcon.png'),
    engLang :  require('./assets/EngB.webp'),
    hindiLang : require('./assets/hindiB.webp'),
    marathiLang : require('./assets/marathiSelect.png'),
    engWhite :  require('./assets/EngA.webp'),
    hindiWhite : require('./assets/hindiA.webp'),
    marathiWhite : require('./assets/marathiUnselect.png'),
    nodataFound: require('./assets/nodatafound.webp'),
    rightTurn: require('./assets/rightTurn.webp'),
    userDefaultImage : require('./assets/userProfile.png'),
    reviewCompany : require('./assets/reviewComp.png'),
    greenStar : require('./assets/greenstar.webp'),
    whiteStar : require('./assets/whitestar.webp'),
    saurabhSharma : require('./assets/saurabh.jpg'),
    deepakJain : require('./assets/deepakjain.jpg'),
    languageIcon: require('./assets/languageicon.png'),
    chaticon: require('./assets/chat.png'),
    notification:require('./assets/notification.png'),
    fafacebook:require('./assets/fa-facebook.png'),
    fainstagram:require('./assets/fa-instagram.png'),
    falinkedin:require('./assets/fa-linkedin.png'),
    fatumblr:require('./assets/fa-tumblr.png'),
    fayoutube:require('./assets/fa-youtube.png'),
    fapinterest:require('./assets/fa-pinterest.png'),
     fatwitter:require('./assets/fa-twitter.png'),

}

export default Images;