/* eslint-disable no-undef */
import React, {Component} from 'react';
import '../ionicons/css/ionicons.min.css';
import CheeseburgerMenu from "cheeseburger-menu";
import HamburgerMenu from 'react-hamburger-menu';
import Footer from '../components/faqFooter';
import {Link} from 'react-router-dom';
import Images from "../Images";
var jobseekerName = "";
export default  class  Faq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };
  }
  componentDidMount() {
    $(document).ready(function(){
      window.scroll(0,0);
   });
  }
  openMenu() {
    this.setState({ menuOpen: true })
  }

  closeMenu() {
    this.setState({ menuOpen: false })
  }
  render() {
    this.state.isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
    jobseekerName = localStorage.getItem("jobseekerName")
    return(
      <div>
       <nav id="header" className="navbar  navbar-expand-lg bg-dark">
        <div className="container">
        <div id="logo" className="pull-left">
            <Link  to="#intro"><img src={Images.logo} width="225" alt="Header Logo" title="" /></Link>
        </div>
        <div className="d-block d-md-none">
            <CheeseburgerMenu
              isOpen={this.state.menuOpen}
              closeCallback={this.closeMenu.bind(this)} backgroundColor={'black'}>
              <ul className="navbar-nav ml-2 pb-2" closeCallback={this.closeMenu.bind(this)}>
                <li className="nav-item emp-text active p-2 mr-md-2 border-bottom">
                  <a className="nav-link text-white myText" href="/"><i className="fas fa-home"></i> HOME</a>
                </li>
                <li className="nav-item emp-text p-2 mr-md-2 border-bottom">
                  <a className="nav-link text-white myText" href="/blog"><i className="fas fa-rss"></i> BLOG</a>
                </li>
                <li className="nav-item emp-text p-2 mr-md-2 border-bottom">
                  <a className="nav-link text-white myText" href="https://jobam.co/joblist"><i className="fas fa-search"></i> SEARCH A JOB</a>
                </li>
                {this.state.isUserLoggedIn == "true" && jobseekerName != null ?
                  <li className="nav-item emp-text p-2 mr-md-2 border-bottom">
                    <a className="nav-link text-white myText" href="https://jobam.co/profile"><i className="fas fa-user"></i> {" "}{jobseekerName}</a>
                  </li> : <li className="nav-item emp-text p-2 mr-md-2 border-bottom">
                    <a className="nav-link text-white myText" href="https://jobam.co/register"><i className="fas fa-user"></i> {" "} SIGN IN</a>
                  </li>}
                <li className="nav-item empButton ml-md-2 mt-3 mt-md-0">
                  <a href="https://jobam.co/employerzone/login.php#signin" target="_blank"
                    className="nav-link text-white text-decoration-none pl-3">Employers | Post a Job</a>
                </li>
                <li className="nav-item empButton ml-md-2 mt-3 mt-md-0">
                  <a style={{marginLeft: '10px'}} href="https://play.google.com/store/apps/details?id=dataflakes.jobs.jhatpatjobs.jobsearch" target="_blank"
                    className="nav-link text-white text-decoration-none pl-3"><i style={{marginRight: '11px'}} className="fas fa-download"></i>Download App</a>
                </li>
              </ul>
            </CheeseburgerMenu>
            
            <HamburgerMenu
              isOpen={this.state.menuOpen}
              menuClicked={this.openMenu.bind(this)}
              width={28}
              height={22}
              strokeWidth={3}
              rotate={0}
              color='white'
              borderRadius={0}
              animationDuration={0.5}
              className="new-menu-icon"
            />
            </div>
        <div className="justify-content-end d-none d-md-block">
        <ul className="navbar-nav ml-2">
          <li className="nav-item">
              <a className="nav-link text-white" href="/"><i className="fas fa-home"></i> HOME</a>
          </li>
          <li className="nav-item emp-text mr-0  mr-md-2">
              <a className="nav-link text-white myText" href="https://jobam.co/blog"><i className="fas fa-rss"></i> Blog</a>
          </li>
          <li className="nav-item">
              <a className="nav-link text-white" href="https://jobam.co/joblist"><i className="fas fa-search"></i> SEARCH A JOB</a>
          </li>
          {this.state.isUserLoggedIn == "true" && jobseekerName != null ?
                  <li className="nav-item">
                    <a className="nav-link text-white " href="https://jobam.co/profile"><i className="fas fa-user"></i> {" "}{jobseekerName}</a>
                  </li> : <li className="nav-item">
                    <a className="nav-link text-white " href="https://jobam.co/register"><i className="fas fa-user"></i> SIGN IN</a>
                  </li>}
          <li className="nav-item empButton ml-md-2 mt-2 mt-md-0 ">
          <a  href="https://jobam.co/employerzone/login.php#signin" target="_blank"
          className="nav-link text-white text-decoration-none pl-3">Employers | Post a Job</a>
          </li>
          <li className="nav-item empButton ml-md-2 mt-3 mt-md-0">
                  <a style={{marginLeft: '10px'}} href="https://play.google.com/store/apps/details?id=dataflakes.jobs.jhatpatjobs.jobsearch" target="_blank"
                    className="nav-link text-white text-decoration-none pl-3"><i style={{marginRight: '11px'}} className="fas fa-download"></i>Download App</a>
                </li>
          </ul>
        </div>
        </div>
        </nav>
        {/*For FAQ*/}
        <section id="faq">
              <div className="container pt-5 pb-3">
                <div className="section-header">
                  <h3 className="section-title">Frequently Asked Questions</h3>
                   <span className="section-divider"></span>
                </div>
                <ul id="faq-list" className="wow fadeInUp col-11 ml-3">
                  <li>
                  <a data-toggle="collapse"  href="#faq1" aria-expanded="true"> What is Jobam? <i className="ion-android-remove"></i></a>
                    <div id="faq1" className="collapse" data-parent="#faq-list">
                      <p>
                       Jobam is a technology-based platform, where employees can post a job and job seeker can find a job.
                      </p>
                    </div>
                  </li>
                  <li>
                    <a  data-toggle="collapse" href="#faq2" className="collapsed"> What Jobam does? <i className="ion-android-remove"></i></a>
                    <div id="faq2" className="collapse" data-parent="#faq-list">
                      <p>
                       In Jobam, Jobs seekers can search for a particular profile where he will get many relevant companies.
                      </p>
                    </div>
                  </li>
                  <li>
                    <a  data-toggle="collapse" href="#faq3" className="collapsed"> How Jobam is different from other applications? <i className="ion-android-remove"></i></a>
                    <div id="faq3" className="collapse" data-parent="#faq-list">
                      <p>
                        It has a GPS system so that one can easily find a job location.<br />
                        This app also provides a video resume option which is very beneficial for job seekers.
                      </p>
                    </div>
                  </li>
                  <li>
                    <a  data-toggle="collapse" href="#faq4" className="collapsed"> How can I apply to a company on Jobam? <i className="ion-android-remove"></i></a>
                    <div id="faq4" className="collapse" data-parent="#faq-list">
                      <p>
                        Go to the filter option and select your specific profile and you will see the list of the companies, select company of your choice and apply for it.
                      </p>
                    </div>
                  </li>
                  <li>
                    <a  data-toggle="collapse" href="#faq5" className="collapsed"> When can I expect a response after I apply to a job? <i className="ion-android-remove"></i></a>
                    <div id="faq5" className="collapse" data-parent="#faq-list">
                      <p>
                        After you apply for a job, you will get a response from the company through emails.
                      </p>
                    </div>
                  </li>
                  <li>
                    <a  data-toggle="collapse" href="#faq6" className="collapsed"> Do I need to pay any charges to get an interview call? <i className="ion-android-remove"></i></a>
                    <div id="faq6" className="collapse" data-parent="#faq-list">
                      <p>
                        No, this app totally free.
                      </p>
                    </div>
                  </li>
                  <li>
                    <a  data-toggle="collapse" className="collapsed" href="#faq7"> Can I apply for more than one job? <i className="ion-android-remove"></i></a>
                    <div id="faq7" className="collapse" data-parent="#faq-list">
                      <p>
                       Yes of course, you can apply for various jobs simultaneously.
                      </p>
                    </div>
                  </li>
                  <li>
                    <a  data-toggle="collapse" href="#faq8" className="collapsed"> How can I update contact details on Jhapat jobs? <i className="ion-android-remove"></i></a>
                    <div id="faq8" className="collapse" data-parent="#faq-list">
                      <p>
                       Go to the profile option, edit your profile and complete the details.
                      </p>
                    </div>
                  </li>
                  <li>
                    <a  data-toggle="collapse" href="#faq9" className="collapsed"> How do I upload a video resume? <i className="ion-android-remove"></i></a>
                    <div id="faq9" className="collapse" data-parent="#faq-list">
                      <p>
                        It has a GPS system so that one can easily find a job location.<br/>
                        This app also provides a video resume option which is very beneficial for job seekers.
                      </p>
                    </div>
                  </li>
                  <li>
                    <a  data-toggle="collapse" href="#faq10" className="collapsed"> Whom to contact when there is any kind of issue? <i className="ion-android-remove"></i></a>
                    <div id="faq10" className="collapse" data-parent="#faq-list">
                      <p>
                        We have chat bot functionality, where you will get regular assistance 24*7.
                      </p>
                    </div>
                  </li>
                  <li>
                    <a  data-toggle="collapse" href="#faq11" className="collapsed"> Does Jobam provide updates? <i className="ion-android-remove"></i></a>
                    <div id="faq11" className="collapse" data-parent="#faq-list">
                      <p>
                        Yes, Jobam provides updates regarding each step by the company through emails and notifications.
                      </p>
                    </div>
                  </li>
                  <li>
                    <a  data-toggle="collapse" href="#faq12" className="collapsed">  Is it useful for fresher? <i className="ion-android-remove"></i></a>
                    <div id="faq12" className="collapse" data-parent="#faq-list">
                      <p>
                        Yes, it is useful for fresher and experienced as well.
                      </p>
                    </div>
                  </li>
                    <li>
                    <a  data-toggle="collapse" href="#faq13" className="collapsed"> Do I have an option to reapply for a particular job? <i className="ion-android-remove"></i></a>
                    <div id="faq13" className="collapse" data-parent="#faq-list">
                      <p>
                        It depends upon the company norms and criteria.
                      </p>
                    </div>
                  </li>
                </ul>

              </div>
            </section>
        <Footer/>
      </div>
    );
  }
}
