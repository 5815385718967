import helpers from "../config";

export const NOTI_DATA_LOADING = "NOTI_DATA_LOADING";
export const NOTI_DATA = "NOTI_DATA";
export const NOTI_FAIL = "NOTI_FAIL";
export const NOTI_TYPE_LOADING = "NOTI_TYPE_LOADING";
export const NOTI_TYPE = "NOTI_TYPE";
export const NOTITYPE_FAIL = "NOTITYPE_FAIL";
export const CLEAR_LOADING = "CLEAR_LOADING";
export const CLEAR_SUCC = "CLEAR_SUCC";
export const CLEAR_FAIL = "CLEAR_FAIL";
export const DEL_LOADING = "DEL_LOADING";
export const DEL_SUCC = "DEL_SUCC";
export const DEL_FAIL = "DEL_FAIL";

export const notiDataList = (jobseekerId) => {
    return (dispatch) => {
      dispatch({type: NOTI_DATA_LOADING})
      helpers.getServerUrl().then(url =>   {
       var url = url.serverUrl
      fetch(url + "/jobs/getNotiByJsId", {
       method: "POST",
       headers: {
         "Content-Type": "application/json"
       },
       body: JSON.stringify({
        jobseekerId: jobseekerId
      })
     })
     .then(response => response.json())
     .then(responseData => {
       dispatch ({
           type:NOTI_DATA,
           payload:responseData,
       })
     })
     .catch(err => {
       dispatch({
           type: NOTI_FAIL,
           payload: err
       })
       console.log('error', err)
   })
  })
}
  }
  export const getNotiTypes = (jobseekerId) => {
    return (dispatch) => {
      dispatch({type: NOTI_TYPE_LOADING})
      helpers.getServerUrl().then(url =>   {
       var url = url.serverUrl
      fetch(url + "/jobseeker/getNotiPrefByJsId", {
       method: "POST",
       headers: {
         "Content-Type": "application/json"
       },
       body: JSON.stringify({
        jobseekerId: jobseekerId
      })
     })
     .then(response => response.json())
     .then(responseData => {
       dispatch ({
           type:NOTI_TYPE,
           payload:responseData.notiPref
       })
     })
     .catch(err => {
       dispatch({
           type: NOTITYPE_FAIL,
           payload: err
       })
       console.log('error', err)
   })
  })
}
  }
  export const clearAllNoti = (jobseekerId) => {
    return (dispatch) => {
      dispatch({type: CLEAR_LOADING})
      helpers.getServerUrl().then(url =>   {
       var url = url.serverUrl
      fetch(url + "/jobseeker/clearAllNotiOfJs", {
       method: "POST",
       headers: {
         "Content-Type": "application/json"
       },
       body: JSON.stringify({
        jobseekerId: jobseekerId
      })
     })
     .then(response => response.json())
     .then(responseData => {
      if(responseData.success){
        dispatch ({
            type:CLEAR_SUCC,
            payload:responseData.success
        })
        return(dispatch(notiDataList(jobseekerId)))
       }
     })
     .catch(err => {
       dispatch({
           type: CLEAR_FAIL,
           payload: err
       })
       console.log('error', err)
   })
  })
}
  }
  export const deleteNotification = (notiIdData,jobseekerId) => {
    return (dispatch) => {
      dispatch({type: DEL_LOADING})
      helpers.getServerUrl().then(url =>   {
       var url = url.serverUrl
      fetch(url + "/jobseeker/clearNotiOfJsByNotiId", {
       method: "POST",
       headers: {
         "Content-Type": "application/json"
       },
       body: JSON.stringify({
        notiId: notiIdData
      })
     })
     .then(response => response.json())
     .then(responseData => {
       if(responseData.success){
       dispatch ({
           type:DEL_SUCC,
           payload:responseData.success
       })
       return(dispatch(notiDataList(jobseekerId)))
      }
     })
     .catch(err => {
       dispatch({
           type: DEL_FAIL,
           payload: err
       })
       console.log('error', err)
   })
  })
}
  }