import React, { Component } from "react";
import helpers from '../config';
import LoadingOverlay from "react-loading-overlay";
import Images from "../Images";
import Strings from "../utilities/stringlanguage";
import { Link } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import '../_style/educationdetail.css';
import ModalDiv from "../components/modal";
import { getJobseekerProfile } from "../actions/profileAction";
import { setFresherData } from "../actions/fresherAction";
var jobseekerId;
var url, imageUrl;
var myData;
var Data;
var skillsData;

class Fresher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      profileSummaryNew: myData,
      profileSummary: myData,
      skillsNew: skillsData,
      skills: skillsData,
      professionalSummary: Data,
      professionalSummaryNew: Data,
      setSummary: false,

    };
  }
  async componentDidMount() {
    let apiUrl = await helpers.getServerUrl()
    url = apiUrl.serverUrl
    imageUrl = apiUrl.imageUrl
    this.state.isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
    jobseekerId = localStorage.getItem("jobseekerId");
    this.props.getJobseekerProfile(jobseekerId);
    this.setState({ profileSummary: myData, profileSummaryNew: myData, professionalSummary: Data, professionalSummaryNew: Data, skillsNew:skillsData, skills:skillsData })
    window.scroll(0, 0);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ profileSummary: nextProps.jobseekerData.profileSummary, profileSummaryNew: nextProps.jobseekerData.profileSummary, professionalSummary: nextProps.jobseekerData.professionalSummary, professionalSummaryNew: nextProps.jobseekerData.professionalSummary, skills: nextProps.jobseekerData.skills, skillsNew: nextProps.jobseekerData.skills })
  }

  skillsHandle = (e) => {
    this.setState({ skills: e.target.value })
  }
  proSummaryHandle = (e) => {
    this.setState({ profileSummary: e.target.value })
  }
  showModal = e => {
    this.setState({ show: true });
  };
  hideModal = e => {
    this.setState({ show: false });
  };
  showModalComplete = e => {
    this.setState({ complete: true });
  }
  hideModalComplete = e => {
    this.setState({ complete: false });
  }

  addFresherData = async (e) => {
    if (this.state.profileSummary !== null && this.state.profileSummary !== "" && this.state.profileSummary !== undefined) {
      if (this.state.profileSummary.trim().length > 20) {
        this.setState({ complete: true, msgcomplete: Strings.workExperienceAddedSuccessfully, setSummary: true, professionalSummary: "" });
        this.props.setFresherData(this.state.skills, this.state.profileSummary);
      }
      else {
        this.showModal();
        this.setState({ msg: Strings.profileSummaryShort });
      }
    }
    else {
      this.showModal();
      this.setState({ msg: Strings.updateProfileSummary });
    }
  }

  updateFresher = () => {
    this.setState({ professionalSummary: "", skills: "" })
  }
  render() {
    let { jobseekerData, jsDataError, jsDataLoading, getFresherData, getFresherLoading, getFresherError } = this.props;
    myData = jobseekerData.profileSummary;
    Data = jobseekerData.professionalSummary;
    skillsData = jobseekerData.experience === "Fresher" ? jobseekerData.skills : "";
    if (this.state.skills == null) {
      this.state.skills = "";
    }
    if (getFresherData != null && getFresherData != "" && getFresherData != undefined) {
      if (getFresherData[0].success) {
        if (this.state.setSummary) {
          this.state.professionalSummary = "";

        }
      }
    }
    return (
      <LoadingOverlay
        active={jsDataLoading}
        spinner={<div className="loading-fresher">
          <center className=" bg-dark3 rounded mt-5 col-md-10 col-sm-6 p-2 col-12">
            <h4 className="loadingText pt-1 mb-1 d-sm-block d-md-block">{Strings.loadingWorkExperience}</h4>
            <h6 className="text-white pt-1 pb-1 d-block d-sm-none d-md-none"></h6>
            <img src={Images.loaderWhite} alt="img" className="pb-2" width="30" />
          </center>
        </div>}
      >
        {this.state.professionalSummary == null || this.state.professionalSummary == "" ||
          this.state.professionalSummary == undefined ?
          <div>
            <div className="container-fluid col-md-12 col-lg-12 col-sm-12 col-12 pt-2">
              <div className="tab-pane fade show active" id="pills-pg" role="tabpanel" aria-labelledby="pills-pg-tab">
                <div className="form-group">
                  <label className="inputHeadingText">{Strings.profileSummary}</label>
                  <input onChange={(e) => this.proSummaryHandle(e)} type="text" className="form-control shadow-sm inputHeadClass" value={this.state.profileSummary} placeholder={Strings.enterYourProfileSummary} />
                </div>
                <div className="form-group">
                  <label className="inputHeadingText">{Strings.skills}</label>
                  <input onChange={(e) => this.skillsHandle(e)} type="text" className="form-control shadow-sm inputHeadClass" value={this.state.skills} placeholder={Strings.enterYourSkills} />
                </div>
                <div className="d-flex justify-content-center">
                  <div type="button" onClick={() => this.addFresherData()} type="submit" className="btn btn-success col-md-12 mt-3 rounded">{Strings.submit}</div>
                </div>
              </div>
            </div>

            <ModalDiv modalReact={true} showModal={this.state.show} msg={this.state.msg} crossBtnModal={true} btn1Text={Strings.oK} btn1Click={()=>this.hideModal()} hideModal={()=>this.hideModal()}/>
            <Link to="/profile">
            <ModalDiv modalReact={true} showModal={this.state.complete} crossBtnModal={true} successModal={true} msg={this.state.msgcomplete} btn1Text={Strings.oK} btn1Click={()=>this.hideModalComplete()} hideModal={()=>this.hideModalComplete()}/>
            </Link>
            
          </div>
          :
          <div className="col-md-10 com-12 fsboxCenter borderRadiusModal">
            <div className="card rounded">
              <h5 className="rounded-pills text-dark text-center mt-3 mb-4 p-2 alreadyExpCls">
                {Strings.youAreAlreadyExperienced}
              </h5>
              <div className="text-center mb-3">
                <Link to="/profile" className="btn bg-success text-white col-md-3 col-4 buttonRounded border-0" data-dismiss="modal">
                  <div type="submit">{Strings.cancelCaps}</div>
                </Link> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				     <button type="submit" className="btn bg-success text-white col-md-3 col-4 buttonRounded border-0" onClick={() => this.updateFresher()} data-dismiss="modal">{Strings.update}</button>
              </div>
            </div>
          </div>
        }
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = state => {

  return {
    jobseekerData: state.profileReducer.jobseekerData,
    jsDataLoading: state.profileReducer.jsDataLoading,
    jsDataError: state.profileReducer.jsDataError,
    getFresherData: state.fresherR.getFresherData,
    getFresherLoading: state.fresherR.getFresherLoading,
    getFresherError: state.fresherR.getFresherError,

  };
};
const mapDispatchToProps = dispatch => {
  return {
    getJobseekerProfile: jobseekerId => dispatch(getJobseekerProfile(jobseekerId)),
    setFresherData: (skills, profileSummary) => dispatch(setFresherData(skills, profileSummary))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Fresher);

