import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../_style/educationdetail.css";
import helpers from "../config";
import Webheader from "../components/webheader";
import PgDetail from "./pgDetail";
import UgDetail from "./ugDetail";
import SscDetail from "./sscDetail";
import HsscDetail from "./hsscDetail";
import Strings from "../utilities/stringlanguage";
import { Route } from 'react-router-dom';
import Footer from "../components/footer";
var url;
var imageUrl;
var jobseekerId;

export default class EducationDetailTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataFound: false,
      loading: true,
    };
  }

  async componentDidMount() {
    let selectedLang;
    let apiUrl = await helpers.getServerUrl()
    url = apiUrl.serverUrl
    imageUrl = apiUrl.imageUrl
    selectedLang = localStorage.getItem("selectedLang")

    if (selectedLang != null && selectedLang != undefined && selectedLang != "") {
      Strings.setLanguage(selectedLang);
    }
    this.state.isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
    jobseekerId = localStorage.getItem("jobseekerId");
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className="container col-md-6 No-Pading col-lg-6 col-sm-6 col-12">
          <Webheader pageName={Strings.educationDetails} backLink="/profile"/>
          <ul className="nav nav-pills-tab nav-fill border-bottom ptop-5" >
           <li className="nav-item">
              <a className="nav-link active text-dark font-weight-bold eduDetail" data-toggle="pill" defaultactivekey="ssc" href="#ssc">{Strings.sscDeta}</a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-dark font-weight-bold eduDetail" data-toggle="pill" href="#hssc">{Strings.hsscDeta}</a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-dark font-weight-bold eduDetail" data-toggle="pill" href="#ug">{Strings.filterUg}</a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-dark font-weight-bold eduDetail"  data-toggle="pill" href="#pg">{Strings.filterPg}</a>
            </li>
          </ul>
          <div className="col-md-12">
            <div className="tab-content">
            <div className="tab-pane container fade" id="ug">
                <div>
                  <UgDetail />
                </div>
              </div>
              <div className="tab-pane container fade" id="pg">
                <div>
                  <PgDetail />
                </div>
              </div>
              <div className="tab-pane container fade show active" id="ssc">
                <div>
                  <SscDetail />
                </div>
              </div>
              <div className="tab-pane container fade" id="hssc">
                <div>
                  <HsscDetail />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid bg-light col-md-6 col-lg-6 col-sm-6 col-12 No-Pading">
          <Route component={Footer} className="d-none d-md-block d-lg-block d-sm-block d-xl-block" />
        </div>
      </div>

    );
  }
}
