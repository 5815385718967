import React, { Component } from "react";
import Images from "../Images";
// import DatePicker from 'react-datepicker';
import helpers from "../config";
import { Modal } from "react-bootstrap";
import Webheader from "../components/webheader";
import "../_style/editProfile.css";
import moment from 'moment';
import Strings from "../utilities/stringlanguage";
import 'react-datepicker/dist/react-datepicker.css';
import ModernDatepicker from 'react-modern-datepicker';
import DatePicker from 'react-date-picker';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoadingOverlay from 'react-loading-overlay';
import DataListInput from 'react-datalist-input';
import { getJobseekerProfile, updateProfileApi } from "../actions/profileAction";
import { connect } from "react-redux";
import ModalDiv from "../components/modal";
var allCities = [];
var jobseekerName;
var jobseekerId;
var otp;
var url;
var imageUrl;
var maxDateForDob;
var mno;
var title;
var keywords;
var description;
var selectedLang;
var body = {
  jobseekerName: "",
  jobseekerId: "",
  dob: "",
  city: "",
  motherName: "",
  fatherName: "",
  emailAdddress: "",
  linkedinUrl: "",
  facebookUrl: "",
  instagramUrl: "", 
  gender: null
};
class EditProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nameText: "",
      mobileNumber: "",
      cityName: "",
      otpView: false,
      mobileLength: 10,
      validNumber: false,
      loading: true,
      dobDate:"",
      skipOtp:"",
      timerOn: true,
      verifyNumber: false,
      age: "",
      gender: "",
      openModal: false,
      otpSent: false,
     updateSuccess : false,
    enterNumberShowModal: false,
    LiName: "",
    FbName: "",
    InName: ""
    };
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  async componentDidMount() {
    let apiUrl = await helpers.getServerUrl()
    url = apiUrl.serverUrl;
    imageUrl = apiUrl.imageUrl;
    selectedLang = localStorage.getItem("selectedLang")
    if (selectedLang != null && selectedLang != undefined && selectedLang != "") {
      Strings.setLanguage(selectedLang);
    }
    jobseekerName = localStorage.getItem("givenName");
    jobseekerId = localStorage.getItem("jobseekerId");
    body.jobseekerId = jobseekerId;
    this.props.getJobseekerProfile(jobseekerId);
    this.getCity();
    this.appSetting()
    maxDateForDob = new Date();
    maxDateForDob.setFullYear( maxDateForDob.getFullYear() - 18 );
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentWillReceiveProps(nextProps) {
       this.setState({
        mobileNumber: nextProps.jobseekerData.phone,
        email: nextProps.jobseekerData.email,
        gender: nextProps.jobseekerData.gender,
        nameText: nextProps.jobseekerData.jobseekerName,
        cityName: nextProps.jobseekerData.city,
        ismobileVerified: nextProps.jobseekerData.ismobileVerified,
        motherName: nextProps.jobseekerData.motherName != null && nextProps.jobseekerData.motherName != undefined && nextProps.jobseekerData.motherName != "" ? nextProps.jobseekerData.motherName : "",
        FaName: nextProps.jobseekerData.fatherName != null && nextProps.jobseekerData.fatherName != undefined && nextProps.jobseekerData.fatherName != "" ? nextProps.jobseekerData.fatherName : "",
        EmName: nextProps.jobseekerData.emailAdddress != null && nextProps.jobseekerData.emailAdddress != undefined && nextProps.jobseekerData.emailAdddress != "" ? nextProps.jobseekerData.emailAdddress : "",
        linkedinUrl: nextProps.jobseekerData.linkedinUrl != null && nextProps.jobseekerData.linkedinUrl != undefined && nextProps.jobseekerData.linkedinUrl != "" ? nextProps.jobseekerData.linkedinUrl : "",
        facebookUrl: nextProps.jobseekerData.facebookUrl != null && nextProps.jobseekerData.facebookUrl != undefined && nextProps.jobseekerData.facebookUrl != "" ? nextProps.jobseekerData.facebookUrl : "",
        instagramUrl: nextProps.jobseekerData.instagramUrl != null && nextProps.jobseekerData.instagramUrl != undefined && nextProps.jobseekerData.instagramUrl != "" ? nextProps.jobseekerData.instagramUrl : "",
        responseDate: nextProps.jobseekerData.dob,
        loading: false,
        mno: nextProps.jobseekerData.phone
      })
      body.gender = this.state.gender
      var date1 = (new Date()).setHours(0, 0, 0, 0);
      var date2 = (new Date(nextProps.jobseekerData.dob)).setHours(0, 0, 0, 0);

      if (date1 == date2) {
        this.setState({ startDate: "" })

      } else {
        if(nextProps.jobseekerData.dob != null){
        let dob = new Date(nextProps.jobseekerData.dob)
        dob.setHours(dob.getHours() + 13);
       this.setState({ startDate: dob,dobDate:dob });
        }
        else{
        this.setState({ startDate: null })
        }
      }
    } catch (err) {
    }
      

  showModal = () => {
    this.setState({
      show: true
    });
  };
  hideModal = () => {
    this.setState({ show: false });
  };

  showModalComplete = () => {
    this.setState({ complete: true });
  }
  hideModalComplete = () => {
    this.setState({ complete: false });
  }

  handleChange(date) {
    var today = new Date();
    var birthDate = new Date(date);  // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    this.setState({ age: age_now });

    if (age_now < 18) {
      this.showModal();
      this.setState({ msg: "Please add the valid DOB" });

    } else {
      // let dateArr=date.split("-")
      // dateArr=dateArr[2]+"-"+dateArr[1]+"-"+dateArr[0]
      this.setState({
        startDate: date,
        dobDate:date
      })
    }
  }

  handleName = e => {

    if (e.target.value.match("^[a-zA-Z ]*$") != null) {
      this.setState({ nameText: e.target.value });
    }
  };

  handlePhoneNo = e => {
    var firstDigit = e.target.value.match(/\d/);
    if (
      (e.target.value !== null)
    ) {
      if (firstDigit == 0) {
        this.setState({ mobileLength: e.target.value.toString().length });
      }
      if ((firstDigit == 9 || firstDigit == 8 || firstDigit == 7 || firstDigit == 6)) {
        this.setState({ mobileLength: e.target.value.toString().length });
      }
      this.setState({ mobileNumber: e.target.value });

    } else {
      e.target.value = "";
    }

  };


  async sendOtp() {
    try {
      this.state.timerOn = true;
      var firstDigit = this.state.mobileNumber.match(/\d/);
      if (firstDigit == 0 && this.state.mobileLength == 11) {
        this.state.validNumber = true;
      }
      else if ((firstDigit == 9 || firstDigit == 8 || firstDigit == 7 || firstDigit == 6) && this.state.mobileLength == 10) {
        this.state.validNumber = true;
      } else {
        this.state.validNumber = false;

      }
      if (this.state.validNumber == true) {

        if (this.state.mobileNumber == this.state.mno) {
          this.showModal();
          this.setState({ msg: Strings.alreadyVerifedNum });

        } else {
          this.timer(60);
          let response = await fetch(url + "/jobseeker/sendOtpMobileNew", {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              phone: this.state.mobileNumber,
              jobseekerId: jobseekerId
            })
          })
          let responseData = await response.json();
          if (responseData[0].success) {
            this.setState({ ismobileVerified: false });
          }
        }
      } else {
        this.showModal();
        this.setState({ msg: Strings.PleaseEnterValidNumber });
      }
    } catch (err) {
    }
  }

  handleOtp = e => {
    otp = e.target.value;
  };

  timer(remaining) {

    if (remaining >= 0 && this.state.timerOn) {
      document.getElementById('timer').classList.remove('d-none');
      document.getElementById('resendcode').classList.add('d-none');

      const objThis = this;
      var m = Math.floor(remaining / 60);
      var s = remaining % 60;

      m = m < 10 ? '0' + m : m;
      s = s < 10 ? '0' + s : s;
      document.getElementById('timer').innerHTML = m + ':' + s;
      remaining -= 1;

      setTimeout(function () {  
        objThis.timer(remaining);
      }, 1000);
      return;
    }
    if (!this.state.timerOn) {
      // Do validate stuff here
      return;
    }

    if (this.state.otpSent === false) {
      document.getElementById('timer').classList.add('d-none');
      document.getElementById('resendcode').classList.remove('d-none');
    }
  }

  async verifyOtp() {
    this.setState({ verifyNumber: true, openModal: false,  })
    if(otp !== "" && otp !== undefined){
    try {
      let response = await fetch(url + "/jobseeker/validateMobileOTP", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          mobileVeriOtp: otp,
          phone: this.state.mobileNumber,
          jobseekerId: jobseekerId
        })
      })
      let responseData = await response.json();
      document.getElementById("otp").value = "";  
      if (responseData[0].success) {
        this.timer(0);
        this.setState({ timerOn: false });
        var element1 = document.getElementById("showtime");
        element1.classList.add("d-none");
        element1.classList.remove("d-block");
        this.setState({ ismobileVerified: true, mno: this.state.mobileNumber });
        this.showModalComplete();
        this.setState({ msgcomplete: Strings.OtpverifiedSuccessfully, verifyNumber: false });
      } else {
        this.showModal();   
        this.setState({ msg: Strings.invalidOtp,verifyNumber: true});
        // this.condition();
      }
  } catch (err) {
    }
  }else{
    this.showModal();
    this.setState({ msg:"Please enter the OTP",verifyNumber: true});
  }
  }

  async skipOtp() {
    try {
      let response = await fetch(url + "/jobseeker/validateMobileOTP", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          mobileVeriOtp: otp,
          phone: this.state.mobileNumber,
          jobseekerId: jobseekerId
        })
      })
      let responseData = await response.json();
      document.getElementById("otp").value = "";  
      if (responseData[0].success) {
        this.showModalComplete();
        this.setState({ ismobileVerified: true,msgcomplete: Strings.OtpverifiedSuccessfully,verifyNumber: false });
      } 
    }
   catch (err) {
    }
  }
  genderValue(item) {
    this.setState({ gender: item });
    body.gender = item;
  }

  validatecharacters(jobseekerName) {
    if (jobseekerName != null) {
      var re = /^[a-zA-Z\s]+$/;
      return re.test(jobseekerName);
    } else {
      return false;
    }
  }

  validateProfile() {
    let jobseekerName = this.state.nameText.trim();
    let validCity = false;
    for(let i=0; i< allCities.length; i++){
    if(allCities[i].value == this.state.cityName){
      validCity = true;
    }
    }

    if ((jobseekerName != null && jobseekerName != '' && jobseekerName != undefined ? body.jobseekerName = jobseekerName : null )
      && (this.state.cityName != null && this.state.cityName != ''
        && this.state.cityName != undefined ? body.city = this.state.cityName : null) && (this.state.startDate != null
          && this.state.startDate != '' && this.state.startDate != undefined ? body.dob = this.state.startDate : null)
      &&(this.state.mobileNumber != null && this.state.mobileNumber != '' && this.state.mobileNumber != undefined
     )&&(this.state.linkedinUrl != null && this.state.linkedinUrl != '' && this.state.linkedinUrl != undefined
     )&&(this.state.facebookUrl != null && this.state.facebookUrl != '' && this.state.facebookUrl != undefined
     )&&(this.state.instagramUrl != null && this.state.instagramUrl != '' && this.state.instagramUrl != undefined
     )) {
      if (this.validatecharacters(jobseekerName)) {
        if (this.state.ismobileVerified === true) {
          if(validCity == true){
            this.updateProfile();
            this.timer(-1);
          }else{
            this.showModal();
            this.setState({ msg:"Please select city from the list" });
          }
        }
        else {
          this.showModal();
          this.setState({ msg: Strings.verifyYourPhoneNumber });
        }
      }
      else {
        this.showModal();
        this.setState({ msg: Strings.enterOnlyAlphabets });
      }
    }
    else {
      this.showModal();
      this.setState({ msg: Strings.pleaseFillDetails });
    }
  }

    updateProfile() {
    this.props.updateProfileApi(body);
    this.setState({updateSuccess : true})
  }


  async  getCity() {
    allCities = [];
    try {
      let responseData = await fetch(url + "/jobseeker/getAllCities", {
        method: "GET"
      })
      responseData = await responseData.json();
      if (responseData != null) {
        for (var i = 0; i < responseData.length; i++) {
          allCities.push({
            value: responseData[i],
            id:i
          });
        }
        this.setState({ cities: allCities });
      }

    } catch (err) {
    }
  }

  cityValue = (selectedValue) => {
    this.setState({ cityName: selectedValue.value });
  }

  cityValueFromInput = (selectedValue) => {

    this.setState({ cityName: selectedValue });
  }



  handleFaName(e) {  
    if (e.target.value.match("^[a-zA-Z ]*$") != null) {
      this.setState({ FaName: e.target.value });
      body.fatherName = e.target.value
    }
  }

  handleMotherName(e) {
    if (e.target.value.match("^[a-zA-Z ]*$") != null) {
      this.setState({ motherName: e.target.value });
      body.motherName = e.target.value
    }
  }

  handleEmName(e) {  
    if (e.target.value.match("^[a-zA-Z ]*$") != null) {
      this.setState({ EmName: e.target.value });
      body.EmailAddress = e.target.value
    }
  }

  handleLiName(e) {  
    if (e.target.value.match("^[a-zA-Z ]*$") != null) {
      this.setState({ linkedinUrl: e.target.value });
      body.linkedinUrl = e.target.value
    }
  }
  
  handleFbName(e) {  
    if (e.target.value.match("^[a-zA-Z ]*$") != null) {
      this.setState({ facebookUrl: e.target.value });
      body.facebookUrl = e.target.value
    }
  }
  handleInName(e) {  
    if (e.target.value.match("^[a-zA-Z ]*$") != null) {
      this.setState({ instagramUrl: e.target.value });
      body.instagramUrl = e.target.value
    }
  }
  appSetting = async () => {
    try {
      let response = await fetch(url + "/admin/getAppSetDetails", {
        method: "GET",
        headers: {
          "content-Type": "application/json"
        }
      })
      response = await response.json()
     let skipOtp = response.skipOtp
    this.setState({ skipOtp: skipOtp });
    } catch (err) {
    }
  }
  condition() {
    document.getElementById("number").value = "";
    this.sendOtp();
    var firstDigit = this.state.mobileNumber.match(/\d/);
    if (this.state.mobileNumber == this.state.mno || (this.state.mobileNumber == "") ||
      (firstDigit == 0 && this.state.mobileLength !== 11) ||
      ((firstDigit == 9 || firstDigit == 8 || firstDigit == 7 || firstDigit == 6) && this.state.mobileLength !== 10)) {
      this.setState({ enterNumberShowModal: false })
    } else {
     if(this.state.skipOtp){
        this.setState({ enterNumberShowModal: false})
        this.skipOtp();
      }else{
      this.setState({ enterNumberShowModal: false, verifyNumber: true })
      }
    }
  }

  componentWillUnmount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside() {
    this.setState({ enterNumberShowModal: !this.state.enterNumberShowModal });
  }

  handleProfileSubmit = () =>
   {
    this.props.history.push('/profile');
   }


   hideProfileSubmit = () =>
   {
     this.setState({updateSuccess : false})
   }

   hidePopupShow = () =>
   {
    this.setState({show : false})
   }
   hideComplet = () =>
   {
    this.setState({complete : false})
   }
  render() {
    let { jobseekerData, jsDataError, jsDataLoading , updateProfileR } = this.props;
       title=jobseekerData.title
       keywords=jobseekerData.keywords
       description=jobseekerData.description
    if(title !=undefined){
      document.title =title;
      document.getElementsByTagName("META")[2].content = description
      document.getElementsByTagName("META")[3].content = keywords
     }
    const CustomInput = (props) => {
      return (
        <input
          className="form-control rounded"
          onClick={props.onClick}
          value={this.state.startDate? moment(this.state.startDate).format(
            "DD MMM YYYY"
          ):""}
          type="text" format="dd MMM yyyy"
          readOnly={true}
        />
      )
    }

    const items = allCities.map((item, i) => {
      return {
          // what to show to the user
          label: item.value,
          // key to identify the item within the array
          key: item.id,
          // feel free to add your own app logic to access those properties in the onSelect function
          // someAdditionalValue: item.someAdditionalValue,
          // or just keep everything
          ...item,
      }
  });
    return (
      <div>
        <LoadingOverlay
          active={jsDataLoading}
          spinner={<div className="loading-edit ">
            <center className=" bg-dark2 rounded mt-5 col-md-6 col-sm-6 p-2 col-12">
              <h4 className="loadingText pt-1 mb-1 d-sm-block d-md-block">{Strings.loadingYourProfile}</h4>
              <h6 className="HeaderText pt-1 pb-1 d-block d-sm-none d-md-none"></h6>
              <img src={Images.loaderWhite} alt="img" className="pb-2" width="30" />
            </center>
          </div>}
           >
          <div className="container col-md-6 No-Pading col-lg-6 col-sm-6 col-12">
            <Webheader pageName={Strings.editProfile} backLink="/profile"/>
            <div className="col-md-12 pt-md-0 pt-2">
              <form>
                <div className="form-group pt-md-4 pt-0">
                  <label className="font-weight-bold text-capitalize">{Strings.fullnameCaps}</label><span className="text-danger">*</span>
                  <input
                    value={this.state.nameText}
                    onChange={e => this.setState({ nameText: e.target.value })}
                    type="text"
                    className="form-control rounded shadow-sm p-3"
                    aria-describedby="textHelp"
                    placeholder={Strings.fullname}
                    required
                  />
                </div> 
                <div className="row">
                  <div className="col-md-6 col-6 mr-0 pr-1">
                    <div className="form-group">
                      <label className="ml-0 font-weight-bold ">{Strings.fatherName}</label>
                      <div className="input-group">
                        <span className=" font-weight-bold mt-2 mr-1">{Strings.mrText}</span>
                        <input onChange={(e) => this.handleFaName(e)} value={this.state.FaName} type="text" className="form-control rounded shadow-sm fatherName" aria-describedby="textHelp" placeholder={Strings.enterFatherName} required />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-6 mr-0 pr-3 pl-1">
                    <div classsName="form-group">
                      <label className="font-weight-bold">{Strings.motherName}</label>
                      <div className="input-group">
                        <span className="font-weight-bold mt-2 mr-1">{Strings.mrsText}</span>
                        <input onChange={(e) => this.handleMotherName(e)} value={this.state.motherName} type="text" className="form-control rounded shadow-sm motherName" aria-describedby="textHelp" placeholder={Strings.enterMotherName} required />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold">{Strings.currentCity}</label><span className="text-danger">*</span>
                      <DataListInput inputClassName="form-control rounded shadow-sm p-3"
                          placeholder={Strings.EnterYourCity}
                          items={items}
                          onInput={this.cityValueFromInput.bind(this)}
                          // dropDownLength={10}
                          //  debounceTime={1000}
                          clearInputOnSelect="true"
                          initialValue={this.state.cityName}
                          // value={this.state.cityName}
                          onSelect={this.cityValue.bind(this)}
                      />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold">{Strings.dateOfBirth}</label><span className="text-danger">*</span><br />
                    <DatePicker className="react-date-picker col-md-12 form-control rounded shadow-sm p-3"
                    minDate={new Date(1960, 1, 1)}
                    maxDate={maxDateForDob}
                     value={this.state.startDate != null ? this.state.startDate: null }
                     format={'dd MMM y'}
                     onChange={date => this.handleChange(date)}
                     placeholder={Strings.enterYourDob}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="" className="font-weight-bold" >{Strings.EmailAddress}</label><span className="text-danger">*</span>
                  <input value={this.state.email} 
                  onChange={(e) => this.handleEmName(e)}
                  type="email" 
                  className="form-control rounded bg-white text-dark shadow-sm p-3" 
                  aria-describedby="textHelp"
                  placeholder={Strings.enterYorEmailAut} required readOnly/>
                </div>
                <div className="form-group">
                  <label htmlFor="" className="font-weight-bold" >{Strings.linkedinaddUrl}</label><span className="text-danger">*</span>
                  <input 
                  value={this.state.linkedinUrl} 
                  onChange={(e) => this.handleLiName(e)}
                  type="text" 
                  className="form-control rounded bg-white text-dark shadow-sm p-3" 
                  aria-describedby="textHelp"
                    placeholder={Strings.enterYorLinkedinUrl} required />
                </div>
                <div className="form-group">
                  <label htmlFor="" className="font-weight-bold" >{Strings.instagramaddUrl}</label><span className="text-danger">*</span>
                  <input value={this.state.instagramUrl} 
                  onChange={(e) => this.handleInName(e)}
                  type="text" 
                  className="form-control rounded bg-white text-dark shadow-sm p-3" aria-describedby="textHelp"
                    placeholder={Strings.enterYorInstagramUrl} required />
                </div>
                <div className="form-group">
                  <label htmlFor="" className="font-weight-bold" >{Strings.facebookaddUrl}</label><span className="text-danger">*</span>
                  <input value={this.state.facebookUrl}
                  onChange={(e) => this.handleFbName(e)} 
                  type="text" 
                  className="form-control rounded bg-white text-dark shadow-sm p-3" aria-describedby="textHelp"
                    placeholder={Strings.enterYorFacebookUrl} required/>
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="" className="font-weight-bold">{Strings.contactNumber}</label><span className="text-danger">*</span>
                  <div className="input-group">
                    <input
                      value={this.state.mno}
                      onChange={e => this.setState({ openModal: true })}
                      type="number"
                      className="form-control rounded bg-white text-dark shadow-sm p-3"
                      aria-describedby="textHelp"
                      placeholder={Strings.enteryourcontactno}
                      required data-toggle="modal" data-target="#phone"
                      readOnly />

                    {/* The Modal */}
                    <div className={this.state.openModal === true ? "modal opePhoneCls" : "modal closeNotPhoneCls" } id="phone">
                      <div className="modal-dialog">
                        <div className="modal-content rounded contentTop">

                          {/* Modal Header  */}
                          <div className="modal-header bg-success modalHeight">
                            <center className="col-center">
                              <div className="icon-circle shadow-sm">
                                <i className="fas fa-phone-alt text-success-gradient fa-3x mt-3"></i>
                              </div>
                            </center>
                          </div>

                          {/* Modal body  */}
                          <div className="modal-body">
                            <center>
                              <p className="mt-5 text-secondary ml-2 mr-2">{Strings.neverAskMoney}</p>
                              <a href="#enterno" className="btn btn-success rounded col-md-4 col-sm-4 col-4 text-white text-decoration-none"
                                data-dismiss="modal" data-toggle="modal">{Strings.oK}</a>
                            </center>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* modal end */}

                    {/* Enter Number Modal Starts */}
                    <div className="modal" id="enterno" >
                      <div className="modal-dialog">
                        <div className="modal-content rounded contentTop">

                          {/* Modal Header  */}
                          <center className="col-center">
                            <img src={"../phoneAuth.webp"} height={100} width={100} alt="Phone" className="rounded-circle mt-2 mr-2" />
                          </center>

                          {/* Modal body  */}
                          <div className="modal-body">
                            <center>
                              <p className="mt-2 text-secondary ml-2 mr-2">{Strings.togetnotifUse}</p>
                              <input
                                value={this.state.mobileNumber}
                                onChange={e => this.handlePhoneNo(e)}
                                id="number"
                                type="number"
                                className="form-control rounded col-md-10 shadow-sm"
                                aria-describedby="textHelp"
                                placeholder={Strings.Enterphonenumber}
                                required
                              />
                              <button type="button" className="btn btn-success rounded col-md-10 mt-2"
                                onClick={() => {
                                  this.condition()
                                }}
                                data-dismiss="modal"
                              >{Strings.verify}
                              </button>
                            </center>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* modal end */}

                    {/* Enter Number Modal Starts */}
                   <div className={this.state.verifyNumber == true ? "modal opePhoneCls" : "modal closeNotPhoneCls"}id="verifyno">
                      <div className="modal-dialog">
                        <div className="modal-content rounded contentTop">

                          {/* Modal Header  */}
                          <center className="col-center">
                            <img src={"../otpMobile.webp"} height={100} width={100} alt="otp" className="rounded-circle mt-2 ml-2 mr-2" />
                          </center>

                          {/* Modal body  */}
                          <div className="modal-body">
                            <center>
                              <p className="mt-2 text-secondary ml-2 mr-2">
                                <span className="plzCodeCls">{Strings.plzentercodeSms}</span>
                              </p>
                              <input
                                type="number"
                                className="form-control rounded col-md-10"
                                placeholder="Code"
                                id="otp"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                onChange={e => this.handleOtp(e)}
                              />
                              <button type="button" className="btn btn-success rounded col-md-10 mt-2" onClick={() => this.verifyOtp()}
                                data-dismiss="modal">{Strings.Confirm} </button>

                              <div className="" id="showtime">{Strings.noCode}  <span id="timer"></span>

                                <span id="resendcode" className="d-none pointer" onClick={() => {
                                  this.condition()
                                }}
                                ><u>{Strings.resendCode}</u>
                                </span>
                              </div>
                            </center>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* modal end */}
                  </div>
                </div>

                <div className="form-group">
                  <label className="font-weight-bold">{Strings.GenderCaps}</label><span className="text-danger">*</span>
                  <br />
                  <div className="input-group">
                    <div className="btn-group btn-group-toggle col-md-5 col-12 col-sm-12 " data-toggle="buttons">
                      <label className={this.state.gender === "Male" ? "btn btn-outline-secondary mr-2 rounded-pill form-control active shadow-sm genderShowCls" : "btn btn-outline-secondary mr-2 mb-2 rounded-pill form-control shadow-sm genderShowCls"}>
                        <i className="fas fa-male"></i>
                        <input type="radio" name="options" autoComplete="off" value="Male" checked={this.state.gender == "Male"} onClick={(e) => this.genderValue(e.target.value)} /> {Strings.Male}
                      </label>
                      <label className={this.state.gender === "Female" ? "btn btn-outline-secondary mr-2 rounded-pill form-control active shadow-sm genderShowCls" : "btn btn-outline-secondary mr-2 mb-2 rounded-pill form-control shadow-sm genderShowCls"}>
                        <i className="fas fa-female"></i>
                        <input type="radio" name="options" autoComplete="off" value="Female" checked={this.state.gender == "Female"} onClick={(e) => this.genderValue(e.target.value)} /> {Strings.Female}
                      </label>
                    </div>
                  </div>

                </div>

                <div className="form-group mb-3 d-none" id="vOtp">
                </div>
                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                </div>
                {(this.state.nameText != null && this.state.nameText != '' && this.state.nameText != undefined)
                  && (this.state.cityName != null && this.state.cityName != ''
                    && this.state.cityName != undefined) && (this.state.startDate != null
                      && this.state.startDate != '' && this.state.startDate != undefined)
                  && (this.state.mobileNumber != null && this.state.mobileNumber != '' && this.state.mobileNumber != undefined
                  ) ?
                  <div
                    onClick={() => this.validateProfile()}
                    type="submit"
                    className="btn btn-success rounded-pill col-md-12  mb-5"

                  >{Strings.submit}</div>
                  :
                  <button onClick={() => this.validateProfile()} type="submit"
                    className="btn btn-success col-md-12 rounded-pill  mb-5 validProCLs" disabled={true}>
                    {Strings.submit}
                  </button>
                }
              </form>
            </div>
          </div>
        </LoadingOverlay>
            <ModalDiv modalReact={true} showModal={this.state.updateSuccess} hideModal={() =>this.hideProfileSubmit()} successModal={true} crossBtnModal={true} msg={Strings.ProfileUpdatedSuccessfully} btn1Click={() =>this.handleProfileSubmit()} btn1Text={Strings.oK}/>
       
        {/* for msg only */}
        <ModalDiv modalReact={true} showModal={this.state.show} crossBtnModal={true} hideModal={() =>this.hidePopupShow()} msg={this.state.msg} btn1Click={() =>this.hideModal()} btn1Text={Strings.oK}/>
              {/* for complete  */}
        <ModalDiv modalReact={true} showModal={this.state.complete} crossBtnModal={true} hideModal={() =>this.hideComplet()} successModal={true} msg={this.state.msgcomplete} btn1Click={() =>this.hideModalComplete()} btn1Text={Strings.oK}/>
      </div>
    );
  }
}

const mapStateToProps = state => {
	return {
	  jobseekerData: state.profileReducer.jobseekerData,
	  jsDataLoading: state.profileReducer.jsDataLoading,
	  jsDataError: state.profileReducer.jsDataError,
	};
  };
  const mapDispatchToProps = dispatch => {
	return {
    getJobseekerProfile: jobseekerId => dispatch(getJobseekerProfile(jobseekerId)),
    updateProfileApi : body => dispatch(updateProfileApi(body))
	};
  };
  export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);