import React, { Component } from "react";
import { Link } from "react-router-dom";
import Images from "../Images";
import $ from 'jquery';
import Footer from "../components/footer";
import Strings from "../utilities/stringlanguage";
import '../_style/jobs.css';
import Webheader from "../components/webheader";
import Moment from 'moment';
import { jobDetailApi, getJobByShareId, getRelatedJobList, getReportData, updateHrApi, clearPreviousData } from "../actions/jobListAction";
import { connect } from "react-redux";
import { Carousel } from 'react-responsive-carousel';
import Speech from "speak-tts";
import { Modal } from "react-bootstrap";
import LoadingOverlay from 'react-loading-overlay';
import GoogleMapReact from 'google-map-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalDiv from '../components/modal';
import {
  EmailShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import swal from "sweetalert";
import helpers from "../config";
const AnyReactComponent = ({ text }) => <div className="mapcontent"><h5 className="mapText pl-1 pr-1">{text}</h5></div>;
var moment = require("moment");
var queryString, stringId, jobId, url, companyMobile, imageUrl, latitude, longitude, companyId, selectedLang, jobseekerName, jobseekerId, areaString, splitData, fakeIcon = false, iForSave;
var reportJobData = [];
var mapLat;
var mapLong;
var fakeId;
var candidateCredits;
var isFirstHrCall;
var relatedJobfakeIcon;
var linkCount=1;
var callHrFrom=9;
var callHrTo=8;
var showInfo=false;
class JobDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      jobId: "",
      companyId: "",
      isSaved: false,
      jobDetailData: [],
      isApplied: false,
      show: false,
      complete: false,
      allow: false,
      allowCredit:false,
      reportShow: false,
      showLoginPopup: false,
      list: [],
      onApplyClick: false,
      availableInCity: "true",
      uploadedResume: "false",
      knowEnglish: "true",
      reportText: "",
      reportMessage: "",
      isUserLoggedIn: localStorage.getItem("isUserLoggedIn"),
      relatedJobs: [],
      detailData: []
    };
  }

  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33
    },
    zoom: 11
  };

  async componentDidMount() {
    this.abortController = new AbortController();
    this.abortController = new AbortController();
    selectedLang = localStorage.getItem("selectedLang");
    if (selectedLang != null && selectedLang != undefined && selectedLang != "") {
      Strings.setLanguage(selectedLang);
    }
    let apiUrl = await helpers.getServerUrl();
    url = apiUrl.serverUrl
    imageUrl = apiUrl.imageUrl
    this.getMyLocation();
    this.appSetting()
    if(linkCount==1){
    this.showPlaystoreLink()
    linkCount=2
    }
    if (this.props.location.state == undefined || this.props.location.state == null) {
      this.state.isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
      jobseekerName = localStorage.getItem("jobseekerName");
      jobseekerId = localStorage.getItem("jobseekerId");
      queryString = window.location.href.length;
      stringId = window.location.href.slice(queryString - 6, queryString);
      localStorage.setItem("stringId", stringId);
      areaString = window.location.href.split("-")[1];
      this.props.getJobByShareId(stringId);
      this.props.getRelatedJobList(jobId);
    }
    else {
      this.state.isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
      jobseekerName = localStorage.getItem("jobseekerName");
      jobseekerId = localStorage.getItem("jobseekerId");
      localStorage.setItem("jobId", this.props.location.state.jobId);
      if (this.props.isFromLogin) {
        localStorage.setItem("jobId", this.props.location.state.jobId);
      }
      if (this.props.location.state.searchJob) {
        this.getSearchedJob();
      }
      this.props.getRelatedJobList();
      jobId = localStorage.getItem("jobId")
      this.props.jobDetailApi(this.props.location.state.jobId);
      this.getReportData();
      if (window.performance) {
        if (performance.navigation.type === 0) {
          localStorage.setItem("jobId", this.props.location.state.jobId);
          jobId = localStorage.getItem("jobId")
          this.props.jobDetailApi(jobId);
        }
      }
    }
    window.scroll(0,0);
    window.addEventListener('popstate', this.onBackButtonEvent);
    $(document).click(function (e) {
      if ($(e.target).is('#myModalReportJob')) {
        $('#myModalReportJob').fadeOut(500);
      }
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === 'desiredState') {
    }
  }
  onBackButtonEvent = (e) => {
    e.preventDefault();
    this.onPauseAudio();
  }

  async getSearchedJob() {
    let jobId = localStorage.getItem("jobId");
    try {
      let response = await fetch(url + "/jobseeker/searchedHistory", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          jobseekerId: jobseekerId,
          jobId: jobId
        })
      })
      response = await response.json()
    } catch (err) {
    }
  }
  showHrFisrtModal = (e) => {
    this.setState({
      hrFisrtModal: true
    });
  };

  hideHrFisrtModal= (e) => {
    this.setState({ hrFisrtModal: false });
  }

  showLoginModal = (e) => {
    this.setState({
      showLoginPopup: true
    });
  };

  hideLoginModal = (e) => {
    this.setState({ showLoginPopup: false });
  };
  showHrLoginModal = (e) => {
    this.setState({
      hrLoginPopup: true
    });
  };

  hideHrLoginModal = (e) => {
    this.setState({ hrLoginPopup: false });
  };
  showModal = e => {
    this.setState({
      show: true
    });
  };

  hideModal = e => {
    this.setState({ show: false });
  };

  showreportModal = e => {
    this.setState({
      reportShow: true
    });
  };

  hidereportModal = e => {
    this.setState({ reportShow: false });
  };

  onPlayAudio() {
    const speech = new Speech();
    speech
      .init({
        volume: 1,
        lang: "en-GB",
        rate: 1,
        pitch: 1,
        listeners: {
          onvoiceschanged: voices => {
          }
        }
      })
      .then(data => {
        this.prepareSpeakButton(speech);
      })
      .catch(e => {
      });

  }

  prepareSpeakButton(speech) {
    const speakButton = document.getElementById("play");
    const pauseButton = document.getElementById("pause");
    const resumeButton = document.getElementById("resume");
    speakButton.addEventListener("click", () => {
      let ele = document.getElementById('pause');
      ele.classList.add("d-block");
      ele.classList.remove("d-none");
      let ele2 = document.getElementById('play');
      ele2.classList.add('d-none');
      ele2.classList.remove('d-block');
      speech
        .speak({
          text: this.state.detailData.audioDescription,
          queue: false,
          listeners: {
            onstart: () => {
            },
            onend: () => {
            },
            onresume: () => {
            },
            onboundary: event => {
            }
          }
        })
        .then(data => {
        })
        .catch(e => {
        });
    });

    pauseButton.addEventListener("click", () => {
      let ele2 = document.getElementById('play');
      ele2.classList.add('d-block');
      let ele = document.getElementById('pause');
      ele.classList.remove("d-block");
      ele.classList.add("d-none");
      speech.pause();
    });

    resumeButton.addEventListener("click", () => {
      speech.resume();
    });
  }

  onPauseAudio() {
    const speech = new Speech();
    speech
      .init({
        volume: 1,
        lang: "en-GB",
        rate: 1,
        pitch: 1,
        listeners: {
          onvoiceschanged: voices => {
          }
        }
      })
      .then(data => {
        speech.pause();
        let ele2 = document.getElementById('play');
        ele2.classList.add('d-block');
        let ele = document.getElementById('pause');
        ele.classList.remove("d-block");
        ele.classList.add("d-none");
      })
      .catch(e => {
      });
  }

  getMyLocation() {
    navigator.geolocation.getCurrentPosition(function (position) {
      latitude = position.coords.latitude.toFixed(4);
      longitude = position.coords.longitude.toFixed(4);
      localStorage.setItem("latitude", latitude);
      localStorage.setItem("longitude", longitude);
    });
  }
  async notInterested() {
    let jobId = localStorage.getItem("jobId");
    try {
      let response = await fetch(url + "/jobseeker/notInterested", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          jobId: jobId,
          jobseekerId: jobseekerId
        })
      });
      response = await response.json();
      if (response.success) {
        this.props.history.goBack();
      }
    }
    catch (err) {
    }
  }

  handleOptionChange = e => {
    if (this.state.availableInCity != e.target.value) {
      this.setState({ availableInCity: e.target.value });
    }
  }

  handleOptionCheckResume = e => {
    if (this.state.uploadedResume != e.target.value) {
      this.setState({ uploadedResume: e.target.value });
    }
  }

  handleOptionChangeEng = e => {
    if (this.state.knowEnglish != e.target.value) {
      this.setState({ knowEnglish: e.target.value });
    }
  }

  showModalComplete = e => {
    this.setState({ complete: true });
  }
  hidePlayStoreLink = (e) => {
    this.setState({ playStoreLink: false });
  };

  showPlaystoreLink = (e) => {
    this.setState({
      playStoreLink: true
    });
  }

  playStoreModal = (e) => {
    window.open('https://play.google.com/store/apps/details?id=dataflakes.jobs.jhatpatjobs.jobsearch', '_blank');
  };
  hideModalComplete = e => {
    this.props.history.push('/joblist');
    this.setState({ complete: false });
  }
  showAllow = e => {
    this.setState({ allow: true });
  }

  hideAllow = e => {
    this.setState({ allow: false });
  }
  showCredit = e => {
    this.setState({ allowCredit: true });
  }
  hideJob = e => {
    this.props.history.goBack();
  }
  showJobAlert = e => {
    this.setState({ showJob: true });
  }


  hideCredit = e => {
    this.setState({ allowCredit: false });
  }

  async applyForJob() {
    // 'Please Upload Resume Before Applying' hide
    // if(this.state.uploadedResume == "true")
    // {
    let jobId = localStorage.getItem("jobId");
    latitude = localStorage.getItem("latitude");
    longitude = localStorage.getItem("longitude");
    let jobseekerId = localStorage.getItem("jobseekerId");
    try {
      if(candidateCredits){
      // if (latitude != null && latitude != undefined && longitude != null && longitude != undefined) { //comment code for upload resume without asking location
        let response = await fetch(url + "/jobseeker/applyJob", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            jobId: jobId,
            jobseekerId: jobseekerId,
            companyId: companyId,
            candCurrentLatitude: latitude,
            candCurrentLongitude: longitude,
            availableInCity: this.state.availableInCity,
            knowEnglish: this.state.knowEnglish
          })
        })
        response = await response.json();
        if (response[0].success) {
          this.showModalComplete();
          this.setState({ msgcomplete: "You have successfully applied for job" });
        }
        this.props.jobDetailApi(jobId);
      // }
      // else {
      //   this.setState({ msgcomplete: Strings.allowJhatpatJobs });
      //   this.showAllow();
      //   this.getMyLocation();
      // }
    }
    else{
      this.setState({ allowCredit: true });
    }
    } catch (err) {
    }
  // }
  // else
  // {
  //   this.showModalComplete();
  //   this.setState({ msgcomplete: "Please Upload Resume Before Applying" });
  // }
  }

  async requestCredit() {
    let jobseekerId = localStorage.getItem("jobseekerId");
    try {
        let response = await fetch(url + "/jobseeker/requestForCredits", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            jobseekerId: jobseekerId,
          })
        })
        response = await response.json();
        if (response.success) {
          this.setState({ allowCredit: false });
          this.showModalComplete();
          this.setState({ msgcomplete: response.msg });
        }
    } catch (err) {
    }
  }

  handleSuggestedJob(item) {
    this.props.jobDetailApi(item.jobId, item.jobLink);
    window.history.replaceState(null, null, "/jobs?" + item.jobLink);
    window.scrollTo(0, 0);
  }

  saveThisJob = async () => {
    // iForSave = 0;
    var i = 0;

    this.setState({ fakeIcon: true });
    if (jobseekerId != null && jobseekerId != "" && jobseekerId != undefined) {
      jobseekerId = jobseekerId;
    }
    if (jobseekerId != null && jobseekerId != undefined) {
      try {
        let responseData = await fetch(url + "/jobs/addJobsToFavourites", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            jobseekerId: jobseekerId,
            jobId: jobId,
            isSaved: this.state.isSaved
          })
        })
        responseData = await responseData.json();
        this.setState({ fakeIcon: false });
        if (responseData.msg == "Job saved successfully") {
          // fakeIcon = false;
        this.showModal()
        this.props.jobDetailApi(jobId);
        this.setState({ msg: Strings.JobSavedSuccessfully });
        this.state.detailData.isSaved = true;
        }
        else {
          this.props.jobDetailApi(jobId);
          this.state.detailData.isSaved = false;
        }
      } catch (err) {
      }
    } else {
      this.showLoginModal()
    }
  }

  savedJobFun = async (item) => {
    if (jobseekerId != null && jobseekerId != "" && jobseekerId != undefined) {
      jobseekerId = jobseekerId;
    }
    if (jobseekerId != null && jobseekerId != undefined) {
      iForSave = 0;
      var i = 0;
      relatedJobfakeIcon = true;
      this.setState({ fakeId: item.jobId });
      try {
        let responseData = await fetch(url + "/jobs/addJobsToFavourites", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            jobseekerId: jobseekerId,
            jobId: item.jobId,
            isSaved: item.isSaved
          })
        })
        responseData = await responseData.json();
        if (responseData.success) {
          this.props.getRelatedJobList();
          relatedJobfakeIcon = false;
        }
      } catch (err) {
      }
    } else {
      this.showLoginModal()
    }
  }

  handleReportCompany(reportText) {
    this.setState({ reportText: reportText })
    if (reportText == 'Other') {
      let di = document.getElementById("displayinput");
      di.classList.add("d-block");
      di.classList.remove("d-none");
    }
    else {
      let di = document.getElementById("displayinput");
      di.classList.add("d-none");
      di.classList.remove("d-block");
    }
  }

  handleReportOther(reportText) {
    this.setState({ reportText: reportText })
  }
  appSetting = async () => {
    try {
      let response = await fetch(url + "/admin/getAppSetDetails", {
        method: "GET",
        headers: {
          "content-Type": "application/json"
        }
      })
      response = await response.json()
       callHrFrom = response.callHrFrom
       callHrTo = response.callHrTo
    } catch (err) {
    }
  }

  async getReportData() {
    reportJobData = [];
    let response = await fetch(url + "/jobs/getReports", { method: "GET" })
    response = await response.json();
    if (response != null) {
      response.forEach(response => {
        reportJobData.push({
          text: response.text,
          index: response.reportId
        });
      });
      reportJobData.push({
        text: "Other",
        index: response.length
      });
    }
  }

  backClicked = () => {
    this.props.clearPreviousData()
    this.props.history.go(-2);
    this.onPauseAudio();
  }
  hrUpdate = () => {
  this.props.updateHrApi()
}

  loginFun = () => {
    this.props.history.push('/register');
  }
  requestFun = () => {
    this.requestCredit()
  }

  async submitReport() {
    if (this.state.reportText != "" && this.state.reportText != null && this.state.reportText != "Other") {
      try {
        let response = await fetch(url + "/jobs/reportTocompany", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            jobseekerId: jobseekerId,
            jobId: jobId,
            text: this.state.reportText
          })
        })
        response = await response.json();
        if (response[0].success) {
          this.setState({ reportMessage: Strings.reportSubmitted })
          this.showreportModal();
          $('#myModalReportJob').fadeOut(500);
        }
      } catch (err) {
      }
    }
    else {
      this.showreportModal();
      $('#myModalReportJob').fadeIn(500);
      this.setState({ reportMessage: Strings.chooseAnyone })
    }
  }

  static getDerivedStateFromProps(props, state) {
 }
 componentWillUnmount() {
  this.abortController.abort();
}
  render() {

    let currentTime= moment();
    let startTime = moment('0'+ callHrFrom +':00 am', "HH:mm a");
    let endTime = moment(callHrTo + ':00 pm', "HH:mm a");
    let amIBetween = currentTime.isBetween(startTime , endTime);
    let { jobDetailData, jobsDataLoading, jobsDataError, relatedJobs, relatedJobsError, reportData } = this.props;
    if (jobDetailData !== null && jobDetailData !== undefined) {
      this.state.detailData = jobDetailData;
      localStorage.setItem("jobId", jobDetailData.jobId);
      localStorage.setItem("creditsAvailable",jobDetailData.creditsAvailable)
      candidateCredits=jobDetailData.candidateCredits
      mapLat = parseFloat(jobDetailData.latitude);
      mapLong = parseFloat(jobDetailData.longitude);
      jobsDataLoading = false
      localStorage.setItem("companyId", this.state.detailData.companyId);
    }
    if (this.state.detailData.experienceLevel == "0 Year") {
      this.state.detailData.experienceLevel = "Fresher"
    }
    if (this.state.detailData.metaElems !== null && this.state.detailData.metaElems !== "" && this.state.detailData.metaElems !== undefined) {
      document.title = this.state.detailData.metaElems.titleNew;
      document.getElementsByTagName("META")[2].content = this.state.detailData.metaElems.description;
      document.getElementsByTagName("META")[4].content = this.state.detailData.metaElems.description;
      document.getElementsByTagName("META")[3].content = this.state.detailData.metaElems.keywords
    }
    
    const shareUrl =
      "https://jobam.co/jobs?/" + this.state.detailData.jobLink;
    const title = "Facebook";

    if (this.state.detailData.reqSkills != undefined && this.state.detailData.reqSkills != null) {
      splitData = this.state.detailData.reqSkills[0].split(",");
    }
    companyMobile = "tel:" + this.state.detailData.contactNo;

    let map = "https://maps.google.com/maps?q=" + this.state.detailData.latitude + "," + this.state.detailData.longitude + "&z=15&output=embed";
    let emailBody = "Job Role : " + this.state.detailData.jobProfile + "  " + "Company : " + this.state.detailData.companyName + "  " + "Have you tried Jobam mobile app yet ? YOu will definately like it :"
    return (
      <LoadingOverlay
        active={jobsDataLoading}
        spinner={<div className="loading-jobs">
          <center className=" bg-dark1 rounded mt-5 col-md-6 col-sm-6 p-2 col-12">
            <h4 className="loadingText pt-1 mb-1 d-sm-block d-md-block">{Strings.loadingJobDetails}</h4>
            <h6 className="text-white pt-1 pb-1 d-block d-sm-none d-md-none"></h6>
            <img src={Images.loaderWhite} alt="img" className="pb-2" width="30" />
          </center>
        </div>}
      >
      { localStorage.getItem("creditsAvailable") === "true" ? 
        <div className="texture">
          <div className="container col-md-6 No-Pading col-lg-6 col-sm-6 col-12">
            <div className="header-top col-md-6 pl-0 pr-0">
              {stringId == undefined && stringId == null ? 
            <Webheader pageName={this.state.detailData.jobProfile} normalText={true} jobReport={true} handleBack={this.backClicked} />
           :  	<div className="bg-success col-md-12 p-1 fixedtop header-height">
                        <div className="row">
                           <div className="text-white text-decoration-none col-1 col-md-2 pointer" id="backButton">
                           </div>
                           <div className="col-md-8 col-7">
								<h5 className="text-white text-center pt-1 textLimit text-uppercase d-none d-sm-block d-lg-block d-md-block mt-1 font-weight-bold jobProCls">{this.state.detailData.jobProfile}</h5>
                <h6 className="text-center text-white text-uppercase textLimit  d-block d-sm-none d-md-none d-lg-none mt-1 pt-1 font-weight-bold jobProCls">{this.state.detailData.jobProfile}</h6>
                </div>
                {this.state.isUserLoggedIn ?
                <div className="col-md-1 col-2 reportButton pt-1 pl-5" data-toggle="modal" data-target="#myModalReportJob">
                <i className="fas fa-exclamation-circle fa-1x text-white rounded pointer bgChange logoSizeCls"></i>
              </div>:
               <div className="col-md-1 col-2 reportButton pt-1 text-right pl-5" data-toggle="modal" data-target="#loginReportJob">
               <i className="fas fa-exclamation-circle fa-1x text-white rounded pointer bgChange logoSizeCls"></i>
             </div>}
            </div>
            </div> }
            </div>
           <ModalDiv successModal={true} modalReact={true} showModal={this.state.complete} hideModal={()=>this.hideModalComplete()} crossBtnModal={true} msg={this.state.msgcomplete} btn1Click={() => this.hideModalComplete()} btn1Text={Strings.oK}/>
           {/* <ModalDiv modalReact={true} successModal={true} successModalNew={true} twoButton={true} showModal={this.state.playStoreLink} crossBtnModal={true} msg="Download Jobam App for better experience" hideModal={()=>this.hidePlayStoreLink()}  btnFirst="Ok" btnFirstClick={() => this.playStoreModal()} btnSecondClick={() => this.hidePlayStoreLink()} btnSecond={Strings.cancelCaps}/> */}
           <ModalDiv  modalReact={true} showModal={this.state.allow} hideModal={()=>this.hideAllow()} msg={this.state.msgcomplete} btn1Click={() => this.hideAllow()} btn1Text={Strings.oK}/>
           <ModalDiv modalReact={true} successModal={true} hideModal={()=>this.hideModal()} crossBtnModal={true} showModal={this.state.show} msg={this.state.msg} btn1Click={() => this.hideModal()} btn1Text={Strings.oK}/>
            {/*modal for report job */}
            <div className="modal" id="myModalReportJob">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content jobs-modal-view">
                  <div className="modal-header">
                    <h5 className="modal-title col-md-12 text-center font-weight-bold d-none d-md-block d-sm-block d-lg-block d-xl-block">{Strings.whatswrongwithjob}</h5>
                    <h5 className="modal-title col-md-12 text-center font-weight-bold d-block d-md-none d-sm-none d-lg-none d-xl-none whatsWrongCls">{Strings.whatswrongwithjob}</h5>
                  </div>
                  <div className="modal-body">
                    <div>
                      {reportJobData != null ? (
                        reportJobData.map(item => (
                          <div className="col-md-12 col-sm-12 col-12 mb-1">
                            <input type="radio" className="form-check-input" name="exampleCheck1" onClick={(e) => this.handleReportCompany(item.text)} /> 
                            <label className="form-check-label" htmlFor="exampleCheck1">{item.text}</label>  
                          </div>
                        ))
                      ) : (
                          null
                        )
                      }
                      <div className={this.state.displayinput ?
                        "col-md-12 col-sm-12 col-12" : "col-md-12 col-sm-12 col-12 d-none"} id="displayinput">
                        <div className="row">
                          <input type="text" className="form-control" placeholder="Enter your concern here"
                            onChange={(e) => this.handleReportOther(e.target.value)}
                          />
                        </div>
                      </div>
                      <center>
                        <div className="col-md-12 col-sm-12 col-12 mt-2">
                          <div className="bg-success col-md-4 col-sm-4 col-6 roundedModal row justify-content-center pointer" onClick={() => this.submitReport()}>
                            <span className="p-2 font-weight-bold text-white" data-dismiss="modal">{Strings.submit}</span>
                          </div>
                        </div>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* For login alert on savejob card */}
           <ModalDiv modalReact={true} showModal={this.state.showLoginPopup} msg={Strings.kindlyLoginTextForSave} crossBtnModal={true} hideModal={()=>this.hideLoginModal()} twoButton={true} btnFirstClick={() => this.loginFun()} btnFirst={Strings.loginCaps} btnSecondClick={()=>this.hideLoginModal()} btnSecond={Strings.cancelCaps}/>

           <ModalDiv modalReact={true} showModal={this.state.allowCredit} msg="Your credit score have been finished. Request to earn more credits" crossBtnModal={true} hideModal={()=>this.hideCredit()} twoButton={true} btnFirstClick={() => this.requestFun()} btnFirst="Request" btnSecondClick={()=>this.hideCredit()} btnSecond={Strings.cancelCaps}/>
           
           <ModalDiv successModal={true} modalReact={true} showModal={this.state.complete} hideModal={()=>this.hideModalComplete()} crossBtnModal={true} msg={this.state.msgcomplete} btn1Click={() => this.hideModalComplete()} btn1Text={Strings.oK}/>

           <ModalDiv modalReact={true} showModal={this.state.hrLoginPopup} msg="Kindly login to contact Hr" crossBtnModal={true} hideModal={()=>this.hideHrLoginModal()} twoButton={true} btnFirstClick={() => this.loginFun()} btnFirst={Strings.loginCaps} btnSecondClick={()=>this.hideHrLoginModal()} btnSecond={Strings.cancelCaps}/>
            {/* modal for report job */}
            <ModalDiv singleBtnModal={true} modalId="loginReportJob" modalText={Strings.kindlyLoginReportText} twoBtn={true} crossBtn={true}  discardText={Strings.loginCaps} discardFun={() => this.loginFun()} discardSaveText={Strings.cancelCaps}/>
            <Modal show={this.state.reportShow} centered>
              <div className="modal-header bg-success borderRadiusModal SaveUnsaveHeader">
                <div className="col-md-12">
                  <div className="row  justify-content-center">
                    <div className="shadow-sm p-3 mb-5 bg-white rounded-circle">
                      {this.state.reportMessage == Strings.chooseAnyone ?
                        <i className="fas fa-exclamation  fa-2x text-success-gradient "></i>
                        : <i className="fas fa-check  fa-2x text-success-gradient "></i>}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <center>
                  <div className="mt-4 mb-2">{this.state.reportMessage}</div></center>
                <div className="tab-content" id="pills-tabContent">
                  <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4 col-4 col-sm-4"></div>
                        <div type="submit" className="btn btn-success col-md-4 mt-2 col-sm-5 col-5 roundedModal" data-dismiss="modal-header" onClick={this.hidereportModal} >{Strings.oK}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
            {/* Report Modal.... */}
            {/* Remove Popup */}
            {/*<div className="modal fade" id="applyPopup">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content jobs-modal-view">
                  <div className="modal-header bg-success">
                    <div className="col-md-12">
                      <div className="row  justify-content-center">
                        <div className="shadow-sm rounded-circle bg-white position-fixed pt-3 pb-2 pl-3 pr-3 mt-3">
                          <i className="fa fa-check fa-2x text-success-gradient "></i>
                        </div>
                      </div>
                      <button type="button" className="close text-white" data-dismiss="modal">&times;</button>
                    </div>
                  </div>
                  <div className="modal-body mt-md-4 mt-4 pb-0 text-center">
                    {Strings.resumeCheck}
                    <br />
                    <div className="form-check-inline mt-2">
                      <label className="customradio">
                        <input type="radio" name="radioResume" checked={this.state.uploadedResume == "true"} value={true} onChange={this.handleOptionCheckResume} />
                        <span className="checkmark"></span>
                        <span> {Strings.yes}</span>
                      </label>
                      &nbsp;&nbsp;&nbsp;
                      <label className="customradio">
                        <input type="radio" name="radioResume" checked={this.state.uploadedResume == "false"} value={false} onChange={this.handleOptionCheckResume} />
                        <span className="checkmark"></span>
                        <span> {Strings.no}</span>
                      </label>
                    </div>
                  </div>
                  <div className="modal-body mt-md-1 mt-0 pb-0 text-center pt-0">
                    {Strings.sameCity}
                    <br />
                    <div className="form-check-inline mt-2">
                      <label className="customradio">
                        <input type="radio" name="radioCity" checked={this.state.availableInCity == "true"} value={true} onChange={this.handleOptionChange} />
                        <span className="checkmark"></span>
                        <span> {Strings.yes}</span>
                      </label>
                      &nbsp;&nbsp;&nbsp;
                      <label className="customradio">
                        <input type="radio" name="radioCity" checked={this.state.availableInCity == "false"} value={false} onChange={this.handleOptionChange} />
                        <span className="checkmark"></span>
                        <span> {Strings.no}</span>
                      </label>
                    </div>
                  </div>
                  <div className="modal-body mt-md-1 mt-0 pb-0 text-center pt-0">
                    {Strings.doYouSpeakEnglish}
                    <br />
                    <div className="form-check-inline mt-2">
                      <label className="customradio">
                        <input type="radio" name="radioEng" checked={this.state.knowEnglish === "true"} value={true} onChange={this.handleOptionChangeEng} />
                        <span className="checkmark"></span>
                        <span> {Strings.yes}</span>
                      </label>
                      &nbsp;&nbsp;&nbsp;
                      <label className="customradio">
                        <input type="radio" name="radioEng" checked={this.state.knowEnglish === "false"} value={false} onChange={this.handleOptionChangeEng} />
                        <span className="checkmark"></span>
                        <span> {Strings.no}</span>
                      </label>
                    </div>
                  </div>
                  <center>
                    <input type="submit" className="btn bg-success text-white rounded mb-4 col-md-5 col-5" value="SUBMIT" data-dismiss="modal" onClick={() => this.applyForJob()} />
                  </center>
                </div>
              </div>
              </div>*/}
            {/* End Remove Popup */}  

            <div className="pt-5">
            <div className="card border-0 section-1 mr-1 ml-1">
              <div className="m-2">
              <div className="d-flex">
              <div className="col-md-4 mt-0 col-sm-4 col-4 firstSec section-1 pl-0 pr-0 bg-white">
                  <Link to={{
                      pathname: "/companyprofile",
                      search: "/" + this.state.detailData.companyLink,
                      state: { companyId: this.state.detailData.companyId, jobId: localStorage.getItem("JobId") }
                      }}>
                      <img
                          src={
                          imageUrl + this.state.detailData.companyImage
                          }
                          className="newImage pl-2 pr-2" alt="company image"
                      />
                      </Link>
                  </div>
                  <div className="col-md-8 col-sm-8 col-8 pl-3 pt-3 pb-3 pr-2 bg-white ml-md-3 ml-2 section-2">
                      <div className="row">
                      <h5 className="text-dark font-weight-bold jobNameLimit col-md-10 col-10 mb-0 pr-0">
                          {this.state.detailData.jobTitle}
                      </h5>
                      <span  onClick={() => this.saveThisJob()}>
                      {this.state.detailData.isSaved ? (
                          <div className="text-white fontB pointer saveIcon-view ml-md-4 ml-2">
                          <img src={Images.saveBookmark} width="30" heigth = "29" alt="img"></img>
                          </div>
                      ) : (
                          <div className="text-white fontB pointer saveIcon-view ml-md-4 ml-2">
                              <img src={Images.unsaveBookmark} width="30" heigth = "29" alt="img"></img></div>
                          )
                      }
                      </span>
                      </div>
                      <div className="row mt-1 mt-md-2">
                          {this.state.detailData.isShortlisted != null && this.state.detailData.isShortlisted != "" && this.state.detailData.isShortlisted != undefined ?
                              <div className="col-md-5 col-5 col-sm-5 roundedForJobs bg-success text-white my-auto text-center p-2 ml-2 font-10">
                              <i className="fas fa-check text-white mr-2"></i>&nbsp;
                              {this.state.detailData.isShortlisted}
                              </div>
                              :
                              <div className="col-md-5 col-5 col-sm-5 roundedForJobs bg-success text-white my-auto text-center p-2 ml-2 font-10">
                              <i className="fas fa-check text-white mr-2"></i>
                              {Strings.notApplied}
                              </div>
                          }
                          <div className="col-md-5 col-5 col-sm-5 roundedForJobs border text-center text-capitalize text-dark my-auto p-2 ml-3 font-10">
                              <i className="fas fa-eye "></i>&nbsp;
                              <span className="col-md-2 col-1 pl-2 pr-2 pr-md-3 pl-md-3">
                          {this.state.detailData.viewJob} {this.state.detailData.viewJob == "1" || this.state.detailData.viewJob == "0" ? "View" : Strings.Views}</span>
                          </div>
                      </div>
                      <div className="row mt-1 mt-md-2">
                        { this.state.isUserLoggedIn ? (amIBetween ?  ((this.state.detailData.isShortlisted == null || this.state.detailData.isShortlisted == "" || this.state.detailData.isShortlisted == undefined) &&candidateCredits === 0 ? <p className="col-md-5 col-5 col-sm-5 roundedForJobs border text-dark my-auto text-center p-2 ml-2 font-10 pointer" data-toggle="modal" onClick={this.showCredit} >
                        <i className="fas fa-phone-alt"></i>
                        <span className="col-md-2 col-1 pl-2 pr-2 pr-md-3 pl-md-3"> {Strings.callHr}</span>
                        </p>
                       :
                        <p className="col-md-5 col-5 col-sm-5 roundedForJobs border text-dark my-auto text-center p-2 ml-2 font-10 pointer" data-toggle="modal" href="#callHR"
                           onClick={this.onPauseAudio,this.hrUpdate}>
                          <i className="fas fa-phone-alt"></i>
                          <span className="col-md-2 col-1 pl-2 pr-2 pr-md-3 pl-md-3"> {Strings.callHr}</span>
                          </p>)
                          : <p className="col-md-5 col-5 col-sm-5 roundedForJobs border text-dark my-auto text-center p-2 ml-2 font-10 pointer" data-toggle="modal" href="#notCallHR" onClick={this.onPauseAudio()}>
                          <i className="fas fa-phone-alt"></i>
                          <span className="col-md-2 col-1 pl-2 pr-2 pr-md-3 pl-md-3"> {Strings.callHr}</span>
                          </p>
                        ) : <p className="col-md-5 col-5 col-sm-5 roundedForJobs border text-dark my-auto text-center p-2 ml-2 font-10 pointer" data-toggle="modal" onClick={this.showHrLoginModal}>
                        <i className="fas fa-phone-alt"></i>
                        <span className="col-md-2 col-1 pl-2 pr-2 pr-md-3 pl-md-3"> {Strings.callHr}</span>
                        </p>
                        }
                          <p onClick={() => this.onPlayAudio()} id="play" className="col-md-5 col-5 col-sm-5 roundedForJobs border text-dark my-auto text-center p-2 ml-3 pointer font-10">
                          <i className="fas fa-volume-up"></i>
                          <span className="col-md-2 col-1 pl-2 pr-2 pr-md-3 pl-md-3">{Strings.playAudio}</span>
                          </p>
                          <p onClick={() => this.onPlayAudio()} id="pause" className="col-md-5 col-5 col-sm-5 roundedForJobs border text-dark my-auto text-center p-2 ml-3 pointer d-none font-10">
                              <i className="fas fa-volume-mute"></i>&nbsp;
                              <span className="col-md-2 col-1 pl-2 pr-2 pr-md-3 pl-md-3">{Strings.playAudio}</span>
                          </p>
                          <div className="modal fade" id="notCallHR">
                              <div className="modal-dialog modal-dialog-centered">
                              <div className="modal-content jobs-modal-view callHRSection rounded">
                                  <div className="modal-header bg-success borderRadiusModal">
                                  <div className="col-md-12">
                                      <div className="row  justify-content-center">
                                      <div className="shadow-sm rounded-circle bg-white position-fixed p-2 mt-2">
                                          <i className="fa fa-exclamation fa-2x text-success-gradient "></i>
                                      </div>
                                      </div>
                                      <button type="button" className="close text-white" data-dismiss="modal">&times;</button>
                                  </div>
                                  </div>
                                  <div className="modal-body text-dark">
                                  <center>
                  <div className="mt-1 mb-2">Unable to contact HR at the moment, Please call between {callHrFrom}AM to {callHrTo}PM</div>
                                  </center>
                                  <center>
                                  <div className="tab-content" id="pills-tabContent">
                                      <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                      <div type="submit" className="btn btn-success col-md-4 mt-2 col-sm-4 col-4 roundedModal" data-dismiss="modal" >Ok</div>
                                      </div>
                                  </div>
                                  </center>
                                  </div>
                              </div>
                              </div>
                          </div>
                          <div className="modal fade"  id="callHR">
                              <div className="modal-dialog modal-dialog-centered">
                              <div className="modal-content jobs-modal-view callHRSection rounded">
                                  <div className="modal-header bg-success borderRadiusModal">
                                  <div className="col-md-12">
                                      <div className="row  justify-content-center">
                                      <div className="shadow-sm rounded-circle bg-white position-fixed p-2 mt-2">
                                          <i className="fa fa-phone-alt fa-2x text-success-gradient "></i>
                                      </div>
                                      </div>
                                      <button type="button" className="close text-white" data-dismiss="modal">&times;</button>
                                  </div>
                                  </div>
                                  <div className="modal-body text-dark">
                                  <center>
                                      <div className="mt-2 mb-1 font-weight-bold">{Strings.companyEmail}</div>
                                      <div className="mt-1 mb-1">{this.state.detailData.email}</div>
                                      <div className="mt-1 mb-1 font-weight-bold">{Strings.companyAddress}</div>
                                      <div className="mt-1 mb-2">{this.state.detailData.location}</div>
                                  </center>
                                  <div className="tab-content HrModal"  id="pills-tabContent">
                                      <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                      <form>
                                          <div className="col-md-12">
                                          <div className="row ml-2">
                                              <a href={companyMobile} className="btn btn-success col-md-5 col-sm-5 col-5 mt-2 pl-1 pr-1 roundedModal callDetails">
                                              <i className="fa fa-phone-alt"></i>{" "}{Strings.callNow}</a>
                                              <div className="col-md-1 col-sm-1 col-1"></div>
                                              <div type="submit" className="btn btn-success col-md-5 mt-2 col-sm-5 col-5 roundedModal pl-1 pr-1" data-dismiss="modal"><i className="fa fa-times"></i>{" "}{Strings.cancelCaps}</div>
                                          </div>
                                          </div>
                                      </form>
                                      </div>

                                  </div>
                                  </div>
                              </div>
                              </div>
                          </div>
                      </div>
                      <div className="row col-md-7 pl-2 pt-2 pr-2">
                        <WhatsappShareButton url={shareUrl} quote={title} className="Demo__some-network__share-button mr-2" onClick={this.onPauseAudio} >
                          <WhatsappIcon size={35} round />
                        </WhatsappShareButton>
                        <FacebookShareButton url={shareUrl} quote={title} className="Demo__some-network__share-button mr-2" onClick={this.onPauseAudio} >
                          <FacebookIcon size={35} round />
                        </FacebookShareButton>
                        <LinkedinShareButton url={shareUrl} quote={title} imageurl={imageUrl + this.state.detailData.companyImage} className="Demo__some-network__share-button mr-2" onClick={this.onPauseAudio}>
                          <LinkedinIcon size={35} round />
                        </LinkedinShareButton>
                        <EmailShareButton url={shareUrl} subject="Download Jobam and get job opportunities" body={emailBody} className="Demo__some-network__share-button mr-2" onClick={this.onPauseAudio} >
                          <img src={Images.gmail} width="35" className="mr-1"  alt="img"></img>
                        </EmailShareButton>
                          </div>
                      </div>
                  </div>
              </div>
              </div>
              {/* for job details */}
              <div className="mt-3">
                <span className="ml-2 text-uppercase font-weight-bold headingColor">{Strings.JobDetailsCaps}</span>
              <div className="card shadow-sm p-3 mb-2 mt-2 bg-white rounded ml-1 mr-1">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-5 font-weight-bold fontqualification text-secondary">{Strings.companyName}</div>
                    <div className="col-md-6 col-sm-6 col-7  font-weight-bold text-secondary compName textLimit">
                      <Link to={{
                        pathname: "/companyprofile",
                        search: "/" + this.state.detailData.companyLink,
                        state: { companyId: this.state.detailData.companyId, jobId: localStorage.getItem("jobId") }
                      }} onClick={this.onPauseAudio}>{this.state.detailData.companyName}</Link></div>
                  </div>
                </div>
                {this.state.detailData.companyName == "bluCursor Infotech Pvt. Ltd." && this.state.detailData.isShortlisted != null && this.state.detailData.isShortlisted != "" && this.state.detailData.isShortlisted != undefined  ?
                <div className="col-md-12 col-sm-12 col-12">
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-5 font-weight-bold fontqualification text-secondary">{Strings.campusDrive}</div>
                    <div className="col-md-6 col-sm-6 col-7  text-secondary word-break"><a href="https://jobam.co/jjcampus/login.php" target="_blank"><u>Please Join</u></a></div>
                  </div>
                </div>
                : ""}
                <div className="col-md-12 col-sm-12 col-12">
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-5 font-weight-bold fontqualification text-secondary">{Strings.titleCaps}</div>
                    <div className="col-md-6 col-sm-6 col-7  text-secondary word-break">{this.state.detailData.jobTitle}</div>
                  </div>
                </div>
                <div className="col-md-12 col-sm-12 col-12">
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-5 font-weight-bold fontqualification text-secondary">{Strings.jobType}</div>
                    <div className="col-md-6 col-sm-6 col-7 text-secondary word-break">{this.state.detailData.jobType}</div>
                  </div>
                </div>
                <div className="col-md-12 col-sm-12 col-12">
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-5 font-weight-bold fontqualification text-secondary">{Strings.experienceCaps}</div>
                    <div className="col-md-6 col-sm-6 col-7 text-secondary">{this.state.detailData.experienceLevel}</div>
                  </div>
                </div>
                {this.state.detailData.education!=null && this.state.detailData.education!="" &&this.state.detailData.education!=undefined ?
                <div className="col-md-12 col-sm-12 col-12">
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-5 font-weight-bold fontqualification text-secondary">{Strings.qualificationCaps}</div>
                    <div className="col-md-6 col-sm-6 col-7 text-secondary">{this.state.detailData.education}</div>
                  </div>
                </div>
                :null}
                <div className="col-md-12 col-sm-12 col-12">
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-5 font-weight-bold fontqualification text-secondary">{Strings.salaryCaps}</div>
                    <div className="col-md-6 col-sm-6 col-7 text-secondary">
                    <i className="fas fa-rupee-sign text-secondary"></i>{" "}{this.state.detailData.salary}</div>
                  </div>
                </div>
                <div className="col-md-12 col-sm-12 col-12">
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-5 font-weight-bold fontqualification text-secondary">{Strings.GenderCaps}</div>
                    <div className="col-md-6 col-sm-6 col-7 text-secondary">{this.state.detailData.gender}</div>
                  </div>
                </div>
                <div className="col-md-12 col-sm-12 col-12">
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-5 font-weight-bold fontqualification text-secondary">{Strings.PostedON}</div>
                    <div className="col-md-6 col-sm-6 col-7 text-secondary">{Moment(this.state.detailData.createdDate).format('DD MMMM YYYY')}</div>
                  </div>
                </div>
                <div className="col-md-12 col-sm-12 col-12">
                  <div className="row">
                    <div className="col-md-6 font-weight-bold text-secondary fontqualification col-sm-6 col-5">{Strings.noOfOpeningTxt}</div>
                    <div className="col-md-6 text-secondary col-sm-6 col-7">{this.state.detailData.noOfPositions}</div>
                  </div>
                </div>
                <div className="col-md-12 col-sm-12 col-12">
                  <div className="row">
                    <div className="col-md-6 font-weight-bold text-secondary fontqualification col-sm-6 col-5">{Strings.cityText}</div>
                    <div className="col-md-6 text-secondary col-sm-6 col-7">{this.state.detailData.city}</div>
                  </div>
                </div>
              </div>

              {/* end job details///// */}
              {this.state.isUserLoggedIn && this.state.detailData.isShortlisted != "Applied" && this.state.detailData.isShortlisted != "Rejected" && this.state.detailData.isShortlisted != null && this.state.detailData.isShortlisted != " " ?
                <Link to={{
                  pathname: "/chatcompany",
                  // search: "/" + item.jobLink,
                  state: { companyId: this.state.detailData.companyId, companyName: this.state.detailData.companyName, companyImage: this.state.detailData.companyImage, jobLink: this.state.jobLink, jobId: this.state.jobId }
                }}
                  onClick={this.onPauseAudio} >
                  <img src={Images.chatIcon} alt="img" className="position-fixed fixed-bottom1" />
                </Link>
                : null}
              {this.state.reqSkills != "" && this.state.reqSkills != [] && this.state.reqSkills != undefined && this.state.reqSkills != null ?
                <div>
                  <div className="mt-3">
                    <span className="ml-2 text-uppercase font-weight-bold headingColor">{Strings.skills}</span>
                  </div>
                  <div className="card mt-2 rounded mb-2 ml-1 mr-1">
                    <div className="card-body text-secondary">
                      <div className="row">
                        {splitData.map(item => (
                          <p className="badge-secondary contentTextColor m-1 p-1">{item}</p>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                : null}
              {/* for map */}
              <div>
                <div className="mt-3">
                  <span className="ml-2 text-uppercase font-weight-bold headingColor">{Strings.location}</span>
                </div>
                <div className="card mt-2 mb-3 ml-1 mr-1">
                  <a
                    href={"https://www.google.com/maps/dir/?api=1&origin=" + latitude +
                      "," +
                      longitude +
                      "&destination=" +
                      this.state.detailData.latitude +
                      "," +
                      this.state.detailData.longitude +
                      "&travelmode=driving"}
                    target="_blank"
                  >
                    <img className="dirImg"
                      src={Images.rightTurn} alt="direction" />
                  </a>
                  <div  className="googleMapCls">
                    <GoogleMapReact
                      resetBoundsOnResize={false}
                      bootstrapURLKeys={{
                        key: "AIzaSyAaXq5aB8iI_QH5SNzKLLlk-qrcjFvtyVI"
                      }}
                      center={{ lat: mapLat, lng: mapLong }}
                      defaultZoom={17}
                    >
                      <div lat={mapLat} lng={mapLong}>
                        <img className="googleLogoCls" src={"./logo192.png"} alt="pin" />
                        <AnyReactComponent
                          lat={this.state.detailData.latitude}
                          lng={this.state.detailData.longitude}
                          text={this.state.detailData.jobProfile}
                        >
                        </AnyReactComponent>  
                      </div>
                    </GoogleMapReact>
                  </div>
                </div>
              </div>
              {/* for map */}
              <div className="mt-3">
                <span className="ml-2 text-uppercase font-weight-bold headingColor">{Strings.JobRequirementCaps}</span>
              </div>
              <div className="card mt-2 rounded mb-2 ml-1 mr-1">
                <div className="card-body text-secondary">
                  <div className="mb-1">
                    {this.state.detailData.requirements != null &&
                      this.state.detailData.requirements != undefined &&
                      this.state.detailData.requirements != "" ? (
                        <div>
                          <span className="contentTextColor">
                            <div dangerouslySetInnerHTML={{ __html: this.state.detailData.requirements }} />
                          </span>
                        </div>
                      ) : (
                        <p></p>
                      )}
                  </div>

                </div>
              </div>
              <div className="mt-3">
                <span className="ml-2 text-uppercase font-weight-bold headingColor">{Strings.jobAddress}</span>
              </div>
              <div className="card mt-2 rounded ml-1 mr-1">
                <div className="card-body text-secondary">
                  <div className="mb-1">
                    {this.state.detailData.location != null &&
                      this.state.detailData.location != undefined &&
                      this.state.detailData.location != "" ? (
                        <span className="contentTextColor">{this.state.detailData.location}</span>
                      ) : null}
                  </div>
                </div>
              </div>
                <div>
                  {relatedJobs !== null &&
                    relatedJobs !== undefined &&
                    relatedJobs.length > 0 ?
                    <p className="text-uppercase font-weight-bold headingColor m-2">{Strings.suggestedJobsCaps} </p>
                    : null}
                  <Carousel showThumbs={false} showArrows={true} showStatus={false} showIndicators={false} autoPlay={false}>
                    {relatedJobs !== null &&
                      relatedJobs !== undefined &&
                      relatedJobs.length > 0 ?
                      relatedJobs.map(item => {
                        return (
                          <div className="card shadow-sm rounded1 mb-2 pt-md-2 pb-md-2 pt-1 pb-1 col-md-12">
                            <div className="col-md-12 pointer pl-0 pr-0">
                              <div className="row">
                                <div onClick={() => this.handleSuggestedJob(item)} className="col-md-3 mt-md-2 mt-0 col-4">
                                  <div className="row">
                                    <img
                                      src={imageUrl + item.companyImage}
                                      className="company-image ml-md-2 col-md-12"
                                      alt="img"
                                    />
                                  </div>
                                </div>
                                <div onClick={() => this.handleSuggestedJob(item)} className="text-left pt-1 col-md-6 col-sm-6 mt-1 col-5 col-lg-6">
                                  <div className="row">
                                    <h6 className="mb-0 pt-1 text-secondary font-weight-normal textLimit">
                                      {item.companyName}
                                    </h6>

                                  </div>
                                  <div className="row">
                                    <h5 className="text-dark mt-1 textLimit jobTitle mb-1 pb-1">
                                      {item.jobTitle}
                                    </h5>
                                  </div>
                                  <div className="row">
                                    <h6 className="text-secondary mobileTextAlign ">
                                      <i className="fas fa-rupee-sign text-secondary"></i>{" "}
                                      {item.salary}{" "}
                                      <span>&nbsp;</span>
                                      <i className="fas fa-calendar-alt"></i>{" "}
                                      {item.postedDate}
                                    </h6>
                                  </div>
                                  {/* </Link> */}
                                </div>
                                <div className="col-md-3 col-sm-3 text-right col-3 mt-2 mt-md-1 col-lg-3">
                                  <h6 className="text-success-gradient">
                                    <div onClick={i => this.savedJobFun(item)} className={relatedJobfakeIcon && item.jobId == this.state.fakeId ? "pointer d-none" : "pointer d-block"}>
                                      {item.isSaved ? (
                                        <FontAwesomeIcon icon={['fas', 'bookmark']} size="1x" />
                                      ) : (
                                          <FontAwesomeIcon icon={['far', 'bookmark']} size="1x" />
                                        )}
                                    </div>
                                    <div onClick={i => this.savedJobFun(item)} className={relatedJobfakeIcon && item.jobId == this.state.fakeId ? "pointer d-block" : "pointer d-none"}>
                                      {item.isSaved ? (
                                        <FontAwesomeIcon icon={['far', 'bookmark']} size="1x" />

                                      ) : (
                                          <FontAwesomeIcon icon={['fas', 'bookmark']} size="1x" />

                                        )}
                                    </div>
                                  </h6>
                                  <div onClick={() => this.handleSuggestedJob(item)} className="badge badge-success rounded-pill mt-md-4 mt-3 pt-1 pb-1 pl-2 pr-2">
                                    {item.isShortlisted != null &&
                                      item.isShortlisted != "" &&
                                      item.isShortlisted != undefined ? (
                                        <span className="badgeFontSize text-uppercase">{item.isShortlisted}</span>
                                      )
                                      :
                                      (
                                        <span className="badgeFontSize text-uppercase text-white">APPLY</span>

                                      )
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }) :
                      null
                    }
                  </Carousel>
                </div>
              <div>
              </div>
            </div>
            {this.state.isUserLoggedIn && (this.state.detailData.isShortlisted == "" ||
              this.state.detailData.isShortlisted == null) && (this.state.detailData.status == "Active" || this.state.detailData.status == "सक्रिय") ? (
                <div>
                  <div className="container-fluid">
                    <div className="row col-md-6 fixed-bottom container No-Pading notInterCls">
                      <div className="bg-light col-md-6 p-0 col-6" data-toggle="modal" href="#notIntersted">
                        <div className="mt-3 text-center">
                          <p className="text-decoration-none pointer applyFont font-weight-bold text-uppercase text-body ">{Strings.notInterested}</p>
                        </div>
                      </div>
                      <div className="bg-success col-md-6 p-0 col-6" data-toggle="modal" href="#applyPopup">
                        <p className="text-decoration-none pointer text-white font-weight-bold applyFont text-center text-uppercase p-3" onClick={() => this.applyForJob()}>{Strings.apply}</p>
                      </div>
                    </div>
                  </div>
                  {/* not interested popup */}
                  <ModalDiv singleBtnModal={true} modalId="notIntersted" crossBtn={true} success={true} modalText={Strings.youAreNotInterested} twoBtn={true} discardText={Strings.yes} discardFun={() => this.notInterested()} discardSaveText={Strings.no}/>
                </div>
              ) : this.state.detailData.status == "Closed" || this.state.detailData.status == "Paused" || this.state.detailData.status == "बन्द" || this.state.detailData.status == "रुकी हुई" || this.state.detailData.status == "विराम दिला" ?(
                  <div className="col-md-6 container-fluid">
                    <div className="row col-md-6 fixed-bottom container No-Pading notInterCls">
                      <div className="bg-close col-md-12 p-0 col-12">
                        <p className="text-decoration-none text-white font-weight-bold applyFont text-center text-uppercase p-3" data-toggle="modal">{this.state.detailData.status}</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  this.state.detailData.isShortlisted == "" ||
                  this.state.detailData.isShortlisted == null ?
                  <Link
                    to={{ pathname: "/register", state: { jobId: jobId } }}
                    className="text-decoration-none text-white"
                  >
                    <div className="container-fluid fixed-bottom bg-success p-1 callHrBlock col-md-6 pointer regAndApplyCls">
                      <p className="lead text-center text-white applyFont font-weight-bold p-1">{Strings.registerAndApply}</p>
                    </div>
                  </Link>:null
                )}
            <div className="pb-4 mb-3">
              <Footer />
            </div>
          </div>
          </div>
          </div>
          : 
          <ModalDiv  modalReact={true} showModal={true} hideModal={()=>this.hideJob()} crossBtnModal={true} msg="You have viewed the maximum number of new jobs. Now you will able to explore the job in next week." btn1Click={() => this.hideJob()} btn1Text={Strings.oK}/> }
      </LoadingOverlay>
    );
  }
}
const mapStateToProps = state => {
  return {
    jobDetailData: state.allJobsReducer.jobDetailData,
    jobsDataLoading: state.allJobsReducer.jobsDataLoading,
    jobsDataError: state.allJobsReducer.jobsDataError,
    relatedJobs: state.allJobsReducer.relatedJobs,
    relatedJobsError: state.allJobsReducer.relatedJobsError,
    reportData: state.allJobsReducer.reportData,
    state
  };
};
const mapDispatchToProps = dispatch => {
  return {
    jobDetailApi: jobId => dispatch(jobDetailApi(jobId)),
    getJobByShareId: stringId => dispatch(getJobByShareId(stringId)),
    getRelatedJobList: () => dispatch(getRelatedJobList()),
    getReportData: () => dispatch(getReportData()),
    updateHrApi: () => dispatch(updateHrApi()),
    clearPreviousData: () => dispatch(clearPreviousData())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobDetail);