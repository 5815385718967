import React from "react";
import helpers from "../config";
import Footer from "../components/footer";
import { Link } from "react-router-dom";
import $ from 'jquery';
import { connect } from "react-redux";
import Images from "../Images";
import { getCompanyDetail, followCompanyApi, searchedHistoryCompany, getPostedJobs } from "../actions/companyProfileAction";
import Strings from "../utilities/stringlanguage";
import Carousel from "react-bootstrap/Carousel";
import ModalDiv from "../components/modal";
import { Row, Col, Modal } from "react-bootstrap";
import CardComponent from "../components/cardComponent";
import {
  EmailShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton, 
} from "react-share";
import "../_style/companyProfile.css";
import Webheader from "../components/webheader";
import LoadingOverlay from "react-loading-overlay";
var selectedLang;
var queryString;
var compShareId;
var jobseekerId;
var companyId;  
var jobId;
var email;
var latitude;
var longitude;
var jobseekerName;
var url;
var imageUrl;
var companyNameShare, companyLinkShare ;
var companyWebsiteShare;
 class CompanyProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      refreshing: false,
      isFollowing: false,
      isWebsite: true,
      isLoading : true,
      showRateImage : true,  
    };
    this.handleBack = this.handleBack.bind(this);
  }

  async componentDidMount() {
    let apiUrl = await helpers.getServerUrl()
    url = apiUrl.serverUrl
    imageUrl = apiUrl.imageUrl
    this.getMyLocation();
    if (this.props.location.state == undefined || this.props.location.state == null) {
      this.state.isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
      jobseekerId = localStorage.getItem("jobseekerId");
      localStorage.getItem("jobLink");
      email = localStorage.getItem("email");
      jobseekerName = localStorage.getItem("jobseekerName");
      localStorage.setItem("jobId", this.state.jobId);
      jobId = localStorage.getItem("jobId");
      queryString = window.location.href.length;
      compShareId = window.location.href.slice(queryString - 6, queryString);
      localStorage.removeItem("companyId");
      localStorage.setItem("compShareId", compShareId);
      selectedLang = localStorage.getItem("selectedLang");
      if (selectedLang != null && selectedLang != undefined && selectedLang != "") {
        Strings.setLanguage(selectedLang);
      }
      this.props.getCompanyDetail();
      this.props.getPostedJobs(companyId, compShareId);
    }
    else {
      this.state.isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
      localStorage.setItem("companyId", this.props.location.state.companyId);
      jobseekerId = localStorage.getItem("jobseekerId");
      localStorage.getItem("jobLink");
      email = localStorage.getItem("email");
      jobseekerName = localStorage.getItem("jobseekerName");
      localStorage.setItem("jobId", this.props.location.state.jobId);
      jobId = localStorage.getItem("jobId");
      companyId = localStorage.getItem("companyId");
      queryString = window.location.href.length;
     this.props.getCompanyDetail();
     this.props.getPostedJobs(companyId, compShareId);
      if (this.props.location.state.searchCompany) {
        this.props.searchedHistoryCompany();
      }
      if (window.performance) {
        if (performance.navigation.type === 0) {
          localStorage.setItem("companyId", this.props.location.state.companyId);
          localStorage.setItem("jobId", this.props.location.state.jobId);
         this.props.getCompanyDetail();
        this.props.getPostedJobs(companyId, compShareId);

        }
      }
    }
    $('body').removeClass('modal-open')
    $('.modal-backdrop').remove()
  }

  getMyLocation() {
    navigator.geolocation.getCurrentPosition(function (position) {
      latitude = position.coords.latitude.toFixed(4);
      longitude = position.coords.longitude.toFixed(4);
      localStorage.setItem("curLatitude", latitude);
      localStorage.setItem("curLongitude", longitude);
    });
  }
  showLoginModal = (e) => {
    this.setState({
      showLoginPopup: true
    });
  };

  hideLoginModal = (e) => {
    this.setState({ showLoginPopup: false });
  };

  showModal = (e) => {
    this.setState({
      show: true
    });
  };
  
  hideModal = (e) => {
    this.setState({ show: false });
  };

  async saveJobs(item) {
    this.setState({ isSaved: !this.state.isSaved });
    if (jobseekerId != null && jobseekerId != undefined) {
      try {
        let response = await fetch(url + "/jobs/addJobsToFavourites", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            jobseekerId: jobseekerId,
            jobId: item.jobId,
            isSaved: item.isSaved,
          })
        })
        response = await response.json()
        if (response.success) {
          this.props.getPostedJobs(companyId, compShareId)
        }
      } catch (err) {
      }
    }
    else {
      this.showLoginModal()
    }
  }

  share() {
    try {
    if (navigator.share) {
      navigator.share({
        title: companyNameShare,
        text: 'Have you tried Jobam mobile app yet ? You will definately like it :',
        url: "https://jobam.co/companyprofile?/" + companyLinkShare
      })
        .then(() => console.log('Share complete'))
        .error((error) => console.error('Could not share at this time', error))
    } 
  } 
  catch (error)
      {
      }
  }

  handleBack = () => {
    this.props.history.go(-2);
  }
  
  render() {
    let { companyDetailData, companyData, followCompany, followCompanyError, companySearchData,  postedJobsData, postedJobsLoading, postedJobsError } = this.props;
    let galleryImages = [];
    if(companyData.title != undefined){
    document.title =companyDetailData.companyName +" - " + companyData.title;
    document.getElementsByTagName("META")[2].content = companyData.description;
    document.getElementsByTagName("META")[3].content = companyData.keywords
    } 
    if(companyDetailData!= "" && companyDetailData != undefined)
    {
      localStorage.setItem("companyId", companyDetailData.companyId);
     this.state.isLoading = false; 
    }
    if(companyDetailData.gallery!== "" && companyDetailData.gallery!== undefined && companyDetailData.gallery!== "undefined")
    {
      let galleryimg = companyDetailData.gallery;
      galleryimg.forEach(item => {
        galleryImages.push({
          url: imageUrl + "jobseeker/getImg/" + item.img
        });
      });
    }
    let postedJobs = [];
    if (postedJobsData.length > 0) {
      postedJobsData.forEach(jobs => {
        postedJobs.push(
          {
            createdDate: jobs.createdDate,
            jobId: jobs.jobId,
            jobLink: jobs.jobLink,
            jobTitle: jobs.jobTitle,  
            companyName: jobs.companyName,
            companyImage: jobs.companyImage,
            salary: jobs.salary,
            status: jobs.status,
            isSaved: jobs.isSaved,
            isShortlisted: jobs.isShortlisted,
            jobType: jobs.jobType,

          }
        )
      })
    }
    companyLinkShare = companyDetailData.companyLink;
     companyNameShare = companyDetailData.companyName;
     companyWebsiteShare = companyDetailData.companyWebsite;
    var companyWeb = companyDetailData.companyWebsite;
    var isWebsite;
    if (companyWeb != null &&
      companyWeb != "" &&
      companyWeb != undefined) {
      isWebsite = true;
      if (companyWeb.includes("https") || companyWeb.includes("http")) {
        companyWeb = companyWeb;
      } else {
        companyWeb = "http://" + companyWeb;
      }
    }
    else {
      isWebsite = false;
    } 
    return ( 
      <LoadingOverlay
        active={this.state.isLoading}
        spinner={
          <div className="loading-companyprofile">
            <center className=" bg-dark1 rounded mt-5 col-md-6 col-sm-6 p-2 col-12">
              <h4 className="loadingText pt-1  mb-1 d-sm-block d-md-block">
                {Strings.loadingCompanyDetails}
              </h4>
              <h6 className="text-white pt-1 pb-1 d-block d-sm-none d-md-none"></h6>
              <img
                src={Images.loaderWhite}
                alt="Loading Image"
                className="pb-2"
                width="30"  
              />
            </center>`   `
          </div>
        }
      >
        <div>
          <div className="container col-md-6 No-Pading col-lg-6 col-sm-6 col-12">
            <div className="company-header-fixed">
            <Webheader pageName={companyDetailData.companyName} shareIcon={true} handleBack={this.handleBack} shareClick={() => this.share()} normalText={true}/>
            <div className="card p-2">
            <div className="row">
              <div className="card p-2 col-md-4 col-4 ml-3">
                <img src={imageUrl + "jobseeker/getImg/" +companyDetailData.companyImage} className="newImage pl-2 pr-2 mx-auto comImgClass" alt="Company Image "/>
              </div>
              <div className="card p-2 col-md-7 col-7 ml-3">
                <div className="row">
                <p className="text-left col-md-10 col-9 py-auto mb-1 addressText pr-1">{companyDetailData.location}</p>
                <a
                  href={"https://www.google.com/maps/dir/?api=1&origin=" + latitude +
                    "," +
                    longitude +
                    "&destination=" +
                    companyDetailData.latitude +
                    "," +
                    companyDetailData.longitude +
                    "&travelmode=driving"}
                  target="_blank"
                    >
                <div className="card shadow-sm p-1 ml-md-2 ml-1"><img src="https://www.thestatesman.com/wp-content/uploads/2020/04/googl_ED.jpg" width="40" height="40" alt="img"/></div>
                </a>
                </div>
                <div className="row ml-1">
                  {/* <div className="row col-md-12">
                    <WhatsappShareButton  className="Demo__some-network__share-button mr-2"  >
                      <WhatsappIcon size={35} round />
                    </WhatsappShareButton>
                    <FacebookShareButton  className="Demo__some-network__share-button mr-2" >
                      <FacebookIcon size={35} round />
                    </FacebookShareButton>
                    <LinkedinShareButton className="Demo__some-network__share-button mr-2" >
                      <LinkedinIcon size={35} round />
                    </LinkedinShareButton>
                    <EmailShareButton subject="Download Jobam and get job opportunities" className="Demo__some-network__share-button mr-2">
                      <img src={Images.gmail} width="35" className="mr-1"  alt="img"></img>
                    </EmailShareButton>
                  </div> */}
                {this.state.isUserLoggedIn != null ? (
                  <div className="d-flex justify-content-end">
                    <span
                      onClick={() => this.props.followCompanyApi()}
                      className="follownbtn p-2"
                    >
                      {followCompany == "Started following the company" ? (
                        <p
                          className="text-white text-center mb-0 followComClass">
                          {Strings.unfollowCaps} 
                        </p>
                      ) : (
                          <p
                            className="text-white text-center mb-0 followComClass">
                            {Strings.followCaps}
                          </p>
                        )}
                    </span>
                  </div>) : (
                    <Link to={{ 
                      pathname: "/register",
                    }}>
                      <div className="d-flex justify-content-end">
                        <span
                          className="follownbtn p-2"
                        >
                          {followCompany == "Started following the company" ? (
                            <p
                              className="text-white text-center mb-0 followComClass">
                              {Strings.unfollowCaps}
                            </p>
                          ) : (
                              <p
                                className="text-white text-center mb-0 followComClass">
                                {Strings.followCaps}
                              </p>
                            )}
                        </span>
                      </div>
                    </Link>
                  )}
                  </div>
              </div>
            </div>
            </div>
            </div>
            
            
            <ul className="nav nav-pills-tabs nav-fill mb-3 bg-white shadow-sm pt-1 pl-0 pr-0 pb-0 navigationPills tab-fixed">
              <li className="nav-item">
                <a
                  className="nav-link active text-dark text-uppercase"
                  data-toggle="pill"
                  href="#home"
                  onClick={() => this.setState({showRateImage : true})}
                >
                  {Strings.details}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link text-dark text-uppercase"
                  data-toggle="pill"
                  href="#menu1"
                  onClick={() => this.setState({showRateImage : false})}
                >
                  {Strings.postedJobs}
                </a>   
              </li>
              { isWebsite ?
              <li className="nav-item">
                <a
                  className="nav-link text-dark text-uppercase"
                  data-toggle="pill"
                  href="#menu2"
                  onClick={() => this.setState({showRateImage : false})}
                >
                  {Strings.websiteCompany}
                </a>
              </li>:null}
            </ul>
            <div className="pt-20">
            <div className="tab-content">
              <div className="tab-pane container active" id="home">
                
                <div className="company-card ml-2 ml-md-0 mr-md-0 mr-2">
                  
                </div>
                <h6 className="text-uppercase mx-md-auto ml-2  pt-3 font-weight-bolder">
                  {Strings.companyDescriptionCaps}
                </h6>
                <div className="company-card ml-2 ml-md-0 mr-md-0 mr-2 p-3 mb-3">
                  <div dangerouslySetInnerHTML={{ __html: companyDetailData.description }} />
                </div>
                {galleryImages !=null &&
                      galleryImages != "" &&
                      galleryImages != undefined ?
                  <h6 className="text-uppercase mx-md-auto ml-2  font-weight-bolder">
                  {Strings.companyPhotosCaps}
                </h6>:null}
                {galleryImages !=null &&
                      galleryImages != "" &&
                      galleryImages != undefined ?
                <div className="company-card ml-2 ml-md-0 mr-md-0 mr-2 pt-2 pb-2 mb-3">
                  <div
                    className="col-md-12 pointer"
                    data-toggle="modal"
                    data-target="#showImages"
                  >
                    {galleryImages != null &&
                      galleryImages != "" &&
                      galleryImages != undefined ? (
                        galleryImages.map((item) =>
                          <img
                            key={item.url}
                            src={item.url}
                            width="90"
                            height="100"
                            alt="Galley Image"
                            className="mt-1 mb-3 ml-2 mr-2"
                          />
                        )
                      ) : (
                        <span> {Strings.NoPhotos}</span>
                      )}
                  </div>
                </div>:null}
              </div>
              <div className="tab-pane container fade" id="menu1">
                <div>
                  {/* <PostedJobs /> */}
            <div>
           <div className=" col-md-12 col-lg-12 col-sm-12 col-12">
          {postedJobs !=null &&
            postedJobs != "" &&
            postedJobs != undefined
            ? postedJobs.map((item) =>
              <div key={item.jobId}>
                <CardComponent companyName={item.companyName} jobType={item.jobType} jobTitle={item.jobTitle} salary={item.salary}
                  createdDate={item.createdDate} status={item.status} jobLink={item.jobLink} jobId={item.jobId}
                  isShortlisted={item.isShortlisted} companyImage={imageUrl + item.companyImage}
                  isSaved={item.isSaved} 
                  callSaved={i => this.saveJobs(item)}
                />
                 <ModalDiv modalReact={true} showModal={this.state.showLoginPopup} msg={"Kindly login to save this job"} btn1Text={Strings.oK} btn1Click={this.hideLoginModal}/>
              </div>
            ) :  
           <ModalDiv loginPopup={true} getImage={Images.noRecord} textMsg={"No posted jobs found"} />
          }
        </div>
      </div>
      {/* POSTED JOBS END */}
                </div>
              </div>
              {companyDetailData.canReview ? 
             this.state.showRateImage ?
                 <Link to={{
              pathname: "/companyreview",
              state: { companyId: localStorage.getItem("companyId") }
               }} >
              <img src={Images.reviewCompany} height = '50px' width = '50px' alt="Company Review" className="position-fixed fixed-bottom1" />
            </Link>
           : null
            : null} 
              <div className="tab-pane container fade" id="menu2">
              <div>
                  {/* <CompanyWebsite /> */}
       <div >
        {isWebsite ?
          <div mt-5>
          <div className="col-md-6 col-12 mymodal-center">
				  <div className="card rounded">
					<center className="card-header p-4 rounded-top bg-success">
					  <div className="icon-circle shadow-sm position-absolute mt-0">
						<img
						  src={Images.websiteLogo}
						  height={50}
						  width={60}
						  alt="Website Logo"
						  className="mt-2 mb-2 ml-1 mr-1"
						/>
					  </div>
					</center>
          <p className="text-secondary pointer mt-5 text-center">To explore the {companyDetailData.companyName} website,<br/> Click the button below</p>
          <a className="nav-link text-decoration-none " target="_blank" href={companyDetailData.companyWebsite}>
          <center><input type="button" className="btn btn-success" value="View Website"/></center>
          </a>
				  </div>
				</div>
          </div>
          :
          <center>
            <h5 className="text-success mt-10">{Strings.noWebsiteFound}</h5></center>
        }
      </div>
                </div>
               </div>
            </div>
            </div>
            <div class="modal" tabindex="-1" role="dialog" id="showImages">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header pt-1 pb-1">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                  <Carousel>
                    {galleryImages != null &&
                      galleryImages != "" &&
                      galleryImages != undefined
                      ? galleryImages.map((item) =>
                        <Carousel.Item>
                          <div class="width-img" key={item.url}>
                              <img
                                className="img-responsive"
                                src={item.url}
                                alt="First slide"
                              />
                          </div>
                        </Carousel.Item>
                      )
                      : null}
                  </Carousel>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </LoadingOverlay>
    );
  }

}
const mapStateToProps = state => {
  return {
    companyDetailData: state.companyProfileReducer.companyDetailData,
    companyData: state.companyProfileReducer.companyData,
    companyDetailLoading: state.companyProfileReducer.companyDetailLoading,
    companyDetailError: state.companyProfileReducer.companyDetailError,
    followCompany:  state.companyProfileReducer.followCompany,
    followCompanyError: state.companyProfileReducer.followCompanyError,
    companySearchData:  state.companyProfileReducer.companySearchData,
    companySearchError: state.companyProfileReducer.companySearchError,
    postedJobsLoading: state.companyProfileReducer.postedJobsLoading,
    postedJobsData: state.companyProfileReducer.postedJobsData,
    postedJobsError: state.companyProfileReducer.postedJobsError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCompanyDetail: () => dispatch(getCompanyDetail()),
    followCompanyApi : (companyId) => dispatch(followCompanyApi(companyId)),
    searchedHistoryCompany : (companyId)=> dispatch(searchedHistoryCompany(companyId)),
    getPostedJobs: (companyId, compShareId) => dispatch(getPostedJobs(companyId, compShareId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfile);

