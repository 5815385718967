import React, { Component } from "react";
import { Route } from 'react-router-dom';

export default class NotFoundPage extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
    <center>
      <h3 className="mt-2">Page not found</h3>
      </center>
        );
    }
}
