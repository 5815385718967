import React from "react";
import LocalizedStrings from 'react-localization';
const strings = new LocalizedStrings({
  "hi": {
    pleaseCheckInternet:"अपने इंटरनेट कनेक्शन की जाँच करें और पुन: प्रयास करें।",
    noWebsiteFound:"कोई वेबसाइट नहीं मिली",
    loadingText:"लोड हो रहा है",
    pleaseReload:"कृपया एप्लिकेशन पुनः लोड करें",
    turnOnContinue:"कृपया जारी रखने के लिए इंटरनेट चालू करें",
    noInterNetConnect:"कोई इंटरनेट कनेक्शन नहीं!",
    pleaeEnableLoca: "कृपया लॉगिन के लिए स्थान अनुमतियां सक्षम करें",
    qrCodeAccess: "कैमरे तक पहुंच से वंचित कर दिया गया है। कृपया QR कोड स्कैन करने की अनुमति दें।",
    gotoSetting: "सेटिंग्स में जाओ",
    pleaseEnableInter: "कृपया Jobam ऐप का उपयोग करने के लिए स्थान अनुमति सक्षम करें",
    newEvents: "आयोजन",
    keepSmsQrCode: "रोजगार फैलाते रहो!",
    myQrcodeSms: "यहां आपका QR कोड आपके दोस्तों के साथ साझा किया गया है और आप दोनों को अधिक नौकरियों पर आवेदन करने के लिए क्रेडिट से सम्मानित किया जाएगा।",
    sscDeta: "एसएससी",
    hsscDeta: "एचएसएससी",
    eventDetails: "आयोजन की जानकारी",
    youapplied:"आपके आवेदन की कंपनी द्वारा समीक्षा की जानी बाकी है। तब तक तलाश करते रहे।",
    notReviewText: "अभी तक कोई समीक्षा नहीं मिली, समीक्षा के लिए पहले रहें",
    pleaseFillDetails:"कृपया सभी विवरण भरें",
    jhatpatjobsStrong: "भंडारण की पहुंच से वंचित कर दिया गया है। कृपया वीडियो को सहेजने की अनुमति दें।",
    videoAccessText: "कैमरे तक पहुंच से वंचित कर दिया गया है। कृपया ऐप की सेटिंग में जाएं और कैमरा और माइक्रोफोन को वीडियो रिकॉर्ड करने की अनुमति दें।",
    courceNameUg: "कृपया पाठ्यक्रम का नाम अपडेट करें",
    askMeLater:"मुझसे बाद में पूछना",
    sureJobDetails: "ज़रूर",
    appExitText: "ऐप से बाहर निकलने के लिए कृपया फिर से दबाएं।",
    dashboardApppercentage:"नौकरी करने वालों के लिए ऐप 100% मुफ्त है। अगर किसी ने पैसे मांगे, तो पैसे मत दो!",
    askDashboad: "समझ गया! फिर कभी मत पूछो",
    filterPg:"पीजी",
    filterUg:"यूजी",
    filthertw: "12वीं",
    filtherten: "10वीं",
    newJobTag: "नया",
    youAreOldVersion: "आप पुराने संस्करण का उपयोग कर रहे हैं नया संस्करण उपलब्ध है!",
    badRating:"खराब",
    okRating:"ठीक",
    goodRating:"अच्छा",
    wowRating:"वाह",
    amazingRating:"गजब का",
    chooseAnyone:"कोई रिपोर्ट डेटा नहीं मिला",
    appRatingStar:"अरे, आपको यह ऐप पसंद आ रहा है। आप हमारी बहुत मदद करेंगे 5-स्टार रेटिंग के साथ!",
    noApplicatinFond:"इस दस्तावेज़ को संभालने के लिए कोई एप्लिकेशन नहीं मिला",
    openResume:"खुला परिणाम",
    clearCacheSucces:"कैश सफलतापूर्वक साफ़ हो गया",
    jobMadeEasy:"जॉब्स ने आसान बनाया",
    nextBtn: "आगे",
    pleaseSelect: "कृपया एक का चयन करें",
    appDisplay:"ऐप प्रदर्शित भाषा",
    jhatpatjobsUpdate: "झटपट नौकरियां",
    didnotGetCode:"वेरिफिकेशन कोड नहीं मिला? पुन: कोड भेजे",
    enterVeri:"सत्यापन कोड यहाँ दर्ज करें ..",
    enterOTPSend: "अपने ईमेल पते पर भेजे गए ओटीपी कोड को दर्ज करें",
    sendVerifiCode: "सत्यापन कोड भेजें",
    enterYorEmailAut:"अपना ईमेल पता दर्ज करें",
    verifEmailAddree: "ईमेल पता दर्ज करें और हम आपको एक सत्यापन कोड भेजेंगे",
    addEnail:"ईमेल पता जोड़ें",
    emailEnter:"कृपया ईमेल आईडी दर्ज करें",
    emailAuth: "कृपया मान्य ईमेल पता दर्ज करें",
    loginTerms:"नियम और शर्तें",
    loginaAgree:"मैं इसके द्वारा सहमत हूँ",
    facebookLogin :"फ़ेसबुक से साइन इन करें",
    googleLogin: "गूगल के साथ साइन इन करें",
    signupFree: "मुफ्त में साइन अप",
    loginWelcome: "झटपट नौकरियों में आपका स्वागत है",
    tabInterviewTips:"प्रशिक्षण",
    notifiClearAll: "सभी साफ करें",
    notificationDel: "हटाएं",
    videoTipsInter: "वीडियो टिप्स",
    eventsProfileTabs: "आयोजन",
    industryTypeSelect: "कृपया उद्योग प्रकार दर्ज करें",
    chooseOneExp: "एक चुनो",
    clearCacheProfile: "कैश को साफ़ करें",
    discardAboutSave: "सहेजें",
    discardAboutText: "रद्द करें",
    aboutTextSave: "क्या आप परिवर्तनों को सेव करना चाहते हैं?",
    jobdetailsVideo: "वीडियो विवरण",
    companyAddre: "कंपनी का पता",
    profileTabs : "प्रोफाइल",
    interviewTabs:"इंटरव्यू  टिप्स",
    scanQrCodeTabs : "स्कैन QR कोड",
    myJobTabs: "मेरी जॉब्स",
    homeTabs: "होम",
    areyouSureDeleteAudio: "क्या आप वाकई ऑडियो हटाना चाहते हैं?",
    deleteRecordText: "ऑडियो हटाएं",
    recordAudioText:"रिकॉर्ड ऑडियो",
    list: "सूची", 
    cityText: "शहर",
    searchHeaderText: "जॉब्स या कंपनियां",
    map: "नक्शा",
    awaitingReview: "प्रतीक्षित सर्वेक्षण",
    shortlisted: "चयनित",
    applied: "आवेदित",
    profileCompleted: "संपन्न रूपरेखा",
    resume: "रिज्यूमे",
    resumeCaps: "रिज्यूमे",
    aadharCard: "आधार कार्ड", 
    identityProof: "पहचान प्रमाण",
    identityProofCaps: "पहचान प्रमाण",
    education: "शिक्षा",
    educationCaps: "शिक्षा",
    workExperienceCaps: "काम का अनुभव",
    videoResume: "वीडियो रिज्यूमे",
    videoResumeCaps: "वीडियो रिज्यूमे",
    resumeCreated: "आपका रिज्यूमे बन गया है",
    outshineYourResume: "अपने शिक्षा विवरण को अपडेट करके फिर से शुरू करें",
    updateAboutMe: "एक बेहतर रिज्यूमे बनाने के लिए 'मेरे बारे में' अपडेट करें",
    filters: "फिल्टर",
    filterText : "फिल्टर",
    hello: "नमस्ते",
    clear: "हटाना",
    experienceLevel: "अनुभव स्तर",
    experienceLevelCaps: "अनुभव स्तर",
    qualification: "शैक्षिक योग्यता",
    qualificationCaps: "योग्यता",
    fresher: "फ्रेशर्",
    professionalSummaryShort: "पेशेवर सारांश बहुत छोटा है",
    salaryExpectation: " वेतन अपेक्षा",
    salary: "वेतन",
    salaryCaps: "वेतन",
    interviewTips: " इण्टरव्यू सलाह",
    interviewTipsCaps: " इण्टरव्यू सलाह",
    share: "शेयर",
    upload: "अपलोड",
    uploadCaps: "अपलोड",
    loadingLanguage : "भाषाएँ लोड हो रही हैं",
    delete: "हटाना",
    Delete: "हटाना",
    highest: "सर्वोच्च",
    degree: "डिग्री",
    startDateCaps: "प्रारंभ दिनांक",
    startDate: "प्रारंभ दिनांक",
    endDateCaps: "अंतिम दिनांक",
    endDate: "अंतिम दिनांक",
    jobPosition: "नौकरी पद",
    industryType: " व्यवसाय प्रकार",
    notificationSmall : "अधिसूचना",
    notificationText : "अधिसूचना",
    chatText : "चेत",
    summary: "संक्षेप",
    organizations: "संस्था का",
    name: "नाम",
    skillsAndInterests: "कौशल",
    skills: "कौशल",
    currentLocation: "वर्तमान स्थान",
    submit: "जमा करें",
    Submit: "जमा करें",
    myJobs: " मेरी नौकरी",
    myJobsCaps: "मेरी नौकरी",
    postedOn: "पोस्ट किया:",
    PostedON: "पोस्ट किया",
    experience: "अनुभव",
    experienceCaps: "अनुभव",
    rejected: "अस्वीकृत",
    rejectedCaps: "अस्वीकृत",
    location: "स्थान",
    awaitingReview: "समीक्षा का इंतिज़ार है",
    interviewed: "साक्षात्कार",
    interviewedCaps: "साक्षात्कार", 
    hired: " नियुक्त किया   ",
    Hired: "नियुक्त किया ",
    scheduled: "अनुसूचित",
    scheduledCaps: "अनुसूचित",
    verificationCode: "पुष्टि संख्या",
    typeAMsg: "एक संदेश लिखें",
    verify: "सत्यापित करें",
    verifyYourPhoneNumber: "अपना फोन नम्बर सत्यापित करें",
    congratulations: " बधाई हो!",
    oK: "ठीक",
    noCredits: "आवेदन करने के लिए कोई क्रेडिट उपलब्ध नहीं है",
    youHaveSuccessfullyAppliedForTheJob: "आपने सफलतापूर्वक नौकरी के लिए आवेदन किया है",
    jobType: "नौकरी प्रकार",
    interviewTips: "साक्षात्कार युक्तियाँ",
    jobProfiles: "जॉब प्रोफ़ाइल",
    FullTime: "फूल टाईम",
    partTime: "पार्ट टाईम",
    internship:"इंटर्नशिप",
    experienced: "अनुभवी",
    Experienced: "अनुभवी",
    profileSummary: "प्रोफ़ाइल सारांश",
    profileBriefDesc: "अपने बारे में संक्षिप्त विवरण लिखें",
    aboutYou: "आपके बारे में",
    reset: "रीसेट",
    Continue: "जारी रहना",
    graduate: "स्नातक",
    sales: "बिक्री",
    finance: "वित्त",
    humanResources: "मानवीय संसाधन",
    marketing: "विपणन",
    engineer: "इंजीनियर",
    noApplyJobText: " आपने अभी तक नौकरी के लिए आवेदन नहीं किया है! नौकरी के लिए आवेदन करें औरसंगठन से सीधे संवाद करें",
    businessDevelopement: "व्यवसाय का विकास",
    accountant: "मुनीम",
    digitalMarketing: "अंकीय क्रय विक्रय",
    graphicDesigner: "",
    teacher: "अध्यापक",
    counselor: "परामर्शदाता",
    contentWriting: "सामग्री लेखन",
    budgetAnalyst: "बजट विश्लेषक",
    buyerPlanner: "खरीदार योजनाकार",
    safetyManagement: "सुरक्षा प्रबंधन",
    qualityAnalyst: " गुणवत्ता विश्लेषक",
    trainer: "प्रशिक्षक",
    technician: "तकनीशियन",
    receptionist: "रिसेप्शनिस्ट",
    delivery: "वितरण",
    tailor: "दर्जी",
    cook: "रसोइया",
    notification: "अधिसूचना",
    logout: "लॉगआउट",
    disableProfile: "प्रोफ़ाइल अक्षम",
    shareApp: "ऐप शेयर",
    companyFeedback: "कंपनी प्रतिक्रिया",
    languageSelection: "भाषा चयन",
    hindi: "हिंदी",
    english: "अंग्रेज़ी",
    jobNotification: "नौकरी अधिसूचना",
    feedbackFromCompanies: "कंपनियों से प्रतिक्रिया",
    email: "ईमेल",
    address: "पता",
    state: "राज्य",
    contact: "संपर्क",
    feedback: "प्रतिक्रिया",
    editProfile: "प्रोफाइल एडिट करें",
    Edit: " एडिट",
    name: "नाम",
    contactNumber: "संपर्क संख्या",
    dateOfBirth: "जन्म की तारीख",
    currentCity: "वर्तमान शहर",
    city: "शहर",
    enterYourDob: "अपने जन्म की तारीख दर्ज करें",
    add: "जोड़ करे",
    download: "डाउनलोड",
    view: "देखें",
    addEducation: "शिक्षा जोड़ें",
    details: "विवरण",
    privacyPolicy: "गोपनीयता नीति",
    percentage: "प्रतिशत",
    examName: "परीक्षा का नाम",
    chooseDegree: "डिग्री चुनें",
    followers: "फोल्लोवेर्स",
    postedJobsCaps: "नौकरी पोस्ट",
    enterYourSkills: "अपना कौशल दर्ज करें",
    chat: "बातचीत",
    enteryourcontactno: "अपना संपर्क नंबर दर्ज करें",
    photos: "तस्वीरें",
    workExperienceAddedSuccessfully: "कार्य अनुभव सफलतापूर्वक जोड़ा गया",
    workExperienceDeletedSuccessfully: "कार्य अनुभव सफलतापूर्वक हटा दिया गया",
    reviews: "समीक्षा",
    reviewCaps: "समीक्षा",
    Views: "व्यूज",
    Viewj: "व्यू",
    userauthentication: "प्रयोक्ता प्रमाणीकरण",
    highersecondaryschoolcertificates: "उच्चतर माध्यमिक विद्यालय प्रमाण पत्र",
    enterscoredpercentage: "दर्ज प्रतिशत दर्ज करें",
    enterjobpos: "नौकरी की स्थिति दर्ज करें",
    pleaseentervalidkey: "कृपया एक वैध कुंजी दर्ज करें।",
    enteryourkeyhere: "अपनी कुंजी यहाँ दर्ज करें ..",
    signinconnectwithusnow: "साइन इन करें और हमारे साथ अभी मुफ्त में कनेक्ट करें",
    signinwithgoogle: "Google के साथ साइन इन करें",
    signinwithfb: "फ़ेसबुक से साइन इन करें",
    organizationname: "संगठन का नाम",
    enterorganization: "संगठन का नाम दर्ज करें",
    rucurrentlyworkhere: "क्या आप वर्तमान में यहां काम कर रहे हैं",
    whatswrongwithjob: "इस नौकरी में क्या खराबी है?",
    youAreAlreadyExperienced: "आप पहले से ही अनुभवी हैं, नए सिरे से विवरण अपडेट करने से आपका अनुभव डेटा हट जायेगा। क्या आप अभी भी इसे अपडेट करना चाहते हैं?",
    description: "विवरण",
    company: "कंपनी",
    job: "काम",
    aadharNo: "आधार संख्या",
    enteryouremail: "अपना ईमेल दर्ज करें",
    enteryourname: "अपना नाम दर्ज करें",
    educationaddedsuccess: "शिक्षा सफलतापूर्वक जोड़ा गया",
    ratings: "रेटिंग",
    Ratings: "रेटिंग्स",
    jobs: "जॉब्स",
    enterConcernHere: "अपनी चिंता यहाँ दर्ज करें",
    companyProfile: "कंपनी प्रोफाइल",
    companyProfileCaps: "कंपनी प्रोफाइल",
    choosefiletoupload: "कृपया अपलोड करने के लिए एक फ़ाइल चुनें",
    yes: "हाँ",
    cancel: "रद्द करना",
    workstatus: "काम स्थिति",
    deleteIdProofDialog: "क्या आप वाकई आईडी प्रमाण को हटाना चाहते हैं?",
    IdProofDeletesuccessfully: "आईडी प्रमाण को सफलतापूर्वक हटा दिया गया।",
    idProofUploadSuccess: "आईडी प्रमाण सफलतापूर्वक अपलोड किया गया",
    clickanyofyouridProof: "अपना कोई भी आईडी प्रूफ चुनने के लिए यहां क्लिक करें।",
    deleteAdhharDialog: "क्या आप वाकई adhaar card को हटाना चाहते हैं?",
    AdhaarDeletesuccessfully: "Adhaar Card सफलतापूर्वक हटाया गया।",
    adhaaruploadsuccess: "आधार सफलतापूर्वक अपलोड किया गया।",
    selectFileClickPhoto: "फोटो खींचकर या लाइब्रेरी से चुनकर फाइल चुनने के लिए यहां क्लिक करें।",
    updateadhaarcontactNoApply: "आवेदन करने से पहले कृपया अपने आधार कार्ड और संपर्क नंबर को अपडेट करें।",
    shareexpandRateUs: "अपना अनुभव हमारे साथ साझा करें और अपनी रेटिंग दें।",
    marksheet: "अंक तालिका",
    deletemarkSheetDialog: "क्या आप वाकई मार्क शीट हटाना चाहते हैं?",
    markSheetDeletesuccessfully: "मार्क शीट सफलतापूर्वक हटा दी गई",
    degreeUploadSuccess: "आपकी डिग्री सफलतापूर्वक अपलोड की गई है",
    deletemarksheet: "मार्क शीट सफलतापूर्वक हटा दी गई",
    resumeDeletesuccessfully: "रेज़्यूमे सफलतापूर्वक हटा दिया गया",
    kindlyselctFileUpload: "अपलोड करने से पहले कृपया एक फ़ाइल चुनें",
    deletemarkResumeDialog: "क्या आप वाकई रेज़्यूमे को हटाना चाहते हैं?",
    deleteVideoDialog: "क्या आप वाकई वीडियो हटाना चाहते हैं?",
    resumeBuilder: "रिज्यूमे निर्माता",
    ResumeBuilder: "रिज्यूमे निर्माता",
    personalInfo: "व्यक्तिगत जानकारी",
    fullname: "पूरा नाम",
    fullnameCaps : "पूरा नाम",
    mobileNumber: "मोबाइल नंबर",
    zipCode: "पिन कोड",
    marksObtained: "प्राप्तांक",
    jobtitle: "नौकरी नाम",
    titleCaps: "शीर्षक",
    interviewDate: "साक्षात्कार तिथि",
    interviewTime: "साक्षात्कार समय",
    highestDegree: "उच्चतम उपाधि",
    jobLocation: "नौकरी स्थान",
    apply: "लागू करें",
    Apply: "लागू करें",
    remove: "हटाना",
    next: "आगामी",
    skip: "छोड़ें",
    done: "किया हुआ",
    JobDetailsCaps: "नौकरी विवरण",
    acceptTermsConditions: "आगे बढ़ने के लिए कृपया नियम और शर्तें स्वीकार करें",
    thankyou: "धन्यवाद",
    noNotificationmsg: "कोई नई अधिसूचना नहीं",
    rateUs: "हमें रेटिंग दें",
    videoResumeIntro: "हमारे ब्रांड के नए वीडियो फिर से शुरू विकल्प के साथ असाधारण बनें, चिंता न करें क्योंकि आपको उसी पर निर्देशित किया जाएगा।",
    resumeIntro: "कंपनियों को अपनी दृश्यता बढ़ाने के लिए अपने दूरगामी रिज्यूम को अपलोड करें",
    adhaarIntro: "प्रामाणिकता सुनिश्चित करने के लिए, आधार कार्ड अनिवार्य है ,इसे अपलोड करना न भूलें",
    IdCardIntro: "आईडी में डील करना कोई अधिक हलचल नहीं है, इसे यहां अपलोड करें और आराम महसूस करें",
    educationIntro: "अपने शैक्षिक विवरणों को जोड़कर अपनी प्रोफ़ाइल को उत्कृष्ट बनाएं",
    logOutConfirmmsg: "क्या आप लॉग आउट करना चाहते हैं?",
    certificatesIntro: "वह सब कुछ जोड़ें जो आपको सपने की नौकरी के योग्य बनाता है ,अपने प्रमाण पत्र जोड़ें और भीड़ से बाहर खड़े हो जाओ।",
    workExpIntro: "अपनी सपनों की कंपनी पर एक लंबे समय तक चलने वाली छाप बनाने के लिए, अपने काम के अनुभव का उल्लेख यहां करें।",
    uploadtheImage: "क्या आप इस छवि को अपलोड करना चाहते हैं?",
    agreetothe: "मैं सहमत हु नियमों और शर्तों से",
    agreeTerms: "नियमों और शर्तों से",
    enterFeedBackHere: "कृपया अपनी प्रतिक्रिया यहाँ लिखें",
    appliedJobs: "लागू नौकरियां",
    AppliedJobs: "आवेदित नौकरियां",
    shortlistedJobs: "शॉर्टलिस्टेड जॉब्स",
    ShortlistedJobs: "शॉर्टलिस्टेड जॉब्स",
    appliedCaps: "आवेदित",
    awaitingCaps: "प्रतीक्षारत",
    showAll: "सभी", 
    km: "किमी",
    ShortlistedCaps: "चयनित",
    item: "वस्तु",
    Profile: "प्रोफ़ाइल",
    disableProfile: "अक्षम प्रोफ़ाइल",
    cancelCaps: "रद्द करना",
    iAgree: "मैं सहमत हूँ",
    disableNow: "अभी अक्षम करें",
    contactSupport: "सहयोग टीम से संपर्क करें",
    contactSpprt: "सहयोग टीम से संपर्क",
    Email: "ईमेल",
    Message: "संदेश",
    settingsCaps: "सेटिंग्स",
    settings : "सेटिंग्स",
    ChooseALanguage: "अपना ऐप डिस्प्ले लेंग्वेज चुनें",
    EnterTitleOfTheCertificate: "प्रमाणपत्र का शीर्षक दर्ज करें",
    hMarkSheet: "10 वीं मार्क शीट",
    ssMarkSheet: "12 वीं मार्क शीट",
    yDoWeNeedUploadId: "हमें आईडी प्रूफ कार्ड की आवश्यकता क्यों है?",
    Do: "ऐसा करें",
    Dont: "ऐसा न करें",
    VideoResumeTips: "वीडियो रिज्यूमे विवरण टिप्स",
    JobRequirementCaps: "नौकरी की आवश्यकता",
    JessicaDOE: "जेसिका डो",
    HR: "मानव संसाधन",
    companyDetailsCaps: "संस्थान के विवरण",
    companyDescriptionCaps: "कंपनी विवरण",
    companyPhotosCaps: "कंपनी तस्वीरें",
    unfollowCaps: "अनफ़ॉलो",
    followCaps: "फॉलो",
    ShareWithYourFriends: "अपने मित्रों के साथ साझा करें",
    SchoolNAME: "विद्यालय का नाम",
    passingYear: "उत्तीर्ण वर्ष",
    marksObtainedCaps: "प्राप्तांक",
    uploadYourSSCMarksheet: "अपलोड एस.एस.सी. अंकतालिका",
    uploadYourHSSCMarksheet: "अपलोड एच.एस.एस.सी. अंकतालिका",
    CollegeName: "कॉलेज का नाम",
    courseCaps: "पाठक्रम",
    EnterYourSchool: "अपना विद्यालय दर्ज करें",
    ChoosePassingYear: "पासिंग ईयर चुनें",
    EnterPercentage: "प्रतिशत दर्ज करें",
    EnterYourCollege: "अपने कॉलेज का नाम दर्ज करें",
    EmailAddress: "ईमेल पता",
    EnterYourNumber: "अपना नंबर दर्ज करें",
    EnterYourCity: "अपना शहर दर्ज करें",
    savedJOBS: "सहेजे गए जॉब्स",
    Notification: "अधिसूचना",
    selectYourInterest: "अपनी रुचि का चयन करें",
    JobProfiles: "जॉब प्रोफ़ाइल",
    Confirm: "पुष्टि करें",
    AccountingFinance: "लेखा और वित्त",
    ComputerTechnology: "कंप्यूटर तकनीक",
    CustomerServices: "ग्राहक सेवाएं",
    SalesMarketing: "खरीद और बिक्री",
   SocialServices: "सामाजिक सेवा",
    Others: "अन्य",
    other: "अन्य",
    MakeResume: "रिज्यूमे बनाना",
    DocumentResumeTips: "दस्तावेज़ रिज्यूमे सलाह",
    JobFilters: "नौकरी फ़िल्टर",
    Rs: "रुपये",
    uploadingVideo : "अपना वीडियो रिज्यूमे अपलोड हो रहा है",
    uploadingResume : "रिज्यूमे अपलोड हो रहा है",
    uploadingProfile : "प्रोफ़ाइल अपलोड हो रही है",
    JobPROFILE: "नौकरी प्रोफ़ाइल",
    EducationalQualification: "शैक्षणिक योग्यता",
    NoLocationProvided: "कोई स्थान प्रदान नहीं किया गया",
    ChangeLanguage: "भाषा बदलो",
    ChooseTheFollowingLanguage: "  निम्न भाषा चुनें",
    HiredJobs: "नियुक्त नौकरियां",
    ScheduledJobs: "अनुसूचित नौकरियां",
    PrivacyPolicies: "गोपनीयता नीतियाँ",
    termsCondition: "नियम व शर्त",
    AreYouSureYouWantToLogout: "क्या आप लॉग आउट करना चाहते हैं?",
    JobSavedSuccessfully: "नौकरी सफलतापूर्वक जमा की गई है",
    JobUnsavedSuccessfully: "नौकरी सफलतापूर्वक हटा दी गई है",
    CompanySavedSuccessfully: "कंपनी सफलतापूर्वक जमा की गई है",
    CompanyUnsavedSuccessfully: "कंपनी सफलतापूर्वक हटा दी गई है",
    YourResumeUploadedSuccessfully: "आपका रिज्यूमे सफलतापूर्वक अपलोड किया गया",
    NoFeedbackFound: "कोई प्रतिक्रिया नहीं मिली",
    NoSavedJobsFound: "नो सेव्ड जॉब्स मिला",
    SorryThisJobHasBeenRemoved: "क्षमा करें, यह कार्य हटा दिया गया है",
    NoInterviewTipsFound: "कोई साक्षात्कार युक्तियाँ नहीं मिलीं",
    NoJobsFound: "कोई नौकरी नहीं मिली",
    Enterthemessage: "संदेश दर्ज करें",
    favoriteCompany: " पसंदीदा कंपनी",
    commentHere: "यहाँ टिप्पणी करें..",
    reportSubmitted : "रिपोर्ट सफलतापूर्वक जोड़ा गया",
    savedJobs: "सहेजे गए जॉब्स",
    logOut: "लॉग आउट",
    FavouriteCompanies: "पसंदीदा कंपनियां",
    NoSavedComnpanies: "नो सेव्ड कंपनियां",
    notificationDialogText: "कोई सूचना नहीं मिली",
    favouriteCompanyText: "कोई पसंदीदा कंपनी नहीं मिली",
    VideoHasBeenUploadedSuccessfully: "वीडियो सफलतापूर्वक अपलोड किया गया है",
    noOfOpeningTxt: "नौकरी की रिक्तियां",
    ProfileUpdatedSuccessfully: "प्रोफाइल को सफलतापूर्वक अपडेट किया गया",
    PleaseEnterNumber: "कृपया फ़ोन नंबर दर्ज करें",
    PleaseEnterValidNumber: "कृपया एक मान्य फोन नंबर दर्ज करें",
    OtpverifiedSuccessfully: "ओटीपी सफलतापूर्वक सत्यापित किया गया",
    invalidOtp: "अमान्य ओटीपी ",
    plzentercodeSms: "कृपया सत्यापन कोड दर्ज करें",
    resendCode: " पुन: कोड भेजे",
    noCode: "कोई कोड नहीं? ",
    togetnotifUse: "तत्काल सूचना प्राप्त करने के लिए अपने व्हाट्सएप नंबर का उपयोग करें",
    Enterphonenumber: "फोन नंबर दर्ज करें",
    verify: "सत्यापित करें",
    PersonalDetails: "व्यक्तिगत विवरण",
    personalDetails: "व्यक्तिगत विवरण",
    ProfileText: "नियोक्ताओं को अपनी ताकत को समझने के लिए कौशल दें",
    AddExperience: "अनुभव जोड़ें",
    AddResume: "रिज्यूमे जोड़ें",
    Gender: "लिंग",
    GenderCaps: "लिंग",
    Male: "पुरुष",
    Female: "स्त्री",
    Readless: "कम पढ़ें",
    ReadMore: "अधिक पढ़ें",
    PleaseTryAgain: "कुछ गलत हो गया! कृपया पुन: प्रयास करें",
    ValidContactNumber: "कृपया एक वैध सम्पर्क नम्बर डालें",
    PleaseEnterContact: "कृपया एक संपर्क नंबर दर्ज करें",
    EnterValidKey: "कृपया एक मान्य कुंजी दर्ज करें",
    NoPhotos: "कंपनी द्वारा कोई तस्वीर पोस्ट नहीं की गई",
    NoRequiredSkillsFound: "कोई आवश्यक कौशल नहीं मिला",
    fillSchool: "कृपया स्कूल का नाम भरें",
    fillSsc: "कृपया ssc भरें",
    alreadyVerifedNum: "यह मोबाइल नंबर पहले से ही सत्यापित है",
    editInterest: "रुचि एडिट करें",
    videoDurationAlert: "वीडियो की अवधि 10 सेकंड से अधिक होनी चाहिए",
    videoDurationLargeAlert: "वीडियो की अवधि 60 सेकंड से अधिक नहीं होनी चाहिए",
    NotAvailable: "उपलब्ध नहीं है",
    Available: "उपलब्ध",
    update: "अपडेट करें",
    kindlyupdate: "कृपया अपनी प्रोफ़ाइल अपडेट करें",
    neverAskMoney: "Jobam आपकी व्यक्तिगत जानकारी को तीसरे पक्ष के साथ साझा नहीं करता है",
    fillCollege: "कृपया कॉलेज का नाम भरें",
    fillUniversity: "कृपया विश्वविद्यालय का नाम भरें",
    chooseInterest: "कृपया अपनी रुचि का क्षेत्र चुनें",
    chooseProfiles: "कम से कम 3 प्रोफ़ाइल चुनें:",
    maxProfile: "आप अधिकतम 6 प्रोफाइल का चयन कर सकते हैं",
    disableNotificationSuccessfully: "अधिसूचना को सफलतापूर्वक अक्षम करें",
    enableNotificationSuccessfully: "अधिसूचना को सफलतापूर्वक सक्षम करें",
    disableNotification: "नोटिफ़िकेशन को अक्षम करो",
    enableNotification: "सूचना सक्षम करें",
    previewResume: "पूर्वावलोकन रिज्यूमे",
    notNow: "अभी नहीं",
    relatedVideos: "संबंधित वीडियो",
    profileSummaryShort: "प्रोफ़ाइल सारांश बहुत छोटा है",
    RelatedVideo: "संबंधित वीडियो",
    imFresher: "मैं फ्रेशर हूं",
    imExperienced: "मैं अनुभवी हूं",
    educationDetails: "शिक्षा विवरण",
    jjBot: "JJ बॉट",
    enterYourProfileSummary: "अपनी प्रोफ़ाइल सारांश दर्ज करें",
    aboutMe: "मेरे बारे में",
    numberCannotBeNull: "कृपया मोबाइल नंबर दर्ज करें",
    professionalSummary: "व्यावसायिक सारांश",
    pleaseUpdateProfileSummary: "प्रोफाइल सारांश अपडेट करें",
    updateProfessionalSummary:"प्रोफाइल सारांश अपडेट करें",
    ProfileCompletion: "प्रोफ़ाइल",
    uploadResume: "अपलोड रिज्यूमे ",
    viewVideoResume: "वीडियो रिज्यूमे देखें",
    uploadFromFile: "फाइल से अपलोड",
    applyNow: "आवेदन करें",
    Verified: "सत्यापित",
    topPicks: "शीर्ष पसंद",
    updatingResumeDetail: "अनुभव विवरण अपडेट करने से आपको बेहतर रिज्यूमे बनाने में मदद मिलेगी",
    scanQrCodeForJobs: "JOBS के लिए QR कोड स्कैन करे",
    allJobs: "सारी नौकरियां",
    callHr: "कॉल एच.आर.",
    callNow: "अब कॉल करें",
    enterEmail: "ईमेल पता दर्ज करें",
    companyEmail: "कंपनी ईमेल",
    emailCaps : "ईमेल",
    companyAddress: "कंपनी का पता",
    enterDob: "जन्म तिथि दर्ज करें",
    createVideoResume: "वीडियो का निर्माण",
    startAudioRecording : "रिकॉर्डिंग शुरू करें",
    locationRadius: "स्थिति रेडियस",
    CurrentWorking: "वर्तमान में कार्यरत",
    To: "तक",
    addWorkExperience: "कार्य अनुभव जोड़ें",
    loginTabs : "लॉग इन",
    loginCaps : "लॉग इन",
    From: "फ्रॉम",
    Years: "वर्षों",
    Jobs: "जॉब्स",
    website : "वेबसाइट",
    chatScreen: "चैट स्क्रीन",
    wantToDisableProfile: "क्या आप अपनी प्रोफ़ाइल को अक्षम करना चाहते हैं?",
    wantToEnableProfile: "क्या आप अपनी प्रोफ़ाइल को सक्षम करना चाहते हैं?",
    doYouSpeakEnglish: "क्या आप अंग्रेज़ी बोलते हैं?",
    searchJobsAndCompaniesHere: "जॉब्स और कंपनियों",
    selectCityWalk:"शहर चुनें",
    searchThisArea: "यह क्षेत्र सर्च करें",
    noJobFoundForLocation: "चयनित स्थान / फ़िल्टर के लिए कोई नौकरी नहीं मिली।",
    noJobFound: "कोई नौकरी नहीं मिली",
    resumeDownloadedSuccessfully: "रिज्यूमे सफलतापूर्वक डाउनलोड किया गया ",
    searchJobsAndCompanies: "जॉब्स और कंपनियां सर्च करें",
    listOfAllJobs: "लिस्ट सभी जॉब्स की",
    search: "सर्च",
    searchSmall : "सर्च",
    All: "आल",
    jobsAndCompanies: "जॉब्स और कंपनियां",
    recentSearchJobs: "अभी सर्च की गई जॉब्स",
    recentSearchCompany: "अभी सर्च की गई कंपनियां",
    popularCompanies: "लोकप्रिय कंपनियां",
    LatestJobs: "नवीनतम नौकरियां",
    SearchedJobs: "खोजी गई जॉब्स",
    SearchedCompanies: "खोजी गई कंपनियां",
    Closed: "बन्द है",
    uploadVideo: "अपलोड वीडियो",
    suggestedJobsCaps: "सुझाव जॉब्स",
    chatWithCompany: "कंपनी के साथ चैट करें",
    scanQrCodeAndGetCompanyDetails: "QR कोड स्कैन करें और जॉब्स का विवरण प्राप्त करें",
    filterYourProfileAndJobType: "जॉब्स को फ़िल्टर करें",
    findAllJobsNearYou: "अपने स्थान के पास नौकरियां खोजें",
    companyWebsite: "कंपनी की वेबसाइट",
    fatherName: 'पिता का नाम',
    enterFatherName: 'पिता का नाम दर्ज करें',
    motherName: "मां का नाम",
    enterMotherName: "माँ का नाम दर्ज करें",
    jobNotAppear: "यह नौकरी भविष्य में दिखाई नहीं देगी",
    notInterested: "रुचि नहीं",
    faQCaps: "सामान्य प्रश्न",
    sameCity: "क्या आप उसी शहर में हैं?",
    resumeCheck: "क्या आपने रिज्यूमे अपलोड किया है?",
    no: "नहीं",
    myQrCode: "मेरा क्यूआर कोड ",
    myQrCodeDrawer:"मेरा क्यूआर कोड ",
    chooseProfileDialog: "कृपया कम से कम 3 प्रोफ़ाइल चुनें",
    feedbackSubmitSuccessfuly: 'आपकी प्रतिक्रिया सफलतापूर्वक सबमिट की गई',
    EnterYourUniversity: "अपने विश्वविद्यालय का नाम दर्ज करें",
    universityName: 'विश्वविद्यालय का नाम',
    creditsAvailable: "क्रेडिट उपलब्ध है",
    selectCityFromList: "कृपया सूची में से शहर का चयन करें",
    enterOnlyAlphabets: "कृपया केवल वर्णमाला दर्ज करें",
    pleaseEnterName: "कृपया अपना नाम दर्ज करें",
    mrText: "श्री",
    mrsText: "श्रीमती",
    deleteResume: "रिज्यूमे हटाएं",
    deleteVideo: "वीडियो हटाएं",
    creatingResume: "रिज्यूमे बन रहा है",
    deletingResume: "रिज्यूमे हटाया जा रहा है",
    deletingVideoResume: "वीडियो रिज्यूमे हटाया जा रहा है",
    recordVideo: "रिकॉर्ड वीडियो",
    stopRecording: "रिकॉर्डिंग बंद करें",
    startRecording: "रिकॉर्डिंग शुरू करें",
    videoPreview: "वीडियो पूर्वावलोकन",
    yourFeedbackMeans: "आपकी प्रतिक्रिया हमारे लिए बहुत मायने रखती है!",
    pleaseWait: "कृपया प्रतीक्षा करें",
    makingQrCode: "Qr कोड बन रहा है",
    moreJob: "अधिक नौकरी",
    moreJobs: "अधिक नौकरियां",
    noDataFound: "कोई डेटा नहीं मिला",
    noFaqFound:"कोई FAQ नहीं मिला",
    processingVideo: "वीडियो की प्रक्रिया हो रही है",
    gettingNearByJobs: "आपके लिए जॉब्स आ रही है",
    qrCodeIsInvalid: "QR कोड अमान्य है",
    code: "कोड",
    scanningInvalidCode: "आप एक अमान्य कोड स्कैन कर रहे हैं",
    scanQrCodeForJobs: "नौकरी और कंपनियों के लिए स्कैन QR कोड",
    jhatpatJobs: "Jobam",
    allowAccess: "उपयोग की अनुमति दें",
    addMoreExperience: "अधिक अनुभव जोड़ें",
    jobsAtTheRate: "नौकरी @",
    youAreAlreadyExperienced: "आप पहले से ही अनुभवी हैं, नए सिरे से विवरण अपडेट करने से आपका अनुभव डेटा हट जायेगा। क्या आप अभी भी इसे अपडेट करना चाहते हैं?",
    allowJhatpatJobs: "Jobam को बेहतर अवसरों के लिए अपना स्थान प्राप्त करने की अनुमति दें",
    alreadyRegisteredInstitute: "आप पहले से ही संस्थान के साथ पंजीकृत हैं",
    successfullyRegInstitute: "आप संस्थान के साथ सफलतापूर्वक पंजीकृत हैं",
    provideAllDetail: "कृपया सभी विवरण प्रदान करें",
    youAreNotInterested: "क्या आप सुनिश्चित हैं कि आप इस नौकरी में दिलचस्पी नहीं रखते हैं",
   updateProfileMoreBenefits: "रिज्यूमे सफलतापूर्वक डाउनलोड किया गया, अधिक लाभ प्राप्त करने के लिए कृपया अपनी प्रोफ़ाइल अपडेट करें",
    deleteAcc:"खाता हटाएं",
    deleteAccountDialog:"क्या आप वाकई खाता हटाना चाहते हैं?",
    deleteCard:"खाता हटाएं",
    fetchingYourLocation : "अपना स्थान प्राप्त हो रहा है, कृपया प्रतीक्षा करें",
    weNeverShare : "हम आपकी व्यक्तिगत जानकारी को किसी भी एजेंसी के साथ साझा नहीं करते हैं",
    haveaRefCode : "क्या आपके पास रेफरल कोड है",
    enterCode : "कोड दर्ज करें",
    updateProfile : "प्रोफ़ाइल अपडेट करें",
    noRefCodeFound : "कोई रेफरल कोड नहीं मिला",
    pleaseChooseDoc : "ओह, यह फ़ाइल प्रकार अनुमत नहीं है, कृपया doc / pdf / docx चुनें",
    experienceUpdated : "अनुभव सफलतापूर्वक अपडेट किया गया",
    noPreviewResume : "रिज्यूम देखने के लिए कोई पूर्वावलोकन उपलब्ध नहीं है",
    appliedNoData:'आपकी लागू की गई नौकरियां यहां दिखाई जाएंगी',
    shortlistedNoData:"आपकी शॉर्टलिस्ट की गई नौकरियां यहां दिखाई जाएंगी",
    hiredNoData:"आपकी नियुक्त की गई नौकरियां यहां दिखाई जाएंगी",
    scheduledNoData:"आपकी अनुसूचित की गई नौकरियां यहां दिखाई जाएंगी",
    rejectedNoData:"आपकी अस्वीकृत की गई नौकरियां यहां दिखाई जाएंगी",
    saveNoData:"आपकी सहेजे गई नौकरियां यहां दिखाई जाएंगी",
    interviewNoData:"आपकी साक्षात्कार नौकरियां यहां दिखाई जाएंगी",
    allNoData:"आपकी सभी नौकरियां यहां दिखाई जाएंगी",
    deleteYourAcc : "आप किसी भी समय अपना खाता Jobam से हटा सकते हैं। खाता हटाने के बाद आप रिक्रूटर्स को दिखाई नहीं देंगे। इसके अलावा, आप ऐप से अपना सारा डेटा खो देंगे और भविष्य में इसे प्राप्त नहीं कर पाएंगे।",
    postedJobsNotFound:"आपकी पोस्ट नौकरियां यहां दिखाई जाएंगी",
    optimizingVideo : "आपके वीडियो का अनुकूलन हो रहा है",
    selectTypeOfNoti : "निष्क्रिय करने के लिए अधिसूचना के प्रकार का चयन करें ",
    jobsFeed : "जॉब्स फीड",
    jobFeedDetail : "नौकरी के विवरण",
    hrIsNotAvailable : "अभी कॉल लेने के लिए HR उपलब्ध नहीं है!",
    credits : "क्रेडिट्स",
    credit : "क्रेडिट्",
    scanQrCode : "क्यूआर कोड स्कैन करे",
    language : "भाषा",
    areaOfInterest : "पंसदीदा छेत्र",
    jobFeed : "जॉब्स फ़ीड",
    audioResume : "ऑडियो रिज्यूमे",
    recordAudio : "ऑडियो रिकॉर्ड करें",
    audioResumeCaps : "ऑडियो रिज्यूमे",
    stopAudioRecording : "रिकॉर्डिंग बंद करें",
    audioPlay : "ऑडियो चलाएं",
    audioRecordSuccessfully :"ऑडियो फिर से सफलतापूर्वक अपलोड किया गया",
    recordAgain : "दुबारा रिकॉर्ड करें",
    HiText : "नमस्ते",
    youHave : "आपके खाते में",
    creditsInYourAcc : "क्रेडिटस हैं, नौकरियों पर लागू करने के लिए क्रेडिटस का उपयोग करें। अधिक क्रेडिटस अर्जित करने के लिए ऐप साझा करें।",
    creditInYourAcc:"क्रेडिट हैं, नौकरियों पर लागू करने के लिए क्रेडिट का उपयोग करें। अधिक क्रेडिटस अर्जित करने के लिए ऐप साझा करें।",
    permissionHasDenied : "अनुमतियों का खंडन किया गया है। कृपया ऑडियो रिकॉर्ड करने के लिए संग्रहण और माइक्रोफ़ोन अनुमतियों को अनुमति दें।",
    allow : "अनुमति दें",
    playAudio : "ऑडियो चलाएं",
    stopAudio : "ऑडियो बंद करो",
    companyName: 'कंपनी का नाम',
    notApplied: 'लागू नहीं',
    postedJobs:'नौकरियां पोस्टिड',
    websiteCompany: "वेबसाइट",
    professionalExperience : "व्यावसायिक अनुभव",
    loadingYourProfile : "आपकी प्रोफ़ाइल लोड हो रही है",
    loadingInterviewTips : "इंटरव्यू टिप्स लोड हो रहे हैं",
    general : "सामान्य",
    logintoSeeinterviewTips : "इंटरव्यू देखने के लिए कृपया लॉगिन करें टिप्स!",
    logintoSeeNotification : "अधिसूचना देखने के लिए कृपया लॉगिन करें!",
    loadingYourJobs : "आपकी नौकरियां लोड हो रही है",
    logintoSeeJobStatus : "आपकी नौकरी की स्थिति देखने के लिए कृपया लॉगिन करें",
    loadingProfileSummary : "आपका प्रोफ़ाइल सारांश लोड हो रहा है",
    loadingAppliedJobs : "आपकी आवेदित जॉब्स लोड हो रही है",
    loadingShortlistedJobs : "आपकी शॉर्टलिस्टेड जॉब्स लोड हो रही है",
    loadingHiredJobs : "आपकी नियुक्त नौकरियां लोड हो रही है",
    loadingScheduledJobs : "आपकी अनुसूचित नौकरियां लोड हो रही है",
    loadingFaqs : "सामान्य प्रश्न लोड हो रहे है",
    loadingCompanyFeedback : "कंपनी फीडबैक लोड हो रहा है",
    loadingJobProfiles : "जॉब प्रोफाइल लोड हो रही है",
    loadMoreItems : "अधिक आइटम लोड करें",
    serachingYourJobs : "आपकी जॉब सर्च हो रही है",
    loadingEducation : "शिक्षा विवरण लोड हो रहा है",
    loadingWorkExperience : "कार्य अनुभव लोड हो रहा है",
    loadingCompanyDetails : "कंपनी विवरण लोड हो रहा है ",
    jobAddress : "जॉब का पता",
    loadingJobDetails : "नौकरी विवरण लोड हो रहा है",
    registerAndApply : "रजिस्टर और आवेदन करें",
    interestedInJob : "यदि आप इस नौकरी में रुचि रखते हैं, तो 'APPLY' बटन को हिट करें और सीधे कंपनी से जुड़ें। किसी तीसरे पक्ष की भागीदारी नहीं। भारत में जॉब खोजने के लिए झटपट की नौकरियां मुफ्त हैं।",
    dreamJob : "हर किसी के लिए ड्रीम जॉब!",
    chooseCity : "शहर चुनें",
    employers : "नियोक्ता",
    postJobNow : "अभी नौकरी पोस्ट करें",
    jobSeekers : "नौकरी खोजनेवाले",
    downloadApp : "ऐप डाउनलोड करें",
    ourMission : "हमारा लक्ष्य",
    aiDrivenPlatform : "Jobam एक एआई संचालित मंच है जो अद्वितीय भारतीय हाइपरलोकल जॉब्स के लिए बनाया गया है।",
    jhatpatActAsSeasoned : "Jobam कंपनियों और नौकरी चाहने वालों के बीच एक अनुभवी मैचमेकर के रूप में कार्य करता है।",
    loadingNotification : "सूचनाएं लोड हो रही है ",
    updateProfileText : "कृपया अपनी प्रोफ़ाइल अपडेट करें",
    kindlyLoginTextForSave : "इस नौकरी को सहेजने के लिए कृपया लॉगिन करें",
    kindlyLoginReportText : "कृपया इस नौकरी की रिपोर्ट करने के लिए लॉगिन करें",
    linkedinaddUrl : "लिंक्डइन यूआरएल",
    facebookaddUrl : "फेसबुक युआरएल",
    instagramaddUrl : "इंस्टाग्राम यूआरएल",
    enterYorLinkedinUrl : "अपना लिंक्डइन यूआरएल दर्ज करें",
    enterYorInstagramUrl : "अपना इंस्टाग्राम यूआरएल दर्ज करें",
    enterYorFacebookUrl : "अपना फेसबुक यूआरएल दर्ज करें"
    },

  "en": {
    pleaseCheckInternet:"Please check your internet connection and try again.",
    noWebsiteFound:"No Website Found",
    dreamJob : "Dream Job For Everyone!",
    postJobNow : "Post a Job now",
    loadingNotification : "Loading notifications",      
    downloadApp : "Download App",
    aiDrivenPlatform : "Jobam is an AI driven platform designed for the unique Indian Hyperlocal Jobs.",
    jhatpatActAsSeasoned : "Jobam acts as a seasoned matchmaker between Companies & Job Seekers.",
    ourMission : "Our Mission",
    employers : "EMPLOYERS",
    jobSeekers : "JOB SEEKERS",
    chooseCity : "Choose a city",
    interestedInJob : "If you are interested in this job, hit 'APPLY' button and connect directly to the company. No third party involvement. Jobam is free of cost for finding jobs in India.",
    loadingJobDetails : "Loading Job Details",
    registerAndApply : "REGISTER AND APPLY",
    loadingCompanyDetails : "Loading Company Details",
    loadingWorkExperience : "Loading Work Experience",
    loadingEducation : "Loading Education Details",
    serachingYourJobs : "Searching your Jobs",
    loadingJobProfiles : "Loading Job Profiles",
    loadingCompanyFeedback : "Loading Company Feedback",
    loadingFaqs : "Loading FAQs",
    loadMoreItems : "Load More Jobs",
    loadingProfileSummary : "Loading your Profile Summary",
    general : "GENERAL",
    loadingAppliedJobs : "Loading Applied Jobs",
    loadingShortlistedJobs : "Loading Shortlisted Jobs",
    loadingHiredJobs : "Loading Hired Jobs",
    loadingScheduledJobs : "Loading Scheduled Jobs",
    loadingYourJobs : "Loading your Jobs",
    logintoSeeJobStatus : "Kindly login to see your Job status",
    logintoSeeinterviewTips : "Kindly login to see the Interview Tips!",
    logintoSeeNotification : "Kindly login to see the notifications!",
    loginTabs : "Login",
    loginCaps : "LOGIN",
    loadingYourProfile : "Loading your Profile",
    loadingInterviewTips : "Loading Interview Tips",
    professionalExperience : "PROFESSIONAL EXPERIENCE",
    loadingText:"Loading",
    pleaseReload:"Please reload the app",
    turnOnContinue:"Please turn on internet to continue",
    noInterNetConnect:"No Internet connection!",
    pleaeEnableLoca: "Please enable location permissions for login",
    qrCodeAccess: "Access to camera has been denied. Please allow access to scan QR Code.",
    gotoSetting: "Go to settings",
    pleaseEnableInter: "Please enable location permission to use the Jobam App",
    newEvents: "EVENTS",
    keepSmsQrCode: "Keep Spreading Employment!",
    myQrcodeSms: "Here is your QR code share it with your friends and you both will be awarded with credits to apply on more jobs.",
    sscDeta: "SSC",
    hsscDeta: "HSSC",
    eventDetails: "Event Details",
    youapplied:"Your application is yet to be reviewed by the company. Till then, keep exploring.",
    notReviewText: "No reviews found yet, be the first to review",
    pleaseFillDetails:"Please fill all the details",
    jhatpatjobsStrong: "Access to storage has been denied. Please allow access to save video.",
    videoAccessText: "Access to camera has been denied. Please go to app's settings and allow Camera and Microphone access to record video.",
    courceNameUg: "Please update course name",
    askMeLater:"Ask me later",
    sureJobDetails: "Sure",
    appExitText: "Please press back again to exit the app.",
    dashboardApppercentage:"App is 100% free for Jobseekers. If anyone asks for money,don't pay!",
    youAreAlreadyExperienced: "You are already experienced, updating fresher details will remove your experience data. Do you still want to update it?",
    askDashboad: " Got it! Never ask again",
    filterPg:"PG",
    filterUg:"UG",
    filthertw: "12TH",
    filtherten: "10TH",
    newJobTag: "New",
    youAreOldVersion: "You are using an older version new version is available!",
    badRating:"Bad",
    okRating:"OK",
    goodRating:"Good",
    wowRating:"Wow",
    amazingRating:"Amazing",
    chooseAnyone:"No report data found",
    reportSubmitted : "Report Submitted Successfully",
    appRatingStar:" Hey,you seems to like this app. You would help us a great deal with a 5-star rating!",
    noApplicatinFond:"No application found to handle this document",
    openResume:"OPEN RESUME",
    clearCacheSucces:"Cache cleared successfully",
    jobMadeEasy:"JOBS MADE EASY",
    pleaseSelect: "Please Select One",
    appDisplay:"App Display Language",
    jhatpatjobsUpdate: "Jobam",
    didnotGetCode:"Did not get verification code? Resend code.",
    enterVeri:"Enter verification code here..",
    enterOTPSend: "Enter the OTP Code sent on Your Email Address",
    sendVerifiCode: "Send Verification Code",
    enterYorEmailAut:"Enter Your Email Address",
    verifEmailAddree: "Enter the Email Address and we will send you a verification code.",
    addEnail:"ADD EMAIL ADDRESS",
    emailEnter:"Please enter email id",
    emailAuth: "Please enter a valid email id",
    loginTerms:"Terms and Conditions",
    loginaAgree:"I hereby agree to the",
    facebookLogin:"SIGN IN WITH FACEBOOK",
    googleLogin: "SIGN IN WITH GOOGLE",
    signupFree: "Sign up for free",
    loginWelcome: "Welcome to Jobam",
    tabInterviewTips:"TRAINING",
    notifiClearAll: "Clear All",
    notificationDel: "Delete",
    videoTipsInter: "VIDEO TIPS",
    eventsProfileTabs: "Events",
    industryTypeSelect: "Please enter industry type",
    chooseOneExp: "Choose one",
    clearCacheProfile: "Clear Cache",
    discardAboutSave: "SAVE",
    discardAboutText: "DISCARD",
    aboutTextSave: "Do you want to save changes?",
    jobdetailsVideo: "VIDEO DESCRPTION",
    companyAddre: "COMPANY ADDRESS",
    jobAddress : "JOB ADDRESS",
    profileTabs : "Profile",
    interviewTabs:"Interview Tips",
    scanQrCodeTabs : "Scan QR Code",
    myJobTabs: "My Jobs",
    homeTabs: "Home",
    playAudio : "Play Audio",
    stopAudio : "Stop Audio",
    companyName: 'COMPANY NAME',
    campusDrive: 'JOIN CAMPUS DRIVE',
    notApplied: 'Not Applied',
    postedJobs:'Posted Jobs',
    websiteCompany: "Website",
    areyouSureDeleteAudio: "Are you sure you want to delete audio resume?",
    deleteRecordText:"DELETE AUDIO",
    recordAudioText:"RECORD AUDIO",
    creditsAvailable: "Credits Available",
    permissionHasDenied : " Permissions has been denied. Please allow Storage and Microphone permissions to record audio.",
    HiText : "Hi",
    allow : "ALLOW",
    youHave : "you have",
    creditsInYourAcc : "credits in your account. Use credits to apply on Jobs and you will lose 1 credit on call Hr. Share an App to earn more credits.",
    creditInYourAcc : "credit in your account. Use credits to apply on Jobs and you will lose 1 credit on call Hr. Share an App to earn more credits.",
    recordAgain : "RECORD AGAIN",
    audioRecordSuccessfully :"Audio resume uploaded successfully",
    audioPlay : "PLAY",
    audioResume : "Audio Resume",
    audioResumeCaps : "AUDIO RESUME",
    startAudioRecording : "START RECORDING",
    stopAudioRecording : "STOP RECORDING",
    recordAudio : "Record Audio", 
    jobFeed : "Jobs Feed",
    credits : "CREDITS",
    credit : "CREDIT",
    areaOfInterest : "Area of Interest",
    settings : "Settings",
    hrIsNotAvailable : "HR is not available to take calls right now!",
    jobsFeed : "JOBS FEED",
    jobFeedDetail : "JOBS FEED DETAILS",
    selectTypeOfNoti : "Select type of Notification to disable",
    optimizingVideo : "Optimizing your video resume",
    deleteYourAcc : "You can delete your account from Jobam at any time. After deleting the account you will be no longer visible to the recruiters. Also you will lose all your data from the app & will not be able to retrieve it in the future.",
    appliedNoData:"Your applied jobs will be shown here",
    shortlistedNoData:"Your shortlisted jobs will be shown here",
    hiredNoData:"Your hired jobs will be shown here",
    scheduledNoData:"Your scheduled jobs will be shown here",
    rejectedNoData:"Your rejected jobs will be shown here",
    saveNoData:"Your saved  jobs will be shown here",
    interviewNoData:"Your interviewed  jobs will be shown here",
    allNoData:"Your all jobs will be shown here",
    websiteNotAvailable : "Company website is not available",
    experienceUpdated : "Work experience updated successfully",
    addMoreExperience: "Add more experience",
    provideAllDetail: "Please provide all the details",
    pleaseChooseDoc : "Oops, This file type is not allowed, please choose doc/pdf/docx.",
    noPreviewResume : "No preview available to view the resume",
    enterCode : "Enter code",
    jhatpatJobs: "Jobam",
    allowJhatpatJobs: "Allow Jobam to get your location for better opportunities",
    allowAccess: "Allow Access",
    youAreAlreadyExperienced: "You are already experienced, updating fresher details will remove your experience data. Do you still want to update it?",
    qrCodeIsInvalid: "QR code is invalid",
    scanningInvalidCode: "You are scanning an invalid code",
    alreadyRegisteredInstitute: "You are already registered with the institute",
    successfullyRegInstitute: "You are successfully registered with the institute",
    moreJob: "More Job..",
    code: "Code",
    moreJobs: "More Jobs..",
    jobsAtTheRate: " JOBS @",
    noDataFound: "No data found",
    noFaqFound:"No FAQ found",
    makingQrCode: "Making QR code",
     gettingNearByJobs: "Getting near by Jobs for you",
    processingVideo: "Processing video",
    scanQrCodeForJobs: "SCAN QR CODE FOR JOBS & COMPANIES",
    selectCityFromList: "Please select city from the list",
    enterOnlyAlphabets: "Please enter alphabets only",
    numberCannotBeNull: "Mobile number can not be null",
    pleaseEnterName: "Please enter your name",
    stopRecording: "STOP RECORDING",
    startRecording: "START RECORDING",
    videoPreview: "VIDEO PREVIEW",
    applyNow: "APPLY",  
    mrText: "Mr.",
    mrsText: "Mrs.",
    pleaseWait: "Please wait",
    deleteResume: "DELETE RESUME",
    deleteVideo: "DELETE VIDEO",
    recordVideo: "RECORD VIDEO",
    creatingResume: "Creating resume",
    deletingResume: "Deleting Resume",
    deletingVideoResume: "Deleting Video Resume",
    uploadingVideo : "Uploading your video resume",
    uploadingResume : "Uploading Resume",
    uploadingProfile : "Uploading Profile",
    cityText: "CITY",
    myQrCode: "MY QR CODE",
    myQrCodeDrawer:'My QR Code',
    universityName: 'UNIVERSITY NAME',
    EnterYourUniversity: "Enter your university",
    no: "NO",
    faQCaps: "FAQs",
    locationRadius: "LOCATION RADIUS",
    feedbackSubmitSuccessfuly: 'Congratulations! Your feedback submitted successfully',
    youAreNotInterested: "Are you sure you are not interested in this Job?",
    sameCity: "Are you in the same city?",
    resumeCheck: "Have You Uploaded Resume?",
    notInterested: "NOT INTERESTED",
    jobNotAppear: "This job won't appear in the future",
    fatherName: "FATHER'S NAME",
    enterFatherName: "Enter father's name",
    motherName: "MOTHER'S NAME",
    enterMotherName: "Enter mother's name",
    suggestedJobsCaps: "SUGGESTED JOBS",
    companyWebsite: "COMPANY WEBSITE",
    Closed: "CLOSED",
    uploadVideo: "UPLOAD VIDEO",
    searchHeaderText: 'JOBS OR COMPANIES',
    postedJobsNotFound   :"Posted jobs will be shown here",
    chatWithCompany: "Chat with company",
    LatestJobs: "Latest Jobs",
    SearchedJobs: "Searched Jobs",
    SearchedCompanies: "Searched Companies",
    popularCompanies: "Popular Companies",
    recentSearchCompany: "Recently Searched Companies",
    recentSearchJobs: "Recently Searched Jobs",
    All: "All",
    search: "SEARCH",
    searchSmall : "Search",
    searchThisArea: "Search This Area",
    findAllJobsNearYou: "Find Jobs Near Your Location",
    scanQrCodeAndGetCompanyDetails: "Scan QR Code to get Job & Company Details",
    scanQrCode : "Scan QR Code",
    listOfAllJobs: "List of all jobs here",
    Jobs: "Jobs",
    noJobFound: "No job found",
    Views: "Views",
    Viewj: "View",
    filterYourProfileAndJobType: "Apply Filters",
    searchJobsAndCompanies: "Search Jobs and Companies",
    jobsAndCompanies: "Jobs and Companies",
    searchJobsAndCompaniesHere: "Jobs & Companies",
    selectCityWalk:"Choose City",
    chatScreen: "CHAT SCREEN",
    fillCollege: "Please fill college name",
    fillUniversity: "Please fill university name",
    wantToDisableProfile: "Do you want to disable your profile?",
    wantToEnableProfile: "Do you want to enable your profile?",
    notNow: "NOT NOW",
    Years: "Years",
    viewVideoResume: "View video resume",
    updatingResumeDetail: "Updating experience details will help you to create a better resume",
    updateAboutMe: "Update profile to build a better resume",
    doYouSpeakEnglish: "Do you speak English?",
    To: "To",
    addWorkExperience: "Add Work Experience",
    CurrentWorking: "Currently Working",
    From: "From",
    enterEmail: "Enter the email address",
    enterDob: "Enter the date of birth",
    companyAddress: "COMPANY ADDRESS",
    createVideoResume: "CREATE VIDEO RESUME",
    companyEmail: "COMPANY EMAIL",
    emailCaps : "EMAIL",
    resumeCreated: "Your resume has been created",
    callNow: "CALL NOW",
    callHr: "CALL HR",
    noJobFoundForLocation: "No jobs found for selected Location/Filter",
    Verified: "Verified",
    video: "APPLY NOW",
    scanQrCodeForJobs: "SCAN QR CODE FOR JOBS",
    topPicks: "TOP PICKS",
    allJobs: "ALL JOBS",
    outshineYourResume: "Outshine your resume by updating Education details",
    uploadFromFile: "UPLOAD FROM FILE",
    uploadResume: "UPLOAD RESUME",
    ProfileCompletion: 'PROFILE',
    aboutMe: "ABOUT ME",
    enterYourProfileSummary: "Enter your profile summary",
    // JJBOT:"JJ BOT",
    educationDetails: "EDUCATION DETAILS",
    imExperienced: "I'M EXPERIENCED",
    imFresher: "I'M FRESHER",
    professionalSummary: "PROFESSIONAL SUMMARY",
    updateProfessionalSummary:"Enter your professional summary",
    pleaseUpdateProfileSummary: "Please update professional summary",
    updateProfileSummary: "Please provide profile summary",
    profileSummaryShort: "Profile summary is too short",
    professionalSummaryShort: "Professional summary is too short",
    RelatedVideo: "RELATED VIDEO",
    relatedVideos: "Related videos",
    resumeDownloadedSuccessfully: "Resume downloaded successfully",
    updateProfileMoreBenefits: "Resume downloaded successfully,please update your profile to get more benefits",
    previewResume: "PREVIEW RESUME",
    disableNotification: "Disable Notification",
    notificationSmall : "Notifications",
    notificationText : "Notifications",
    chatText : "Chat",
    enableNotification: "Enable Notification",
    enableNotificationSuccessfully: "Enable notification successfully",
    disableNotificationSuccessfully: "Disable notification successfully",
    neverAskMoney: "Jobam does not share your personal details with third party",
    kindlyupdate: "Kindly update your profile",
    chooseInterest: "Please choose your area of interest",
    chooseProfiles: "Choose atleast 3 profiles:",
    chooseProfileDialog: "Please choose atleast 3 profiles",
    maxProfile: "You can select maximum 6 profiles",
    update: "UPDATE",
    alreadyVerifedNum: "This mobile number is already verified",
    verify: "VERIFY",
    NotAvailable: "Not Available",
    Available: "Available",
    chooseOne: "Choose one",
    commentHere: "Comment here..",
    fillSchool: "Please fill school name",
    fillSsc: "Please fill ssc",
    Male: "Male",
    Female: "Female",
    NoRequiredSkillsFound: "No required skills found",
    postedJobsCaps: "POSTED JOBS",
    NoPhotos: "No photos posted by company",
    EnterValidKey: "Please enter a valid key",
    PleaseEnterContact: "Please enter a contact number",
    PleaseTryAgain: "Something went wrong! Please try again",
    ValidContactNumber: "Please enter a valid contact number",
    Gender: "Gender",
    GenderCaps: "GENDER",
    AddResume: "Add Resume",
    AddExperience: "Add Experience",
    ProfileText: "Give skills to let employers understand your strengths",
    PersonalDetails: "Personal Details",
    personalDetails: "PERSONAL DETAILS",
    Edit: "Edit",
    Enterphonenumber: "Enter phone number",
    togetnotifUse: " To get instant notification use your whatsapp number",
    noCode: " No code? ",
    resendCode: " Resend code",
    plzentercodeSms: "Please enter the OTP",
    invalidOtp: "Invalid OTP",
    OtpverifiedSuccessfully: "OTP verified successfully",
    PleaseEnterValidNumber: "Please enter a valid phone number",
    PleaseEnterNumber: "Please enter phone number ",
    ProfileUpdatedSuccessfully: "Profile updated successfully",
    noOfOpeningTxt: "JOB OPENINGS",
    yes: "YES",
    VideoHasBeenUploadedSuccessfully: "Video has been uploaded successfully",
    notificationDialogText: "No notifications yet!",
    favouriteCompanyText: "No favourite companies found",
    NoSavedComnpanies: "No Saved Companies",
    favoriteCompany: "Favourite Company",
    FavouriteCompanies: "FAVOURITE COMPANIES",
    logOut: "Log Out",
    savedJobs: "Saved Jobs",
    editInterest: "Edit Interests",
    Enterthemessage: "Enter the message",
    reviewCaps: "REVIEW",
    NoJobsFound: "No Jobs Found",
    Readless: "Read Less",
    ReadMore: "Read More",
    NoInterviewTipsFound: "No interview tips found",
    SorryThisJobHasBeenRemoved: "Sorry,this job has been removed",
    NoSavedJobsFound: "No saved jobs found",
    NoFeedbackFound: "No feedback found",
    YourResumeUploadedSuccessfully: "Your resume uploaded successfully",
    CompanyUnsavedSuccessfully: "Company unsaved successfully",
    CompanySavedSuccessfully: "Company saved successfully",
    JobSavedSuccessfully: "Job saved successfully",
    JobUnsavedSuccessfully: "Job unsaved successfully",
    AreYouSureYouWantToLogout: "Are you sure you want to logout?",
    termsCondition: "TERMS & CONDITIONS",
    PrivacyPolicies: "PRIVACY POLICY",
    ShortlistedJobs: "SHORTLISTED JOBS",
    ScheduledJobs: "SCHEDULED JOBS",
    HiredJobs: "HIRED JOBS",
    AppliedJobs: "APPLIED JOBS",
    ChooseTheFollowingLanguage: "Choose the following language",
    language : "Language",
    ChangeLanguage: "CHANGE LANGUAGE",
    Apply: "Apply",
    NoLocationProvided: "No location provided",
    EducationalQualification: "EDUCATIONAL QUALIFICATION",
    JobPROFILE: "JOB PROFILES",
    Experienced: "EXPERIENCED",
    Rs: "Rs.",
    JobFilters: "JOB FILTERS",
    DocumentResumeTips: "DOCUMENT RESUME TIPS",
    MakeResume: "Make Resume",
    Delete: "DELETE",
    Others: "Others",
    other: "other",
    SocialServices: "Social Services",
    CustomerServices: "Customer Services",
    SalesMarketing: "Sales & Marketing",
    ComputerTechnology: "Computer & Technology",
    AccountingFinance: "Accounting & Finance",
    Confirm: "CONFIRM",
    JobProfiles: "Job Profiles",
    selectYourInterest: "SELECT YOUR INTEREST",
    savedJOBS: "SAVED JOBS",
    Notification: "NOTIFICATIONS",
    EnterYourCity: "Enter your city",
    feedbackFromCompanies: "FEEDBACK FROM COMPANIES",
    experienceLevelCaps: "EXPERIENCE LEVEL",
    EnterYourNumber: "Enter your number",
    EmailAddress: "EMAIL ADDRESS",
    chooseDegree: "Choose degree",
    EnterYourCollege: "Enter your college",
    EnterPercentage: "Enter Percentage/CGPA",
    ChoosePassingYear: "Choose passing year",
    EnterYourSchool: "Enter your school",
    uploadCaps: "UPLOAD",
    CollegeName: "COLLEGE NAME",
    courseCaps: "COURSE",
    uploadYourHSSCMarksheet: "UPLOAD YOUR HSSC MARKSHEET",
    uploadYourSSCMarksheet: "UPLOAD YOUR SSC MARKSHEET",
    passingYear: "PASSING YEAR",
    marksObtainedCaps: "MARKS OBTAINED",
    SchoolNAME: "SCHOOL NAME",
    ShareWithYourFriends: "SHARE WITH YOUR FRIENDS",
    followCaps: "FOLLOW",
    unfollowCaps: "UNFOLLOW",
    Submit: "Submit",
    companyPhotosCaps: "COMPANY PHOTOS",
    companyDescriptionCaps: "COMPANY DESCRIPTION",
    companyDetailsCaps: "COMPANY DETAILS",
    HR: "HR",
    JessicaDOE: "JESSICA DOE",
    JobDetailsCaps: "JOB DETAILS",
    JobRequirementCaps: "JOB REQUIREMENTS",
    salaryCaps: "SALARY",
    PostedON: "POSTED ON",
    jobType: "JOB TYPE",
    VideoResumeTips: "VIDEO RESUME TIPS",
    Dont: "Don'ts",
    qualificationCaps: "QUALIFICATION",
    experienceCaps: "EXPERIENCE",
    Do: "Do's",
    yDoWeNeedUploadId: "WHY WE NEED ID PROOF?",
    hMarkSheet: "10th MARK SHEET",
    ssMarkSheet: "12th MARK SHEET",
    EnterTitleOfTheCertificate: "Enter Title Of The Certificate",
    ChooseALanguage: "CHOOSE YOUR WEBSITE DISPLAY LANGUAGE",
    settingsCaps: "SETTINGS",
    Message: "MESSAGE",
    Email: "Email",
    contactSupport: "CONTACT SUPPORT",
    contactSpprt: "Contact Support",
    iAgree: "I agree",
    disableNow: "DISABLE NOW",
    cancelCaps: "CANCEL",
    disableProfile: "DISABLE PROFILE",
    resumeCaps: "RESUME",
    Profile: "PROFILE",
    educationCaps: "EDUCATION",
    ShortlistedCaps: "SHORTLISTED",
    videoResumeCaps: "VIDEO RESUME",
    identityProofCaps: "IDENTITY PROOF",
    item: "item",
    km: "km",
    workExperienceCaps: "WORK EXPERIENCE",
    interviewTipsCaps: "INTERVIEW TIPS",
    showAll: "All",
    myJobsCaps: "MY JOBS",
    interviewedCaps: "INTERVIEWED",
    awaitingCaps: "AWAITING",
    appliedCaps: "APPLIED",
    cancel: "Cancel",
    Hired: "HIRED",
    scheduledCaps: "SCHEDULED",
    job: "Job",
    rejectedCaps: "REJECTED",
    rateUs: "Rate Us",
    logOutConfirmmsg: "Are you sure you want to logout?",
    workExpIntro: "To make a long lasting impression on your dream company, mention your work experience details here",
    uploadtheImage: "Do you want to upload this image?",
    agreetothe: "I hereby agree to the",
    agreeTerms: "Terms and Conditions",
    videoResumeIntro: "Be exceptional with our brand new video resume option, do not worry as you will be guided on the same",
    resumeIntro: "Upload your far-reaching resume to increase your visibility to companies",
    resumeBuilder: "Resume Builder",
    ResumeBuilder: "RESUME BUILDER",
    personalInfo: "Personal Information",
    adhaarIntro: "In order to ensure the authenticity, aadhar card is a must .Don’t forget to upload it",
    IdCardIntro: "Dealing in IDs is no more a hustle, upload it here and feel free for the rests",
    educationIntro: "Make your profile look outstanding by adding your educational details",
    certificatesIntro: "Add everything that makes you eligible for a dream job. Add your certificates and stand out of the crowd",
    fullnameCaps: "FULL NAME",
    fullname : "Full name",
    jobtitle: "Job Title",
    appliedJobs: "Applied Jobs",
    shortlistedJobs: "Shortlisted Jobs",
    noApplyJobText: " You haven't applied for a job yet! Apply for a job and communicate directly with the organization",
    jobLocation: "Job Location",
    resumeDeletesuccessfully: "Resume deleted successfully",
    deletemarkResumeDialog: "Are you sure you want to delete resume?",
    deleteVideoDialog: "Are you sure you want to delete video resume?",
    noNotificationmsg: "No new notification",
    whatswrongwithjob: "What's wrong with this job?",
    interviewDate: "Interview Date",
    interviewTime: "Interview Time",
    thankyou: "Thank You!",
    next: "Next",
    nextBtn: "NEXT",
    skip: "Skip",
    yourFeedbackMeans: "Your feedback means a lot to us!",
    done: "Done",
    enterFeedBackHere: "Please write your feedback here",
    titleCaps: "TITLE",
    enterConcernHere: "Enter your concern here",
    videoDurationAlert: "Video duration must be more then 10 seconds",
    videoDurationLargeAlert: "Video duration should not be more then 60 seconds",
    deleteIdProofDialog: "Are you sure you want to delete Id Proof?",
    IdProofDeletesuccessfully: "Id proof deleted successfully",
    idProofUploadSuccess: "Identity proof uploaded successfully",
    clickanyofyouridProof: "Click here to select any of your ID proof",
    deleteAdhharDialog: "Are you sure you want to delete adhaar card?",
    AdhaarDeletesuccessfully: "Adhaar Card deleted successfully",
    adhaaruploadsuccess: "Aadhaar uploaded successfully",
    selectFileClickPhoto: "Click here to select a file by taking a photo or choosing from the library",
    marksheet: "Marksheet",
    deletemarkSheetDialog: "Are you sure you want to delete marksheet?",
    markSheetDeletesuccessfully: "Marksheet deleted successfully",
    degreeUploadSuccess: "Marksheet is uploaded successfully",
    deletemarksheet: "Are you sure want to delete your marksheet?",
    list: "LIST",
    map: "MAP",
    sort: "SORT",
    awaitingReview: "Awaiting Review",
    state: "State",
    noCredits: "No credits available for applying",
    youHaveSuccessfullyAppliedForTheJob: "You have successfully applied for the job",
    educationaddedsuccess: "Education added successfully",
    highersecondaryschoolcertificates: "Higher Secondary School Certificate",
    Continue: "CONTINUE",
    congratulations: "Congratulations!",
    choosefiletoupload: "Please choose a file to upload",
    kindlyselctFileUpload: "Kindly select a file before uploading",
    oK: "OK",
    hello: "Hello",
    verifyYourPhoneNumber: "Verify your phone number",
    hired: "Hired",
    applied: "Applied",
    verify: "Verify",
    verificationCode: "Verification Code ",
    details: "Details",
    ratings: "RATINGS",
    Ratings: "Ratings",
    scheduled: "Scheduled",
    pleaseentervalidkey: "Please enter a valid key",
    loadingLanguage : "Loading languages",
    photos: "Photos",
    interviewed: "Interviewed",
    shortlisted: "Shortlisted",
    chat: "CHAT",
    profileCompleted: "Profile Completed",
    enterscoredpercentage: "Enter Scored Percentage",
    enterjobpos: "Enter job position",
    rucurrentlyworkhere: "Are you currently working here",
    resume: "Resume",
    aadharCard: "Aadhar Card",
    identityProof: "Identity Proof",
    education: "Education",
    rejected: "Rejected",
    typeAMsg: "Type a Message",
    workExperienceAddedSuccessfully: "Work experience added successfully",
    workExperienceDeletedSuccessfully: "Work experience deleted successfully",
    enterYourSkills: "Enter your skills",
    videoResume: "Video Resume",
    followers: "FOLLOWERS",
    filter: "FILTERS",
    clear: "CLEAR",
    experienceLevel: "Experience Level",
    qualification: "Educational Qualification",
    fresher: "FRESHER",
    salaryExpectation: "Salary Expectation",
    share: "SHARE",
    upload: "Upload",
    delete: "Delete",
    highest: "Highest",
    highestDegree: "Highest Degree",
    degree: "Degree",
    startDate: "Start date",
    startDateCaps: "START DATE",
    endDate: "End date",
    endDateCaps: "END DATE",
    jobPosition: "JOB POSITION",
    industryType: "INDUSTRY TYPE",
    summary: "Summary",
    name: "Name",
    totalViews: "Total views",
    apply: "APPLY",
    enteryourcontactno: "Enter Your contact number",
    experience: "Experience",
    skillsAndInterests: "SKILLS",
    profileSummary: "PROFILE SUMMARY",
    currentLocation: "CURRENT LOCATION",
    location: "LOCATION",
    marksObtained: "Marks Obtained",
    submit: "SUBMIT",
    shareexpandRateUs: "Share your experience with us and give your ratings",
    profileBriefDesc: "Write brief description about yourself",
    aboutYou: "About Me",
    description: "Description",
    skills: "SKILLS",
    myJobs: "My Jobs",
    filters: "Filters",
    filterText : "Filter",
    interviewTips: "Interview Tips",
    summary: "Summary",
    organizations: "Organization's",
    postedOn: "Posted On:",
    salary: "Salary",
    totalViews: "Total Views",
    aadharNo: "Aadhaar Number",
    companyProfile: "Company Profile",
    companyProfileCaps: "COMPANY PROFILE",
    city: "City",
    zipCode: "Zip Code",
    jjBot: "JJ BOT",
    jobProfiles: "Job Profiles",
    FullTime: "FULL TIME",
    partTime: "PART TIME",
    internship:"INTERNSHIP",
    experienced: "EXPERIENCED",
    reset: "RESET",
    graduate: "Graduate",
    sales: "Sales",
    finance: "Finance",
    humanResources: "Human Resources",
    marketing: "Marketing",
    engineer: "Engineer",
    businessDevelopement: "Business Development",
    accountant: "Accountant",
    digitalMarketing: "Digital Marketing",
    graphicDesigner: "Graphic Designer",
    teacher: "Teacher",
    counselor: "Counsellor",
    contentWriting: "Content Writing",
    budgetAnalyst: "Budget Analyst",
    buyerPlanner: "Buyer planner",
    safetyManagement: "Safety Management",
    qualityAnalyst: "Quality Analyst",
    trainer: "Trainer",
    technician: "Technician",
    receptionist: "Receptionist",
    delivery: "Delivery",
    tailor: "Tailor",
    cook: "Cook",
    notification: "Notifications",
    logout: "Logout",
    disableProfile: "Disable Profile",
    shareApp: "Share App",
    companyFeedback: "Company Feedback",
    company: "Company",
    languageSelection: "Language Selection",
    updateadhaarcontactNoApply: "Kindly update your profile before applying",
    acceptTermsConditions: "Please accept terms & conditions to proceed",
    hindi: "Hindi",
    english: "English",
    jobNotification: "Job Notification",
    email: "Email",
    address: "ADDRESS",
    contact: "Contact",
    feedback: "Feedback",
    mobileNumber: "Mobile Number",
    editProfile: "EDIT PROFILE",
    name: "Name",
    contactNumber: "CONTACT NUMBER",
    dateOfBirth: "DATE OF BIRTH",
    currentCity: "CURRENT CITY",
    enteryouremail: "Enter your email",
    enterYourDob: "Enter your dob",
    enteryourname: "Enter your name",
    add: "Add",
    download: "Download",
    view: "view",
    addEducation: "Add Education",
    organizationname: "ORGANIZATION'S NAME",
    enterorganization: "Enter organization name",
    privacyPolicy: "Privacy Policy",
    workstatus: "Work Status",
    percentage: "Percentage",
    examName: "Exam Name",
    jobs: "JOBS",
    reviews: "Reviews",
    remove: "Remove",
    userauthentication: "User Authentication",
    enteryourkeyhere: "Enter Your Key Here",
    signinconnectwithusnow: "Sign in and connect with us now for free",
    signinwithgoogle: "Sign in with google",
    signinwithfb: "Sign In with Facebook ",
    deleteAcc:"DELETE ACCOUNT",
    deleteAccountDialog:"Are you sure you want to delete account?",
    deleteCard:"Delete Account",
    fetchingYourLocation : "Fetching your location, please wait",
    weNeverShare : "We never share your personal information with any of the agencies",
    haveaRefCode : "HAVE A REFERRAL CODE?",
    updateProfile : "UPDATE PROFILE",
    noRefCodeFound : "No referral code found",
    website : "WEBSITE",
    updateProfileText : "Please Update Your Profile",
    kindlyLoginTextForSave : "Kindly login to save this job",
    kindlyLoginReportText : "Kindly login to report this job",
    linkedinaddUrl : "LinkedIn URL",
    facebookaddUrl : "Facebook URL",
    instagramaddUrl : "Instagram URL",
    enterYorLinkedinUrl : "Enter Your LinkedIn URL",
    enterYorInstagramUrl : "Enter Your Instagram URL",
    enterYorFacebookUrl : "Enter Your Facebook URL"
},
  "mr": {
    pleaseCheckInternet:"कृपया आपले इंटरनेट कनेक्शन तपासा आणि पुन्हा प्रयत्न करा.",
    noWebsiteFound:"कोणतीही वेबसाइट आढळली नाही",
    loadingText:"लोड करीत आहे",
    pleaseReload:"कृपया अ‍ॅप रीलोड करा",
    turnOnContinue:"कृपया सुरू ठेवण्यासाठी इंटरनेट चालू करा",
    noInterNetConnect:"इंटरनेट कनेक्शन नाही!",
    pleaeEnableLoca: "कृपया लॉगिनसाठी स्थान परवानग्या सक्षम करा",
    qrCodeAccess: "कॅमेर्‍यावर प्रवेश नाकारला गेला आहे. कृपया QR कोड स्कॅन करण्यासाठी प्रवेशास अनुमती द्या.",
    gotoSetting: "सेटिंग्ज वर जा",
    pleaseEnableInter: "कृपया झटपट जॉब अ‍ॅप वापरण्यासाठी स्थान परवानगी सक्षम करा",
    newEvents: "कार्यक्रम",
    keepSmsQrCode: "रोजगार प्रसार करत रहा!",
    myQrcodeSms: "आपला QR कोड हा आपल्या मित्रांसह सामायिक करा आणि आपण दोघांना अधिक नोकरीवर अर्ज करण्यासाठी क्रेडिट दिली जाईल",
    youAreAlreadyExperienced: "आपण आधीपासून अनुभवी आहात, नवीन तपशील अद्यतनित केल्याने आपला अनुभव डेटा हटविला जाईल. आपण अद्याप अद्यतनित करू इच्छिता?",
    hsscDeta: "एचएसएससी",
    sscDeta: "एसएससी",
    eventDetails: "कार्यक्रमाचा तपशील",
    youapplied:"आपल्या अर्जाचे अद्याप कंपनीद्वारे पुनरावलोकन केले गेले नाही. तोपर्यंत, एक्सप्लोर करत रहा.",
    notReviewText: "अद्याप कोणतीही पुनरावलोकने आढळली नाहीत, पुनरावलोकन करणार्‍यासाठी प्रथम व्हा",
    pleaseFillDetails:"कृपया सर्व तपशील भरा",
    jhatpatjobsStrong: "संचयन प्रवेश नाकारला गेला आहे. कृपया व्हिडिओ जतन करण्यासाठी प्रवेशास अनुमती द्या.",
    videoAccessText: "कॅमेर्‍यावर प्रवेश नाकारला गेला आहे. कृपया अॅपच्या सेटिंग्जवर जा आणि व्हिडिओ रेकॉर्ड करण्यासाठी कॅमेरा आणि मायक्रोफोन प्रवेशास अनुमती द्या.",
    courceNameUg: "कृपया कोर्सचे नाव अद्यतनित करा",
    askMeLater:"मला नंतर विचार",
    sureJobDetails: "नक्की",
    appExitText: "कृपया अ‍ॅपमधून बाहेर पडण्यासाठी पुन्हा दाबा.",
    dashboardApppercentage:"जॉबसीकरसाठी अ‍ॅप 100% विनामूल्य आहे. जर कोणी पैसे मागितले तर पैसे देऊ नका!",
    askDashboad: "समजले! पुन्हा कधीही विचारू नका",
    filterPg:"पीजी",
    filterUg:"यूजी",
    filthertw: "12वी",
    filtherten: "10वी",
    newJobTag: "नवीन",
    youAreOldVersion: "आपण जुनी आवृत्ती वापरत आहात नवीन आवृत्ती उपलब्ध आहे!",
    badRating:"वाईट",
    okRating:"ठीक आहे",
    goodRating:"चांगले",
    wowRating:"वाह",
    amazingRating:"आश्चर्यकारक",
    chooseAnyone:"कोणताही अहवाल डेटा आढळला नाही",
    appRatingStar:"अहो, आपणास हा अॅप आवडला आहे असे दिसते. आपण आम्हाला मोठ्या प्रमाणात मदत कराल 5-तारा रेटिंगसह!",
    noApplicatinFond:"हा कागदजत्र हाताळण्यासाठी कोणताही अनुप्रयोग आढळला नाही",
    openResume:"पुन्हा सुरू करा",
    clearCacheSucces:"कॅशे यशस्वीरित्या साफ झाला",
    jobMadeEasy:"जॉब सुलभ केले",
    pleaseSelect: "कृपया एक निवडा", 
    appDisplay:"ऐप प्रदर्शन भाषा",
    jhatpatjobsUpdate: "झटपत जॉब्स",
    didnotGetCode:"सत्यापन कोड मिळाला नाही? कोड पुन्हा पाठवा.",
    enterVeri:"येथे सत्यापन कोड प्रविष्ट करा ..",
    enterOTPSend: "आपल्या ईमेल पत्त्यावर पाठविलेला ओटीपी कोड प्रविष्ट करा",
    sendVerifiCode: "सत्यापन कोड पाठवा",
    enterYorEmailAut:"तुमचा इमेल पत्ता लिहा",
    verifEmailAddree: "ईमेल पत्ता प्रविष्ट करा आणि आम्ही आपल्याला एक सत्यापन कोड पाठवू",
    addEnail:"ईमेल पत्ता जोडा",
    emailEnter:"कृपया ईमेल आयडी प्रविष्ट करा",
    emailAuth: "कृपया वैध ईमेल आयडी प्रविष्ट करा",
    loginTerms:"नियम आणि अटी",
    loginaAgree:"मी याद्वारे सहमत आहे",
    facebookLogin :"फेसबुक सह साइन इन करा",
    googleLogin: "गूगल सह साइन इन करा",
    signupFree: "विनामूल्य साइन अप करा",
    loginWelcome: "झटपट जॉबमध्ये आपले स्वागत आहे",
    tabInterviewTips:"प्रशिक्षण",
    notifiClearAll: "सर्व साफ करा",
    notificationDel: "हटवा",
    videoTipsInter: "व्हिडिओ टिप्स",
    eventsProfileTabs: "कार्यक्रम",
    industryTypeSelect: "कृपया उद्योग प्रकार प्रविष्ट करा",
    chooseOneExp: "एक निवडा",
    clearCacheProfile: "कॅशे साफ करा",
    discardAboutSave: "जतन करा",
    discardAboutText: "रद्द करा",
    aboutTextSave: "आपण बदल जतन करू इच्छिता?",
    jobdetailsVideo: "व्हिडिओ वर्णन",
    companyAddre: "कंपनीचा पत्ता",
    profileTabs : "प्रोफाइल",
    interviewTabs:"इंटरव्यू  टिप्स",
    scanQrCodeTabs : "स्कैन QR कोड",
    myJobTabs: "मेरी जॉब्स",
    homeTabs: "होम",
    playAudio : "ऑडिओ प्ले करा",
    stopAudio : "ऑडिओ थांबवा",
    companyName: 'कंपनीचे नाव',
    notApplied: 'लागू नाही',
    postedJobs:'नोकर्‍या पोस्टिड',
    websiteCompany: "वेबसाइट",
    areyouSureDeleteAudio: "आपणास खात्री आहे की आपण ऑडियो रीझ्युमे हटवू इच्छिता?",
    deleteRecordText: "ऑडियो हटवा",
    recordAudioText:"रेकॉर्ड ऑडिओ",
    applyNow: "आवेदित",
    no: "नाही",
    interviewNoData:"आपल्या मुलाखत घेतली केलेल्या नोकर्‍या येथे दर्शविल्या जातील",
    postedJobsNotFound:"आपल्या पोस्ट केलेल्या नोकर्‍या येथे दर्शविल्या जातील",
    scheduledNoData:"आपल्या नियोजित केलेल्या नोकर्‍या येथे दर्शविल्या जातील",
    rejectedNoData:"आपल्या नाकारले केलेल्या नोकर्‍या येथे दर्शविल्या जातील",
    saveNoData:"आपल्या जतन केलेल्या नोकर्‍या येथे दर्शविल्या जातील",
    allNoData:"आपल्या सर्व केलेल्या नोकर्‍या येथे दर्शविल्या जातील",
    faQCaps: "सामान्य प्रश्न",
    shortlistedNoData:"आपल्या शॉर्टलिस्ट केलेल्या नोकर्‍या येथे दर्शविल्या जातील",
    appliedNoData:"आपल्या लागू केलेल्या नोकर्‍या येथे दर्शविल्या जातील",
    hiredNoData:"आपल्या नियुक्त केलेल्या नोकर्‍या येथे दर्शविल्या जातील",
    feedbackSubmitSuccessfuly: 'आपला अभिप्राय यशस्वीरित्या सबमिट केला',
    youAreNotInterested: "आपली खात्री आहे की आपल्याला या जॉबमध्ये रस नाही?",
    sameCity: "आपण त्याच शहरात आहात?",
    resumeCheck: "तुम्ही रेझ्युमे अपलोड केला आहे का?",
    notInterested: "रुचि नाही",
    jobNotAppear: "ही नोकरी भविष्यात दिसणार नाही",
    fatherName: "वडीलांचे नावं",
    enterFatherName: "वडीलांचे नावं प्रविष्ट करा",
    motherName: "आईचे नाव",
    enterMotherName: "आईचे नाव प्रविष्ट करा",
    suggestedJobsCaps: "सुचवलेल्या नोकर्‍या",
    companyWebsite: "कंपनी वेबसाइट",
    website : "वेबसाइट",
    Closed: "बंद आहे",
    uploadVideo: "व्हिडिओ अपलोड करा",
    searchHeaderText: 'नोकरी किंवा कंपन्या',
    moreJobs: 'अधिक जॉब्स..',
    chatWithCompany: "कंपनीशी चैट करा",
    LatestJobs: "नवीनतम नोकर्‍या",
    SearchedJobs: "शोधलेली नोकरी",
    SearchedCompanies: "शोधलेली कंपन्या",
    popularCompanies: "लोकप्रिय कंपन्या",
    recentSearchCompany: "अलीकडे शोधलेली कंपनी",
    recentSearchJobs: "अलीकडे शोधलेली नोकरी",
    All: "सर्व",
    search: "शोध",
    searchSmall : "शोध",
    searchThisArea: "हे क्षेत्र शोधा",
    employers : "नियोक्ता",
    findAllJobsNearYou: "आपल्या स्थान जवळ नोकर्‍या शोधा",
    scanQrCodeAndGetCompanyDetails: "जॉब आणि कंपनीचा तपशील मिळविण्यासाठी क्यूआर कोड स्कॅन करा",
    listOfAllJobs: "येथे सर्व नोकर्यांची यादी ",
    Jobs: "नोकर्‍या",
    noJobFound: "नोकरी मिळाली नाही",
    Views: "व्यूज",
    Viewj: "व्यू",
    filterYourProfileAndJobType: "फिल्टर लागू करा",
    searchJobsAndCompanies: "नोकर्‍या आणि कंपन्या शोधा",
    jobsAndCompanies: "नोकर्‍या आणि कंपन्या",
    searchJobsAndCompaniesHere: "नोकर्‍या आणि कंपन्या",
    selectCityWalk:"शहर चुनें",
    chatScreen: "चैट स्क्रीन",
    fillCollege: "कृपया कॉलेजचे नाव भरा",
    wantToDisableProfile: "आपण आपले प्रोफाइल अक्षम करू इच्छिता?",
    wantToEnableProfile: "आपण आपले प्रोफाइल सक्षम करू इच्छिता? ",
    notNow: "आता नाही",
    Years: "वर्ष",
    viewVideoResume: "व्हिडिओ रिज्यूमे पहा",
    updatingResumeDetail: "अनुभवाचे तपशील अद्यतनित करणे आपल्याला चांगला रेझ्युमे तयार करण्यात मदत करेल",
    updateAboutMe: " चांगला रेझ्युमे  तयार करण्यासाठी माझ्याबद्दल अद्यतनित करा",
    doYouSpeakEnglish: "आपण इंग्रजी बोलता का?",
    To: "पर्यंत",
    addWorkExperience: "कार्य अनुभव जोडा",
    CurrentWorking: "सध्या कार्यरत",
    From: "फ्रॉम",
    enterEmail: "ईमेल पत्ता प्रविष्ट करा",
    enterDob: "जन्मतारीख प्रविष्ट करा",
    companyAddress: "कंपनीचा पत्ता",
    createVideoResume: "आत्ता रेकॉर्डिंग प्रारंभ करा",
    companyEmail: "कंपनी ईमेल",
    emailCaps : "ईमेल",
    resumeCreated: "आपला रेझ्युमे तयार केला गेला आहे",
    callNow: "आत्ताच कॉल करा",
    callHr: "कॉल एचआर",
    noJobFoundForLocation: "निवडलेल्या स्थान / फिल्टरसाठी नोकर्‍या आढळल्या नाहीत",
    Verified: "सत्यापित",
    video: "आवेदित करा",
    scanQrCodeForJobs: "जॉब्स  साठी QR कोड स्कॅन करा",
    topPicks: "शीर्ष निवडी",
    allJobs: "सर्व रोजगार",
    outshineYourResume: "आपले शैक्षणिक तपशील अद्यतनित करून पुन्हा सुरु करा",
    uploadFromFile: "फाइल वरून अपलोड करा",
    uploadResume: "रेझ्युमे अपलोड करा",
    ProfileCompletion: 'प्रोफाइल',
    aboutMe: "माझ्याबद्दल",
    enterYourProfileSummary: "प्रोफाइल सारांश प्रविष्ट करा",
    educationDetails: "शैक्षणिक विवरण ",
    imExperienced: "मी अनुभवी आहे",
    imFresher: "मी फ्रेशर आहे",
    professionalSummaryShort: "व्यावसायिक सारांश खूप छोटा आहे",
    professionalSummary: "व्यावसायिक सारांश",
    websiteNotAvailable : "कंपनीची वेबसाइट उपलब्ध नाही",
    updateProfessionalSummary:"प्रोफाइल सारांश अपडेट करें",
    pleaseUpdateProfileSummary: "कृपया प्रोफाइल सारांश अद्यतनित करा",
    updateProfileSummary: "कृपया प्रोफाइल सारांश अपडेट करें",
    profileSummaryShort: "प्रोफाइल सारांश खूप लहान आहे",
    RelatedVideo: "संबंधित व्हिडिओ",
    relatedVideos: "संबंधित व्हिडिओ",
    resumeDownloadedSuccessfully: "रेझ्युमे यशस्वीरित्या डाउनलोड केलेले ",
    updateProfileMoreBenefits: "रेझ्युमे यशस्वीरित्या डाउनलोड केलेले ,कृपया अधिक लाभ मिळविण्यासाठी आपले प्रोफाइल अद्यतनित करा",
    previewResume: "पूर्वावलोकन रेझ्युमे",
    disableNotification: "सूचना अक्षम करा",
    enableNotification: "सूचना सक्षम करा",
    enableNotificationSuccessfully: "सूचना यशस्वीरित्या सक्षम करा",
    disableNotificationSuccessfully: "सूचना यशस्वीरित्या अक्षम करा",
    neverAskMoney: "झटपत जॉब्स तृतीय पक्षाबरोबर आपली वैयक्तिक माहिती सामायिक करत नाही",
    kindlyupdate: "कृपया आपले प्रोफाइल अद्यतनित करा",
    chooseInterest: "कृपया आपले आवडते क्षेत्र निवडा",
    chooseProfiles: "किमान 3 प्रोफाइल निवडा:",
    maxProfile: "आपण जास्तीत जास्त 6 प्रोफाइल निवडू शकता",
    update: "अद्यतनित करा",
    alreadyVerifedNum: "हा मोबाइल नंबर आधीपासून पडताळलेला आहे",
    verify: "सत्यापित करा",
    NotAvailable: "उपलब्ध नाही",
    Available: "उपलब्ध आहे",
    fillSchool: "कृपया शाळेचे नाव भरा",
    fillSsc: "कृपया ssc भरा",
    Male: "पुरुष",
    ourMission : "आमचे अभियान",
    Female: "महिला",
    NoRequiredSkillsFound: "आवश्यक कौशल्ये आढळली नाहीत",
    postedJobsCaps: "पोस्ट केलेले जॉब",
    NoPhotos: "कंपनीने कोणतेही फोटो पोस्ट केलेले नाहीत",
    EnterValidKey: "कृपया एक वैध की प्रविष्ट करा",
    PleaseEnterContact: "कृपया संपर्क क्रमांक प्रविष्ट करा",
    PleaseTryAgain: "काहीतरी चूक झाली! कृपया पुन्हा प्रयत्न करा",
    ValidContactNumber: "कृपया वैध संपर्क क्रमांक प्रविष्ट करा",
    Gender: "लिंग",
    GenderCaps: "लिंग",
    AddResume: " रेझ्युमे  जोडा",
    AddExperience: "अनुभव जोडा",
    ProfileText: "नियोक्तांना आपली सामर्थ्य समजू देण्यास कौशल्य द्या",
    PersonalDetails: "वैयक्तिक माहिती",
    personalDetails: "वैयक्तिक माहिती",
    Edit: "एडिट करा",
    Enterphonenumber: "फोन नंबर प्रविष्ट करा",
    togetnotifUse: " त्वरित सूचना मिळविण्यासाठी आपला व्हॉट्सअ‍ॅप नंबर वापरा ",
    noCode: " कोड नाही? ",
    resendCode: " कोड पुन्हा पाठवा ",
    plzentercodeSms: "कृपया ओटीपी प्रविष्ट करा",
    invalidOtp: "अवैध ओटीपी",
    OtpverifiedSuccessfully: "ओटीपी सत्यापित यशस्वी",
    PleaseEnterValidNumber: "कृपया वैध फोन क्रमांक प्रविष्ट करा",
    PleaseEnterNumber: "कृपया फोन नंबर प्रविष्ट करा ",
    ProfileUpdatedSuccessfully: "प्रोफाइल यशस्वीरित्या अद्यतनित केले",
    noOfOpeningTxt: "नोकरीच्या रिक्त जागा",
    yes: "होय",
    VideoHasBeenUploadedSuccessfully: "व्हिडिओ यशस्वीरित्या अपलोड झाला आहे",
    notificationDialogText: "अद्याप सूचना नाहीत!",
    favouriteCompanyText: "कोणत्याही आवडत्या कंपन्या सापडल्या नाहीत",
    NoSavedComnpanies: "जतन केलेली जोडपे नाहीत",
    favoriteCompany: "आवडती कंपनी",
    FavouriteCompanies: "आवडती कंपन्या",
    logOut: "लॉग आउट",
    savedJobs: "जतन केलेल्या नोकर्‍या",
    editInterest: "स्वारस्ये संपादित करा",
    Enterthemessage: "संदेश प्रविष्ट करा",
    reviewCaps: "पुनरावलोकन",
    NoJobsFound: "नोकर्‍या सापडल्या नाहीत",
    Readless: "कमी वाचा",
    ReadMore: "पुढे वाचा",
    NoInterviewTipsFound: "No interview tips found",
    SorryThisJobHasBeenRemoved: "क्षमस्व, ही नोकरी काढली गेली आहे",
    NoSavedJobsFound: "जतन केलेल्या नोकर्‍या आढळल्या नाहीत",
    NoFeedbackFound: "कोणताही अभिप्राय आढळला नाही",
    YourResumeUploadedSuccessfully: "आपला रेझ्युमे यशस्वी अपलोड झाला",
    CompanyUnsavedSuccessfully: "कंपनी यशस्वीरित्या काढली गेली आहे",
    CompanySavedSuccessfully: "कंपनी यशस्वीरित्या जतन केली",
    JobSavedSuccessfully: "नोकरी यशस्वीरित्या जतन केली",
    JobUnsavedSuccessfully: "नोकरी यशस्वीरित्या काढली गेली आहे",
    AreYouSureYouWantToLogout: "आपली खात्री आहे की आपण लॉगआउट करू इच्छिता?",
    termsCondition: "नियम व अटी",
    PrivacyPolicies: "प्रायव्हसी पॉलिसी",
    ShortlistedJobs: "शॉर्टलिस्टेड जॉब्स",
    ScheduledJobs: "निवडले जॉब्स",
    HiredJobs: "नियुक्त केलेल्या जॉब्स",
    AppliedJobs: "आवेदित जॉब्स",
    ChooseTheFollowingLanguage: "पुढील भाषा निवडा",
    ChangeLanguage: "भाषा बदला",
    Apply: "आवेदित करा",
    NoLocationProvided: "कोणतेही स्थान प्रदान केलेले नाही",
    EducationalQualification: "शैक्षणिक पात्रता",
    JobPROFILE: "कामाचे स्वरूप",
    Experienced: "अनुभवी",
    reportSubmitted : "अहवाल यशस्वीरित्या सबमिट केला",
    Rs: "रु",
    JobFilters: "जॉब फिल्टर",
    DocumentResumeTips: "दस्तऐवज रेझ्युमे टिपा",
    MakeResume: "रेझ्युमे बनवा",
    Delete: "हटवा",
    Others: "इतर",
    other: "इतर",
    commentHere: "येथे टिप्पणी..",
    SocialServices: "समाजसेवा",
    CustomerServices: "ग्राहक सेवा",
    SalesMarketing: "विक्री आणि विपणन",
    ComputerTechnology: "कंप्यूटर तकनीक",
    AccountingFinance: "लेखा आणि वित्त",
    Confirm: "कन्फर्म",
    JobProfiles: "जॉब प्रोफाइल",
    selectYourInterest: "आपली आवड निवडा",
    savedJOBS: "जॉब जतन केल्या",
    Notification: "अधिसूचना",
    EnterYourCity: "आपले शहर प्रविष्ट करा",
    feedbackFromCompanies: "कंपनीकडून फीडबॅक",
    experienceLevelCaps: "अनुभव स्तर",
    EnterYourNumber: "आपला नंबर प्रविष्ट करा",
    EmailAddress: "ईमेल पत्ता",
    chooseDegree: "डिग्री निवडा",
    EnterYourCollege: "आपल्या महाविद्यालयात प्रवेश करा",
    EnterPercentage: "टक्केवारी प्रविष्ट करा",
    ChoosePassingYear: "उत्तीर्ण वर्ष निवडा",
    EnterYourSchool: "आपल्या शाळेचे नाव प्रविष्ट करा",
    uploadCaps: "अपलोड करा",
    CollegeName: "महाविद्यालयाचे नाव",
    courseCaps: "कोर्स",
    uploadYourHSSCMarksheet: "आपले hssc मार्कशीट अपलोड करा",
    uploadYourSSCMarksheet: "आपले ssc मार्कशीट अपलोड करा",
    passingYear: "उत्तीर्ण वर्ष",
    marksObtainedCaps: "गुण प्राप्त केले",
    SchoolNAME: "शाळेचे नाव",
    ShareWithYourFriends: "आपल्या मित्रांसह सामायिक करा",
    followCaps: "फॉलो",
    unfollowCaps: "अनफ़ॉलो",
    Submit: "जमा करा",
    companyPhotosCaps: "कंपनीचे फोटो",
    companyDescriptionCaps: "कंपनीचे वर्णन",
    companyDetailsCaps: "कंपनीचा तपशील",
    JessicaDOE: "जेसिका डो",
    HR: "मानव संसाधन",
    JobDetailsCaps: "जॉब तपशील",
    JobRequirementCaps: "नोकरीची आवश्यकता",
    salaryCaps: "पगार",
    PostedON: "पोस्ट केले",
    jobType: "कामाचा प्रकार",
    VideoResumeTips: "व्हिडिओ रीझ्युम टिपा",
    Dont: "करू नका",
    qualificationCaps: "पात्रता",
    experienceCaps: "अनुभव",
    Do: "करा",
    yDoWeNeedUploadId: "आम्हाला आयडी प्रूफ का आवश्यक आहे?",
    hMarkSheet: "10 वी मार्क पत्रक",
    ssMarkSheet: "12 वी मार्क पत्रक",
    EnterTitleOfTheCertificate: "प्रमाणपत्र शीर्षक प्रविष्ट करा",
    ChooseALanguage: "आपला अ‍ॅप प्रदर्शन लँगगेज निवडा",
    settingsCaps: "सेटिंग्ज",
    Message: "संदेश",
    Email: "ईमेल",
    contactSupport: "समर्थन कार्यसंघाशी संपर्क साधा",
    contactSpprt: "समर्थन कार्यसंघाशी संपर्क साधा",
    iAgree: "मी सहमत आहे",
    disableNow: "आत्ता अक्षम करा",
    cancelCaps: "रद्द करा",
    disableProfile: "अक्षम करा प्रोफाइल",
    resumeCaps: "रिज्यूमे",
    Profile: "प्रोफाइल",
    educationCaps: "शिक्षण",
    ShortlistedCaps: "निवडले",
    videoResumeCaps: "व्हिडिओ रिझ्यूम",
    identityProofCaps: "ओळख पुरावा",
    item: "वस्तु",
    km: "किमी",
    workExperienceCaps: "कामाचा अनुभव",
    interviewTipsCaps: "मुलाखत टिपा",
    showAll: "सर्व",
    myJobsCaps: "माझे जॉब",
    interviewedCaps: "मुलाखत घेतली",
    awaitingCaps: "प्रतीक्षेत",
    provideAllDetail: "कृपया सर्व तपशील प्रदान करा",
    appliedCaps: "लागू",
    cancel: "रद्द करा",
    Hired: "नियुक्त केले आहे",
    scheduledCaps: "नियोजित",
    job: "नोकरी",
    rejectedCaps: "नाकारले",
    rateUs: "आम्हाला रेट करा",
    jobSeekers : "काम शोधणारा",
    logOutConfirmmsg: "आपली खात्री आहे की आपण लॉगआउट करू इच्छिता?",
    workExpIntro: "आपल्या स्वप्नातील कंपनीवर कायम टिकणारी छाप पाडण्यासाठी, आपल्या कामाच्या अनुभवाच्या तपशीलांचा येथे उल्लेख करा",
    uploadtheImage: "आपण ही प्रतिमा अपलोड करू इच्छिता?",
    agreetothe: "मी याद्वारे सहमत आहे",
    agreeTerms: "नियम आणि अटी",
    videoResumeIntro: "आमच्या अगदी नवीन व्हिडिओ रेझ्युमे पर्यायासह अपवादात्मक रहा, काळजी करू नका कारण आपणास त्याचे मार्गदर्शन मिळेल",
    resumeIntro: "कंपन्यांमध्ये आपली दृश्यमानता वाढविण्यासाठी आपला दूरगामी सारांश अपलोड करा",
    resumeBuilder: "रिज्यूमे निर्माता",
    ResumeBuilder: "रिज्यूमे निर्माता",
    postJobNow : "आता नोकरी पोस्ट करा",
    personalInfo: "वैयक्तिक माहिती",
    adhaarIntro: "सत्यता सुनिश्चित करण्यासाठी, आधार कार्ड आवश्यक आहे .हे अपलोड करण्यास विसरू नका",
    IdCardIntro: "आयडीमध्ये व्यवहार करणे आता कोणतीही घाई नाही, ते येथे अपलोड करा आणि विश्रांतीसाठी मोकळे व्हा",
    educationIntro: "आपले शैक्षणिक तपशील जोडून आपले प्रोफाइल थकबाकीदार बनवा",
    certificatesIntro: "आपल्याला स्वप्नातील नोकरीसाठी पात्र बनवणारी प्रत्येक गोष्ट जोडा. आपली प्रमाणपत्रे जोडा आणि गर्दीतून उभे रहा",
    fullname: "पूर्ण नाव",
    fullnameCaps : "पूर्ण नाव",
    jobtitle: "नोकरी शीर्षक",
    appliedJobs: "आवेदित जॉब्स",
    shortlistedJobs: "शॉर्टलिस्टेड जॉब्स",
    noApplyJobText: " आपण अद्याप नोकरीसाठी अर्ज केलेला नाही! नोकरीसाठी अर्ज करा आणि संस्थेशी थेट संवाद साधा",
    jobLocation: "नोकरीचे स्थान",
    resumeDeletesuccessfully: "रीझ्युमे यशस्वीरित्या हटविला",
    deletemarkResumeDialog: "आपणास खात्री आहे की आपण रीझ्युमे हटवू इच्छिता?",
    deleteVideoDialog: "आपणास खात्री आहे की आपण व्हिडिओ रीझ्युमे हटवू इच्छिता?",
    noNotificationmsg: "कोणतीही नवीन सूचना नाही",
    whatswrongwithjob: "या नोकरीमध्ये काय चुकले आहे?",
    interviewDate: "मुलाखतीची तारीख",
    interviewTime: "मुलाखतीची वेळ",
    thankyou: "धन्यवाद!",
    next: "पुढे",
    nextBtn: "पुढे",
    skip: "वगळा",
    done: "पूर्ण झाले",
    enterFeedBackHere: "कृपया आपला अभिप्राय येथे लिहा",
    titleCaps: "शीर्षक",
    enterConcernHere: "आपली चिंता येथे प्रविष्ट करा",
    videoDurationAlert: "व्हिडिओ कालावधी 10 सेकंद नंतर अधिक असणे आवश्यक आहे",
    videoDurationLargeAlert: "व्हिडिओ कालावधी 60 सेकंदांपेक्षा जास्त नसावा",
    deleteIdProofDialog: "आपली खात्री आहे की आपण आयडी प्रूफ हटवू इच्छिता?",
    IdProofDeletesuccessfully: "आयडी पुरावा यशस्वीरित्या हटविला",
    idProofUploadSuccess: "ओळख पुरावा यशस्वीरित्या अपलोड केला",
    clickanyofyouridProof: "तुमचा कोणताही आयडी पुरावा निवडण्यासाठी येथे क्लिक करा",
    deleteAdhharDialog: "आपली खात्री आहे की आपण आधार कार्ड हटवू इच्छिता?",
    AdhaarDeletesuccessfully: "आधार कार्ड यशस्वीरित्या हटविले",
    adhaaruploadsuccess: "आधार यशस्वीरित्या अपलोड केला",
    selectFileClickPhoto: "फोटो घेऊन किंवा लायब्ररीतून फाईल निवडण्यासाठी येथे क्लिक करा",
    marksheet: "गुणपत्रिका",
    deletemarkSheetDialog: "आपली खात्री आहे की आपण मार्कशीट हटवू इच्छिता?",
    markSheetDeletesuccessfully: "मार्कशीट यशस्वीरित्या हटविली",
    degreeUploadSuccess: "मार्कशीट यशस्वीरित्या अपलोड केले गेले",
    deletemarksheet: "आपली खात्री आहे की आपण आपली मार्कशीट हटवू इच्छिता?",
    list: "यादी",
    map: "नकाशा",
    sort: "सॉर्ट",
    awaitingReview: "पुनरावलोकनाच्या प्रतीक्षेत",
    state: "राज्य",
    noCredits: "अर्ज करण्यासाठी कोणतेही क्रेडिट्स उपलब्ध नाहीत",
    youHaveSuccessfullyAppliedForTheJob: "आपण नोकरीसाठी यशस्वीरित्या अर्ज केला आहे",
    educationaddedsuccess: "शिक्षण यशस्वीरित्या जोडले",
    highersecondaryschoolcertificates: "उच्च माध्यमिक शालेय प्रमाणपत्र",
    Continue: "सुरू ठेवा",
    congratulations: "अभिनंदन!",
    choosefiletoupload: "कृपया अपलोड करण्यासाठी फाईल निवडा",
    kindlyselctFileUpload: "कृपया अपलोड करण्यापूर्वी एक फाइल निवडा",
    oK: "ठीक आहे",
    hello: "नमस्कार",
    verifyYourPhoneNumber: "आपला फोन नंबर सत्यापित करा",
    hired: "नियुक्त केले आहे",
    applied: "आवेदित",
    verify: "सत्यापित करा",
    verificationCode: "सत्यापन कोड ",
    loginTabs : "लॉग इन",
    loginCaps : "लॉग इन",
    details: "तपशील",
    ratings: "रेटिंग्ज",
    Ratings: "रेटिंग्ज",
    scheduled: "नियोजित",
    pleaseentervalidkey: "कृपया एक वैध की प्रविष्ट करा",
    photos: "छायाचित्र",
    interviewed: "मुलाखत घेतली",
    shortlisted: "शॉर्टलिस्टेड",
    chat: "चॅट",
    profileCompleted: "प्रोफाईल पूर्ण झाले",
    enterscoredpercentage: "स्कोअर टक्केवारी प्रविष्ट करा",
    enterjobpos: "नोकरीची स्थिती प्रविष्ट करा",
    rucurrentlyworkhere: "आपण सध्या येथे काम करीत आहात?",
    resume: "रीझ्युमे",
    aadharCard: "आधार कार्ड",
    identityProof: "ओळख पुरावा",
    education: "शिक्षण",
    rejected: "नाकारले",
    typeAMsg: "संदेश टाइप करा",
    workExperienceAddedSuccessfully: "कार्य अनुभव यशस्वीरित्या जोडला",
    enterYourSkills: "आपली कौशल्ये प्रविष्ट करा",
    videoResume: "व्हिडिओ रीझ्युमे",
    followers: "अनुयायी",
    filter: "फिल्टर्स",
    clear: "काढण्यासाठी",
    updateProfileSummary: "कृपया प्रोफाइल सारांश अद्यतनित करा",
    experienceLevel: "अनुभव पातळी",
    qualification: "शैक्षणिक पात्रता",
    fresher: "फ्रेशर",
    salaryExpectation: "पगाराची अपेक्षा",
    share: "सामायिक करा",
    upload: "अपलोड करा",
    delete: "हटवा",
    highest: "सर्वोच्च",
    highestDegree: "सर्वोच्च पदवी",
    degree: "पदवी",
    startDate: "प्रारंभ तारीख",
    startDateCaps: "प्रारंभ तारीख",
    endDate: "शेवटची तारीख",
    endDateCaps: "शेवटची तारीख",
    jobPosition: "नोकरीची स्थिती",
    industryType: "उद्योग प्रकार",
    summary: "सारांश",
    name: "नाव",
    totalViews: "Total views",
    apply: "आवेदित",
    enteryourcontactno: "आपला संपर्क क्रमांक प्रविष्ट करा",
    experience: "अनुभव",
    skillsAndInterests: "कौशल्य",
    profileSummary: "प्रोफाइल सारांश",
    currentLocation: "वर्तमान स्थान",
    location: "स्थान",
    marksObtained: "गुण प्राप्त झाले",
    submit: "जमा करा",
    shareexpandRateUs: "आपला अनुभव आमच्याबरोबर सामायिक करा आणि आपली रेटिंग द्या",
    profileBriefDesc: "आपल्याबद्दल थोडक्यात वर्णन लिहा",
    aboutYou: "माझ्याबद्दल",
    description: "वर्णन",
    skills: "कौशल्य",
    myJobs: "माझ्या नोकर्‍या",
    filters: "फिल्टर",
    filterText : "फिल्टर",
    interviewTips: "मुलाखतीच्या टीपा",
    summary: "सारांश",
    organizations: "संस्थेचे",
    postedOn: "पोस्ट केले:",
    salary: "पगार",
    totalViews: "एकूण दृश्ये",
    aadharNo: "आधार क्रमांक",
    companyProfile: "कंपनी प्रोफाइल",
    companyProfileCaps: "कंपनी प्रोफाइल",
    city: "शहर",
    zipCode: "पिनकोड",
    jjBot: "JJ बॉट",
    jobProfiles: " नोकरी प्रोफाइल",
    FullTime: "पूर्ण वेळ",
    partTime: "अर्ध - वेळ",
    internship:"इंटर्नशिप",
    areaOfInterest : "पंसदीदा छेत्र",
    experienced: "अनुभवी",
    reset: "रीसेट",
    graduate: "पदवीधर",
    sales: "विक्री",
    finance: "वित्त",
    humanResources: "मानव संसाधन",
    marketing: "विपणन",
    engineer: "इंजीनियर",
    businessDevelopement: "व्यवसाय विकास",
    accountant: "लेखापाल",
    digitalMarketing: "डिजिटल विपणन",
    graphicDesigner: "ग्राफिक डिझायनर",
    teacher: "शिक्षक",
    counselor: "सल्लागार",
    contentWriting: "सामग्री लेखन",
    budgetAnalyst: "अंदाजपत्रक विश्लेषक",
    buyerPlanner: "खरेदीदार नियोजक",
    deleteYourAcc : "आपण कधीही खात खात Jobam वरून हटवू शकता. खाते हटवल्यानंतर आपण भरती करणार्‍यांना यापुढे दिसणार नाही. तसेच आपण अॅपमधून आपला सर्व डेटा गमवाल आणि भविष्यात तो परत मिळविण्यात सक्षम होणार नाही.",
    safetyManagement: "सुरक्षा व्यवस्थापन",
    qualityAnalyst: " गुणवत्ता विश्लेषक",
    trainer: "ट्रेनर",
    technician: "तंत्रज्ञ",
    receptionist: "रिसेप्शनिस्ट",
    delivery: "वितरण",
    tailor: "टेलर",
    cook: "कूक",
    notification: "अधिसूचना",
    logout: "लॉगआउट",
    disableProfile: "प्रोफाइल अक्षम करा",
    shareApp: "ऐप शेयर",
    companyFeedback: "कंपनी अभिप्राय",
    company: "कंपनी",
    languageSelection: "भाषा निवड",
    language : "भाषा",
    updateadhaarcontactNoApply: "अर्ज करण्यापूर्वी कृपया आपले प्रोफाइल अद्यतनित करा",
    acceptTermsConditions: "कृपया पुढे जाण्यासाठी अटी व शर्ती मान्य करा",
    hindi: "हिंदी",
    english: "अंग्रेज़ी",
    jobNotification: "नोकरी अधिसूचना",
    email: "ई - मेल",
    address: "पत्ता",
    contact: "संपर्क",
    feedback: "अभिप्राय",
    mobileNumber: "मोबाइल नंबर",
    editProfile: "प्रोफाईल संपादित करा",
    name: "नाव",
    contactNumber: "संपर्क क्रमांक",
    dateOfBirth: "जन्मतारीख",
    currentCity: "सध्याचे शहर",
    enteryouremail: "आपला ई - मेल प्रविष्ट करा",
    enterYourDob: "आपली जन्मतारीख प्रविष्ट करा",
    yourFeedbackMeans: "आपला अभिप्राय आमच्यासाठी खूप अर्थपूर्ण आहे!",
    enteryourname: "आपले नांव लिहा",
    add: "जोडा",
    download: "डाउनलोड करा",
    downloadApp : "ऐप डाउनलोड करा",
    aiDrivenPlatform : "झटपत जॉब्स हा एक एआय चालित व्यासपीठ आहे जो वेगळ्या भारतीय हायपरलोकल जॉबसाठी डिझाइन केलेला आहे.",
    jhatpatActAsSeasoned : "कंपन आणि जॉब सीकर्स यांच्यात Jobam हंगामी मॅचमेकर म्हणून काम करते. ",
    view: "पहा",
    settings : "सेटिंग्स",
    addEducation: "शिक्षण जोडा",
    organizationname: "संघटनेचे नाव",
    enterorganization: "संस्थेचे नाव प्रविष्ट करा",
    privacyPolicy: "गोपनीयता धोरण",
    workstatus: "कामाची स्थिती",
    percentage: "टक्केवारी",
    examName: "परीक्षेचे नाव",
    cityText: "शहर",
    workExperienceDeletedSuccessfully: "कामाचा अनुभव यशस्वीरित्या काढला",
    fillUniversity: "कृपया विद्यापीठाचे नाव प्रविष्ट करा",
    locationRadius: "स्थान त्रिज्या",
    myQrCode: "माझा QR कोड",
    myQrCodeDrawer :  "माझा QR कोड",
    chooseProfileDialog: "कृपया किमान 3 प्रोफाइल निवडा",
    EnterYourUniversity: "आपल्या विद्यापीठाचे नाव प्रविष्ट करा",
    universityName: "विद्यापीठाचे नाव",
    creditsAvailable: "क्रेडिट उपलब्ध आहे",
    optimizingVideo :  "आपला व्हिडिओ ऑप्टिमायझिंग" ,
    selectCityFromList: "कृपया सूचीमधून शहर निवडा",
    enterOnlyAlphabets: "कृपया फक्त वर्णमाला प्रविष्ट करा",
    pleaseEnterName: "कृपया आपले नाव प्रविष्ट करा",
    mrText: "श्री.",
    mrsText: "सौ.",
    deleteResume: "रिझ्यूमे हटवणे",
    deleteVideo: "व्हिडिओ हटवा",
    creatingResume: "रिझ्यूमे बनत आहे",
    deletingResume: "रिज्यूमे हटाया जा रहा है",
    deletingVideoResume: "वीडियो रिज्यूमे हटाया जा रहा है",
    recordVideo: "रेकॉर्ड व्हिडिओ",
    stopRecording: "रेकॉर्डिंग बंद करा",
    startRecording: "व्हिडिओ तयार",
    videoPreview: "व्हिडिओ पूर्वावलोकन",
    pleaseWait: "कृपया प्रतीक्षा करा",
    makingQrCode: "Qr कोड बनत आहे",
    moreJob: "अधिक नोकरी",
    noDataFound: "कोणताही डेटा नाही",
    noFaqFound: "कोणताही FAQ नाही",
    processingVideo: "व्हिडिओ प्रक्रिया आहे",
    gettingNearByJob: "आपल्यासाठी नोकर्‍या येत आहेत",
    qrCodeIsInvalid: "QR कोड अवैध आहे",
    code: "कोड",
    scanningInvalidCode: "आपण अवैध कोड स्कॅन करीत आहात",
    jhatpatJobs: "Jobam",
    allowAccess: "प्रवेशास अनुमती द्या",
    addMoreExperience: "अधिक अनुभव जोडा",
    jobsAtTheRate: "नौकरी @",
    youAreAlreadyExperienced: "आपण आधीपासून अनुभवी आहात, नवीन तपशील अद्यतनित केल्याने आपला अनुभव डेटा हटविला जाईल. आपण अद्याप अद्यतनित करू इच्छिता?",
    allowJhatpatJobs: "झटपत जॉब्सच्या प्रशिक्षकांना आपले स्थान मिळविण्याची परवानगी द्या",
    alreadyRegisteredInstitute: "आपण आधीच संस्थेत नोंदणीकृत आहात",
    successfullyRegInstitute: "आपण संस्थेत यशस्वीरित्या नोंदणीकृत आहात",
    gettingNearByJobs: "आपल्यासाठी नोकर्‍या येत आहेत",
    jobs: "जॉब्स",
    reviews: "पुनरावलोकन",
    remove: "काढा",
    loadingLanguage : "भाषा लोड करीत आहे",
    userauthentication: "वापरकर्ता प्रमाणीकरण",
    enteryourkeyhere: " आपली की येथे प्रविष्ट करा",
    signinconnectwithusnow: "साइन इन करा आणि आता आमच्याशी विनामूल्य संपर्क साधा",
    signinwithgoogle: "गूगल सह साइन इन करा",
    signinwithfb: " फेसबुक सह साइन इन करा ",
    numberCannotBeNull: "मोबाइल नंबर शून्य असू शकत नाही",
    deleteAcc:"खाते काढा",
    deleteCard:"खाते काढा",
    deleteAccountDialog:"आपली खात्री आहे की आपण खाते हटवू इच्छिता?",
    fetchingYourLocation : "आपले स्थान प्राप्त करत आहे, कृपया प्रतीक्षा करा",
    weNeverShare : "आम्ही आपली वैयक्तिक माहिती कोणत्याही एजन्सीसह सामायिक करीत नाही",
    haveaRefCode : "रेफरल कोड आहे?",
    enterCode : "कोड प्रविष्ट करा",
    updateProfile : "प्रोफाइल अद्यतनित करा",
    noRefCodeFound : "कोणताही संदर्भ कोड आढळला नाही",
    uploadingVideo : "आपला व्हिडिओ रिज्यूमे अपलोड करीत आहे",
    uploadingResume : "रिज्यूमे अपलोड हो रहा है",
    uploadingProfile : "प्रोफ़ाइल अपलोड हो रही है",
    pleaseChooseDoc : "या फाईल प्रकारास परवानगी नाही, कृपया दस्तऐवज / पीडीएफ / डॉक्स निवडा.",
    experienceUpdated : "अनुभव यशस्वीरित्या अद्यतनित केला",
    noPreviewResume : "सारांश पहाण्यासाठी कोणतेही पूर्वावलोकन उपलब्ध नाही",
    selectTypeOfNoti : "अक्षम करण्यासाठी सूचना प्रकार निवडा ",
    jobsFeed : "जॉब्स फीड",
    jobFeedDetail : "नौकरी के विवरण",
    hrIsNotAvailable : "आत्ता कॉल घेण्यासाठी एचआर उपलब्ध नाही",
    credits : "क्रेडिट्स",
    credit: "क्रेडिट्",
    scanQrCode : "क्यूआर कोड स्कॅन करा",
    notificationSmall : "अधिसूचना",
    notificationText : "अधिसूचना",
    chatText : "चेत",
    jobFeed : "जॉब्स फ़ीड",
    audioResume : "ऑडियो रिज्यूमे",
    audioResumeCaps : "ऑडियो रिज्यूमे",
    recordAudio : "ऑडियो रिकॉर्ड करा",
    startAudioRecording : "रिकॉर्डिंग शुरू करा",
    stopAudioRecording : "रिकॉर्डिंग बंद करा",
    audioPlay : "ऑडियो चलाएं",
    recordAgain : "दुबारा रिकॉर्ड करें",
    audioRecordSuccessfully :"ऑडियो फिर से सफलतापूर्वक अपलोड किया गया",
    HiText : "नमस्ते",
    youHave : "तुझ्याकडे आहे",
    professionalExperience : "व्यावसायिक अनुभव",
    creditsInYourAcc : "आपल्या खात्यात जमा नोकरीवर अर्ज करण्यासाठी क्रेडिट्स वापरा. अधिक क्रेडिट्स मिळविण्यासाठी अॅप सामायिक करा.",
    creditInYourAcc:"आपल्या खात्यात जमा नोकरीवर अर्ज करण्यासाठी क्रेडिट् वापरा. अधिक क्रेडिट्स मिळविण्यासाठी अॅप सामायिक करा.",
    permissionHasDenied : "परवानग्या नाकारल्या गेल्या आहेत. कृपया ऑडिओ रेकॉर्ड करण्यासाठी स्टोरेज आणि मायक्रोफोन परवानग्यांना परवानगी द्या.",
    allow : "परवानगी द्या",
    loadingYourProfile : "आपले प्रोफाइल लोड करीत आहे",
    loadingInterviewTips : "मुलाखत टिपा लोड करीत आहे",
    general : "सामान्य",
    logintoSeeinterviewTips : "मुलाखत पाहण्यासाठी कृपया लॉगिन करा टिपा!",
    logintoSeeNotification : "अधिसूचना पाहण्यासाठी कृपया लॉगिन करा!",
    loadingYourJobs : "आपल्या नोकर्या लोड करीत आहे",
    logintoSeeJobStatus : "आपली नोकरी स्थिती पाहण्यासाठी कृपया लॉगिन करा",
    loadingProfileSummary : "आपला प्रोफाइल सारांश लोड करीत आहे",
    loadingAppliedJobs : "आपल्या लागू केलेल्या नोकर्‍या लोड होत आहेत",
    loadingShortlistedJobs : "आपल्या शॉर्टलिस्ट केलेल्या नोकर्‍या लोड होत आहेत",
    loadingHiredJobs : "आपल्या नियुक्त केलेल्या नोकर्या लोड होत आहेत",
    loadingScheduledJobs : "आपल्या नियोजित नोकर्या लोड होत आहेत",
    loadingFaqs : "सामान्य प्रश्न लोड हो रहे है",
    loadingCompanyFeedback : "कंपनीचा अभिप्राय लोड करीत आहे",
    loadingJobProfiles : "जॉब प्रोफाइल लोड करीत आहे",
    loadMoreItems : "अधिक वस्तू लोड करा",
    loadingEducation : "शिक्षण तपशील लोड करीत आहे",
    serachingYourJobs : "आपल्या नोकर्‍या शोधत आहात",
    loadingWorkExperience : "कामाचा अनुभव लोड होत आहे",
    loadingCompanyDetails : "कंपनीचे वर्णन लोड करीत आहे ",
    jobAddress : "नोकर्या पता",
    registerAndApply : "नोंदणी करा आणि अर्ज करा",
    interestedInJob : "आपणास या नोकरीमध्ये स्वारस्य असल्यास, 'लागू करा' बटण दाबा आणि थेट कंपनीशी संपर्क साधा. तृतीय पक्षाचा सहभाग नाही. भारतातील रोजगार शोधण्यासाठी झटपट रोजगार विनामूल्य आहेत.",
    loadingJobDetails : "नोकरी तपशील लोड करीत आहे",
    dreamJob : "प्रत्येकासाठी स्वप्नातील नोकरी!",
    chooseCity : "शहर निवडा",
    loadingNotification : "सूचना लोड करीत आहे",
    updateProfileText : "कृपया आपले प्रोफाइल अद्यतनित करा",
    kindlyLoginTextForSave : "ही नोकरी जतन करण्यासाठी कृपया लॉगिन करा",
    kindlyLoginReportText : "या नोकरीचा अहवाल देण्यासाठी कृपया लॉग इन करा",
    linkedinaddUrl : "लिंक्डइन url",
    facebookaddUrl : "फेसबुक url",
    instagramaddUrl : "इंस्टाग्राम url",
    enterYorLinkedinUrl : "तुमची लिंक्डइन url एंटर करा",
    enterYorInstagramUrl : "तुमची इंस्टाग्राम url एंटर करा",
    enterYorFacebookUrl : "तुमची फेसबुक url एंटर करा"
  },
  
});
export default strings;