import React from "react";
// import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import Images from "../Images";
import { Link } from "react-router-dom";
import "../_style/register.css";
import "../_style/animate.css";
import helpers from "../config";
import swal from "sweetalert";
import { Modal } from "react-bootstrap";
import ModalDiv from "../components/modal";
// import { gapi } from "gapi-script";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";


var email;
var name;
var givenName;
var jobseekerImage;
var token;
var tokenId;
var jobId;
var jobLink;
var url;
var imageUrl;
class Register extends React.Component {
  constructor() {
    super();
    this.state = {
      userDetails: {},
      isUserLoggedIn: false,
      isFromLogin: true,
      show: false,
      termsAgreed: false,
      tAgreed: "false"
    };
  }
  showModal (e) {
    this.setState({
      show: true
    });
  };

   hideModal = e => {
    this.setState({ show: false });
  };
  async verifyAuth() {
    var jobseekerId = localStorage.getItem("jobseekerId");
    try {
    let response = await fetch(url + "/admin/getAppSetDetails1", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        jobseekerId: jobseekerId,
        tokenId:tokenId,
        loginFrom: "Web"
      })
    })
      response = await response.json()
        if (response != null) {
          if (response.hasOwnProperty("ismobileVerified")) {
            if (response.ismobileVerified) { 
            let userLang=localStorage.getItem("userLang")
             localStorage.setItem("selectedLang", userLang)
              this.props.history.go(-1) 
            } else if (!response.ismobileVerified) {
              this.props.history.push({
                pathname: "/changelanguage"
              });
            }
          }
        }  
      }catch(err){
      }
  }
  

  async componentDidMount() {
    let apiUrl =  await helpers.getServerUrl()
      url = apiUrl.serverUrl
      imageUrl = apiUrl.imageUrl
   tokenId = localStorage.getItem("tokenId");
    jobLink = localStorage.getItem("jobLink");
    jobId = localStorage.getItem("jobId");
    if(localStorage.getItem("isUserLoggedIn")) 
    {
      this.props.history.push('/profile')
    }
  }
  handleClick = () => {
    window.FB.login(this.checkLoginState());
  }

  termsCondition = () => {
    // if(window.gapi !== null && window.gapi !== undefined)
    // {
    //   window.gapi.load('auth2', () => {
    //     if(window.gapi.client.init !== undefined)
    //     {
    //       window.gapi.auth2.init({
    //           clientId: '686714802941-4u852m4f6pok016g05n7hdlut2jdq22q.apps.googleusercontent.com',
    //       })
    //     }
    //   })
    // }
    if(this.state.termsAgreed==false){
    this.setState({ termsAgreed:true });
    }
    else{
    this.setState({ termsAgreed:false });
    }
  }
  agreeTerms = (e) => {
    this.setState({
      show: true
    });
  };

  sessionTime = () => {
  var now = new Date().getTime();
  localStorage.setItem('setupTime', now)
  }
 async googleLogin() {
    email = this.state.userDetails.email;
    name = this.state.userDetails.name;
    token = this.state.token;
    jobseekerImage = localStorage.getItem("userImage")
   try {
    let response = await fetch(url + "/jobseeker/GoogleLogin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email: email,
        jobseekerName: name,
        jobseekerImage:jobseekerImage,
        tokenId: tokenId,
        loginFrom: "Web"
      })
    })
       response=await response.json(); 
        if (response != null) {
          var res = response[0];
          localStorage.setItem("jobseekerId", res.jobseekerId);
          localStorage.setItem("jsObjectId", res.jsObjectId);
          localStorage.setItem("adminId", res.adminId);
          localStorage.setItem("email", res.email);
          localStorage.setItem("referralCode", res.referralCode);
          localStorage.setItem("jobseekerName", res.jobseekerName);
          localStorage.setItem("resume", res.resume);
         let jsnm=localStorage.getItem("jobseekerName").split(" ")
         localStorage.setItem("givenName",jsnm[0])
          localStorage.setItem("tokenId",tokenId);
          localStorage.setItem("isUserLoggedIn", this.state.isUserLoggedIn);
          this.verifyAuth(); 
          this.sessionTime();
        }
      }catch(err){
      }
  }

  async fbLogin() {
    email = this.state.email;
    name = this.state.name;
    token = this.state.token;
    jobseekerImage=this.state.jobseekerImage;
  try {
   let response = await fetch(url + "/jobseeker/fbLogin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email: email,
        jobseekerName: name,
        socialId: token,
        jobseekerImage:jobseekerImage,
        loginFrom: "Web"
      })
    })
      response = await response.json()
        if (response != null) {
          var res = response[0];
          localStorage.setItem("jobseekerId", res.jobseekerId);
          localStorage.setItem("jsObjectId", res.jsObjectId);
          localStorage.setItem("adminId", res.adminId);
          localStorage.setItem("email", res.email);
          localStorage.setItem("referralCode", res.referralCode);
          localStorage.setItem("jobseekerName", res.jobseekerName);
          let jsnm=localStorage.getItem("jobseekerName").split(" ")
          localStorage.setItem("givenName",jsnm[0])
          localStorage.setItem("tokenId",tokenId);
          localStorage.setItem("isUserLoggedIn", this.state.isUserLoggedIn);
          this.verifyAuth();
          this.sessionTime()
        }
      }catch (err) {
      }
  }
  
  responseGoogle = response => {
    const userObject = jwt_decode(response.credential);
    console.log("userObject",userObject)
    this.setState({
      userDetails: userObject,
      token: response.accessToken,
      isUserLoggedIn: true
    });
    // localStorage.setItem("givenName", this.state.userDetails.givenName);
    localStorage.setItem("givenName", "");
    localStorage.setItem("userImage","");
    // localStorage.setItem("userImage", this.state.userDetails.imageUrl);
    this.googleLogin();
  };
  
  responseFacebook = response => {
    if (response.id != null && response.id != "" && response.id !=undefined) {
    this.setState({
      name: response.name,
      email: response.email,
      jobseekerImage:response.picture.data.url,
      token: response.id,
      isUserLoggedIn: true
    });
    localStorage.setItem("givenName", this.state.name);
    localStorage.setItem("userImage", this.state.jobseekerImage);
    this.fbLogin();
  } 
  };
  render() {
    return (
      <div>
        <div className="loginBackground"></div>
        <div className="wrapper fadeInDown">
          <div id="formContent" className="pt-3">

            <div className="fadeIn first">
              <img src={Images.jjIcon} id="icon" alt="User Icon" />
              <h4>Welcome to Jobam</h4>
              <p>Sign up for free</p>
            </div>
            <div className="pt-0 mt-0 pl-5 pr-5">
            {this.state.termsAgreed ? (
              // <GoogleLogin
              //   clientId="686714802941-4u852m4f6pok016g05n7hdlut2jdq22q.apps.googleusercontent.com"
              //   render={renderProps => (
                <GoogleOAuthProvider clientId="686714802941-4u852m4f6pok016g05n7hdlut2jdq22q.apps.googleusercontent.com">
                <GoogleLogin 
                      render={renderProps => (

                  <button
                    className="btn btn-danger danger-gradient w-100 mb-2 mt-1"
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                  >
                    <span class="google-text"><i className="fab fa-google text-white"></i> SIGN IN WITH GOOGLE</span>
                  </button>    
                )}
                onSuccess={this.responseGoogle}
                onFailure={this.responseGoogle}
                cookiePolicy="single_host_origin"
              />
              </GoogleOAuthProvider>
            ) : (
              <button
                className="btn btn-danger danger-gradient w-100 mb-2 mt-1"
                onClick={() => this.agreeTerms()}
              >
              <span class="google-text"><i className="fab fa-google text-white"></i> SIGN IN WITH GOOGLE</span>
              </button>
            )}
            {/* {this.state.termsAgreed ? (
              <FacebookLogin
              appId="1075135189493469"
              cssClass="kep-login-facebook"
              fields="name,email,picture"
              autoLoad={true}
              textButton = "SIGN IN WITH FACEBOOK"
              cookie={true}
              xfbm={true}
              callback={this.responseFacebook}
              disableMobileRedirect={true}
            /> ): (
              <button
                className="btn btn-primary primary-gradient w-100 mb-2 mt-1"
                onClick={() => this.agreeTerms()}
              >
               <span class="facebook-text"><i className="fab fa-facebook-f"></i> SIGN IN WITH FACEBOOK</span>
              </button>
            )}  */}
            {/* Terms & conditions modal*/}
            <ModalDiv modalReact={true} showModal={this.state.show} msg="Please accept terms and conditions to proceed" crossBtnModal={true} btn1Text="OK" hideModal={() => this.hideModal()} btn1Click={() => this.hideModal()}/>
            
            <div className="col-md-12">
              <div className="d-inline-flex mt-2">
                <input type="checkbox" onChange={() => this.termsCondition()}/>
                <span className="termText ml-md-1"><Link to="./privacypolicy" target="_blank" className="text-decoration-none ml-1 text-dark"><span className="agreeText">I hereby agree to the </span><span className="text-success-gradient text-decoration-none">Terms and Conditions</span></Link></span>
              </div>
            </div>
            </div>

            <div id="formFooter">
              <a href="https://play.google.com/store/apps/details?id=dataflakes.jobs.jhatpatjobs.jobsearch&hl=en" className="text-decoration-none text-success-gradient" target="_blank"><i className="fab fa-google-play text-success-gradient"></i> Download Jobam App</a>
            </div>

          </div>
        </div>
        {/* For new */}
      </div>

    );
  }
}

export default Register;
