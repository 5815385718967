import helpers from "../config";

export const SHOW_JOB_LIST = 'SHOW_JOB_LIST';
export const SHOW_LIST_SUCCESS = 'SHOW_LIST_SUCCESS';
export const SHOW_LIST_FAILED = 'SHOW_LIST_FAILED';

export const SHOW_JOB_DETAIL = 'SHOW_JOB_DETAIL';
export const SHOW_DETAIL_LOADING = 'SHOW_DETAIL_LOADING';
export const SHOW_JOB_FAILED = 'SHOW_JOB_DETAIL';

export const RELATED_JOB_LOADING = 'RELATED_JOB_LOADING';
export const SHOW_RELATED_JOBS = 'SHOW_RELATED_JOBS';
export const RELATED_JOB_FAIL = 'RELATED_JOB_FAIL';

export const REPORT_DATA_LOAD = 'REPORT_DATA_LOAD';
export const GET_REPORT_DATA = 'GET_REPORT_DATA';
export const REPORT_DATA_FAILED = 'REPORT_DATA_FAILED';

export const SAVEJOBS_DATA_LOADING = "SAVEJOBS_DATA_LOADING";
export const SHOW_SAVEJOBS_DATA = "SHOW_SAVEJOBS_DATA";
export const SAVEJOBS_LOAD_FAIL = "SAVEJOBS_LOAD_FAIL";

export const FILTER_JOB_DETAIL = 'FILTER_JOB_DETAIL';
export const FILTER_DETAIL_SUCCESS = 'FILTER_DETAIL_SUCCESS';
export const FILTER_JOB_FAILED = 'FILTER_JOB_FAILED';

export const SHOW_TOP_PICKS = 'SHOW_TOP_PICKS';
export const SHOW_TOP_PICKS_SUCCESS = 'SHOW_TOP_PICKS_SUCCESS';
export const SHOW_TOP_PICKS_FAILED = 'SHOW_TOP_PICKS_FAILED';

export const GET_CITIES = 'GET_CITIES';
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS';
export const GET_CITIES_FAILED = 'GET_CITIES_FAILED';

export const SHOW_FILTER_ICON = 'SHOW_FILTER_ICON';
export const SHOW_FILTER_ICON_SUCCESS = 'SHOW_FILTER_ICON_SUCCESS';
export const SHOW_FILTER_ICON_FAILED = 'SHOW_FILTER_ICON_FAILED';

export const SHOW_FILTER_SEARCH = 'SHOW_FILTER_SEARCH';
export const SHOW_FILTER_SEARCH_SUCCESS = 'SHOW_FILTER_SEARCH_SUCCESS';
export const SHOW_FILTER_SEARCH_FAILED = 'SHOW_FILTER_SEARCH_FAILED';

export const REMOVE_JOB_DATA = "REMOVE_JOB_DATA";
export const TOKEN_UPDATED = "TOKEN_UPDATED";
export const TOKEN_UPDATED_FAIL = "TOKEN_UPDATED_FAIL";
export const HRDATA_UPDATED = "TOKEN_UPDATED";
export const HRDATA_UPDATED_FAIL = "TOKEN_UPDATED_FAIL";

var jobId;
var pageNo = 0
var jobseekerId;
var currentLat;
var currentLng;
export const clearPreviousData = () => {
  return (dispatch) => {
dispatch({type: REMOVE_JOB_DATA})
}
}

export const allJobList = () => {
  jobseekerId = localStorage.getItem("jobseekerId");
  pageNo = pageNo + 1
  return (dispatch) => {
    dispatch({ type: SHOW_JOB_LIST })
    helpers.getServerUrl().then(url => {
      var url = url.serverUrl
      fetch(url + "/jobs/getAllJobs", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          pageNo: pageNo,
          size: 200,
          extraSkip: 0,
          jobseekerId: jobseekerId,
          loginFrom: "Web"
        })
      })
        .then(response => response.json())
        .then(responseData => {
          dispatch({
            type: SHOW_LIST_SUCCESS,
            payload: responseData
          })
        })
        .catch(err => {
          dispatch({
            type: SHOW_LIST_FAILED,
            payload: err
          })
        })
    })
  }
}

export const jobDetailApi = (jobId) => {
  jobseekerId = localStorage.getItem("jobseekerId");
  let jobseekerIdApi = jobseekerId != "" ? jobseekerId : "";
  return (dispatch) => {
    dispatch({ type: SHOW_DETAIL_LOADING })
    helpers.getServerUrl().then(url => {
      var url = url.serverUrl
      fetch(url + "/jobs/getJobById2", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          jobId: jobId,
          jobseekerId: jobseekerIdApi,
          loginFrom: "Web"
        })
      })
        .then(response => response.json())
        .then(responseData => {
          dispatch({
            type: SHOW_JOB_DETAIL,
            payload: responseData
          })
        })
        .catch(err => {
          dispatch({
            type: SHOW_JOB_FAILED,
            payload: err
          })
        })
    })
  }
}

export const getJobByShareId = (stringId) => {
  jobseekerId = localStorage.getItem("jobseekerId");
  return (dispatch) => {
    dispatch({ type: SHOW_DETAIL_LOADING })
    helpers.getServerUrl().then(url => {
      var url = url.serverUrl
      fetch(url + "/jobs/getJobByShareId", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          shareId: stringId,
          jobseekerId:jobseekerId
        })
      })
        .then(response => response.json())
        .then(responseData => {
          localStorage.setItem("creditsAvailable",responseData.creditsAvailable)
          dispatch({
            type: SHOW_JOB_DETAIL,
            payload: responseData
          })
        })
        .catch(err => {
          dispatch({
            type: SHOW_JOB_FAILED,
            payload: err
          })
        })
    })
  }
}


export const getRelatedJobList = () => {
  jobseekerId = localStorage.getItem("jobseekerId");
  jobId = localStorage.getItem("jobId");
  return (dispatch) => {
    dispatch({ type: RELATED_JOB_LOADING })
    helpers.getServerUrl().then(url => {
      var url = url.serverUrl
      fetch(url + "/jobs/jobtitleForcard", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          jobId: jobId,
          jobseekerId: jobseekerId
        })
      })
        .then(response => response.json())
        .then(responseData => {
          dispatch({
            type: SHOW_RELATED_JOBS,
            payload: responseData
          })
        })
        .catch(err => {
          dispatch({
            type: RELATED_JOB_FAIL,
            payload: err
          })
        })
    })
  }
}

export const getReportData = () => {
  return (dispatch) => {
    dispatch({ type: REPORT_DATA_LOAD })
    helpers.getServerUrl().then(url => {
      var url = url.serverUrl
      fetch(url + "/jobs/getReports",
        {
          method: "GET"
        })
        .then(response => response.json())
        .then(responseData => {
          dispatch({
            type: GET_REPORT_DATA,
            payload: responseData
          })
        }).catch(err => {
          dispatch({
            type: REPORT_DATA_FAILED,
            payload: err
          })
        })
    })
  }
}

export const getTopPicks = (body) => {
jobseekerId = localStorage.getItem("jobseekerId");
currentLat = body.latitude;
currentLng = body.longitude;
currentLat=parseFloat(currentLat)
currentLng=parseFloat(currentLng)
  return (dispatch) => {
    dispatch({ type: SHOW_TOP_PICKS })
    helpers.getServerUrl().then(url => {
      var url = url.serverUrl
    fetch(url + "/jobseeker/getTopPickedJobs", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        jobseekerId: jobseekerId,
        latitude: currentLat,
        longitude: currentLng
      })
    })
      .then(response => response.json())
      .then(responseData => {
        dispatch({
          type: SHOW_TOP_PICKS_SUCCESS,
          payload: responseData
        })
      })
      .catch(err => {
        dispatch({
          type: SHOW_TOP_PICKS_FAILED,
          payload: err
        })
      })
    })
  }
}

export const getCities = () => {
  return (dispatch) => {
    dispatch({ type: GET_CITIES })
    helpers.getServerUrl().then(url => {
      var url = url.serverUrl
    fetch(url + "/jobseeker/getCities", {
      method: "GET",
    })
      .then(response => response.json())
      .then(responseData => {
       dispatch({
          type: GET_CITIES_SUCCESS,
          payload: responseData
        })
      })
      .catch(err => {
        dispatch({
          type: GET_CITIES_FAILED,
          payload: err
        })
      })
    })
  }
}

export const getFiltersWithIcons = () => {
   return (dispatch) => {
    dispatch({ type: SHOW_FILTER_ICON })
    helpers.getServerUrl().then(url => {
      var url = url.serverUrl
    fetch(url + "/jobs/getAllJobsTypes", {
      method: "GET",
    })
      .then(response => response.json())
      .then(responseData => {
        dispatch({
          type: SHOW_FILTER_ICON_SUCCESS,
          payload: responseData
        })
      })
      .catch(err => {
        dispatch({
          type: SHOW_FILTER_ICON_FAILED,
          payload: err
        })
      })
    })
  }
}

export const getFilterSearch = (body) => {
   return (dispatch) => {
    dispatch({ type: SHOW_FILTER_SEARCH })
    helpers.getServerUrl().then(url => {
      var url = url.serverUrl
    fetch(url + "/jobs/filterJobsCandidatePanel", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then(responseData => {
        dispatch({
          type: SHOW_FILTER_SEARCH_SUCCESS,
          payload: responseData
        })
      })
      .catch(err => {
        dispatch({
          type: SHOW_FILTER_SEARCH_FAILED,
          payload: err
        })
      })
    })
  }
}
export const updateTokenApi = () => {
 let jobseekerId = localStorage.getItem("jobseekerId");
 let tokenId = localStorage.getItem("tokenId");
  return (dispatch) => {
   helpers.getServerUrl().then(url =>   {
    var url = url.serverUrl
   helpers.getServerUrl().then(url => {
     var url = url.serverUrl
   fetch(url + "/jobseeker/savingTokenId", {
     method: "POST",
     headers: {
       "Content-Type": "application/json"
     },
     body: JSON.stringify({
      jobseekerId: jobseekerId,
      tokenId: tokenId,
      loginFrom: "Web"
    })
   })
     .then(response => response.json())
     .then(responseData => {   
       dispatch({
         type: TOKEN_UPDATED,
         payload : responseData
       })
     })
     .catch(err => {
       dispatch({
         type: TOKEN_UPDATED_FAIL,
         payload: err
       })
     })
   })
  })
 }
}

export const updateHrApi = () => {
  let jobseekerId = localStorage.getItem("jobseekerId");
  let companyId = localStorage.getItem("companyId");
  let jobId= localStorage.getItem("jobId");
   return (dispatch) => {
    helpers.getServerUrl().then(url =>   {
     var url = url.serverUrl
    helpers.getServerUrl().then(url => {
      var url = url.serverUrl
    fetch(url + "/jobseeker/getHr", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
       jobseekerId: jobseekerId,
       companyId: companyId,
       jobId:jobId
     })
    })
      .then(response => response.json())
      .then(responseData => {   
        dispatch({
          type: HRDATA_UPDATED,
          payload : responseData
        })
      })
      .catch(err => {
        dispatch({
          type: HRDATA_UPDATED_FAIL,
          payload: err
        })
      })
    })
   })
  }
 }



