import React from 'react';
import helpers from '../config';
import "../_style/cardComponent.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { Link, NavLink, withRouter} from "react-router-dom";
var moment = require("moment");
var url, imageUrl, jobseekerId;
 class cardComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    async componentDidMount() {
        await helpers.getServerUrl().then(data => {
            url = data.serverUrl;
            imageUrl = data.imageUrl
        });
        jobseekerId = localStorage.getItem("jobseekerId");
    }

    handleClick(i) {
        this.props.history.push({
            pathname: '/jobs',
            search:  "/" + this.props.jobLink,
            state: { jobId:this.props.jobId }
          })
    }
    render() {
        return (
            <div className= {this.props.status=="Active" ? "card shadow-sm rounded1 mb-2 pt-md-2 pb-md-2 pt-1 pb-1" : "cardExpiry shadow-sm rounded1 mb-2 pt-md-2 pb-md-2 pt-1 pb-1" } >
                <div className="col-md-12 pointer ">
                    <div className="row">
                        <div  onClick = {(i) => this.handleClick(i)} className="col-md-3 mt-md-2 mt-0 col-4">
                            <div className="row">
                                <img
                                    src={ this.props.companyImage }
                                    className="company-image ml-md-2 col-md-12"
                                    alt="Company Image"
                                />
                            </div>
                        </div>
                        <div onClick = {(i) => this.handleClick(i)} className="text-left pt-1 col-md-6 col-sm-6 mt-1 col-5 col-lg-6">
                            <div className="row">
                                <h6 className="mb-0 pt-1 text-secondary font-weight-normal textLimit">
                                    {this.props.companyName}
                                </h6>
                            </div>
                            <div className="row">
                                <h5 className="text-dark mt-1 textLimit jobTitle mb-1 pb-1">
                                    {this.props.jobTitle}
                                </h5>
                            </div>
                            <div className="row">
                                <h6 className="text-secondary mobileTextAlign ">
                                {this.props.jobType}{" "}{"| "} {" "}
                                    <i className="fas fa-rupee-sign text-secondary"></i>{" "}
                                    {this.props.salary}{" "}
                                    <span>&nbsp;</span>
                                    <i className="fas fa-calendar-alt"></i>{" "}
                                    {this.props.createdDate}
                                </h6>
                            </div>
                        </div>
                        <div className= {this.props.mapState ? "col-md-3 mapCards col-sm-3 text-right  col-3 mt-2 mt-md-1 col-lg-3": "col-md-3 col-sm-3 text-right col-3 mt-2 mt-md-1 col-lg-3"} >
                            <h6 className="text-success-gradient">
                                <div onClick={this.props.callSaved} className={this.props.fakeIcon && this.props.jobId == this.props.fakeId?  "pointer d-none" : "pointer d-block" }>
                                    {this.props.isSaved ? (
                                    <FontAwesomeIcon icon={['fas', 'bookmark']} size="1x" />
                                      ) : (
                                    <FontAwesomeIcon icon={['far', 'bookmark']} size="1x" />
                                        )}
                                </div>
                                <div onClick={this.props.callSaved} className={this.props.fakeIcon && this.props.jobId == this.props.fakeId? "pointer d-block" : "pointer d-none"}>
                                    {this.props.isSaved ? (
                                    <FontAwesomeIcon icon={['far', 'bookmark']} size="1x" />

                                      ) : (
                                    <FontAwesomeIcon icon={['fas', 'bookmark']} size="1x" />

                                        )}
                                </div>
                            </h6>
                            <div onClick = {(i) => this.handleClick(i)} className= {this.props.status=="Active" ? "badge badge-success rounded-pill mt-md-4 mt-3 pt-1 pb-1 pl-2 pr-2" : "badge textExpiry rounded-pill mt-md-4 mt-3 pt-1 pb-1 pl-2 pr-2" }>
                                {this.props.isShortlisted != null &&
                                    this.props.isShortlisted != "" &&
                                    this.props.isShortlisted != undefined &&
                                    this.props.status=="Active" ? (
                                        <span className="badgeFontSize text-uppercase">{this.props.isShortlisted}</span>
                                    )
                                    : 
                                    (
                                   this.props.status=="Closed" || this.props.status=="Paused" ? 
                                    <span className="badgeFontSize text-uppercase text-white">{this.props.status}</span>
                                   : <span className="badgeFontSize text-uppercase text-white">APPLY</span>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
        )
    }
}
export default withRouter(cardComponent);