import React, { Component } from "react";
import { Link, Route } from "react-router-dom";
import Footer from "../components/footer";
import Webheader from "../components/webheader";
import Strings from "../utilities/stringlanguage";
import Images from "../Images";
import LoadingOverlay from 'react-loading-overlay';
import { connect } from "react-redux";
import { faq } from "../actions/candidateFaqAction";
import helpers from "../config";
var jobseekerId;
var url,imageUrl;
var selectedLang;
 class CandidateFaq extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          isLoading: true,
            faqDataList:[],
        }
    }
    async componentDidMount() {
      let apiUrl =  await helpers.getServerUrl()
      url = apiUrl.serverUrl
      imageUrl = apiUrl.imageUrl
      this.props.faq(1);
          selectedLang = localStorage.getItem("selectedLang")
          if(selectedLang != null && selectedLang != undefined && selectedLang != "")
          {
            Strings.setLanguage(selectedLang);
          }
     
        jobseekerId = localStorage.getItem("jobseekerId")
    }
 

    render() {
      let { candidateData, faqData, candidateDataLoading, candidateDataError } = this.props;
      if(candidateData != "" && candidateData != undefined)
      {
        this.state.isLoading = false;
      }
      if(candidateData.length > 0){
        this.state.faqDataList = candidateData;
      }
      if(faqData.title != undefined){
      document.title = faqData.title;
      document.getElementsByTagName("META")[2].content = faqData.description;
      document.getElementsByTagName("META")[3].content = faqData.keywords
      }
        return (
          <LoadingOverlay
          active={this.state.isLoading}
          spinner={
            <div className="loading-search">
              <center className=" bg-dark1 rounded mt-5 col-md-6 col-sm-6 p-2 col-12">
                <h4 className="loadingText pt-1  mb-1 d-sm-block d-md-block">
                  {Strings.loadingFaqs}
              </h4>
                <h6 className="text-white pt-1 pb-1 d-block d-sm-none d-md-none"></h6>
                <img
                  src={Images.loaderWhite}
                  alt="img"
                  className="pb-2"
                  width="30"
                />
              </center>
            </div>
          }
        >
            <div>
                <div className="container col-md-6 No-Pading col-lg-6 col-sm-6 col-12">
                <Webheader pageName={Strings.faQCaps} backLink="/setting" settingState={true}/>
                    <section id="faq" className="p-0 mt-2">
                        <ul id="faq-list" className="wow fadeInUp col-11 ml-3">
                        {candidateData.map((item,index) => (
                            <li key={item.qId}>
                             <a data-toggle="collapse" href={"#"+item.qId} className="collapsed text-decoration-none"> {item.question}<i className="ion-android-remove"></i></a>
                             <div id={item.qId} className="collapse" data-parent="#faq-list">
                              <p>
                              {item.answer}
                            </p>
                              </div>
                            </li>
                        ))} 
                        </ul>
                    </section>
                </div>
                <div className="container-fluid bg-light col-md-6 col-lg-6 col-sm-6 col-12 No-Pading">
                    <Route component={Footer} className="d-none d-md-block d-lg-block d-sm-block d-xl-block" />
                </div>
            </div>
            </LoadingOverlay>
        );
    }
}
const mapStateToProps = state => {
  return {
    candidateData: state.candidateFaqR.candidateData,
    faqData: state.candidateFaqR.faqData,
    candidateDataLoading: state.candidateFaqR.candidateDataLoading,
    candidateDataError: state.candidateFaqR.candidateDataError,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    faq: () => dispatch(faq())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CandidateFaq);
