import React, {Component} from 'react';
import '../ionicons/css/ionicons.min.css';
import {Link} from 'react-router-dom';
import CheeseburgerMenu from "cheeseburger-menu";
import HamburgerMenu from 'react-hamburger-menu';
import Images from '../Images';
import Footer from '../components/faqFooter';
var jobseekerName = "";
class  PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };
  }
  componentDidMount() {
    // eslint-disable-next-line no-undef
    $(document).ready(function(){
      // eslint-disable-next-line no-undef
      $(this).scrollTop(0);
   });
  }
  openMenu() {
    this.setState({ menuOpen: true })
  }

  closeMenu() {
    this.setState({ menuOpen: false })
  }
  render() {
    this.state.isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
   jobseekerName = localStorage.getItem("jobseekerName")
    return(
      <div>
       <nav id="header" class="navbar  navbar-expand-lg bg-dark">
        <div className="container">
        <a class="navbar-brand" href="/">
        <div id="logo" className="pull-left">
            <Link  to="#intro"><img src={Images.logo} width="225" alt="Header Logo" title="" /></Link>
        </div>
        </a>
        <div className="d-block d-md-none">
            <CheeseburgerMenu
              isOpen={this.state.menuOpen}
              closeCallback={this.closeMenu.bind(this)} backgroundColor={'black'}>
              <ul className="navbar-nav ml-2 pb-2" closeCallback={this.closeMenu.bind(this)}>
                <li className="nav-item emp-text active p-2 mr-md-2 border-bottom">
                  <a className="nav-link text-white myText" href="/"><i className="fas fa-home"></i> HOME</a>
                </li>
                <li className="nav-item emp-text p-2 mr-md-2 border-bottom">
                  <a className="nav-link text-white myText" href="/blog"><i className="fas fa-rss"></i> BLOG</a>
                </li>
                <li className="nav-item emp-text p-2 mr-md-2 border-bottom">
                  <a className="nav-link text-white myText" href="https://jobam.co/joblist"><i className="fas fa-search"></i> SEARCH A JOB</a>
                </li>
                {this.state.isUserLoggedIn == "true" && jobseekerName != null ?
                  <li className="nav-item emp-text p-2 mr-md-2 border-bottom">
                    <a className="nav-link text-white myText" href="https://jobam.co/profile"><i className="fas fa-user"></i> {" "}{jobseekerName}</a>
                  </li> : <li className="nav-item emp-text p-2 mr-md-2 border-bottom">
                    <a className="nav-link text-white myText" href="https://jobam.co/register"><i className="fas fa-user"></i> SIGN IN</a>
                  </li>}
                <li className="nav-item empButton ml-md-2 mt-3 mt-md-0">
                  <a href="https://jobam.co/employerzone/login.php#signin" target="_blank"
                    className="nav-link text-white text-decoration-none pl-3">Employers | Post a Job</a>
                </li>
                <li className="nav-item empButton ml-md-2 mt-3 mt-md-0">
                  <a style={{marginLeft: '10px'}} href="https://play.google.com/store/apps/details?id=dataflakes.jobs.jhatpatjobs.jobsearch" target="_blank"
                    className="nav-link text-white text-decoration-none pl-3"><i style={{marginRight: '11px'}} className="fas fa-download"></i>Download App</a>
                </li>
              </ul>
            </CheeseburgerMenu>
            
            <HamburgerMenu
              isOpen={this.state.menuOpen}
              menuClicked={this.openMenu.bind(this)}
              width={28}
              height={22}
              strokeWidth={3}
              rotate={0}
              color='white'
              borderRadius={0}
              animationDuration={0.5}
              className="new-menu-icon"
            />
            </div>
        <div className="justify-content-end d-none d-md-block">
        <ul class="navbar-nav ml-2">
          <li class="nav-item ">
              <a class="nav-link text-white" href="/"><i class="fas fa-home"></i> HOME</a>
          </li>
          <li className="nav-item emp-text mr-0  mr-md-2">
              <a className="nav-link text-white myText" href="https://jobam.co/blog"><i className="fas fa-rss"></i> Blog</a>
          </li>
          <li class="nav-item">
              <a class="nav-link text-white" href="https://jobam.co/joblist"><i class="fas fa-search"></i> SEARCH A JOB</a>
          </li>
          {this.state.isUserLoggedIn == "true" && jobseekerName != null ?
                  <li className="nav-item">
                    <a className="nav-link text-white " href="https://jobam.co/profile"><i className="fas fa-user"></i> {" "}{jobseekerName}</a>
                  </li> : <li className="nav-item ">
                    <a className="nav-link text-white " href="https://jobam.co/register"><i className="fas fa-user"></i> SIGN IN</a>
                  </li>}
          <li class="nav-item empButton ml-md-2 mt-2 mt-md-0 ">
          <a  href="https://jobam.co/employerzone/login.php#signin" target="_blank"
            className="nav-link text-white text-decoration-none pl-3">Employers | Post a Job</a>
          </li>
          <li className="nav-item empButton ml-md-2 mt-3 mt-md-0">
                  <a style={{marginLeft: '10px'}} href="https://play.google.com/store/apps/details?id=dataflakes.jobs.jhatpatjobs.jobsearch" target="_blank"
                    className="nav-link text-white text-decoration-none pl-3"><i style={{marginRight: '11px'}} className="fas fa-download"></i>Download App</a>
                </li>
          </ul>
        </div>
        </div>
        </nav>
        <section id="faq">
          <div className="container pt-5">
            <div className="section-header">
              <h3 className="section-title">Privacy Policy</h3>
             <span className="section-divider"></span>
            </div>
            <ul id="faq-list" className="wow fadeInUp col-11 ml-3">
              <li>
                <a data-toggle="collapse" className="collapsed text-decoration-none" href="#faq1">INTRODUCTION <i className="ion-android-remove"></i></a> 
                <div id="faq1" className="collapse" data-parent="#faq-list">
                  <p>
                    Data Flakes Innovations pvt ltd built the Jobam app as a Free app.
                    This SERVICE is provided by Data Flakes at no cost and is intended for use as is.This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.
                    If you choose to Interact with our Service, then you must agree to the collection and use of information in relation to this policy.
                  </p>
                </div>
              </li>
              <li>
                <a data-toggle="collapse" href="#faq2" className="collapsed text-decoration-none">IDENTIFICATION INFORMATION (PERSONAL) <i className="ion-android-remove"></i></a> 
                <div id="faq2" className="collapse" data-parent="#faq-list">
                  <p>
                    When you interact with our services, we collect the information that you choose to share with us.
                    The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy. It has a map which shows the job. A job seeker can apply for job using this App. Job seekers can also upload their Video resume.
                    The information that we request will be retained on your device and is not collected by us in anyway.
                  </p>
                </div>
              </li>
              <li>
                <a data-toggle="collapse" href="#faq3" className="collapsed text-decoration-none">IDENTIFICATION INFORMATION (NON-PERSONAL)<i className="ion-android-remove"></i></a> 
                <div id="faq3" className="collapse" data-parent="#faq-list">
                  <p>
                    We may collect non-personal identification information about Users whenever they interact with our Site or App. Non-personal identification information may include the browser name, the type of computer and technical information about Users means of connection to our Site, such as the operating system and the Internet service providers utilized including IP address and other similar information.
                    The app may use third party code and libraries that use "cookies" to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.
                  </p>
                </div>
              </li>
              <li>
                <a data-toggle="collapse" href="#faq4" className="collapsed text-decoration-none">HOW WE USE COLLECTED INFORMATION?<i className="ion-android-remove"></i></a> 
                <div id="faq4" className="collapse" data-parent="#faq-list">
                  <p>
                    Jobam App collects and uses Users personal information for the following purposes
                  </p>
                </div>
              </li>
              <li>
                <a data-toggle="collapse" href="#faq5" className="collapsed text-decoration-none">PERSONAL INFO GIVEN BY USER <i className="ion-android-remove"></i></a> 
                <div id="faq5" className="collapse" data-parent="#faq-list">
                  <p>
                    We use this information to provide matching job. This information is also shared with employers(registered on Jobam)
                  </p>
                </div>
              </li>
              <li>
                <a data-toggle="collapse" href="#faq6" className="collapsed text-decoration-none">USER LOCATION<i className="ion-android-remove"></i></a> 
                <div id="faq6" className="collapse" data-parent="#faq-list">
                  <p>
                    We use user's Location Information to provide Jobs in nearest location
                  </p>
                </div>
              </li>
              <li>
                <a data-toggle="collapse" href="#faq7" className="collapsed text-decoration-none">CALL LOG<i className="ion-android-remove"></i></a> 
                <div id="faq7" className="collapse" data-parent="#faq-list">
                  <p>
                    Job Application is made via phone call, So Call log is used to identify weather application was successful or not for the Job and based on the connected call we notify the address of the job to user.When you use our services, we collect information about which of those services you've used and how you've used them. We might know, for instance, that you saw a particular job, called a particular job, etc.
                  </p>
                </div>
              </li>
              <li>
                <a data-toggle="collapse" href="#faq8" className="collapsed text-decoration-none">HOW WE PROTECT YOUR INFORMATION?<i className="ion-android-remove"></i></a> 
                <div id="faq8" className="collapse" data-parent="#faq-list">
                  <p>
                    We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our App/Site.
                  </p>
                </div>
              </li>
              <li>
                <a data-toggle="collapse" href="#faq9" className="collapsed text-decoration-none">SHARING YOUR PERSONAL INFORMATION<i className="ion-android-remove"></i></a> 
                <div id="faq9" className="collapse" data-parent="#faq-list">
                  <p>
                    We do not sell, trade, or rent Users personal identification information to any 3rd party. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates and advertisers for the purposes outlined above.
                  </p>
                </div>
              </li>
              <li>
                <a data-toggle="collapse" href="#faq10" className="collapsed text-decoration-none">THIRD PARTY WEBSITES<i className="ion-android-remove"></i></a> 
                <div id="faq10" className="collapse" data-parent="#faq-list">
                  <p>
                    Users may find advertising or other content on our Site that link to the sites and services of our partners, institutes, advertisers, sponsors and other third parties. We do not control the content or links that appear on these sites and are not responsible for the practices employed by websites linked to or from our Site. In addition, these sites or services, including their content and links, may be constantly changing. These sites and services may have their own privacy policies and customer service policies. Browsing and interaction on any other website, including websites which have a link to our Site, is subject to that website's own terms and policies.
                  </p>
                </div>
              </li>
              <li>
                <a data-toggle="collapse" href="#faq11" className="collapsed text-decoration-none">ADVERTISING<i className="ion-android-remove"></i></a> 
                <div id="faq11" className="collapse" data-parent="#faq-list">
                  <p>
                    Advertisements appearing on our site may be delivered to Users by advertising partners, who may set cookies. These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile non-personal identification information about you or others who use your computer. This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you. This privacy policy does not cover the use of cookies by any advertisers.
                  </p>
                </div>
              </li>
              <li>
                <a data-toggle="collapse" href="#faq12" className="collapsed text-decoration-none">GOOGLE ADSENSE<i className="ion-android-remove"></i></a> 
                <div id="faq12" className="collapse" data-parent="#faq-list">
                  <p>
                    Some of the ads may be served by Google. Google's use of the DART cookie enables it to serve ads to Users based on their visit to our Site and other sites on the Internet. DART uses "non personally identifiable information" and does NOT track personal information about the user, such as their name, email address, physical address, etc. User may opt out of the use of the DART cookie by visiting the Google ad and content network privacy policy.
                  </p>
                </div>
              </li>
              <li>
                <a data-toggle="collapse" href="#faq13" className="collapsed text-decoration-none">CHANGES TO THIS PRIVACY POLICY<i className="ion-android-remove"></i></a> 
                <div id="faq13" className="collapse" data-parent="#faq-list">
                  <p>
                    jobam.co has the discretion to update this privacy policy at any time. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.
                  </p>
                </div>
              </li>
              <li>
                <a data-toggle="collapse" href="#faq14" className="collapsed text-decoration-none">YOUR ACCEPTANCE OF THESE TERMS<i className="ion-android-remove"></i></a> 
                <div id="faq14" className="collapse" data-parent="#faq-list">
                  <p>
                    By using this Site, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our Site. Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes.

                  </p>
                </div>
              </li>
              <li>
                <a data-toggle="collapse" href="#faq15" className="collapsed text-decoration-none">CONTACTING US<i className="ion-android-remove"></i></a> 
                <div id="faq15" className="collapse" data-parent="#faq-list">
                  <p>
                    If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this site, please contact us at: contact@jobam.co

                  </p>
                </div>
              </li>
            </ul>

          </div>
        </section>
        <Footer/>
      </div>
    );
  }
}
export default PrivacyPolicy;
