import * as actionTypes from '../actions/searchJobAction';

const initialState = {
    recentPopularData: [],
    recentPopularLoading: false,
    recentPopularError: null,
    searchData : [],
    searchDataLoading : false,
    searchDataError : null,
   
};

const reducer = (state = initialState, action) => {
   // console.log("company profile REDUCER CALLED =======" + JSON.stringify(action.payload))
    switch (action.type) {
        case actionTypes.SHOW_RECENT_POPULAR_SEARCH:
            return {
                ...state,
                recentPopularLoading: true,
                recentPopularError: null
            };

        case actionTypes.SHOW_RECENT_POPULAR_SUCCESS:
            return {
                ...state,
               
                recentPopularData:action.payload,
                recentPopularError: null,
                recentPopularLoading: false,
            };

        case actionTypes.SHOW_RECENT_POPULAR_FAILED:
            return {
                ...state,
                recentPopularLoading: false,
                recentPopularData: [],
                 recentPopularError: action.payload
            };
            case actionTypes.SHOW_SEARCH_DATA:
                return {
                    ...state,
                    searchDataLoading: true,
                    searchDataError: null
                };
    
            case actionTypes.SHOW_SEARCH_DATA_SUCCESS:
                return {
                    ...state,
                   
                    searchData:action.payload,
                    searchDataError: null,
                    searchDataLoading: false,
                };
    
            case actionTypes.SHOW_SEARCH_DATA_FAILED:
                return {
                    ...state,
                    searchDataLoading: false,
                    searchData: [],
                    searchDataError: action.payload
                };
           default:
            return state;
    }
};

export default reducer;