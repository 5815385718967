// const url = "https://www.jhatpatjobs.io:4142";
// export default url;
var url ={};
// const proxyurl = "https://cors-anywhere.herokuapp.com/";
const helpers = {
    getServerUrl: function() {
      url ={}
      return fetch("https://jobam.co:4141/getServerUrlRouter/getMethodServerDetails?serverId=jjJobseekerPanel", {
        method: "POST",
        // mode: 'no-cors',
        headers: {
          Accept: "application/json",
          // "Access-Control-Allow-Origin": "*",
          // 'Access-Control-Allow-Credentials':'true',
          "Content-Type": "application/json"
        } 
      })
        .then(response => response.json())
        .then(responseData => {
          if (responseData != null) {
            url={
              imageUrl:responseData.response.imageUrl,
              serverUrl: responseData.response.serverUrl,
              chatUrl: responseData.response.chatUrl
            }
          }
          return url;
        });
    }
}
export default helpers;
