import React, { Component } from "react";
import { Link, Route } from "react-router-dom";
import helpers from "../config";
import Strings from "../utilities/stringlanguage";
import Webheader from "../components/webheader";
import Images from '../Images';
import { connect } from "react-redux";
import { getReview } from "../actions/companyFeedbackAction"; 
import LoadingOverlay from 'react-loading-overlay';
import Footer from "../components/footer";
import "../_style/companyFeedback.css";
import ModalDiv from "../components/modal";

var jobseekerId;
var url;
var imageUrl;
var selectedLang;
 class CompanyFeedback extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        }
    }
    async componentDidMount() {
      let apiUrl =  await helpers.getServerUrl()
      url = apiUrl.serverUrl
      imageUrl = apiUrl.imageUrl
        selectedLang = localStorage.getItem("selectedLang")
		if(selectedLang != null && selectedLang != undefined && selectedLang != "")
		{
		  Strings.setLanguage(selectedLang);
		}
        jobseekerId = localStorage.getItem("jobseekerId");
        this.props.getReview();

    }
    
    render() {
      let { getFeedbackData, getFeedbackLoading, getFeedbackError } = this.props;
        return (
            <LoadingOverlay
            active={getFeedbackLoading}
            spinner={<div className="loading-feedback">
              <center className=" bg-dark2 rounded mt-5 col-md-6 col-sm-6 p-2 col-12">
        <h4 className="loadingText pt-1 mb-1 d-sm-block d-md-block">{Strings.loadingCompanyFeedback}</h4>
                <h6 className="text-white pt-1 pb-1 d-block d-sm-none d-md-none"></h6>
                <img src={Images.loaderWhite} alt="img" className="pb-2" width="30" />
              </center>
            </div>}
          >
            <div>
                <div className="container col-md-6 No-Pading col-lg-6 col-sm-6 col-12">
                    <Webheader settingState={true} pageName={Strings.feedbackFromCompanies} backLink="/setting"/>
                    <div className="container-fluid pt-5">
                        {getFeedbackData !== null &&
                            getFeedbackData !== "" &&
                            getFeedbackData!== undefined && getFeedbackData.length > 0 ? getFeedbackData.map(item => (

                                <div className="card mb-2 rounded">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-3 col-3 pt-4">
                                                <img src={imageUrl + item.companyImage} alt="Company Image" className="col-md-12" />
                                            </div>
                                            <div className="col-md-9 col-9">
                                                <h5 className="card-title"><i className="fas fa-star text-success"></i> <i className="fas fa-star text-success"></i> <i className="fas fa-star text-success"></i> <i className="far fa-star text-success"></i> <i className="far fa-star text-success"></i></h5>
                                                <h5 className="card-title comName">{item.companyName}</h5>
                                                <p className="card-text text-muted">{item.comments}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )) :
                            <ModalDiv getImage={Images.noRecord} textMsg={Strings.NoFeedbackFound}/>
                        }
                    </div>
                </div>
                <div className="container-fluid bg-light col-md-6 col-lg-6 col-sm-6 col-12 No-Pading">
          <Route component={Footer} className="d-none d-md-block d-lg-block d-sm-block d-xl-block" />
        </div>
            </div>
            </LoadingOverlay>
        );
    }
}

const mapStateToProps = state => {
    return {
        getFeedbackData: state.companyFeedbackReducer.getFeedbackData,
        getFeedbackLoading: state.companyFeedbackReducer.getFeedbackLoading,
        getFeedbackError: state.companyFeedbackReducer.getFeedbackError,
    };
  };
  const mapDispatchToProps = dispatch => {
    return {
        getReview: () => dispatch(getReview())
    };
  };
  export default connect(mapStateToProps, mapDispatchToProps)(CompanyFeedback);