import React, { Component } from "react";
import $ from 'jquery';
import helpers from "../config";
import 'react-datepicker/dist/react-datepicker.css';
import Footer from "../components/footer";
import { Modal } from "react-bootstrap";
import Webheader from "../components/webheader";
import { Route } from 'react-router-dom';
import Strings from "../utilities/stringlanguage";
import ModernDatepicker from 'react-modern-datepicker';
import DatePicker from 'react-date-picker';
import '../_style/updateprofile.css';
import { updateProfileApi } from "../actions/profileAction";
import { connect } from "react-redux";
import ModalDiv from "../components/modal";
var moment = require("moment");
var jobseekerName;
var jobseekerId;
var otp;
var url;
var timerOn = true;
var imageUrl;
var selectedLang;
var currentLat;
var currentLng;
var linkedinaddUrl;
var facebookaddUrl;
var instagramaddUrl
var body = {
  jobseekerName: "",
  jobseekerId: "",
  dob: "",
  gender: "",
  linkedinaddUrl: "",
  facebookaddUrl: "",
  instagramaddUrl: ""
}
class updateProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nameText: "",
      mobileNumber: "",
      disableNum: false,
      validNumber: false,
      otpView: false,
      timerOn: true,
      enterNumberShowModal: false,
      verifyNumber: false,
      otpKey: "",
      refCode: "",
      startDate: "",
      dobDate: "",
      skipOtp:"",
      otpSent: false,
      changeNumState: false,
      verifiedNumber: "",
      genderData: [
        { value: "Male", isClicked: false, icon: "fas fa-male" },
        {
          value: "Female",
          isClicked: false,
          icon: "fas fa-female"
        }
      ],
    };

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    let apiURL = await helpers.getServerUrl();
    url = apiURL.serverUrl;
    imageUrl = apiURL.imageUrl
    selectedLang = localStorage.getItem("selectedLang")
    if (selectedLang != null && selectedLang != undefined && selectedLang != "") {
      Strings.setLanguage(selectedLang);
    }
    jobseekerName = localStorage.getItem("jobseekerName");
    this.setState({ nameText: jobseekerName });
    jobseekerId = localStorage.getItem("jobseekerId");
    body.jobseekerId = jobseekerId;
    this.getMyLocation();
    this.appSetting()
    document.removeEventListener('mousedown', this.handleClickOutside);
    $(document).click(function (e) {
      if ($(e.target).is('#enterno')) {
        $('#enterno').fadeOut(500);
      }
      if ($(e.target).is('#enterMobile')) {
        $('#enterno').fadeIn(500);
      }
    })
  }

  componentWillUnmount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  appSetting = async () => {
    try {
      let response = await fetch(url + "/admin/getAppSetDetails", {
        method: "GET",
        headers: {
          "content-Type": "application/json"
        }
      })
      response = await response.json()
     let skipOtp = response.skipOtp
    this.setState({ skipOtp: skipOtp });
    } catch (err) {
    }
  }
  handleClickOutside() {
    this.setState({ enterNumberShowModal: !this.state.enterNumberShowModal });
  }
  getMyLocation() {
    navigator.permissions.query({
      name: 'geolocation'
    }).then(function (result) {
      if (result.state == 'granted') {
      } else if (result.state == 'prompt') {
      } else if (result.state == 'denied') {
      }
      result.onchange = function () {
      }
    });
    navigator.geolocation.getCurrentPosition(function (position) {
      currentLat = position.coords.latitude.toFixed(4);
       currentLng = position.coords.longitude.toFixed(4);
    });
  }

  handleName = e => {
    this.setState({ nameText: e.target.value });
  };

  handlePhoneNo = e => {
    var firstDigit = e.target.value.match(/\d/);
    if (
      (e.target.value !== null)
    ) {
      if (firstDigit == 0) {
        this.setState({ mobileLength: e.target.value.toString().length });
      }
      if ((firstDigit == 9 || firstDigit == 8 || firstDigit == 7 || firstDigit == 6)) {
        this.setState({ mobileLength: e.target.value.toString().length });
      }
      this.setState({ mobileNumber: e.target.value });

    } else {
      this.setState({ mobileNumber: this.state.mno });
      e.target.value = "";
    }

  };

  showModal = e => {
    this.setState({
      show: true
    });
  };
  hideModal = e => {
    this.setState({ show: false });
  };
  showModalComplete = e => {
    this.setState({ complete: true });
  }
  hideModalComplete = e => {
    this.setState({ complete: false });
  }
  async sendOtp() {
    this.state.timerOn = true;
    this.timer(60);

    try {
      var firstDigit = this.state.mobileNumber.match(/\d/);
      this.state.mobileLength = this.state.mobileNumber.length

      if (firstDigit == 0 && this.state.mobileLength == 11) {
        this.state.validNumber = true
      }
      else if ((firstDigit == 9 || firstDigit == 8 || firstDigit == 7 || firstDigit == 6) && this.state.mobileLength == 10) {
        this.state.validNumber = true

      } else {
        this.setState({ validNumber: false })
      }
      if (this.state.validNumber == true) {

        if (this.state.mobileNumber == this.state.mno) {
          this.showModal();
          this.setState({ msg: Strings.alreadyVerifedNum });
        }
        else {
          this.setState({ otpView: true, disableNum: true, changeNumState: true, })
          let responseData = await fetch(url + "/jobseeker/sendOtpMobileNew", {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              phone: this.state.mobileNumber,
              jobseekerId: jobseekerId
            })
          })
          responseData = responseData.json();
          if (responseData[0].success && responseData[0].OTPsend) {
          }
        }

      } else {
        this.showModal();
        this.setState({ msg: Strings.PleaseEnterValidNumber });
      }
    } catch (err) {
    }
  }

  referralCode(e) {
    this.setState({ refCode: e.target.value });
  }

  applyCode() {
    if (
      this.state.refCode != null &&
      this.state.refCode != "" &&
      this.state.refCode != undefined
    ) {
      let referralCode = this.state.refCode.trim();
      if (referralCode.length > 0) {
        this.checkReferralCode(referralCode);
      } else {
        this.showModal();
        this.setState({ msg: "No referral code found", refCodeErr: true });
      }
    } else {
      this.showModal();
      this.setState({ msg: "No referral code found", refCodeErr: true });
    }
  }

  async checkReferralCode(referralCode) {
    try {
      let response = await fetch(url + "/jobseeker/applyReferralCode", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          jobseekerId: jobseekerId,
          referralCode: referralCode,
        })
      })
      let responseData = await response.json();

      if (responseData.success) {

        this.showModal();
        this.setState({ msg: responseData.msg, refCodeErr: true, invalidCode: false });
      } else {
        this.showModal();
        this.setState({ msg: responseData.msg, refCodeErr: true, invalidCode: false });
      }
    } catch (err) {
    }
  }

  handleOtp = e => {
    this.setState({
      otpKey: e.target.value
    });

  }
  async skipOtp() {
    try {
      let response = await fetch(url + "/jobseeker/validateMobileOTP", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          mobileVeriOtp: this.state.otpKey,
          phone: this.state.mobileNumber,
          jobseekerId: jobseekerId
        })
      })
      let responseData = await response.json();
      if (responseData[0].success) {
        this.setState({ verifyNumber: false, enterNumberShowModal: false, otpVerified: true, verifiedNumber: this.state.mobileNumber, msgcomplete: Strings.OtpverifiedSuccessfully, mno: this.state.mobileNumber, timerOn: false });
        this.showModalComplete();
      }
    }
   catch (err) {
    }
  }
  async verifyOtp() {
    if (this.state.otpKey !== "" && this.state.otpKey !== undefined) {
      try {
        let response = await fetch(url + "/jobseeker/validateMobileOTP", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            mobileVeriOtp: this.state.otpKey,
            phone: this.state.mobileNumber,
            jobseekerId: jobseekerId
          })
        })
        let responseData = await response.json();
        if (responseData[0].success) {
          this.timer(0);
          this.setState({ verifyNumber: false, enterNumberShowModal: false, otpVerified: true, verifiedNumber: this.state.mobileNumber, msgcomplete: Strings.OtpverifiedSuccessfully, mno: this.state.mobileNumber, timerOn: false });
          this.showModalComplete();
        } else {
          this.showModal();
          this.setState({ msg: Strings.invalidOtp, otpKey: "" });
        }
      } catch (err) {
      }
    } else {
      this.showModal();
      this.setState({ msg: "Please enter the OTP", verifyNumber: true });
    }
  }

  genderValue(item,) {
    this.setState({ gender: item });
  }

  validatecharacters(jobseekerName) {
    if (jobseekerName != null) {
      var re = /^[a-zA-Z\s]+$/;
      return re.test(jobseekerName);
    } else {
      return false;
    }
  }
  backClicked = () => {
    this.props.history.goBack()
  }

  validateProfile() {
    let jobseekerName = this.state.nameText.trim();
    if ((jobseekerName != null && jobseekerName != '' && jobseekerName != undefined)
      && (this.state.gender != null && this.state.gender != '' && this.state.gender != undefined) && (this.state.startDate != null && this.state.startDate != '' && this.state.startDate != undefined)
      &&(this.state.mobileNumber != null && this.state.mobileNumber != '' && this.state.mobileNumber != undefined)
    ) {
      if (this.validatecharacters(jobseekerName)) {

        if (this.state.otpVerified) {

          this.updateProfiles();
        }
        else {
          this.showModal();
          this.setState({ msg: Strings.verifyYourPhoneNumber });
        }

      }
      else {
        this.showModal();
        this.setState({ msg: Strings.enterOnlyAlphabets });
      }
    }
    else {
      this.showModal();
      this.setState({ msg: Strings.pleaseFillDetails });
    }
  }
   
  changeNumber() {
    this.setState({ disableNum: false, otpKey: "", mobileNumber: "", otpView: false })
  }

  updateProfiles() {
    let newdate = new Date(this.state.dobDate)
    body.dob = newdate;
    body.jobseekerName = this.state.nameText.trim();
    body.gender = this.state.gender;
    body.latitude=currentLat
    body.longitude=currentLng
    this.props.updateProfileApi(body);
    this.props.history.push('/interest')
  }

  handleChange(date) {
    var today = new Date();
    var birthDate = new Date(date);  // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    this.setState({ age: age_now });
    if (age_now < 18) {
      this.showModal();
      this.setState({ msg: "Please add the valid DOB" });
    } else {
      // let dateArr = date.split("-")
      // dateArr = dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0]
      this.setState({
        startDate: date,
        dobDate: date
      })
    }
  }

  validateBirthDate(date) {
    var today = new Date();
    var birthDate = new Date(date);  // create a date object directly from `dob1` argument

    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }

    this.setState({ age: age_now });
    if (age_now < 18) {
      this.showModal();
      this.setState({ msg: "Please add the valid DOB" });
    } else {
      this.setState({
        date: birthDate,
        startDate: date
      })
    }
  }

  timer(remaining) {
    if (remaining >= 0 && this.state.timerOn) {
      document.getElementById('timer').classList.remove('d-none');
      document.getElementById('resendcode').classList.add('d-none');
      const objThis = this;
      var m = Math.floor(remaining / 60);
      var s = remaining % 60;

      m = m < 10 ? '0' + m : m;
      s = s < 10 ? '0' + s : s;
      document.getElementById('timer').innerHTML = m + ':' + s;
      remaining -= 1;

      setTimeout(function () {
        objThis.timer(remaining);
      }, 1000);
      return;
    }
    if (!this.state.timerOn) {
      // Do validate stuff here
      return;
    }

    // Do timeout stuff here
    // alert("time out");
    if (this.state.otpSent === false) {
      document.getElementById('timer').classList.add('d-none');
      document.getElementById('resendcode').classList.remove('d-none');
    }
  }


  condition() {
    this.sendOtp();
    var firstDigit = this.state.mobileNumber.match(/\d/);
    if (this.state.mobileNumber == this.state.mno || (this.state.mobileNumber == "") ||
      (firstDigit == 0 && this.state.mobileLength !== 11) ||
      ((firstDigit == 9 || firstDigit == 8 || firstDigit == 7 || firstDigit == 6) && this.state.mobileLength !== 10)) {
      this.setState({ enterNumberShowModal: false })
    } 
    else {
      if(this.state.skipOtp){
        this.setState({ enterNumberShowModal: false})
        this.skipOtp();
      }else{
      this.setState({ enterNumberShowModal: false, verifyNumber: true })
      }
    }
  }

  checkBoxExpLevel(index) {
    this.setState({ expIndex: index })
    let resExpLevel = this.state.genderData.map((value, indx) => {
      if (indx == index) {
        value.isClicked = !value.isClicked;
        if (!value.isClicked) {
          this.setState({ gender: value.value });
        } else {
          this.setState({ gender: value.value });
        }
      } else {
        value.isClicked = false;
      }
      return value;
    });
    this.setState({ genderData: resExpLevel, expVal: this.state.genderData[1].isClicked });
  }

  render() {
    const CustomInput = (props) => {
      return (
        <input
          className="form-control rounded"
          onClick={props.onClick}
          value={moment(this.state.startDate).format(
            "DD MMM YYYY"
          )}
          type="text" format="dd MMM yyyy"
          readOnly={true}
        />
      )
    }
    let d = new Date();
    d.setFullYear(d.getFullYear() - 18);

    let place_holder =
      this.state.date != "" && this.state.date != null
        ? moment(this.state.date).format("DD MMM YYYY")
        : Strings.enterYourDob;

    return (
      <div className="texture">
        <div className="container col-md-6 col-lg-6 col-sm-6 col-12">
          <Webheader pageName={Strings.updateProfile} backLink="/joblist" handleBack={i => this.backClicked} />
          <div className="col-md-12 pt-3">
            <form>
              <div className="form-group">
                <label htmlFor="" className="font-weight-bold">{Strings.fullnameCaps}</label><span className="text-danger">*</span>
                <input
                  onChange={e => this.handleName(e)}
                  type="text"
                  value={this.state.nameText}
                  className="form-control rounded shadow-sm"
                  aria-describedby="textHelp"
                  placeholder={Strings.enteryourname}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="" className="font-weight-bold">{Strings.dateOfBirth}</label><span className="text-danger">*</span><br />

                <DatePicker className="react-date-picker col-md-12 form-control rounded shadow-sm p-3"
                  minDate={new Date(1960, 1, 1)}
                  maxDate={new Date(d)}
                  format={'dd MMM y'}
                  value={this.state.startDate}
                  onChange={date => this.handleChange(date)}
                  placeholder={Strings.enterYourDob}
                />
              </div>
              {/* for mobile number */}
              <div className="form-group mb-3">
                <label htmlFor="" className="font-weight-bold">{Strings.contactNumber}</label><span className="text-danger">*</span>
                <div className="input-group">

                  <input
                    value={this.state.mno}
                    onClick={e => this.setState({ enterNumberShowModal: true })}
                    type="number"
                    id="enterMobile"
                    value={this.state.mno}
                    className="form-control rounded  shadow-sm"
                    aria-describedby="textHelp"
                    placeholder={Strings.EnterYourNumber}
                    required data-toggle="modal" data-target="#phone"
                    readOnly />

                  {/* Enter Number Modal Starts */}
                  <div className={this.state.enterNumberShowModal == true ? "modal enterMobDisplay" :"modal enterMobNotDisplay"} id="enterno">
                    <div className="modal-dialog updateModal">
                      <div className="modal-content rounded contentTop">
                        {/* Modal Header  */}
                        <center className="col-center pt-3">
                          <img src={"../phoneAuth.webp"} height={100} width={100} alt="PhoenAuth" className="rounded-circle mt-2 mb-2 mr-2" />
                        </center>
                        {/* Modal body  */}
                        <div className="modal-body">
                          <center>
                            <p className="text-secondary ml-2 mr-2">{Strings.togetnotifUse}</p>
                            <input
                              value={this.state.mobileNumber}
                              onChange={e => this.handlePhoneNo(e)}
                              type="number" pattern="^\d{4}-\d{3}-\d{4}$"
                              className="form-control rounded col-md-10 shadow-sm"
                              aria-describedby="textHelp"
                              placeholder={Strings.Enterphonenumber}
                              required
                            />
                            <button type="button" className="btn btn-success rounded col-md-10 mt-2"
                              onClick={() => {
                                this.condition()
                              }}
                              data-dismiss="modal"
                            >{Strings.verify}
                            </button>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* modal end */}
                  {/* Enter Number Modal Starts */}
                  <div className={this.state.verifyNumber == true ? "modal enterMobDisplay" :"modal enterMobNotDisplay"} id="verifyno">
                    <div className="modal-dialog">
                      <div className="modal-content rounded contentTop">
                        {/* Modal Header  */}
                        <center className="col-center">
                          <img src={"../otpMobile.webp"} height={80} width={100} alt="Otp" className="rounded-circle mt-2 mb-2 ml-2 mr-2" />
                        </center>
                        {/* Modal body  */}
                        <div className="modal-body">
                          <center>
                            <p className="mt-2 text-secondary ml-2 mr-2">
                              <h5>{Strings.plzentercodeSms}</h5>
                            </p>
                            <input
                              type="number"
                              className="form-control rounded col-md-10"
                              placeholder="Code"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              onChange={e => this.handleOtp(e)}
                            />
                            <button type="button" className="btn btn-success rounded col-md-10 mt-2" onClick={() => this.verifyOtp()}
                              data-dismiss="modal">{Strings.Confirm} </button>

                            <div className="" id="showtime">{Strings.noCode} <span id="timer"></span>
                              <span id="resendcode" className="d-none pointer" onClick={() => {
                                this.condition()
                              }}
                              ><u>{Strings.resendCode}</u>
                              </span>
                            </div>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* modal end */}
                </div>
              </div>
              {/* for mobile number */}
              <div className="form-group">
                <label className="font-weight-bold">{Strings.GenderCaps}</label><span className="text-danger">*</span>
                <br />
                <div className="input-group">
                  <div className="btn-group btn-group-toggle col-md-5 col-12 col-sm-12 " data-toggle="buttons">
                    {this.state.genderData.map((item, index) => (
                      <label className={item.isClicked ? "btn btn-outline-secondary mr-2 rounded-pill form-control active shadow-sm updateProCls" : "btn btn-outline-secondary mr-2 mb-2 rounded-pill form-control shadow-sm updateProCls"}>
                        <div className="text-capitalize">
                          <i className={item.icon}></i>{" "} <span className="pl-2">{item.value}</span>
                        </div>
                        <input
                          // className="mt-2"
                          name="checkmark"
                          checked={localStorage.getItem("experience") == item.value ? true : false}
                          type="radio"
                          onClick={i => this.checkBoxExpLevel(index)}
                        />
                      </label>
                    ))}
                  </div>
                </div>
              </div>
              <label className="font-weight-bold">
                {Strings.haveaRefCode}{" "}
                <i className="fa fa-info-circle text-success" data-toggle="tooltip" data-placement="top" title="Here you have to add the refferal code and you will 3 extra create points"></i>
              </label>
              <div className="row text-center">
                <input
                  type="text"
                  value={this.state.refCode}
                  className="form-control rounded col-md-6 col-sm-6 col-7 mt-2 ml-3 mb-2"
                  placeholder={Strings.enterCode}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  onChange={e => this.referralCode(e)}
                // onChange={(e) => this.referralCode(e.target.value)}
                />
                <div className="col-md-4 col-sm-4 col-4 mt-2 text-left">
                  <button type="button" className="btn btn-success rounded col-md-6 col-sm-6 col-10"
                    data-dismiss="modal" id="basic-addon1" onClick={() => this.applyCode()}>{Strings.apply}</button>
                </div>
              </div>
              <center>
                <p className="font-weight-bold m-3">{Strings.weNeverShare}</p>
              </center>
              <div onClick={() => this.validateProfile()} type="submit" className="btn btn-success col-md-12 mt-2 rounded">
                {Strings.submit}
              </div>
            </form>
          </div>
        </div>
        <div className="container-fluid bg-light col-md-6 col-lg-6 col-sm-6 col-12 No-Pading">
          <Route component={Footer} className="d-none d-md-block d-lg-block d-sm-block d-xl-block" />
        </div>
        {/* fill all the details popup */}
<ModalDiv modalReact={true} showModal={this.state.show} msg={this.state.msg} crossBtnModal={true} btn1Text={Strings.oK} hideModal={() => this.hideModal()} btn1Click={() => this.hideModal()}/>
{/* complete  */}
<ModalDiv modalReact={true} successModal={true} showModal={this.state.complete} crossBtnModal={true} msg={this.state.msgcomplete} btn1Text={Strings.oK} hideModal={() => this.hideModalComplete()} btn1Click={() => this.hideModalComplete()}/>
    
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateProfileApi: body => dispatch(updateProfileApi(body))
  };
};
export default connect(null, mapDispatchToProps)(updateProfile);