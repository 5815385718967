import React from 'react';
import '../_style/modal.css';
import Images from "../Images";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

class modalClass extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div>
				{this.props.modalReact ? 
				<Modal show={this.props.showModal} onHide={this.props.hideModal} centered>
				<div className="modal-header bg-success borderRadiusModal SaveUnsaveHeader">
				  <div className="col-md-12">
					<div className="row  justify-content-center">
					  <div className="shadow-sm pl-4 pr-4 pt-3 pb-2 mt-2 ml-2 mt-md-3 bg-white rounded-circle">
					  {this.props.successModal ? ( this.props.successModalNew ? <img width="30" src={Images.jjLogo}></img> :
						<i className="fas fa-check text-success-gradient fa-2x"></i>)
						:
						<i className="fas fa-exclamation text-success-gradient fa-2x"></i>
					}
					  </div>  
					</div>
				  </div>
				  {this.props.crossBtnModal
						?         
						<button type="button" className="close text-white mymodal-close"  onClick={this.props.hideModal} data-dismiss="modal">&times;</button>
					:
					null}
				</div>
				<div className="modal-body">
				  <center>
					<div className="mt-4 mb-2">{this.props.msg}</div></center>
				  <div className="tab-content" id="pills-tabContent">
					<div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
				
					{this.props.twoButton?
							(<div className="text-center">
							<button
							  type="submit"
							  className="btn bg-success text-white col-md-5 col-5 buttonRounded border-0"
							  onClick={this.props.btnFirstClick}
							  data-dismiss="modal-header"
							>
							  {this.props.btnFirst}
							</button>
							<button
							  type="submit"
							  className="btn bg-success ml-2 text-white col-md-5 col-5 buttonRounded border-0"
							  onClick={this.props.btnSecondClick}
							  data-dismiss="modal-header"
							>
							  {this.props.btnSecond}
							</button>
						  </div>)
								:
					  (<div className="col-md-12">
						<div className="row">
						  <div className="col-md-4 col-4 col-sm-4"></div>
						 { this.props.settingData ? 
							<Link to={{ pathname: "/setting", state: { isAvailable: this.state.isAvailable } }} className="btn btn-success col-md-4 mt-2 col-sm-5 col-5 roundedModal" data-dismiss="modal-header"  >
							<div type="submit" onClick={this.props.btn1Click}>{this.props.btn1Text}</div>
						  </Link>
						  :
						  <div type="submit" className="btn btn-success col-md-4 mt-2 col-sm-5 col-5 roundedModal" data-dismiss="modal-header" onClick={this.props.btn1Click} >{this.props.btn1Text}</div>
						 }
						</div>
					  </div>)
                 	}
					</div>
				  </div>
				</div>
			  </Modal>
			  :  null}
			  {this.props.modalReact ?null:this.props.singleBtnModal ? (
				<div className="modal fade" id={this.props.modalId}>
				  <div className="modal-dialog modal-dialog-centered modal-sm">
					<div className="modal-content rounded w-100">
					  <div className="modal-header bg-success borderRadiusModal">
						<div className="modal-title circleSetJob">
						  <div className="position-fixed shadow-sm pt-3 pb-2 pl-4 pr-4 bg-white rounded-circle mt-md-4 mt-4 mt-md-3 ml-2">
							{this.props.success ? (
							  <i className="fas fa-check text-success-gradient fa-1x"></i>
							) : (
							  <i className="fas fa-exclamation text-success-gradient fa-2x"></i>
							)}
						  </div>
						</div>
						{this.props.crossBtn
						 ?         
						 <button type="button" className="close text-white" data-dismiss="modal">&times;</button>
						:
						null}
					  </div>
					  <div className="modal-body mt-4">
						<p className="text-center">{this.props.modalText}</p>
						{this.props.twoBtn ? (
						  <div className="text-center">
							<button
							  type="submit"
							  className="btn bg-success text-white col-md-5 col-5 buttonRounded border-0"
							  onClick={this.props.discardFun}
							  data-dismiss="modal"
							>
							  {this.props.discardText}
							</button>
							<button
							  type="submit"
							  className="btn bg-success ml-2 text-white col-md-5 col-5 buttonRounded border-0"
							  onClick={this.props.buttonClick}
							  data-dismiss="modal"
							>
							  {this.props.discardSaveText}
							</button>
						  </div>
						) : (
						  <div className="text-center">
							<button
							  type="submit"
							  data-dismiss="modal"
							  className="btn bg-success text-white col-md-5 col-5 buttonRounded border-0"
							  onClick={this.props.buttonClick}
							>
							  {this.props.btnText1}
							</button>
						  </div>
						)}
					  </div>
					</div>
				  </div>
				</div>
			  ) : (
				<div className="col-md-6 col-12 modal-center">
				  <div className="card rounded">
					<center className="card-header p-4 rounded-top bg-success">
					  <div className="icon-circle shadow-sm position-absolute mt-0">
						<img
						  src={this.props.getImage}
						  height={50}
						  width={60}
						  alt="Circle Image"
						  className="mt-2 mb-2 ml-2 mr-2"
						/>
					  </div>
					</center>
					{this.props.loginPopup ?
					<h5 className="rounded-pills text-center mt-5 text-secondary mb-3 text-capitalize">{this.props.textMsg}</h5>
					:
					<h6 className="rounded-pills text-dark text-center mt-5 p-3 text-decoration-none">{this.props.textMsg}</h6>
					}
				  </div>
				</div>
			  )}

			</div>
		);
	}
}
export default modalClass;
