import helpers from "../config";

export const SHOW_COMPANY_DETAIL ='SHOW_COMPANY_DETAIL';
export const SHOW_COMPANY_DETAIL_SUCCESS ='SHOW_COMPANY_DETAIL_SUCCESS';
export const SHOW_COMPANY_DETAIL_FAILED ='SHOW_COMPANY_DETAIL_FAILED';

export const FOLLOW_COMPANY ='FOLLOW_COMPANY';
export const FOLLOW_COMPANY_SUCCESS ='FOLLOW_COMPANY_SUCCESS';
export const FOLLOW_COMPANY_FAILED ='FOLLOW_COMPANY_FAILED';

export const SEARCH_HISTORY_COMPANY ='SEARCH_HISTORY_COMPANY';
export const SEARCH_HISTORY_COMPANY_SUCCESS ='SEARCH_HISTORY_COMPANY_SUCCESS';
export const SEARCH_HISTORY_COMPANY_FAILED ='SEARCH_HISTORY_COMPANY_FAILED';

export const SHOW_POSTED_JOBS ='SHOW_POSTED_JOBS';
export const SHOW_POSTED_JOBS_SUCCESS ='SHOW_POSTED_JOBS_SUCCESS';
export const SHOW_POSTED_JOBS_FAILED ='SHOW_POSTED_JOBS_FAILED';

export const SHOW_COMPANY_REVIEW ='SHOW_COMPANY_REVIEW';
export const SHOW_COMPANY_REVIEW_SUCCESS ='SHOW_COMPANY_REVIEW_SUCCESS';
export const SHOW_COMPANY_REVIEW_FAILED ='SHOW_COMPANY_REVIEW_FAILED';

 var jobseekerId;  var compShareId, shareId;
var companyId;

 export const getCompanyDetail = () => {
   jobseekerId = localStorage.getItem("jobseekerId");
   companyId = localStorage.getItem("companyId");
   compShareId = localStorage.getItem("compShareId");
  
   return (dispatch) => {
    dispatch({type: SHOW_COMPANY_DETAIL})
    helpers.getServerUrl().then(url =>   {
      var url = url.serverUrl 
     
    fetch(url +"/employer/getCompanyByIdForApp1", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        jobseekerId: jobseekerId,
          companyId: companyId,
          compShareId: compShareId,
          loginFrom: "Web"
      })
    })
      .then(response => response.json())
      .then(responseData => {
       dispatch ({
            type: SHOW_COMPANY_DETAIL_SUCCESS,
            payload: responseData,
           })
      })
      .catch(err => {
        dispatch({
            type: SHOW_COMPANY_DETAIL_FAILED,
            payload: err
        })
    })
    })
 }
    
}


export const followCompanyApi = (companyId) => {
  companyId = localStorage.getItem("companyId");
  jobseekerId = localStorage.getItem("jobseekerId");
   return (dispatch) => {
   dispatch({type: FOLLOW_COMPANY})
   helpers.getServerUrl().then(url =>   {
   var url = url.serverUrl
   fetch(url + "/jobseeker/followCompany", {
     method: "POST",
     headers: {    
       "Content-Type": "application/json"
     },
     body: JSON.stringify({
       jobseekerId: jobseekerId,
         companyId: companyId,
       
     })
   })
     .then(response => response.json())
     .then(responseData => {
  
       dispatch ({
           type: FOLLOW_COMPANY_SUCCESS,
           payload: responseData[0].msg,
          })
     })
     .catch(err => {
       dispatch({
           type: FOLLOW_COMPANY_FAILED,
           payload: err
       })
   })
 })
}
}

export const searchedHistoryCompany = (companyId) => {
 
  companyId = localStorage.getItem("companyId");
  jobseekerId = localStorage.getItem("jobseekerId");
   return (dispatch) => {
   dispatch({type: SEARCH_HISTORY_COMPANY})
   helpers.getServerUrl().then(url =>   {
   var url = url.serverUrl
   fetch(url + "/jobseeker/searchedHistoryComps", {
     method: "POST",
     headers: {
       "Content-Type": "application/json"
     },
     body: JSON.stringify({
       jobseekerId: jobseekerId,
         companyId: companyId,
         
     })
   })
     .then(response => response.json())
     .then(responseData => {
       dispatch ({
           type: SEARCH_HISTORY_COMPANY_SUCCESS,
           payload: responseData,
          })
     })
     .catch(err => {
       dispatch({
           type: SEARCH_HISTORY_COMPANY_FAILED,
           payload: err
       })
   })
 })
}
}

export const getPostedJobs = (companyId, compShareId) => {
  shareId = localStorage.getItem("compShareId");
  companyId = localStorage.getItem("companyId");
  jobseekerId = localStorage.getItem("jobseekerId");
   return (dispatch) => {
   dispatch({type: SHOW_POSTED_JOBS})
   helpers.getServerUrl().then(url =>   {
   var url = url.serverUrl
   fetch(url + "/jobs/getAllPostedJobsByCompanyIdNew", {
     method: "POST",
     headers: {
       "Content-Type": "application/json"
     },
     body: JSON.stringify({
       jobseekerId: jobseekerId,
         companyId: companyId,
         shareId: shareId
     })
   })
     .then(response => response.json())
     .then(responseData => {
       dispatch ({
           type: SHOW_POSTED_JOBS_SUCCESS,
           payload: responseData,
          })
     })
     .catch(err => {
       dispatch({
           type: SHOW_POSTED_JOBS_FAILED,
           payload: err
       })
   })
 })
}
}

export const getCompanyReviewData = (ratingValue) => {
  companyId = localStorage.getItem("companyId");
  jobseekerId = localStorage.getItem("jobseekerId");
   return (dispatch) => {
   dispatch({type: SHOW_COMPANY_REVIEW})
   helpers.getServerUrl().then(url =>   {
   var url = url.serverUrl
   fetch(url + "/employer/getCompanyDetailsById", {
     method: "POST",
     headers: {
       "Content-Type": "application/json"
     },
     body: JSON.stringify({
      companyId: companyId,
      rating: ratingValue,
      jobseekerId: jobseekerId
         
     })
   })
     .then(response => response.json())
     .then(responseData => {
       dispatch ({
           type: SHOW_COMPANY_REVIEW_SUCCESS,
           payload: responseData,
          })
     })
     .catch(err => {
       dispatch({
           type: SHOW_COMPANY_REVIEW_FAILED,
           payload: err
       })
   })
 })
}
}



