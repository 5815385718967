import React from "react";
import Images from "../Images";
// import "./qrcode.css";
import { Link, Route } from 'react-router-dom';
import Webheader from "../components/webheader";
import QRCode from 'qrcode-react';
import helpers from "../config";
import '../_style/profile.css';
import Strings from "../utilities/stringlanguage";
import MetaTags from 'react-meta-tags';
import {
  EmailShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import Footer from "../components/footer";
var referralCode;
var url;
var imageUrl;
var selectedLang;
export default class QrCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }
  async  componentDidMount() {
    let apiUrl =  await helpers.getServerUrl()
    url = apiUrl.serverUrl
    imageUrl = apiUrl.imageUrl
    selectedLang = localStorage.getItem("selectedLang")
		if(selectedLang != null && selectedLang != undefined && selectedLang != "")
		{
		  Strings.setLanguage(selectedLang);
		}
    referralCode = localStorage.getItem("referralCode");
  }
  render() {
    referralCode = localStorage.getItem("referralCode");
     document.title = "Tell your friends to share their skills and get their dream jobs";
    // document.getElementsByTagName("META")[2].content = description;
    document.getElementsByTagName("META")[3].content = "Careers,Jobs,Job Portal,Jhatpat app,Browse Jobs, searching job sites,QR code ,Scan ,Connect ,Easily ,Apply,Best way to connect,Jobam ,job hunting,find a job."
    const shareUrl =
    "Hey! Have you tried our Jobam mobile application yet? If not, try it now! https://play.google.com/store/apps/details?id=dataflakes.jobs.jhatpatjobs.jobsearch&hl=en Its absolutely Free Fast and Secure. Use my referral code : "+referralCode+" or scan my QR code within the app to get more credits and boost your carrier opportunities now!";
  const title = "";
  const shareImage = "https://www.steadylearner.com/static/images/brand/prop-passer.png";
  const size = "2.5rem";
  var emailBody = "Hey! Have you tried our Jobam mobile application yet? If not, try it now! https://play.google.com/store/apps/details?id=dataflakes.jobs.jhatpatjobs.jobsearch&hl=en Its absolutely Free Fast and Secure. Use my referral code : "+referralCode+" or scan my QR code within the app to get more credits and boost your carrier opportunities now!";
    return (
      <div>
        <MetaTags>
          {/* for browser */}
          <title>Tell Your Friends To Share Their Skills & Get Their Dream Jobs - Jobam</title>
          {/* For Link sharing in social from url */}
          <meta property="og:title" content="Tell Your Friends To Share Their Skills & Get Their Dream Jobs - Jobam" />
          <meta property="og:url" content="https://jobam.co/qrcode"/>
          <meta property="og:image" content="../assets/logo192.png" />
          <meta property="og:site_name" content="Jobam" />
          {/* For twitter sharing from url  */}
          <meta name="twitter:title" content="Tell Your Friends To Share Their Skills & Get Their Dream Jobs - Jobam"/>
          <meta name="twitter:image" content="../assets/logo192.png"/>
          <meta name="twitter:site" content="@JhatpatJ"/>
          <meta name="twitter:creator" content="@JhatpatJ"/>
        </MetaTags>
        <div className="container col-md-6 No-Pading col-lg-6 col-sm-6 col-12">
          <Webheader pageName={Strings.myQrCode} backLink="/profile"/>
          <div className="card pt-5">
            <div className="card-header text-center qrWallCls">
              <img src={Images.qrwall} alt="Qr-wall" className="w-75" alt="" />
            </div>
            <div className="text-center col-md-12">
            <h3 className="text-dark d-none d-sm-block d-md-block d-lg-block d-xl-block text-capitalize font-weight-bold">{Strings.keepSmsQrCode}</h3>
             <p>{Strings.myQrcodeSms}</p>
            </div>
            <center className="mb-4">
              <QRCode
                value={referralCode}
                size={200}
                logo={Images.jjLogo}
                fgColor="#0DD3AF"
                bgColor="#fff"
                logoWidth={60}
                logoHeight={60}
                logobgColor="#fff"
              />
            </center>
            <div className="card-footer text-center d-md-block d-sm-block d-none">
              <div className="row">
            <div className="col-md-2 col-2 fa-1x"><u>{Strings.share}</u></div>
              <div className="col-md-8 col-8 text-center">
                    <span className="">
                            <WhatsappShareButton
                              url={shareUrl}
                              quote={title}
                              className="Demo__some-network__share-button"
                            >
                              <WhatsappIcon size={34} round />
                            </WhatsappShareButton>
                          </span>
                          <span className="ml-2 text-center shareButtons">
                            <FacebookShareButton
                              url={shareUrl}
                              quote={title}
                              className="Demo__some-network__share-button"
                              size={size}>
                              <FacebookIcon size={34} round />
                            </FacebookShareButton>
                          </span>
                          <span className="ml-2 text-center shareButtons">
                            <LinkedinShareButton
                              url={shareUrl}
                              quote={title}
                              className="Demo__some-network__share-button"
                            >
                              <LinkedinIcon size={34} round />
                            </LinkedinShareButton>
                          </span>
                          <span className="ml-2 text-center shareButtons">
                            <EmailShareButton
                              url={shareUrl}
                              subject="Download Jobam and get job opportunities"
                              className="Demo__some-network__share-button">
                              <img src={Images.gmail} width="34" alt="Gmail" className="mr-1"></img>
                            </EmailShareButton>
                          </span>
                          </div>
                    </div>
                    </div>
            <div className="card-footer text-center d-sm-block d-md-none d-lg-none d-xs-none">
              <button  data-toggle="modal" data-target="#shareModal" className="bg-success rounded-pill border-0 text-white btn-lg col-md-12 col-12">{Strings.share}</button>
            </div>
            <div className="modal fade" id="shareModal">
                  <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content ">
                      <h5 className="text-center mt-4">JhatpatJobs</h5>
                      <div className="row mt-2 col-12 col-md-12 col-sm-12">
                        <span className="col-md-1 col-1  text-center ml-5 shareButtons">
                          <WhatsappShareButton
                            url={shareUrl}
                            quote={title}
                            className="Demo__some-network__share-button"
                          >
                            <WhatsappIcon size={34} round />
                          </WhatsappShareButton>
                          
                        </span>
                        <span className="col-md-1 col-1 ml-3 text-center shareButtons">
                          <FacebookShareButton
                            url={shareUrl}
                            quote={title}
                            className="Demo__some-network__share-button"
                          >
                            <FacebookIcon size={34} round />
                          </FacebookShareButton>
                        </span>
                        <span className="col-md-1 col-1 ml-3 text-center shareButtons">
                          <LinkedinShareButton
                            url={shareUrl}
                            quote={title}
                            imageurl={imageUrl + this.state.companyImage}
                            className="Demo__some-network__share-button"
                          >
                            <LinkedinIcon size={34} round />
                          </LinkedinShareButton>
                        </span>
                        <span className="col-md-1 col-1 ml-3 text-center shareButtons">
                          <EmailShareButton
                            url={shareUrl}
                            subject="Download Jobam and get job opportunities"
                            className="Demo__some-network__share-button" >
                            <img src={Images.gmail} width="34" alt="Gmail" className="mr-1"></img>
                          </EmailShareButton>
                        </span>
                      </div>
                      <div className="modal-body mt-3">
                        <div className="text-center">
                          <button type="submit" className="btn geryColor col-md-12 col-12 buttonRounded " data-dismiss="modal">Cancel</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
             </div>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
        </div>
        <div className="container-fluid bg-light col-md-6 col-lg-6 col-sm-6 col-12 No-Pading">
          <Route component={Footer} className="d-none d-md-block d-lg-block d-sm-block d-xl-block" />
        </div>
      </div>
    );
  }
}