import helpers from "../config";
import strings from "../utilities/stringlanguage";

export const SHOW_PG_DEGREE_DATA ='SHOW_PG_DEGREE_DATA';
export const SHOW_PG_DEGREE_DATA_SUCCESS ='SHOW_PG_DEGREE_DATA_SUCCESS';
export const SHOW_PG_DEGREE_DATA_FAILED ='SHOW_PG_DEGREE_DATA_FAILED';

export const SHOW_UG_DEGREE_DATA ='SHOW_UG_DEGREE_DATA';
export const SHOW_UG_DEGREE_DATA_SUCCESS ='SHOW_UG_DEGREE_DATA_SUCCESS';
export const SHOW_UG_DEGREE_DATA_FAILED ='SHOW_UG_DEGREE_DATA_FAILED';

export const PASSING_YEAR_DATA ='PASSING_YEAR_DATA';
export const PASSING_YEAR_DATA_SUCCESS ='PASSING_YEAR_DATA_SUCCESS';
export const PASSING_YEAR_DATA_FAILED ='PASSING_YEAR_DATA_FAILED';

export const SHOW_UNIVERSITY_DATA ='SHOW_UNIVERSITY_DATA';
export const SHOW_UNIVERSITY_DATA_SUCCESS ='SHOW_UNIVERSITY_DATA_SUCCESS';
export const SHOW_UNIVERSITY_DATA_FAILED ='SHOW_UNIVERSITY_DATA_FAILED';

export const GET_ALL_EDUCATION_DATA ='GET_ALL_EDUCATION_DATA';
export const GET_ALL_EDUCATION_SUCCESS ='GET_ALL_EDUCATION_SUCCESS';
export const GET_ALL_EDUCATION_FAILED ='GET_ALL_EDUCATION_FAILED';

export const ADD_EDUCATION_DATA ='ADD_EDUCATION_DATA';
export const ADD_EDUCATION_DATA_SUCCESS ='ADD_EDUCATION_DATA_SUCCESS';
export const ADD_EDUCATION_DATA_FAILED ='ADD_EDUCATION_DATA_FAILED';

var jobseekerId; 

 export const getPgDegree = () => {
    return (dispatch) => {
    dispatch({type: SHOW_PG_DEGREE_DATA})
    helpers.getServerUrl().then(url =>   {
      var url = url.serverUrl
    fetch(url +"/jobseeker/getDegress?degreeType=PG", {
      method: "GET",
      })
      .then(response => response.json())
      .then(responseData => {
       dispatch ({
            type: SHOW_PG_DEGREE_DATA_SUCCESS,
            payload: responseData.degreeList,
           })
      })
      .catch(err => {
        dispatch({
            type: SHOW_PG_DEGREE_DATA_FAILED,
            payload: err
        })
        console.log('error', err)
    })
    })
 }
    
}

export const getUgDegree = () => {
  return (dispatch) => {
  dispatch({type: SHOW_UG_DEGREE_DATA})
  helpers.getServerUrl().then(url =>   {
    var url = url.serverUrl
  fetch(url +"/jobseeker/getDegress?degreeType=UG", {
    method: "GET",
    })
    .then(response => response.json())
    .then(responseData => {
     dispatch ({
          type: SHOW_UG_DEGREE_DATA_SUCCESS,
          payload: responseData.degreeList,
         })
    })
    .catch(err => {
      dispatch({
          type: SHOW_UG_DEGREE_DATA_FAILED,
          payload: err
      })
      console.log('error', err)
  })
  })
}
  
}

export const getPassingYearData = () => {
return (dispatch) => {
dispatch({type: PASSING_YEAR_DATA})
helpers.getServerUrl().then(url =>   {
  var url = url.serverUrl
 fetch(url +"/jobseeker/getYears", {
  method: "GET",
  })
  .then(response => response.json())
  .then(responseData => {
   dispatch ({
        type: PASSING_YEAR_DATA_SUCCESS,
        payload: responseData.years,
       })
  })
  .catch(err => {
    dispatch({
        type: PASSING_YEAR_DATA_FAILED,
        payload: err
    })
    console.log('error', err)
})
})
}

}
  
export const getUniversityData = () => {
return (dispatch) => {
dispatch({type: SHOW_UNIVERSITY_DATA})
helpers.getServerUrl().then(url =>   {
  var url = url.serverUrl
 fetch(url +"/jobseeker/getAllUniversities", {
  method: "GET",
  })
  .then(response => response.json())
  .then(responseData => {
   dispatch ({
        type: SHOW_UNIVERSITY_DATA_SUCCESS,
        payload: responseData,
       })
  })
  .catch(err => {
    dispatch({
        type: SHOW_UNIVERSITY_DATA_FAILED,
        payload: err
    })
    console.log('error', err)
})
})
}

}

export const getEducationByJobseekerID = () => {
jobseekerId = localStorage.getItem("jobseekerId");
 return (dispatch) => {
 dispatch({type: GET_ALL_EDUCATION_DATA})
 helpers.getServerUrl().then(url =>   {
   var url = url.serverUrl
  
 fetch(url +"/jobseeker/getJobseekerEducationById", {
   method: "POST",
   headers: {
     "Content-Type": "application/json"
   },
   body: JSON.stringify({
     jobseekerId: jobseekerId,
   })
 })
   .then(response => response.json())
   .then(responseData => {
    dispatch ({
         type: GET_ALL_EDUCATION_SUCCESS,
         payload: responseData,
        })
   })
   .catch(err => {
     dispatch({
         type: GET_ALL_EDUCATION_FAILED,
         payload: err
     })
     console.log('error', err)
 })
 })
}
 
}




