import helpers from "../config";

export const GET_FRESHER = "GET_FRESHER";
export const GET_FRESHER_DATA = "GET_FRESHER_DATA";
export const GET_FRESHER_FAIL = "GET_FRESHER_FAIL";


var jobseekerId;
  export const setFresherData = (skills,profileSummary) => {
    jobseekerId = localStorage.getItem("jobseekerId");
      return (dispatch) => {
       dispatch({type: GET_FRESHER})
       helpers.getServerUrl().then(url =>   {
        var url = url.serverUrl
       fetch( url + "/jobseeker/addExperienceByJobseeker", {
        method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            jobseekerId: jobseekerId,
            skills: skills,
            profileSummary: profileSummary,
            organizationName: null,
            jobPosition: null,
            experience: "Fresher",
            experienceLevel: null,
            userCurrentLocation: null,
            professionalSummary: null,
            jobIndustry: null,
            jobStartDate: null,
            jobEndDate: null,
            currentlyWorking: null
          })
        })
      .then(response => response.json())
      .then(responseData => {
        dispatch ({
            type: GET_FRESHER_DATA,
            payload: responseData
        })
      })
      .catch(err => {
        dispatch({
            type: GET_FRESHER_FAIL,
            payload: err
        })
    })
  })
 }
}

