import * as actionTypes from "../actions/mapJobsAction";
const initialState= {
    cityData: [],
    cityDataLoading: false,
    cityDataError: null,
    mapData:[],
    mapDataLoading:false,
    mapDataError:null,
};
const mapJobs = (state = initialState, action) => {
    switch ( action.type ) {
        case actionTypes.CITY_DATA_LOADING: 
            return{...state, cityDataLoading: true, cityDataError: null};
        case actionTypes.SHOW_CITY_DATA: 
            return{...state, cityDataLoading: false, cityData: action.payload, cityDataError: null};
        case actionTypes.CITY_LOAD_FAIL: 
            return{...state, cityDataLoading: false, cityData: [], cityDataError: action.payload};

        case actionTypes.MAP_DATA_LOADING: 
            return{...state, mapDataLoading: true, mapDataError: null};
        case actionTypes.SHOW_MAP_DATA: 
            return{...state, mapDataLoading: false, mapData: action.payload, mapDataError: null};
        case actionTypes.MAP_LOAD_FAIL: 
            return{...state, mapDataLoading: false, mapData: [], mapDataError: action.payload};
        default: 
            return state;
    }  
};
 
export default mapJobs;




