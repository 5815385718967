import helpers from "../config";

export const MYJOBS_DATA_LOADING = "MYJOBS_DATA_LOADING";
export const SHOW_MYJOBS_DATA = "SHOW_MYJOBS_DATA";
export const MYJOBS_LOAD_FAIL = "MYJOBS_LOAD_FAIL";

export const SAVEJOBS_DATA_LOADING = "SAVEJOBS_DATA_LOADING";
export const SHOW_SAVEJOBS_DATA = "SHOW_SAVEJOBS_DATA";
export const SAVEJOBS_LOAD_FAIL = "SAVEJOBS_LOAD_FAIL";

export const jobseekerVerify_DATA_LOADING = "jobseekerVerify_DATA_LOADING";
export const SHOW_jobseekerVerify_DATA = "SHOW_jobseekerVerify_DATA";
export const jobseekerVerify_LOAD_FAIL = "jobseekerVerify_LOAD_FAIL";

var jobseekerId;
var tokenId;
var isUserLoggedIn;
var status;
  export const getJobs = (jobStatus) => {
    jobseekerId = localStorage.getItem("jobseekerId");
      return (dispatch) => {
       dispatch({type: MYJOBS_DATA_LOADING})
       helpers.getServerUrl().then(url =>   {
        var url = url.serverUrl
       fetch( url+"/jobseeker/getUserById2", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          jobseekerId: jobseekerId,
          isShortlisted: jobStatus,
          loginFrom: "Web"
        })
      })
      .then(response => response.json())
      .then(response => {
        dispatch ({
            type: SHOW_MYJOBS_DATA,
            payload: response[0]
        })
      })
      .catch(err => {
        dispatch({
            type: MYJOBS_LOAD_FAIL,
            payload: err
        })
    })
 
})
}
}


export const savedJobFun = (item) => {
    status = localStorage.getItem("status");
    if (status == 'All') {
          var jobStatus = '';
        } else {
          var jobStatus = status;
        }
    jobseekerId = localStorage.getItem("jobseekerId");
      return (dispatch) => {
       dispatch({type: SAVEJOBS_DATA_LOADING})
       helpers.getServerUrl().then(url =>   {
        var url = url.serverUrl
       fetch( url+"/jobs/addJobsToFavourites", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          jobseekerId: jobseekerId,
          jobId: item.jobId,
          isSaved: item.isSaved,
        })
      })
      .then(response => response.json())
      .then(response => {
        dispatch({
          type: SHOW_SAVEJOBS_DATA,
          payload: response.success
      });
        return dispatch(getJobs(jobStatus));
      })
      .catch(err => {
        dispatch({
            type: SAVEJOBS_LOAD_FAIL,
            payload: err
        })
    })
  })
 }
}

export const jobseekerVerify = () => {
      isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
      jobseekerId = localStorage.getItem("jobseekerId");
      tokenId = localStorage.getItem("tokenId");
        return (dispatch) => {
         dispatch({type: jobseekerVerify_DATA_LOADING})
         helpers.getServerUrl().then(url =>   {
          var url = url.serverUrl
         fetch( url+"/admin/getAppSetDetails1", {
          method: "POST",
          headers: {  
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            jobseekerId: jobseekerId,
            tokenId:tokenId,
            loginFrom: "Web"
          })
        })
        .then(response => response.json())
        .then(response => {
          dispatch ({
              type: SHOW_jobseekerVerify_DATA,
              payload: response
          })
        })
        .catch(err => {
          dispatch({
              type: jobseekerVerify_LOAD_FAIL,
              payload: err
          })
      })
    })
   }
  }

