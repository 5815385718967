import React, { Component } from "react";
import helpers from "../config";
import Strings from "../utilities/stringlanguage";
import stringlanguage from "../utilities/stringlanguage";
import Images from '../Images';
import LoadingOverlay from 'react-loading-overlay';
import Footer from "../components/footer";
import Webheader from "../components/webheader";
import "../_style/changeLanguage.css";
var jobseekerId;
var url;
var imageUrl;

export default class SelectLanguage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            selectedIndex: 0,
            lang: [
                { lang: "en", Img: Images.engLang, fImg: Images.engWhite },
                { lang: "hi", Img: Images.hindiLang, fImg: Images.hindiWhite },
                { lang: "mr", Img: Images.marathiLang, fImg: Images.marathiWhite }
            ]
        }
    }
    async componentDidMount() {
        var selectedLang;
        await helpers.getServerUrl().then(data => {
            url = data.serverUrl
            imageUrl = data.imageUrl
        });
        jobseekerId = localStorage.getItem("jobseekerId");
        selectedLang = localStorage.getItem("selectedLang")
        // console.log("selected language==" + JSON.stringify(selectedLang))
        if (selectedLang == "hi") {
            Strings.setLanguage(selectedLang);
            this.setState({ selectedIndex: 1, selectedLang: selectedLang, loading: false })
        } else if (selectedLang == "mr") {
            Strings.setLanguage(selectedLang);
            this.setState({ selectedIndex: 2, selectedLang: selectedLang, loading: false })
        } else {
            this.setState({ selectedIndex: 0, selectedLang: selectedLang, loading: false })
        }
    }

    onLangClick(lang, index) {
        // stringlanguage.setLanguage(lang);
        this.setState({ selectedIndex: index, selectedLang: lang })
    }

    onSelectLanguage() {
        localStorage.setItem("selectedLang", this.state.selectedLang)
        stringlanguage.setLanguage(this.state.selectedLang);
        fetch(url + "/jobseeker/updateLanguage", {
            method: "POST", headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                jobseekerId: jobseekerId,
                selectedLang: this.state.selectedLang
            })
        })
            .then(response => response.json())
            .then(responseData => {
                if (responseData.success) {
                    this.props.history.push('/updateprofile');
                }
            });
    }  
    backClicked = () => {
        this.props.history.go(-2);
      }
    render() {
        return (
            <LoadingOverlay
                active={this.state.loading}
                spinner={
                    <div className="loading-search">
                    <center className=" bg-dark1 rounded mt-5 col-md-6 col-sm-6 p-2 col-12">
                      <h4 className="loadingText pt-1  mb-1 d-sm-block d-md-block">
                        {Strings.loadingLanguage}
                    </h4>
                      <h6 className="text-white pt-1 pb-1 d-block d-sm-none d-md-none"></h6>
                      <img
                        src={Images.loaderWhite}
                        alt="img"
                        className="pb-2" 
                        width="30"
                      />
                    </center>
                  </div>
                }
            >
                <div className="container col-md-6 col-sm-6 col-12">
                    <Webheader pageName={Strings.ChangeLanguage} handleBack={() =>this.backClicked()}/>
                    <div className="mt-4 justify-content-center">
                        <center>
                            <img
                                src={Images.logo}
                                alt="logo"
                                width="225"
                                 className="mt-2"
                            />
                        </center>
                        <center>
                            <div className="mt-1 col-md-6 col-lg-6 col-sm-8 col-8 roundedField p-2">
                                <h5 className="text-dark ">{Strings.appDisplay}</h5>
                                <span className="text-secondary mt-2">{Strings.pleaseSelect}</span>
                                <div className="row" >
                                    {this.state.lang.map((item, index) =>
                                        <div key={index} className=" mt-3 pointer col-md-4 col-4 col-sm-4" onClick={() => this.onLangClick(item.lang, index)}>
                                            <img className="img-fluid" height='120' alt="Language" width='120' src={this.state.selectedIndex != index
                                                ? item.fImg
                                                : item.Img}>
                                            </img>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </center>
                    </div>
                    <center>
                        <div
                            onClick={() => this.onSelectLanguage()}
                            type="button"
                            className="btn fixed-bottom-next btn-success rounded-pill text-uppercase col-md-5 col-9 col-sm-9"
                        >
                            {Strings.nextBtn}
                              </div>
                    </center>
                </div>

            </LoadingOverlay>
        );
    }
}